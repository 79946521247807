import React, { useState } from 'react';
import { Container, Button, Modal, Image } from 'react-bootstrap';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import social from '../../../apis/social';
import RemoveEmployeePng from '../images/delete-user.png';
import { UserAttributes } from '../../../apis/attributes/user';

export const RemoveEmployee: React.FC<{
  employeeId: string;
  userId: string;
}> = ({ employeeId, userId }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteEmployee = async () => social.service('employee').remove(employeeId);

  const [employeeInUser] = useObservable<UserAttributes | null>(
    () => social.service('user').watch().get(userId) as any,
    [userId],
  );
  return (
    <Container className='p-0 d-flex'>
      <Button variant='outline-light' onClick={handleShow}>
        <Image src={RemoveEmployeePng} alt='' width='26px' height='26px' />
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='contained-modal-title-hcenter'>
            {t('DELETING_EMPLOYEE') as string}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-4'>
          {t('QUESTION_CONT_DEL') as string}

          <input
            className='w-100 mt-3'
            onChange={(e) => {
              setEmail(e.currentTarget.value.toLowerCase());
            }}
            type='email'
            placeholder='email@email.email'
          />
        </Modal.Body>
        <Modal.Footer className='d-flex justif-content-center'>
          <Button
            variant='outline-dark'
            disabled={email !== employeeInUser?.email}
            onClick={async () => {
              await deleteEmployee();
              handleClose();
            }}
          >
            {t('REMOVE') as string}
          </Button>
          <Button className='px-5' variant='primary' onClick={handleClose}>
            {t('CANCEL') as string}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RemoveEmployee;
