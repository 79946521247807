import jsPDF from 'jspdf';
import { ITranslationLabel } from '../model/TranslationLabel';
import { IReportHeader } from '../model/ReportHeader';
import logoKidigBase54Data from './logoKidigBase64Data';

export default function generateHeaderReport(doc: jsPDF,
  headerData: IReportHeader,
  translateObj: ITranslationLabel): number {
  const {
    nameAdministration,
    firstNameEmployee,
    lastNameEmployee,
    numberWorkdaysInPeriod,
    printDate,
    specialVacationDays,
    specialVacationDaysLeft,
    vacationDays,
    vacationDaysLeft,
    regenerationDays,
    regenerationDaysLeft,
    conversionDays,
    conversionDaysLeft,
    period,
  } = headerData;
  const employeeName = `${firstNameEmployee} ${lastNameEmployee}`.trim();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;

  doc.setFontSize(17);

  doc.setFillColor(5, 206, 145);
  doc.rect(0, 0, 450, 40, 'F');

  doc.setFillColor(208, 255, 241);
  doc.rect(0, 40, 450, 70, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.setFont('Nunito-Bold');
  doc.text(`${translateObj.REPORT_MONTH_OVERVIEW}: ${period}`, pageWidth / 2, 22, { align: 'center' });

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Bold');
  doc.text(`${translateObj.REPORT_ADMINISTRATOR}: ${nameAdministration}`, 320, 15, { align: 'left' });

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Bold');
  doc.text(`${translateObj.REPORT_NAME_EMPLOYEE}: ${employeeName}`, 320, 30, { align: 'left' });

  doc.setFontSize(14);
  doc.addImage(logoKidigBase54Data,
    'PNG', 15, 10, 60, 25);

  // =============================================================================
  // HEADER DATA =================================================================
  // COL 1
  // header: Period
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');

  // doc.text(`${translateObj.REPORT_PERIOD}:`, 10, 60, { align: 'left' });

  // doc.setFillColor(5, 206, 145);
  // doc.rect(130 - doc.getTextWidth(period) - 5, 60 - doc.getLineHeight() /
  // doc.internal.scaleFactor, doc.getTextWidth(period) + 10, 10, 'F');

  // doc.setTextColor(255, 255, 255);
  // doc.setFillColor(208, 255, 241);
  // doc.setFontSize(9);
  // doc.setFont('Nunito-Regular');
  // doc.text(period, 130, 60, { align: 'right' });

  // header: Vacation days
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS}:`, 10, 55, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 - doc.getTextWidth(vacationDays.toString()) - 5, 55 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(vacationDays.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(vacationDays.toString(), 130, 55, { align: 'right' });

  // header: Spezial vacation days
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_SPECIAL}:`, 10, 70, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 - doc.getTextWidth(specialVacationDays.toString()) - 5, 70 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(specialVacationDays.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(specialVacationDays.toString(), 130, 70, { align: 'right' });

  // header: Regeneration Days
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_REGENERATION}:`, 10, 85, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 - doc.getTextWidth(regenerationDays.toString()) - 5, 85 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(regenerationDays.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(regenerationDays.toString(), 130, 85, { align: 'right' });

  // header: Conversion Days
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_CONVERSION}:`, 10, 100, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 - doc.getTextWidth(conversionDays.toString()) - 5, 100 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(conversionDays.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(conversionDays.toString(), 130, 100, { align: 'right' });

  // COL 2
  // header: Vacation days left
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_LEFT}:`, 10 + 150, 55, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 + 150 - doc.getTextWidth(vacationDaysLeft.toString()) - 5, 55 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(vacationDaysLeft.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(vacationDaysLeft.toString(), 130 + 150, 55, { align: 'right' });

  // header: Spezial vacation days left
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_SPECIAL_LEFT}:`, 10 + 150, 70, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 + 150 - doc.getTextWidth(specialVacationDaysLeft.toString()) - 5, 70 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(specialVacationDaysLeft.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(specialVacationDaysLeft.toString(), 130 + 150, 70, { align: 'right' });

  // header: Regeneration vacation days left
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_REGENERATION_LEFT}:`, 10 + 150, 85, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 + 150 - doc.getTextWidth(regenerationDaysLeft.toString()) - 5, 85 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(regenerationDaysLeft.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(regenerationDaysLeft.toString(), 130 + 150, 85, { align: 'right' });

  // header: Conversion vacation days left
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_VACATION_DAYS_CONVERSION_LEFT}:`, 10 + 150, 100, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 + 150 - doc.getTextWidth(conversionDaysLeft.toString()) - 5, 100 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(conversionDaysLeft.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(conversionDaysLeft.toString(), 130 + 150, 100, { align: 'right' });

  // COL 3
  // header: Number workdays in this month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_NUMBER_WORKDAYS_IN_THIS_MONTH}:`, 10 + 150 + 150, 85, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 + 150 + 150 - doc.getTextWidth(numberWorkdaysInPeriod.toString()) - 5, 85 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(numberWorkdaysInPeriod.toString()) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(numberWorkdaysInPeriod.toString(), 130 + 150 + 150, 85, { align: 'right' });

  // header: Print Date:
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(`${translateObj.REPORT_Print_Date}:`, 10 + 150 + 150, 100, { align: 'left' });

  doc.setFillColor(5, 206, 145);
  doc.rect(130 + 150 + 150 - doc.getTextWidth(printDate) - 5, 100 - doc.getLineHeight() / doc.internal.scaleFactor, doc.getTextWidth(printDate) + 10, 10, 'F');

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(printDate, 130 + 150 + 150, 100, { align: 'right' });
  return 120;
}
