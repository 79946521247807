import {TimeRange} from "./timeRange";
import {DateTime} from "luxon";
import {WEEKDAY} from "./enums";
import moment from "moment";
import {DayAttributes} from "../apis/attributes";

/**
 * Creates a sorted and non-overlapping array of intervals from an array of intervals.
 * @param {TimeRange[]} timeRanges - Array of intervals.
 * @param onlyDay
 * @returns {TimeRange[]} - Returns a sorted and non-overlapping array of intervals.
 */
function buildTimeRangeCollection(timeRanges: TimeRange[], onlyDay: boolean = false): TimeRange[] {

    const timeRangeList: TimeRange[] = [];

    for (const timeRange of timeRanges) {
        if (onlyDay) {
            insertTimeRangeOnDayOnly(timeRangeList, timeRange);
        } else {
            insertTimeRange(timeRangeList, timeRange);
        }
    }

    return timeRangeList;
}

/**
 * Inserts an interval into an interval list.
 * @param {TimeRange[]} timeRangeList - List of intervals.
 * @param {TimeRange} timeRange - Interval to be inserted.
 */
function insertTimeRange(timeRangeList: TimeRange[], timeRange: TimeRange): void {

    // If list is empty
    if (timeRangeList.length === 0) {
        timeRangeList.push(timeRange);
        return;
    }

    for (let i = 0; i < timeRangeList.length; i++) {
        const currentRange = timeRangeList[i];

        // New range is before the current range
        if (timeRange.end <= currentRange.start) {
            timeRangeList.splice(i, 0, timeRange);
            return;
        }

        // New range overlaps with the current range
        if ((timeRange.start <= currentRange.start && currentRange.start <= timeRange.end) ||
            (currentRange.start <= timeRange.start && timeRange.start <= currentRange.end)) {
            throw new Error("Given event lies inside already taken interval.");
        }

        // New range fully contains the current range
        if (timeRange.start <= currentRange.start && timeRange.end >= currentRange.end) {
            throw new Error("Given event lies inside already taken interval.");
        }

        // Special case: end of the list
        if (i === timeRangeList.length - 1 && timeRange.start >= currentRange.end) {
            timeRangeList.push(timeRange);
            return;
        }
    }
}

function insertTimeRangeOnDayOnly(timeRangeList: TimeRange[], timeRange: TimeRange): void {
    // If list is empty
    if (timeRangeList.length === 0) {
        timeRangeList.push(timeRange);
        return;
    }

    for (let i = 0; i < timeRangeList.length; i++) {
        const currentRange = timeRangeList[i];

        // New range is before the current range
        if (getDateWithoutTime(timeRange.end) < getDateWithoutTime(currentRange.start)) {
            timeRangeList.splice(i, 0, timeRange);
            return;
        }

        // New range overlaps with the current range or shares a boundary
        if ((getDateWithoutTime(timeRange.start) <= getDateWithoutTime(currentRange.start) && getDateWithoutTime(currentRange.start) <= getDateWithoutTime(timeRange.end)) ||
            (getDateWithoutTime(currentRange.start) <= getDateWithoutTime(timeRange.start) && getDateWithoutTime(timeRange.start) <= getDateWithoutTime(currentRange.end))) {
            throw new Error("Given event lies inside or shares a boundary with an already taken interval.");
        }

        // Special case: end of the list
        if (i === timeRangeList.length - 1 && getDateWithoutTime(timeRange.start) > getDateWithoutTime(currentRange.end)) {
            timeRangeList.push(timeRange);
            return;
        }
    }
}

/**
 * Returns a DateTime object without time information.
 * @param dateTime - DateTime object.
 * @returns {DateTime} - DateTime object without time information (time set to 00:00:00).
 */
function getDateWithoutTime(dateTime: DateTime): DateTime {
    return dateTime.startOf('day');
}

/**
 * Converts a numeric day of week index to a day of week.
 * @param n - Numeric day of week index.
 * @returns {WEEKDAY} - Day of week.
 */
export function numericDayOfWeekIndexToDayOfWeek(n: number): WEEKDAY {
    switch (n) {
        case 0:
            return WEEKDAY.SUNDAY;
        case 1:
            return WEEKDAY.MONDAY;
        case 2:
            return WEEKDAY.TUESDAY;
        case 3:
            return WEEKDAY.WEDNESDAY;
        case 4:
            return WEEKDAY.THURSDAY;
        case 5:
            return WEEKDAY.FRIDAY;
        case 6:
            return WEEKDAY.SATURDAY;
        default:
            throw new Error(`Invalid number ${n} for day of week.`);
    }
}

// TODO: Make this more generic, comment all the things below
export interface DBDateKey {
    dayOfWeek: number;
    weekOfYear: number;
    monthOfYear: number;
    year: number;
}

interface DateKeyTuple {
    key: DBDateKey;
    date: DateTime;
}

function translateDateToDBDateKey(date: Date): DBDateKey {
    const moDate = moment(date);

    const dayOfWeek = moDate.day(); // Day of the week (0 for Sunday to 6 for Saturday)
    const weekOfYear = moDate.isoWeek(); // ISO week of the year
    const monthOfYear = moDate.month(); // Month of the year (0 for January to 11 for December)
    const year = moDate.year(); // Year

    return { dayOfWeek, weekOfYear, monthOfYear, year };
}

// make this into a map
function generateDateKeyMapForYear(year: number): DateKeyTuple[] {
    const dateKeyMap = [];

    // Start from the first day of the year
    const currentDate = moment().year(year).startOf('year');

    // Iterate over each day of the year
    while (currentDate.year() === year) {
        const dbDateKey = translateDateToDBDateKey(currentDate.toDate());
        dateKeyMap.push({ key: dbDateKey, date: DateTime.fromJSDate(currentDate.toDate()) });

        // Move to the next day
        currentDate.add(1, 'day');
    }

    return dateKeyMap;
}

function dbKeyFromDayAttributes(attributes: DayAttributes): DBDateKey {
    const dayOfWeek = attributes.dayOfWeek;
    const weekOfYear = attributes.weekOfMonth;
    const monthOfYear = attributes.monthOfYear;
    const year = attributes.year;

    return { dayOfWeek, weekOfYear, monthOfYear, year };
}

// Debugging in findDateForKey
function findDateForKey(keyMap: DateKeyTuple[], key: DBDateKey) {
    const matchingEntry = keyMap.find(entry => {
        return entry.key.dayOfWeek === key.dayOfWeek &&
            entry.key.weekOfYear === key.weekOfYear &&
            entry.key.monthOfYear === key.monthOfYear &&
            entry.key.year === key.year;
    });

    return matchingEntry ? matchingEntry.date : null;
}

// Assuming flattenedValues is an array of DayAttributes
function updateDatesInFlattenedValues(flattenedValues: DayAttributes[], keyMap: DateKeyTuple[]): DayAttributes[] {
    return flattenedValues.map(item => {
        const key: DBDateKey = {
            dayOfWeek: item.dayOfWeek,
            weekOfYear: item.weekOfMonth,
            monthOfYear: item.monthOfYear,
            year: item.year
        };

        const foundDate = findDateForKey(keyMap, key);
        return {
            ...item,
            date: foundDate // Update the date property
        };
    });
}




export { insertTimeRange, buildTimeRangeCollection, generateDateKeyMapForYear, translateDateToDBDateKey, dbKeyFromDayAttributes, updateDatesInFlattenedValues, insertTimeRangeOnDayOnly };