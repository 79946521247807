import { DateTime, Duration } from "luxon";

/**
 * TimeRange interface represents a time range with a start and end date.
 * @interface TimeRange
 * @property {DateTime} start - Start date of the time range.
 * @property {DateTime} end - End date of the time range.
 * @property {function} compareTo - Compares this time range to another time range.
 * @property {function} length - Returns the length of the time range.
 */
export interface TimeRange {
    start: DateTime;
    end: DateTime;
    compareTo(other: TimeRange): number;
    length(): Duration;
}

/**
 * Compares two time ranges.
 * @param {TimeRange} a - First time range.
 * @param {TimeRange} b - Second time range.
 * @returns {number} -1 if a is before b, 1 if a is after b, 0 if a overlaps b.
 */
export function compareTimeRanges(a: TimeRange, b: TimeRange): number {
    if (a.end <= b.start) return -1;
    if (a.start >= b.end) return 1;
    return 0;
}

/**
 * Returns the length of a time range.
 * @param {TimeRange} tr - Time range.
 * @returns {Duration} Length of the time range.
 */
export function timeRangeLength(tr: TimeRange): Duration {
    return tr.end.diff(tr.start);
}