import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TimeReport from '../../utility/TimeReport';
import TranslationLabel, { ITranslationLabel } from './model/TranslationLabel';
import generateMonthReportDetailed from './MonthReportDetailed';
import MonthReportOverview from './MonthReportOverview';

import './style.css';

const defaultSelected = {
  reportDetailed: false,
  reportOverview: true,
};

const MonthReport = ({ timeReport }: { timeReport: TimeReport }) => {
  const { t, i18n } = useTranslation();
  const [reportOverview, setReportOverview] = useState(defaultSelected.reportOverview);
  const [reportDetailed, setReportDetailed] = useState(defaultSelected.reportDetailed);
  const [translateObj, setTranslateObj]: [
    ITranslationLabel,
    React.Dispatch<React.SetStateAction<ITranslationLabel>>,
  ] = useState(new TranslationLabel());

  useEffect(() => {
    const tempTranslate: ITranslationLabel = {
      REPORT_ACTUAL: t('REPORT_ACTUAL'),
      REPORT_ACTUAL_MONTH: t('REPORT_ACTUAL_MONTH'),
      REPORT_ADMINISTRATOR: t('REPORT_ADMINISTRATOR'),
      REPORT_BALANCE: t('REPORT_BALANCE'),
      REPORT_BALANCE_1_TOTAL_THIS_MONTH: t('REPORT_BALANCE_1_TOTAL_THIS_MONTH'),
      REPORT_BALANCE_2_TOTAL_LAST_MONTH: t('REPORT_BALANCE_2_TOTAL_LAST_MONTH'),
      REPORT_BALANCE_2_TOTAL_THIS_MONTH: t('REPORT_BALANCE_2_TOTAL_THIS_MONTH'),
      REPORT_CHILD: t('REPORT_CHILD'),
      REPORT_CHILD_ACTUAL: t('REPORT_CHILD_ACTUAL'),
      REPORT_CHILD_BALANCE: t('REPORT_CHILD_BALANCE'),
      REPORT_CHILD_TARGET: t('REPORT_CHILD_TARGET'),
      REPORT_CONTRACT_DAY: t('REPORT_CONTRACT_DAY'),
      REPORT_CONTRACT_HOURS: t('REPORT_CONTRACT_HOURS'),
      REPORT_DATE: t('REPORT_DATE'),
      REPORT_DATE_NAME: t('REPORT_DATE_NAME'),
      REPORT_DAY: t('REPORT_DAY'),
      REPORT_DETAILED_HEADER: t('REPORT_DETAILED_HEADER'),
      REPORT_DISPOSAL: t('REPORT_DISPOSAL'),
      REPORT_DISPOSAL_ACTUAL: t('REPORT_DISPOSAL_ACTUAL'),
      REPORT_DISPOSAL_BALANCE: t('REPORT_DISPOSAL_BALANCE'),
      REPORT_DISPOSAL_TARGET: t('REPORT_DISPOSAL_TARGET'),
      REPORT_EMPLOYEE_NAME: t('REPORT_EMPLOYEE_NAME'),
      REPORT_END: t('REPORT_END'),
      REPORT_FACILITY_DAYTYPE: t('REPORT_FACILITY_DAYTYPE'),
      REPORT_INTERVALS: t('REPORT_INTERVALS'),
      REPORT_IRREGULAR_DISPOSAL: t('REPORT_IRREGULAR_DISPOSAL'),
      REPORT_IRREGULAR_DISPOSAL_ACTUAL: t('REPORT_IRREGULAR_DISPOSAL_ACTUAL'),
      REPORT_IRREGULAR_DISPOSAL_BALANCE: t('REPORT_IRREGULAR_DISPOSAL_BALANCE'),
      REPORT_IRREGULAR_DISPOSAL_TARGET: t('REPORT_IRREGULAR_DISPOSAL_TARGET'),
      REPORT_KEYWORD: t('REPORT_KEYWORD'),
      REPORT_KEYWORDS_LIST: t('REPORT_KEYWORDS_LIST'),
      REPORT_LAST_CHANGE_ON: t('REPORT_LAST_CHANGE_ON'),
      REPORT_MONTH_OVERVIEW: t('REPORT_MONTH_OVERVIEW'),
      REPORT_NAME_EMPLOYEE: t('REPORT_NAME_EMPLOYEE'),
      REPORT_NUMBER_WORKDAYS_IN_THIS_MONTH: t('REPORT_NUMBER_WORKDAYS_IN_THIS_MONTH'),
      REPORT_OVERVIEW_HEADER: t('REPORT_OVERVIEW_HEADER'),
      REPORT_PAUSE: t('REPORT_PAUSE'),
      REPORT_PAUSE_TOTAL: t('REPORT_PAUSE_TOTAL'),
      REPORT_PAYED_OUT_HOURS: t('REPORT_PAYED_OUT_HOURS'),
      REPORT_PERIOD: t('REPORT_PERIOD'),
      REPORT_Print_Date: t('REPORT_Print_Date'),
      REPORT_RECEIVED_HOURS: t('REPORT_RECEIVED_HOURS'),
      REPORT_SICK_DAYS_MONAT: t('REPORT_SICK_DAYS_MONAT'),
      REPORT_SIGNATURE: t('REPORT_SIGNATURE'),
      REPORT_SORT_FRIDAY: t('REPORT_SORT_FRIDAY'),
      REPORT_SORT_MONDAY: t('REPORT_SORT_MONDAY'),
      REPORT_SORT_SATURDAY: t('REPORT_SORT_SATURDAY'),
      REPORT_SORT_SUNDAY: t('REPORT_SORT_SUNDAY'),
      REPORT_SORT_THURSDAY: t('REPORT_SORT_THURSDAY'),
      REPORT_SORT_TUESDAY: t('REPORT_SORT_TUESDAY'),
      REPORT_SORT_WEDNESDAY: t('REPORT_SORT_WEDNESDAY'),
      REPORT_START: t('REPORT_START'),
      REPORT_TAKEN_SPECIAL_VACATION_DAYS_A_MONTH: t('REPORT_TAKEN_SPECIAL_VACATION_DAYS_A_MONTH'),
      REPORT_TARGET: t('REPORT_TARGET'),
      REPORT_TARGET_TOTAL_MONTH: t('REPORT_TARGET_TOTAL_MONTH'),
      REPORT_TOTAL_ACTUAL: t('REPORT_TOTAL_ACTUAL'),
      REPORT_TOTAL_ACTUAL_DAY: t('REPORT_TOTAL_ACTUAL_DAY'),
      REPORT_TOTAL_BALANCE: t('REPORT_TOTAL_BALANCE'),
      REPORT_TOTAL_BALANCE_DAY: t('REPORT_TOTAL_BALANCE_DAY'),
      REPORT_TOTAL_TARGET: t('REPORT_TOTAL_TARGET'),
      REPORT_TOTAL_TARGET_DAY: t('REPORT_TOTAL_TARGET_DAY'),
      REPORT_UNTIL: t('REPORT_UNTIL'),
      REPORT_VACATION_DAYS: t('REPORT_VACATION_DAYS'),
      REPORT_VACATION_DAYS_A_MONTH: t('REPORT_VACATION_DAYS_A_MONTH'),
      REPORT_VACATION_DAYS_CONVERSION: t('REPORT_VACATION_DAYS_CONVERSION'),
      REPORT_VACATION_DAYS_CONVERSION_LEFT: t('REPORT_VACATION_DAYS_CONVERSION_LEFT'),
      REPORT_VACATION_DAYS_CONVERSION_TAKEN_IN_PERIOD: t(
        'REPORT_VACATION_DAYS_CONVERSION_TAKEN_IN_PERIOD',
      ),
      REPORT_VACATION_DAYS_LEFT: t('REPORT_VACATION_DAYS_LEFT'),
      REPORT_VACATION_DAYS_REGENERATION: t('REPORT_VACATION_DAYS_REGENERATION'),
      REPORT_VACATION_DAYS_REGENERATION_LEFT: t('REPORT_VACATION_DAYS_REGENERATION_LEFT'),
      REPORT_VACATION_DAYS_REGENERATION_TAKEN_IN_PERIOD: t(
        'REPORT_VACATION_DAYS_REGENERATION_TAKEN_IN_PERIOD',
      ),
      REPORT_VACATION_DAYS_SPECIAL: t('REPORT_VACATION_DAYS_SPECIAL'),
      REPORT_VACATION_DAYS_SPECIAL_LEFT: t('REPORT_VACATION_DAYS_SPECIAL_LEFT'),
      REPORT_VACATION_DAYS_SPECIAL_TAKEN_IN_PERIOD: t(
        'REPORT_VACATION_DAYS_SPECIAL_TAKEN_IN_PERIOD',
      ),
      REPORT_VACATION_DAYS_TAKEN_IN_PERIOD: t('REPORT_VACATION_DAYS_TAKEN_IN_PERIOD'),
      REPORT_WEEK: t('REPORT_WEEK'),
      REPORT_WEEKLY_TIME_TOTAL: t('REPORT_WEEKLY_TIME_TOTAL'),
      REPORT_WEEKLY_WORKING_DAY: t('REPORT_WEEKLY_WORKING_DAY'),
      REPORT_WORKING_DAY_BALANCE_TIME: t('REPORT_WORKING_DAY_BALANCE_TIME'),
    };
    setTranslateObj(tempTranslate);
  }, [t, i18n.language]);

  const toggleReportOverview = useCallback(() => {
    setReportOverview(!reportOverview);
  }, [reportOverview]);

  const toggleReportDetailed = useCallback(() => {
    setReportDetailed(!reportDetailed);
  }, [reportDetailed]);

  const createReport = () => {
    if (reportDetailed) {
      generateMonthReportDetailed(timeReport, i18n.language, translateObj);
    }
    if (reportOverview) {
      MonthReportOverview(timeReport, i18n.language, translateObj);
    }
  };

  return (
    <div className='month-reports'>
      <Row>
        <Col xs={12}>
          <div className='month-report-header'>{t('MONTH_STATS_REPORT_HEADER') as string}</div>
        </Col>
        <Col xs={6}>
          <Form style={{ display: 'inline-flex', float: 'right' }}>
            <Form.Label className='report-label' onClick={toggleReportOverview}>
              {t('MONTH_STATS_REPORT_OVERVIEW_CHECK') as string}
            </Form.Label>
            <label className='report-checkbox'>
              <input type='checkbox' checked={reportOverview} onChange={toggleReportOverview} />
              <span className='checkmark' onChange={toggleReportOverview} />
            </label>
          </Form>
        </Col>
        <Col xs={6}>
          <Form style={{ display: 'inline-flex', float: 'left' }}>
            <Form.Label className='report-label' onClick={toggleReportDetailed}>
              {t('MONTH_STATS_REPORT_DETAILED') as string}
            </Form.Label>
            <label className='report-checkbox'>
              <input type='checkbox' checked={reportDetailed} onChange={toggleReportDetailed} />
              <span className='checkmark' onChange={toggleReportDetailed} />
            </label>
          </Form>
        </Col>
        <Col xs={12} className='report-button-container'>
          <Button variant='success' className='btn-report' onClick={createReport}>
            {t('MONTH_STATS_REPORT_BUTTON') as string}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

MonthReport.defaultPops = {};

export default MonthReport;
