import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, Container, Button, Form } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router';
import moment from 'moment';
import usePromise from 'react-promise';
import { useObservable } from 'react-use-observable';
import { TariffAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import 'flatpickr/dist/themes/material_green.css';
import { useTranslation } from 'react-i18next';
import TariffTimeSection from '../../TimeInterval/containers/TariffTimeSection';
import { TariffTimeInterval } from '../../TimeInterval/containers/Availability';
import InfoModal from '../../Facility/containers/InfoModal';

type GroupIntervals = [
  {
    dayType: 'National holiday';
    enabled: boolean;
    freeIntervals: TariffTimeInterval[];
  },
  {
    dayType: 'Official work day';
    enabled: boolean;
    freeIntervals: TariffTimeInterval[];
  },
];

const blankIntervals = [
  {
    dayType: 'National holiday',
    enabled: true,
    freeIntervals: [
      {
        endDate: undefined,
        startDate: undefined,
      },
    ],
  },
  {
    dayType: 'Official work day',
    enabled: true,
    freeIntervals: [
      {
        endDate: undefined,
        startDate: undefined,
      },
    ],
  },
];

const processDateStringsToDate = (data: GroupIntervals | null | undefined) => {
  if (!data) {
    return blankIntervals;
  }
  return data.map((group) => ({
    dayType: group?.dayType,
    enabled: group?.enabled,
    freeIntervals: group?.freeIntervals?.map((interval) => ({
      endDate: !interval?.endDate ? undefined : moment(interval?.endDate)?.toDate(),
      id: interval?.id,
      percentage: interval.percentage,
      startDate: !interval?.startDate ? undefined : moment(interval?.startDate).toDate(),
    })),
  }));
};

export const TimeAddition: React.FC<{ tariffId: string }> = ({ tariffId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const formCheck = useRef<any>();
  const formCheck24 = useRef<any>();
  const formCheck31 = useRef<any>();
  const [groupIntervals, setgroupIntervals] = useState<GroupIntervals | undefined>(undefined);

  const { value } = usePromise(authenticatedFacility());
  const [tariff] = useObservable<TariffAttributes | null>(
    () => social.service('tariff').watch().get(tariffId) as any,
    [value],
  );

  useEffect(() => {
    social
      .service('tariff')
      .get(tariffId)
      .then((tariffIntervals) =>
        setgroupIntervals(processDateStringsToDate(tariffIntervals?.groupIntervals) as any),
      )
      .catch(() => {});
  }, [tariffId]);

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  const updateDB = useCallback(async () => {
    try {
      if (!tariffId) return;
      await social.service('tariff').patch(tariffId, {
        dec24NotWorking: formCheck24?.current?.checked,
        dec31NotWorking: formCheck31?.current?.checked,
        finalSubmission: true,
        groupIntervals,
        startDate: tariff?.startDate,
        timeAdditionActive: formCheck?.current?.checked,
      });
      navigateTo('/admin/employees-overview');
    } catch (error) {
      alert('Error: please reload the page');
    }
  }, [tariffId, groupIntervals, formCheck, formCheck24, formCheck31, tariff]);

  return (
    <Container className='pb-5'>
      <p className='mt-4 mb-3 mb-1'>
        <strong>{t('DEC_24_31') as string}</strong>{' '}
        <InfoModal content={t('DEC_24_31_MODAL') as string} />{' '}
      </p>

      <Row>
        <Col>
          {tariff && (
            <Form.Check
              type='checkbox'
              id='custom-checkbox-24'
              label={t('DEC_24_NOT_WORKING') as string}
              ref={formCheck24}
              defaultChecked={tariff?.dec24NotWorking ?? true}
            />
          )}
        </Col>
        <Col>
          {tariff && (
            <Form.Check
              type='checkbox'
              id='custom-checkbox-31'
              label={t('DEC_31_NOT_WORKING') as string}
              ref={formCheck31}
              defaultChecked={tariff?.dec31NotWorking ?? true}
            />
          )}
        </Col>
      </Row>
      <p className='mt-4 mb-3 mb-1'>
        <strong>
          {t('TIME_ADDITION') as string}
          <InfoModal content={t('TIME_ADDITIONS_INFO') as string} />
        </strong>
      </p>

      <Row>
        <Col xs={9}>
          <p>{t('TIME_ADDITION_ACTIVE') as string} </p>
        </Col>

        <Col>
          {tariff && (
            <Form.Check
              type='switch'
              id='custom-switch'
              ref={formCheck}
              defaultChecked={tariff?.timeAdditionActive ?? false}
            />
          )}
        </Col>
      </Row>

      {groupIntervals?.map((group, idx) => (
        <>
          <TariffTimeSection
            key={`${group.dayType.toString()}10`}
            dayStr={group.dayType}
            timeIntervals={group.freeIntervals}
            enabled={group.enabled}
            onSubmit={(intervals, enabled) => {
              const groupIntervalsArray = groupIntervals;
              groupIntervalsArray[idx].freeIntervals = intervals;
              groupIntervalsArray[idx].enabled = enabled;
              setgroupIntervals(groupIntervalsArray);
            }}
          />
        </>
      ))}
      {/* <p className="mt-4">Official work day</p> */}

      <div style={{ height: '50px' }} />
      {/* <Link to={`/admin/tariffs/pause/${tariffId}`}> */}
      <Button onClick={updateDB} block className='mt-5 py-2'>
        {t('SAVE') as string}
      </Button>
      {/* </Link> */}
    </Container>
  );
};

export default TimeAddition;
