import {DateTime, Duration} from "luxon";
import { WorkdayDefiner } from "./workdayDefiner";
import { TimeRange, timeRangeLength, compareTimeRanges } from "./timeRange";
import {dateToWeekday} from "./enums";
import {EmploymentAttributes} from "../apis/attributes";
import {getFloatFromHoursMinutesSeconds} from "../utility/time";
import {createDurationFromHours, parseWorkdayDefiner} from "./workdayDefiner";

/**
 * Employment class represents an employment with its properties and methods to calculate various durations
 */
export class Employment implements TimeRange {

    constructor(
        public start: DateTime,
        public end: DateTime = DateTime.fromObject({ year: 2100, month: 1, day: 1 }),
        public careWorkingPerWeek: Duration = Duration.fromObject({ hours: 32 }),
        public disposalRegularPerWeek: Duration = Duration.fromObject({ hours: 6 }),
        public disposalIrregularPerWeek: Duration = Duration.fromObject({ hours: 1 }),
        public limitHoursPerDay: Duration = Duration.fromMillis(Number.MAX_SAFE_INTEGER),
        public limitHoursPerMonth: Duration = Duration.fromMillis(Number.MAX_SAFE_INTEGER),
        public workDayDefiners: WorkdayDefiner[] = [new WorkdayDefiner()],
    ) {
        if (workDayDefiners.length === 0) {
            throw new Error("WorkDayDefiners must not be empty.");
        }
        if (end < start) {
            throw new Error(`end argument smaller start argument: !${end} < ${start}`);
        }
    }

    compareTo(other: Employment): number {
        return compareTimeRanges(this, other);
    }

    length(): Duration {
        return timeRangeLength(this);
    }

    getAverageNumberOfWorkDays(): number {
        return this.workDayDefiners.map((d) => d.getNumberOfWorkDays()).reduce((a, b) => a + b, 0) / this.workDayDefiners.length;
    }

    getCareTimeDaily(): Duration {
        return Duration.fromMillis(this.careWorkingPerWeek.toMillis() / this.getAverageNumberOfWorkDays());
    }

    getDisposalTimeTotalDaily(): Duration {
        return Duration.fromMillis(this.disposalRegularPerWeek.plus(this.disposalIrregularPerWeek).toMillis() / this.getAverageNumberOfWorkDays());
    }

    getDisposalTimeRegularDaily(): Duration {
        return Duration.fromMillis(this.disposalRegularPerWeek.toMillis() / this.getAverageNumberOfWorkDays());
    }

    getDisposalTimeIrregularDaily(): Duration {
        return Duration.fromMillis(this.disposalIrregularPerWeek.toMillis() / this.getAverageNumberOfWorkDays());
    }

    getTargetTimeTotalDaily(): Duration {
        return Duration.fromMillis(this.careWorkingPerWeek
            .plus(this.disposalRegularPerWeek)
            .plus(this.disposalIrregularPerWeek).toMillis() / this.getAverageNumberOfWorkDays());
    }

    /**
     * Calculates the week number since the start of the employment.
     * @param date The date for which to calculate the week number.
     * @return The week number.
     */
    private calculateWeekNumber(date: DateTime): number {
        const weeks = this.start.until(date).count('weeks');
        return Math.floor(weeks);
    }

    /**
     * Determines if a given date is a working day.
     * @param date The date to check.
     * @return true if the given date is a working day, false otherwise.
     */
    isWorkingDay(date: DateTime): boolean {
        const weekNumber = this.calculateWeekNumber(date);
        const definerIndex = weekNumber % this.workDayDefiners.length;
        const workdayDefiner = this.workDayDefiners[definerIndex];

        // Convert the date to a WEEKDAY and check if it's a working day
        const weekday = dateToWeekday(date);
        return workdayDefiner.isWorkingDay(weekday);
    }

    /**
     * Parses an employment from the given attributes (e.g. from the database).
     * @param attributes - Employment attributes (e.g. from the database).
     * @returns {Employment} - Returns a new Employment.
     */
    static fromAttributes(attributes: EmploymentAttributes): Employment {
        return parseEmploymentAttributes(attributes);
    }
}

/**
 * Parses the attributes of an employment.
 * @param employmentAttributes - Employment attributes (e.g. from the database).
 * @returns {Employment} - Returns a new Employment.
 */
export function parseEmploymentAttributes(employmentAttributes: EmploymentAttributes): Employment {
    const start = DateTime.fromISO(employmentAttributes.start);
    const end = DateTime.fromISO(employmentAttributes.end);

    const workDayDefiners = employmentAttributes.multiWorkDayDefiner.map((definer) => parseWorkdayDefiner(definer.workDayDefiner));

    return new Employment(
        start,
        end,
        createDurationFromHours(employmentAttributes.careWorkingPerWeek),
        createDurationFromHours(employmentAttributes.disposalRegularPerWeek),
        createDurationFromHours(employmentAttributes.disposalIrregularPerWeek),
        createDurationFromHours(getFloatFromHoursMinutesSeconds(employmentAttributes.limitHoursPerDay)),
        createDurationFromHours(employmentAttributes.limitHoursPerMonth),
        employmentAttributes.multiWorkDayDefiner.map((definer) => parseWorkdayDefiner(definer.workDayDefiner)),
    );
}