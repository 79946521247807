import React, { useState } from 'react';
import { Container, Button, Modal, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import social from '../../../apis/social';
import RemoveContract from '../images/delete-contract.png';

export const RemoveEmpContract: React.FC<{
  employmentId: string;
  disabled: boolean;
}> = ({ employmentId, disabled }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteEmployment = async () => social.service('employment').remove(employmentId);

  return (
    <Container className='p-0'>
      <Button disabled={disabled} variant='outline-light' onClick={handleShow}>
        <Image src={RemoveContract} alt='' width='26px' height='26px' />
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='contained-modal-title-hcenter'>
            {' '}
            {t('DELETING_EMP_CONTRACT') as string}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-4'>{t('QUESTION_EMP_DEL') as string}</Modal.Body>
        <Modal.Footer className='d-flex justif-content-center'>
          <Button
            variant='outline-dark'
            onClick={async () => {
              await Promise.all([deleteEmployment()]);
              handleClose();
            }}
          >
            {t('REMOVE') as string}
          </Button>
          <Button className='px-5' variant='primary' onClick={handleClose}>
            {t('CANCEL') as string}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RemoveEmpContract;
