import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import usePromise from 'react-promise';
import { generatePath, useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import { FacilityAttributes } from '../../../apis/attributes';
import { social, containsUndefined, authenticatedFacility } from '../../../apis/social';

export const OrgName: React.FC = observer(() => {
  const { t } = useTranslation();
  const [localNameOfOrg, setLocalNameOfOrg] = useState<string | undefined>(undefined);
  const [localNumberGroups, setLocalNumberGroups] = useState<number | undefined>(0);

  const history = useHistory();

  const { value } = usePromise(authenticatedFacility());
  const [facility] = useObservable<FacilityAttributes | null>(
    () =>
      social
        .service('facility')
        .watch()
        .get((value as any)?.user?.facility?.id as any) as any,
    [value],
  );

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  const updateDB = async () => {
    try {
      if (!facility) return;

      await social.service('facility').patch(facility?.id, {
        name: localNameOfOrg,
        numberGroups: localNumberGroups,
      });
      navigateTo('/admin/onboarding/submit');
    } catch (error) {
      alert('Error: please reload the page');
    }
  };

  return (
    <Container className='pt-5 px-4 pb-4'>
      <strong className='creatorTitle colorBodyText mt-2'>{t('NAME_ORG') as string}</strong>

      <input
        className='mt-2 w-100 '
        placeholder=''
        defaultValue={facility?.name || ''}
        onChange={(e) => setLocalNameOfOrg(e.currentTarget.value)}
      />
      <br />

      <p className='mt-5 mb-1'>
        <strong>{t('DEFINE_GROUP_NUMBER') as string}</strong>
      </p>
      {facility && (
        <input
          className='mt-2 w-100'
          placeholder=''
          defaultValue={facility?.numberGroups ?? 0}
          type='number'
          min={1}
          max={700}
          onChange={(e) => setLocalNumberGroups(parseInt(e.currentTarget.value, 10))}
        />
      )}

      {localNumberGroups && localNumberGroups <= 0 ? (
        <p className='text-danger'>{t('NUMB_EMP_GREATER') as string}</p>
      ) : (
        <></>
      )}

      <div style={{ height: '250px' }} />
      <Button
        className='py-2 px-3 w-100'
        variant='primary'
        disabled={!(localNameOfOrg || !containsUndefined({ a: facility?.name }))}
        onClick={() => updateDB()}
      >
        {t('SAVE') as string}
      </Button>
    </Container>
  );
});

export default OrgName;
