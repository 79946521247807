import TimeSummary from './TimeSummary';
import TimeTriplet from './TimeTriplet';
import WeekReport from './WeekReport';

class TimeReport extends TimeSummary {
  startDate: string;

  endDate: string;

  nameAdministration: string;

  nameFacility: string;

  firstNameEmployee: string;

  lastNameEmployee: string;

  statementOfCopyRight: string;

  versionNumber: string;

  printDate: string;

  paidOutHoursTotal: string;

  receivedHoursTotal: string;

  numberWorkdaysInPeriod: number;

  vacationDays: number;

  vacationDaysTakenInPeriod: number;

  vacationDaysLeft: number;

  specialVacationDays: number;

  specialVacationDaysTakenInPeriod: number;

  specialVacationDaysLeft: number;

  regenerationDays: number;

  regenerationDaysTakenInPeriod: number;

  regenerationDaysLeft: number;

  conversionDays: number;

  conversionDaysTakenInPeriod: number;

  conversionDaysLeft: number;

  sickDays: number;

  workTimeCompensationDays: number;

  weeks: WeekReport[];

  dependentBalanceTotal: string;

  dependentBalanceTotalLastMonth: string;

  constructor(
    startDate: string,
    endDate: string,
    nameAdministration: string,
    nameFacility: string,
    firstNameEmployee: string,
    lastNameEmployee: string,
    statementOfCopyRight: string,
    versionNumber: string,
    printDate: string,
    paidOutHoursTotal: string,
    receivedHoursTotal: string,
    numberWorkdaysInPeriod: number,
    vacationDays: number,
    vacationDaysTakenInPeriod: number,
    vacationDaysLeft: number,
    specialVacationDays: number,
    specialVacationDaysTakenInPeriod: number,
    specialVacationDaysLeft: number,
    regenerationDays: number,
    regenerationDaysTakenInPeriod: number,
    regenerationDaysLeft: number,
    conversionDays: number,
    conversionDaysTakenInPeriod: number,
    conversionDaysLeft: number,
    sickDays: number,
    workTimeCompensationDays: number,
    weeks: WeekReport[],
    dependentBalanceTotal: string,
    dependentBalanceTotalLastMonth: string,
    targetTimeDetailed: TimeTriplet,
    actualTimeDetailed: TimeTriplet,
    balanceDetailed: TimeTriplet,
    targetTimeTotal: string,
    actualTimeTotal: string,
    balanceTotal: string,
  ) {
    super(
      targetTimeDetailed,
      actualTimeDetailed,
      balanceDetailed,
      targetTimeTotal,
      actualTimeTotal,
      balanceTotal,
    );
    this.startDate = startDate;
    this.endDate = endDate;
    this.nameAdministration = nameAdministration;
    this.nameFacility = nameFacility;
    this.firstNameEmployee = firstNameEmployee;
    this.lastNameEmployee = lastNameEmployee;
    this.statementOfCopyRight = statementOfCopyRight;
    this.versionNumber = versionNumber;
    this.printDate = printDate;
    this.paidOutHoursTotal = paidOutHoursTotal;
    this.receivedHoursTotal = receivedHoursTotal;
    this.numberWorkdaysInPeriod = numberWorkdaysInPeriod;

    this.vacationDays = vacationDays;
    this.vacationDaysTakenInPeriod = vacationDaysTakenInPeriod;
    this.vacationDaysLeft = vacationDaysLeft;

    this.specialVacationDays = specialVacationDays;
    this.specialVacationDaysTakenInPeriod = specialVacationDaysTakenInPeriod;
    this.specialVacationDaysLeft = specialVacationDaysLeft;

    this.regenerationDays = regenerationDays;
    this.regenerationDaysTakenInPeriod = regenerationDaysTakenInPeriod;
    this.regenerationDaysLeft = regenerationDaysLeft;

    this.conversionDays = conversionDays;
    this.conversionDaysTakenInPeriod = conversionDaysTakenInPeriod;
    this.conversionDaysLeft = conversionDaysLeft;

    this.sickDays = sickDays;
    this.workTimeCompensationDays = workTimeCompensationDays;
    this.weeks = weeks;
    this.dependentBalanceTotal = dependentBalanceTotal;
    this.dependentBalanceTotalLastMonth = dependentBalanceTotalLastMonth;
  }
}

export default TimeReport;
