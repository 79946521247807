import React, { useCallback, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router';
import social from '../../../apis/social';

export const RecoverPwdConfirm: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resetToken = query.get('reset');

  const [newPwd, setNewPwd] = useState('');
  const [confPwd, setConfPwd] = useState('');

  const history = useHistory();

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  const requestPwdReset = async (password: string) =>
    await (social.service('user') as any).create({
      jwt: resetToken,
      password,
      shouldResetPwd: true,
    });

  return (
    <div
      style={{
        backgroundColor: '#f2fcff',
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: 100,
      }}
    >
      <Container style={{ paddingTop: '80px' }}>
        <h4 className='text-center'>{t('RESET_PASSWORD') as string}</h4>

        <p className='text-center mb-3'>{t('ENTER_NEW_PASSWORD') as string}</p>

        <br />
        <Form.Group className='mt-5 pt-3' controlId='formBasicEmail'>
          <Form.Label>{t('NEW_PASSWORD') as string}</Form.Label> <br />
          <input
            className='w-100'
            onChange={(e) => {
              setNewPwd(e.currentTarget.value);
            }}
            type='password'
            placeholder=''
          />
          {newPwd.length < 5 && (
            <p className='mb-3 text-danger'> {t('PASSWORD_CHECK') as string} </p>
          )}
          <Form.Label>{t('CONFIRM_PASSWORD') as string}</Form.Label> <br />
          <input
            className='w-100'
            onChange={(e) => {
              setConfPwd(e.currentTarget.value);
            }}
            type='password'
            placeholder=''
          />
          {confPwd !== newPwd && (
            <p className='mb-3 text-danger'> {t('PASSWORD_DONT_MATCH') as string} </p>
          )}
        </Form.Group>

        <div style={{ height: '100px' }} />
        <Button
          disabled={newPwd.length < 5 || confPwd !== newPwd}
          type='submit'
          className='py-2 w-100 mt-5'
          onClick={async () => {
            try {
              localStorage.clear();
              const res = await requestPwdReset(confPwd);
              localStorage.clear();
              if (res?.role === 'ADMIN') {
                navigateTo('/');
              } else {
                navigateTo('/employee/login');
              }
            } catch (error) {
              alert('Request invalid, please request a new password reset link');
            }
          }}
        >
          {t('SIGN_IN') as string}
        </Button>
      </Container>
    </div>
  );
};

export default RecoverPwdConfirm;
