import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './Plans.css';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { useObservable } from 'react-use-observable';
import social, { authenticatedFacility } from '../../../apis/social';
import { FacilityAttributes } from '../../../apis/attributes';
import PlanConfirmModal from './PlanConfirmModal';

export enum PLAN_CTA {
  ACTIVATE = 'ACTIVATE',
  CANCEL = 'CANCEL',
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
}

export enum SUBSCRIPTION_STATUS {
  NONE = 'NONE', // default
  ACTIVE = 'ACTIVE',
}

export type KIDIG_PLAN = {
  id: number;
  title: string;
  description: string;
  features: string[];
  priceCents: number;
  planCTA: PLAN_CTA;
  priceId: string;
  employeeCount: number;
};

const findColors = (planCTA: PLAN_CTA) => {
  if (planCTA === PLAN_CTA.ACTIVATE) return 'primary';
  if (planCTA === PLAN_CTA.CANCEL) return 'outline-danger';
  if (planCTA === PLAN_CTA.UPGRADE) return 'primary';
  if (planCTA === PLAN_CTA.DOWNGRADE) return 'outline-warning';
  return '';
};

export const Plans: React.FC = () => {
  const { t } = useTranslation();
  const { value } = usePromise(authenticatedFacility());

  const [shownPlan, setShownPlan] = useState<KIDIG_PLAN | undefined>();

  // handlers for payment confirmation modal
  const [confirmShow, setConfirmShow] = useState(false);
  const handleConfirmPlanClose = () => setConfirmShow(false);
  const handleConfirmPlanShow = () => setConfirmShow(true);

  const [facility] = useObservable<FacilityAttributes | null>(
    () =>
      social
        .service('facility')
        .watch()
        .get((value as any)?.user?.facility?.id as any) as any,
    [value],
  );

  const resumeSubscription = async () => {
    const payload = {
      resumeSubscription: true,
    };
    try {
      await social.service('facility').get((value as any)?.user?.facility?.id, { query: payload });
      // reload page
      window.location.reload();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  return (
    <Container
      fluid
      id='pricing'
      className='our_pricing section-padding'
      style={{ minHeight: '100vh' }}
    >
      <div className='section-title text-center'>
        <h1 className='section-title-white'>{t('PRICING_PLAN') as string}</h1>
        <p className='section-title-white'>{t('PRICING_DESC') as string}</p>
      </div>
      {(facility?.subscriptionStatusDesc ?? '').length > 1 && (
        <p>
          {t(facility?.subscriptionStatusDesc ?? '') as string}{' '}
          {facility?.subscriptionStatusDesc.toLowerCase().includes('cancel') && (
            <Button
              style={{ paddingBottom: '10px', paddingTop: '6px' }}
              variant='link'
              onClick={() => resumeSubscription()}
            >
              {t('RESUME_SUBSCRIPTION') as string}
            </Button>
          )}
          {facility?.subscriptionStatusDesc.toLowerCase().includes('downgrade') && (
            <Button
              style={{ paddingBottom: '10px', paddingTop: '6px' }}
              variant='link'
              onClick={() => resumeSubscription()}
            >
              {t('DOWNGRADE_REVERT') as string}
            </Button>
          )}
        </p>
      )}
      <Row>
        {/* <!-- END COL  --> */}
        {(facility as any)?.plans?.map((plan: KIDIG_PLAN, planIdx: number) => (
          <Col key={plan.id.toString()} xs={12} md={6} lg={3}>
            <div className='pricingTable pricingTable-2 rounded'>
              <div className='pricingTable-header'>
                <h3 className='title'>{t(plan.title)}</h3>
                <h5 className='price-month'>{t('YEARLY') as string}</h5>
                <h1 className='price-value'>
                  {(plan?.priceCents ?? 0) / 100}
                  <span className='value-bg'>{(plan?.priceCents ?? 0) / 100}€</span>
                </h1>
              </div>
              <ul className='pricing-content'>
                {plan.features?.map((feature: string, idx: number) => (
                  <li key={feature}>{t(`${planIdx}_FEATURE_${idx + 1}`)}</li>
                ))}
              </ul>
              <Button
                className='btn-lg '
                style={{ borderRadius: '15px' }}
                variant={findColors(plan.planCTA)}
                onClick={() => {
                  setShownPlan(plan);
                  setTimeout(() => {
                    handleConfirmPlanShow();
                  }, 500);
                }}
              >
                {t(plan.planCTA)}
              </Button>
            </div>
          </Col>
        ))}
      </Row>

      {shownPlan && (
        <PlanConfirmModal
          show={confirmShow}
          handleClose={handleConfirmPlanClose}
          shownPlan={shownPlan}
        />
      )}
    </Container>
  );
};

export default Plans;
