import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './INTL_ENG.json';
import translationGER from './INTL_GER.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ger: {
    translation: translationGER,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    keySeparator: false,

    lng: localStorage.getItem('lan') ?? 'de',

    resources,
  });

export default i18n;
