import moment from 'moment';
import React, { useCallback } from 'react';
import { Container, Button } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { EmploymentAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import MultiWorkdayDefiner from './MultiWorkdayDefiner';

export const daysStrings = (t: any) => [
  t('Sunday'.toUpperCase()),
  t('Monday'.toUpperCase()),
  t('Tuesday'.toUpperCase()),
  t('Wednesday'.toUpperCase()),
  t('Thursday'.toUpperCase()),
  t('Friday'.toUpperCase()),
  t('Saturday'.toUpperCase()),
];

export const EmploymentContract: React.FC<{
  employmentId: string;
}> = ({ employmentId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { value } = usePromise(authenticatedFacility());

  const [employment] = useObservable<EmploymentAttributes | null>(
    () => social.service('employment').watch().get(employmentId) as any,
    [employmentId],
  );

  const [prevEmployment] = useObservable<any | null>(
    () =>
      social
        .service('employment')
        .watch()
        .find({
          query: {
            $limit: 1,
            $sort: { createdAt: -1 },
            employeeId: employment?.employeeId,
            facilityId: (value as any)?.user?.facility?.id,
            finalSubmission: true,
            id: {
              $ne: employment?.id,
            },
          },
        }) as any,
    [employment?.employeeId, employment?.id, value, (value as any)?.user],
  );

  const navigateToTariff = useCallback(
    (pathName: string) => {
      history.push({
        pathname: generatePath(`${pathName}/:employmentId`, {
          employmentId,
        }),
      });
    },
    [employmentId],
  );

  const updateDB = useCallback(
    async (data: {
      start: string;
      end: string;
      multiWorkDayDefiner: {
        id: string;
        desc: string;
        workDayDefiner: any[];
      }[];
    }) => {
      try {
        await social.service('employment').patch(employmentId, {
          ...data,
        });
        navigateToTariff('/admin/create-employee-contract/weekly-hours');
      } catch (error) {
        alert('Error: please reload the page');
      }
    },
    [employmentId, employment],
  );

  const displayDate = (time: string | undefined) => {
    if (!time) return undefined;
    return moment(time).format('YYYY-MM-DD');
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          end: employment?.end ?? '',
          multiWorkDayDefiner: employment?.multiWorkDayDefiner ?? [],
          start: employment?.start ?? '',
        }}
        validationSchema={Yup.object({
          end: Yup.string().required(t('REQUIRED')),
          start: Yup.string().required(t('REQUIRED')),
          workDayDefiner: Yup.array().length(1, t('REQUIRED')),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            updateDB(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {(props: FormikValues) => (
          <Form>
            {employment && (
              <>
                <h4 className='mt-4 mb-5'>{t('EMPLOYMENT_CONTRACT_SETTINGS') as string}</h4>

                <p className='mb-1 mt-3'>
                  {' '}
                  <strong>{t('START_DATE') as string}</strong>{' '}
                </p>
                <input
                  name='start'
                  className='w-100 '
                  type='date'
                  min={`${
                    prevEmployment?.data?.length && prevEmployment?.data[0]?.end
                      ? moment(prevEmployment?.data[0]?.end)
                          .add(1, 'day')
                          .format('YYYY-MM-DD')
                      : ''
                  }`}
                  defaultValue={displayDate(employment?.start)}
                  onChange={props.handleChange}
                />
                {props?.errors?.start && (
                  <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                    {' '}
                    {props?.errors?.start}{' '}
                  </p>
                )}

                <p className='mb-1 mt-3'>
                  {' '}
                  <strong>{t('END_DATE') as string}</strong>{' '}
                </p>
                <input
                  name='end'
                  className='w-100 mb-2'
                  type='date'
                  min={`${
                    prevEmployment?.data?.length && prevEmployment?.data[0]?.end
                      ? moment(prevEmployment?.data[0]?.end)
                          .add(2, 'day')
                          .format('YYYY-MM-DD')
                      : ''
                  }`}
                  defaultValue={displayDate(employment?.end)}
                  onChange={props.handleChange}
                />
                {props?.errors?.end && (
                  <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                    {' '}
                    {props?.errors?.end}{' '}
                  </p>
                )}
              </>
            )}

            {employment && (
              <MultiWorkdayDefiner
                multiWorkDayDefiner={
                  employment?.multiWorkDayDefiner?.length
                    ? employment?.multiWorkDayDefiner
                    : undefined
                }
                latestResult={(res) => {
                  props.setFieldValue('multiWorkDayDefiner', res);
                }}
              />
            )}

            {props?.errors?.multiWorkDayDefiner && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.multiWorkDayDefiner}{' '}
              </p>
            )}

            <div style={{ height: '100px' }} />
            <Button type='submit' className='py-2 px-3 w-100 mb-4' variant='primary'>
              {t('SAVE') as string}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EmploymentContract;
