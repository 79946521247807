import React, { useCallback } from 'react';
import { Button, Col, Image, ProgressBar, Row } from 'react-bootstrap';
import usePromise from 'react-promise';
import { Link, useHistory, generatePath, useParams } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { uuid } from 'uuidv4';
import moment from 'moment';
import { EmployeeAttributes, FacilityAttributes, TariffAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import LeftArrow from '../images/leftArrow.svg';

export type TopBarAttributes = {
  navigateBackTo?: string;
  postId?: string;
  title: string;
  progress: number;
  addBtnPath?: string;
};

export const customIntervals = () => [
  {
    dayType: 'NIGHT',
    enabled: true,
    freeIntervals: [
      {
        endDate: moment(new Date(1970, 0, 0, 6, 0, 0)).toISOString(),
        id: uuid(),
        percentage: 0.2,
        startDate: moment(new Date(1970, 0, 0, 0, 0, 0)).toISOString(),
      },
      {
        endDate: moment(new Date(1970, 0, 0, 23, 59, 59)).toISOString(),
        id: uuid(),
        percentage: 0.2,
        startDate: moment(new Date(1970, 0, 0, 21, 0, 0)).toISOString(),
      },
    ],
  },
  {
    dayType: 'SATURDAY',
    enabled: true,
    freeIntervals: [
      {
        endDate: moment(new Date(1970, 0, 0, 21, 0, 0)).toISOString(),
        id: uuid(),
        percentage: 0.2,
        startDate: moment(new Date(1970, 0, 0, 13, 0, 0)).toISOString(),
      },
    ],
  },
  {
    dayType: 'SUNDAY',
    enabled: true,
    freeIntervals: [
      {
        endDate: moment(new Date(1970, 0, 0, 23, 59, 59)).toISOString(),
        id: uuid(),
        percentage: 0.25,
        startDate: moment(new Date(1970, 0, 0, 0, 0, 0)).toISOString(),
      },
    ],
  },
  {
    dayType: 'FEAST',
    enabled: true,
    freeIntervals: [
      {
        endDate: moment(new Date(1970, 0, 0, 23, 59, 59)).toISOString(),
        id: uuid(),
        percentage: 0.35,
        startDate: moment(new Date(1970, 0, 0, 0, 0, 0)).toISOString(),
      },
    ],
  },
  {
    dayType: 'DEC_24',
    enabled: true,
    freeIntervals: [
      {
        endDate: moment(new Date(1970, 0, 0, 23, 59, 59)).toISOString(),
        id: uuid(),
        percentage: 0.35,
        startDate: moment(new Date(1970, 0, 0, 6, 0, 0)).toISOString(),
      },
    ],
  },
  {
    dayType: 'DEC_31',
    enabled: true,
    freeIntervals: [
      {
        endDate: moment(new Date(1970, 0, 0, 23, 59, 59)).toISOString(),
        id: uuid(),
        percentage: 0.35,
        startDate: moment(new Date(1970, 0, 0, 6, 0, 0)).toISOString(),
      },
    ],
  },
];

export const TopBar: React.FC<TopBarAttributes> = ({
  navigateBackTo,
  postId,
  children,
  title,
  progress,
  addBtnPath,
}) => {
  const history = useHistory();

  // url could have employeeId useParams
  const { employeeId } = useParams<{ employeeId?: string }>();

  const { value } = usePromise(authenticatedFacility());
  const [facility] = useObservable<FacilityAttributes | null>(
    () =>
      social
        .service('facility')
        .watch()
        .get((value as any)?.user?.facility?.id as any) as any,
    [value],
  );

  const navigateTo = () => {
    if (!navigateBackTo) return '';
    // if url contains employeeId then replace it
    let processedPath = navigateBackTo;

    if (processedPath.includes('employeeId')) {
      processedPath = processedPath.replace('employeeId', employeeId ?? '');
    }

    if (postId) return `${processedPath}/${postId}`;
    return processedPath;
  };

  const navigateToEmployees = useCallback(
    async (pathName: string) => {
      const employeeObj: EmployeeAttributes = (await social
        .service('employee')
        .create({ facilityId: facility?.id })) as any;
      history.push({
        pathname: generatePath(`${pathName}/:employeeId`, {
          employeeId: employeeObj?.id,
        }),
      });
    },
    [facility?.id],
  );

  const navigateToTariffs = useCallback(
    async (pathName: string) => {
      const tariffObj: TariffAttributes = (await social.service('tariff').create({
        facilityId: facility?.id,
        groupIntervals: customIntervals(),
      })) as any;

      history.push({
        pathname: generatePath(`${pathName}/:tariffId`, {
          tariffId: tariffObj?.id,
        }),
      });
    },
    [facility?.id],
  );

  return (
    <>
      <div
        className='d-flex justify-content-center'
        style={{
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: 1000,
        }}
      >
        <Row className='d-flex justify-content-start bg-light ' style={{ width: '100%' }}>
          <Col xs={1} className='d-flex justify-content-end align-items-center pl-4 ml-3'>
            {navigateBackTo && (
              <Link to={navigateTo()}>
                <Image className='' src={LeftArrow} style={{ height: '22px', width: '22px' }} />
              </Link>
            )}
            {!navigateBackTo && (
              <Button variant='link' onClick={() => history?.goBack()}>
                <Image className='' src={LeftArrow} style={{ height: '22px', width: '22px' }} />
              </Button>
            )}
          </Col>

          <Col xs={8} className='pl-0 d-flex align-items-center '>
            <p className='mt-3 bodyText colorTextTitles'>{title}</p>
          </Col>

          {addBtnPath && (
            <Col className='pl-0 d-flex align-items-center '>
              <Button
                variant='link'
                onClick={
                  addBtnPath?.includes('create-employee')
                    ? () => navigateToEmployees(addBtnPath)
                    : () => navigateToTariffs(addBtnPath)
                }
              >
                <p className='py-0 my-0 ml-4'>
                  <strong>
                    {' '}
                    <h1 className='py-0 my-0'>+</h1>
                  </strong>
                </p>
              </Button>
            </Col>
          )}

          <Col xs={12} className='px-0 mx-0'>
            <ProgressBar className='mx-0 px-0' now={progress} style={{ height: '2px' }} />
          </Col>
        </Row>
      </div>
      <br />

      {children}
    </>
  );
};

export default TopBar;
