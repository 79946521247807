import moment from 'moment';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmploymentAttributes } from '../../../apis/attributes';
import { numberToDayOfWeek } from '../../../utility/time';

export type EmploymentCardProps = {
  employment: EmploymentAttributes | null;
};

const getWorkDaysInWeek = (workDayDefiner: any[] | undefined, language = 'de'): string => {
  if (workDayDefiner === null) {
    return '';
  }
  if (workDayDefiner === undefined) {
    return '';
  }
  const days: string[][] = workDayDefiner.map((wd) =>
    wd.workDayDefiner.map((d: number) => numberToDayOfWeek(d, language)),
  );
  return days.map((d) => d.join(', ')).join(' / ');
};

const getWorkHoursPerWeek = (employment: any): string => {
  const care = (
    Math.round(((employment.careWorkingPerWeek as number) ?? 0) * 100) / 100
  ).toString();
  const disposal = (
    Math.round(((employment.disposalRegularPerWeek as number) ?? 0) * 100) / 100
  ).toString();
  const iDisposal = (
    Math.round(((employment.disposalIrregularPerWeek as number) ?? 0) * 100) / 100
  ).toString();
  return `${care} h, ${disposal} h, ${iDisposal} h`;
};

export const EmploymentCard: React.FC<EmploymentCardProps> = ({ employment }) => {
  const { t, i18n } = useTranslation();

  return (
    <Card className='w-100 shadow' style={{ width: '18rem' }}>
      <Card.Body>
        <Row className='py-0'>
          <Col md={6} xs={6} className='py-0'>
            <p className='text-center'>
              <strong className=' text-primary'>{t('START_DATE') as string}</strong>
              <br />
              <small>{moment(employment?.start)?.format('DD-MM-YYYY') as string}</small>
            </p>
          </Col>
          <Col md={6} xs={6} className='py-0'>
            <p className='text-center '>
              <strong className=' text-primary'>{t('DEADLINE') as string}</strong>
              <br />
              <small>
                {employment?.end
                  ? moment(employment?.end)?.format('DD-MM-YYYY')
                  : (t('ONGOING') as string)}
              </small>
            </p>
          </Col>
          <Col md={6} xs={6} className='py-0'>
            <p className='text-center'>
              <strong className=' text-primary'>{t('WORKING_HOURS') as string}</strong>
              <br />
              <small>{getWorkHoursPerWeek(employment)}</small>
            </p>
          </Col>
          <Col md={6} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('WORKING_DAYS') as string}</strong>
              <br />
              <small>{getWorkDaysInWeek(employment?.multiWorkDayDefiner, i18n.language)}</small>
            </p>
          </Col>
          <Col md={6} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('MONTHLY_LIMIT_ACTIVE') as string}</strong>

              <br />
              <small>{employment?.monthlyLimitsActive ? t('YES') : (t('NO') as string)}</small>
            </p>
          </Col>
          <Col md={6} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('DAILY_LIMIT_ACTIVE') as string}</strong>
              <br />
              <small>{employment?.dailyLimitActive ? t('YES') : (t('NO') as string)}</small>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
// `${Math.round((employment?.careWorkingPerWeek ?? 0) * 100) / 100 + Math.round((employment?.disposalRegularPerWeek ?? 0) * 100) / 100 + Math.round((employment?.disposalIrregularPerWeek ?? 0) * 100) / 100} h`
export default EmploymentCard;
