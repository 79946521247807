import { Paginated } from '@feathersjs/feathers';
import { DateTime, Duration } from 'luxon';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useObservable } from 'react-use-observable';
import { EmployeeAttributes } from '../../../apis/attributes';
import { YearlySickNotesDB } from '../../../apis/attributes/yearly-sick-notes';
import { social } from '../../../apis/social';
import { SickNoteCollection } from '../../../core/SickNoteCollection';
import { TimeRange, compareTimeRanges, timeRangeLength } from '../../../core/timeRange';
import FacilitySickEntryEmployee from './FacilitySickEntryEmployee';
import Switch from 'react-bootstrap/esm/Switch';

class RangeSelector implements TimeRange {
    constructor(
        public start: DateTime,
        public end: DateTime,
    ) {
        if (start > end) {
            throw new Error(`Start date after end date: ${start} > ${end}`);
        }
    }
    compareTo(other: TimeRange): number {
        return compareTimeRanges(this, other);
    }

    length(): Duration {
        return timeRangeLength(this);
    }
}

type ExtendedSickNotes = {
    firstName: string;
    lastName: string;
    employeeId: string;
    sickNotesCollections: SickNoteCollection[];
}[];

interface EmployeeSickProps {
    sickNoteExtended: ExtendedSickNotes[number];
}
const EmployeeSickDetails: React.FC<EmployeeSickProps> = ({ sickNoteExtended }) => {
    if (!sickNoteExtended.sickNotesCollections?.length) return <div />;

    return (
        <Container className='mt-4 shadow py-4 px-sm-5 rounded'>
            <h4 className='fw-bold text-primary'>
                {sickNoteExtended.firstName} {sickNoteExtended.lastName}
            </h4>
            <div className='pl-4 mt-4'>
                <FacilitySickEntryEmployee
                    sickNotes={sickNoteExtended.sickNotesCollections}
                    disabled={false}
                    onSickNotesChange={() => {}}
                />
            </div>
        </Container>
    );
};

const FacilitySickEntryViewComponent: React.FC<{ facilityId: string | undefined }> = observer(
    ({ facilityId }) => {
        const { t: t18 } = useTranslation();

        // store the collection in a state
        const [sickNotesExtendedList, setSickNotesExtendedList] = useState<ExtendedSickNotes>();

        const [filteredSickNotesExtendedList, setFilteredSickNotesExtendedList] =
            useState<ExtendedSickNotes>();

        // create useStates for each of the filters
        const [sickChild, setSickChild] = useState<boolean>(true);
        const [alert, setAlert] = useState<undefined | string>(undefined);
        const showAlert = (msg: string) => {
            setAlert(msg);
        };

        useEffect(() => {
            if (alert) {
                const timeout = setTimeout(() => {
                    setAlert(undefined);
                }, 3000);

                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [alert]);

        const now = DateTime.now();
        const [range, setRange] = useState<TimeRange>(new RangeSelector(now, now.plus({ weeks: 2 })));

        const [employees] = useObservable<Paginated<EmployeeAttributes> | null>(
            () =>
                social
                    .service('employee')
                    .watch()
                    .find({
                        query: {
                            $sort: {
                                lastName: 1,
                            },
                            facilityId: facilityId,
                        },
                    }),
            [facilityId],
        );

        // now get the sick notes for each employee
        const [allSickNotes] = useObservable<Paginated<YearlySickNotesDB> | null>(
            () =>
                social
                    .service('yearly-sick-notes')
                    .watch()
                    .find({
                        query: {
                            $sort: {
                                year: 1,
                            },
                            employeeId: {
                                $in: employees?.data?.map((e) => e.id) ?? [],
                            },
                        },
                    }),
            [employees],
        );

        // store using setSickNotesExtendedList on useEffect
        useEffect(() => {
            if (!allSickNotes?.data) return;
            const paddedDataToBeAdded = allSickNotes?.data.map((sn) =>
                SickNoteCollection.fromAttributes(sn),
            );
            // group by employeeId
            const groupedByEmployeeId = paddedDataToBeAdded.reduce(
                (acc, curr) => {
                    const employeeId = curr.employeeId;
                    if (!acc[employeeId]) {
                        acc[employeeId] = [];
                    }
                    acc[employeeId].push(curr);
                    return acc;
                },
                {} as { [key: string]: SickNoteCollection[] },
            );

            // now attach the employee name to each collection
            const sickNotesExtended: {
                firstName: string;
                lastName: string;
                employeeId: string;
                sickNotesCollections: SickNoteCollection[];
            }[] = Object.keys(groupedByEmployeeId).map((key) => {
                const employee = employees?.data?.find((e) => e.id === key);
                return {
                    firstName: employee?.firstName ?? '',
                    lastName: employee?.lastName ?? '',
                    employeeId: key,
                    sickNotesCollections: groupedByEmployeeId[key],
                };
            });

            setSickNotesExtendedList(sickNotesExtended);
            setFilteredSickNotesExtendedList(sickNotesExtended);
        }, [allSickNotes]);

        // This useEffect filters the sick notes based on the sick child status and the date range
        useEffect(() => {
            if (!sickNotesExtendedList) return;
            //   Step 1: filter the sick notes based on the date range
            const filteredSickNotesStep1 = sickNotesExtendedList.map((sickNoteExtended) => {
                const filteredSickNotes = sickNoteExtended.sickNotesCollections.map(
                    (sickNoteCollection) => {
                        // sickNoteCollection is a SickNoteCollection
                        const filteredSickNotes = sickNoteCollection.sickNotes.filter((sickNote) => {
                            // sickNote is a SickNote
                            // check if the sick note is in the range
                            const isStartDateInRange = sickNote.start.startOf('day') >= range.start.startOf('day') && sickNote.start <= range.end;
                            return isStartDateInRange;
                        });
                        return new SickNoteCollection(
                            sickNoteCollection.id,
                            sickNoteCollection.employeeId,
                            sickNoteCollection.year,
                            filteredSickNotes,
                        );
                    },
                );

                return {
                    firstName: sickNoteExtended.firstName,
                    lastName: sickNoteExtended.lastName,
                    employeeId: sickNoteExtended.employeeId,
                    sickNotesCollections: filteredSickNotes,
                };
            });

            const filteredSickNotesStep2 = filteredSickNotesStep1;
            // Step 2: filter the sick notes based on the sick child status
            if (!sickChild) {
                // remove all entries from individual sick notes that have sick child if the filter is not set to true
                for (const sickNoteExtended of filteredSickNotesStep2) {

                    for (const sickNoteCollection of sickNoteExtended.sickNotesCollections) {
                        const filteredSickNotes = sickNoteCollection.sickNotes.filter((sickNote) => {
                            return !sickNote.sickChild;
                        });
                        if (filteredSickNotes.length === 0) {
                            sickNoteExtended.sickNotesCollections = sickNoteExtended.sickNotesCollections.filter(
                                (snc) => snc.id !== sickNoteCollection.id,
                            );
                        } else {
                            sickNoteCollection.sickNotes = filteredSickNotes;
                        }
                    }
                }
            }

            // fully remove all entries from the list that have no sick notes
            const filteredSickNotesStep3 = filteredSickNotesStep2.filter((sickNoteExtended) => {
                const sickNotesCollections = sickNoteExtended.sickNotesCollections;
                if (sickNotesCollections.length === 0) {
                    return false;
                }
                else {
                    const firstSickNoteCollection = sickNotesCollections[0];
                    if (firstSickNoteCollection.sickNotes.length === 0) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            });

            setFilteredSickNotesExtendedList(filteredSickNotesStep3 as ExtendedSickNotes);
        }, [sickChild, range, sickNotesExtendedList, JSON.stringify(sickNotesExtendedList)]);

        return (
            <Container className='pt-3'>
                <Row className='mt-3 mb-4'>
                    <Col xs={12}>
                        <h4 className='fw-bold text-primary'>{t18('OVERVIEW_SICKNESS')}</h4>
                    </Col>
                </Row>

                <Row style={{ maxWidth: '500px' }}>
                    <Col xs={6}>
                        <label style={{ marginBottom: '8px' }}>{t18('FROM')}:</label>
                        <DatePicker
                            selected={new Date(range.start.toString())}
                            onChange={(date: Date) => {
                                const dateAsDateTime = DateTime.fromJSDate(date);
                                const newRange = new RangeSelector(dateAsDateTime, dateAsDateTime.plus({ weeks: 2 }));
                                setRange(newRange);
                            }}
                            dateFormat='dd/MM/yyyy'
                            className='w-100'
                            minDate={new Date(`01/01/${now.year - 1}`)}
                            maxDate={new Date(`12/31/${now.year + 1}`)}
                        />
                    </Col>
                    <Col xs={6}>
                        <label style={{ marginBottom: '8px' }}>{t18('TO')}:</label>
                        <DatePicker
                            selected={new Date(range.end.toString())}
                            onChange={(date: Date) => {
                                try {
                                    setRange(new RangeSelector(range.start, DateTime.fromJSDate(date)));
                                } catch (e) {
                                    showAlert(t18('INTERVAL_BUILD_WARNING'));
                                }
                            }}
                            dateFormat='dd/MM/yyyy'
                            className='w-100'
                            // use the min date from the start date
                            minDate={new Date(range.start.toString())}
                            maxDate={new Date(`12/31/${now.year + 1}`)}
                        />
                    </Col>
                </Row>

                <Row className='my-4'>
                    <Col xs={12}>
                        <div className='d-flex flex-wrap'>
                            <div className='mr-4'>
                                {/* use a switch instead of an input */}
                                <Switch
                                    style={{ display: 'inline-block' }}
                                    id='sickChild'
                                    name='sickChild'
                                    defaultChecked={sickChild}
                                    onChange={(bool) => setSickChild(bool.target.checked)}
                                />
                                <label htmlFor='sickChild' className='ml-2'>
                                    {t18('SICK_CHILD')}
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className='d-flex justify-content-center' style={{ marginTop: '16px' }}>
                    <Alert variant='danger' hidden={!alert}>
                        {alert}
                    </Alert>
                </Row>
                <Row className='d-flex justify-content-center' style={{ marginTop: '8px' }}>
                    {filteredSickNotesExtendedList?.map((sickNoteExtended, index) => (
                        <Col key={index} xs={12} md={6} lg={12}>
                            <EmployeeSickDetails sickNoteExtended={sickNoteExtended} />
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    },
);

export default FacilitySickEntryViewComponent;