import React from 'react';
import { Spinner } from 'react-bootstrap';

export const LoadingPage: React.FC = () => (
  <>
    <div className='d-flex justify-content-center align-items-center fixed-top h-100'>
      <Spinner animation='border' role='status' />
      <span className='m-2'>Loading...</span>
    </div>
  </>
);

export default LoadingPage;
