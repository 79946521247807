import jsPDF from 'jspdf';
import autoTable, { CellDef, CellHookData, Styles } from 'jspdf-autotable';
import { isNil, isEmpty, get, countBy, max } from 'lodash';
import { ITranslationLabel } from '../model/TranslationLabel';

import DayReport from '../../../utility/DayReport';
import WeekReport from '../../../utility/WeekReport';
import WeekTableLastPos from '../model/WeekTableLastPos';
import Interval from '../../../utility/Interval';
import careTimeIcon from './careTimeIcon';
import disposalTimeIcon from './disposalTimeIcon';
import irregularTimeIcon from './irregularTimeIcon';

const dateOfWeek = [
  'VER_COLUMN',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

const timeIntervalType = ['CARE', 'DISPOSAL', 'IRREGULAR_DISPOSAL'];

const timeIntervalTypeMap = {
  CARE: 'CARE',
  DISPOSAL: 'DISPOSAL',
  IRREGULAR_DISPOSAL: 'IRREGULAR_DISPOSAL',
};

interface IHorizontalHeader {
  key: string;
  label: string;
  span?: number;
  styles?: Partial<Styles> | undefined;
  bodyStyles?: Partial<Styles> | undefined;
  bodyStylesWithValue?: Partial<Styles> | undefined;
}

const getVerticalHeader = (translateObj: ITranslationLabel): IHorizontalHeader[] => [
  {
    key: 'date',
    label: translateObj.REPORT_DATE,
  },
  {
    key: 'intervals',
    label: translateObj.REPORT_INTERVALS,
  },
  {
    key: 'lastChangeOn',
    label: translateObj.REPORT_LAST_CHANGE_ON,
  },
  {
    bodyStylesWithValue: {
      fillColor: '#FBCA32',
    },
    key: 'keyWord',
    label: translateObj.REPORT_KEYWORD,
  },
  {
    key: 'pause',
    label: translateObj.REPORT_PAUSE_TOTAL,
  },
  {
    bodyStyles: {
      fillColor: '#886CE4',
      textColor: '#FFF',
    },
    key: 'actualTimeDetailed.careTime',
    label: translateObj.REPORT_CHILD_ACTUAL,
    styles: {
      fillColor: '#886CE4',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#886CE4',
      textColor: '#FFF',
    },
    key: 'targetTimeDetailed.careTime',
    label: translateObj.REPORT_CHILD_TARGET,
    styles: {
      fillColor: '#886CE4',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#886CE4',
      textColor: '#FFF',
    },
    key: 'balanceDetailed.careTime',
    label: translateObj.REPORT_CHILD_BALANCE,
    styles: {
      fillColor: '#886CE4',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#16C60C',
      textColor: '#FFF',
    },
    key: 'actualTimeDetailed.disposalTime',
    label: translateObj.REPORT_DISPOSAL_ACTUAL,
    styles: {
      fillColor: '#16C60C',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#16C60C',
      textColor: '#FFF',
    },
    key: 'targetTimeDetailed.disposalTime',
    label: translateObj.REPORT_DISPOSAL_TARGET,
    styles: {
      fillColor: '#16C60C',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#16C60C',
      textColor: '#FFF',
    },
    key: 'balanceDetailed.disposalTime',
    label: translateObj.REPORT_DISPOSAL_TARGET,
    styles: {
      fillColor: '#16C60C',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#F7630C',
      textColor: '#FFF',
    },
    key: 'actualTimeDetailed.irregularDisposalTime',
    label: translateObj.REPORT_IRREGULAR_DISPOSAL_ACTUAL,
    styles: {
      fillColor: '#F7630C',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#F7630C',
      textColor: '#FFF',
    },
    key: 'targetTimeDetailed.irregularDisposalTime',
    label: translateObj.REPORT_IRREGULAR_DISPOSAL_TARGET,
    styles: {
      fillColor: '#F7630C',
      textColor: '#FFF',
    },
  },
  {
    bodyStyles: {
      fillColor: '#F7630C',
      textColor: '#FFF',
    },
    key: 'balanceDetailed.irregularDisposalTime',
    label: translateObj.REPORT_IRREGULAR_DISPOSAL_BALANCE,
    styles: {
      fillColor: '#F7630C',
      textColor: '#FFF',
    },
  },
  {
    key: 'actualTimeTotal',
    label: translateObj.REPORT_TOTAL_ACTUAL_DAY,
  },
  {
    key: 'targetTimeTotal',
    label: translateObj.REPORT_TOTAL_TARGET_DAY,
  },
  {
    key: 'balanceTotal',
    label: translateObj.REPORT_TOTAL_BALANCE_DAY,
  },
];

const generateDataDetailedFromWeekData = (
  weekData: WeekReport,
  maxIntervalCount: number,
  lang: string,
  translateObj: ITranslationLabel,
): CellDef[][] => {
  const dataList: CellDef[][] = [];
  const days: DayReport[] = weekData.week;

  const daysDictionary: { [key: string]: DayReport } = {};
  for (const day of days) {
    daysDictionary[day.dayOfWeek] = day;
  }

  const verticalHeader = getVerticalHeader(translateObj);

  for (let verIndex = 0; verIndex < verticalHeader.length; verIndex++) {
    const verHeader = verticalHeader[verIndex];
    const data: CellDef[] = [];

    for (let columnIndex = 0; columnIndex < dateOfWeek.length; columnIndex++) {
      const dateInWeek = dateOfWeek[columnIndex];
      const dayData = daysDictionary[dateInWeek];
      if (dateInWeek === 'VER_COLUMN') {
        if (verHeader.key === 'intervals') {
          for (let addCIndex = 0; addCIndex < maxIntervalCount - 1; addCIndex++) {
            dataList.push([{ title: '' }]);
          }
        }
        data.push({
          styles: verHeader.styles,
          title: verHeader.label,
        });
      } else if (verHeader.key !== 'intervals') {
        let title =
          isNil(dayData) || isNil(verHeader.key) || isEmpty(verHeader.key)
            ? ''
            : get(dayData, verHeader.key);
        if (dayData && verHeader.key === 'keyWord') {
          title = dayData.keyWordTranslation(lang);
        }
        data.push({
          styles: {
            ...verHeader.bodyStyles,
            ...(title && !isEmpty(title) ? verHeader.bodyStylesWithValue : {}),
          },
          title,
        });
        // bodyStylesWithValue
      }
    }
    dataList.push(data);
  }

  return dataList;
};

export default function generateDetailedWeekTable(
  doc: jsPDF,
  mapData: WeekReport,
  lang: string,
  translateObj: ITranslationLabel,
  startYparam?: number | undefined,
): WeekTableLastPos {
  const maxIntervalCount: number = max(
    Object.keys(countBy(mapData.week, 'intervals.length') as Object).map(Number),
  ) as unknown as number;
  const dataFromWeekData = generateDataDetailedFromWeekData(
    mapData,
    maxIntervalCount,
    lang,
    translateObj,
  );
  let startDateWeek = '--.--';
  let endDateWeek = '--.--';
  if (mapData.week && mapData.week.length > 0) {
    startDateWeek = mapData.week[0].date.substring(5, 10);
    endDateWeek = mapData.week[mapData.week.length - 1].date.substring(5, 10);
  }

  const cellWidthDefine = 55;
  let xPos = 0;
  let yPos = 0;
  let nextYResult = 0;
  autoTable(doc, {
    alternateRowStyles: {
      fillColor: '#FFF',
    },
    body: dataFromWeekData,
    columnStyles: {
      0: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      1: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      2: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      3: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      4: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      5: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      6: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
      7: {
        lineColor: '#000',
        lineWidth: 0.5,
      },
    },
    didDrawCell(data: CellHookData) {
      if (
        data.row.index > 0 &&
        data.row.index <= maxIntervalCount &&
        data.cell.section === 'body'
      ) {
        const currentDayData = mapData.week.find(
          (findDay) => findDay.dayOfWeek === dateOfWeek[data.column.index],
        );
        const currentIntervalData: Interval = get(
          currentDayData,
          `intervals[${data.row.index - 1}]`,
        ) as unknown as Interval;
        if (currentIntervalData) {
          const { comment, intervalType, start, end, translateIntervalType } = currentIntervalData;

          const { x, y } = data.cell;

          const cellWidth = cellWidthDefine;
          const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
          const imageWidth = lineHeight;
          const centerX = x + cellWidth / 2;
          const imageX = x + cellWidth / 2 - imageWidth / 2;
          let nextY = y + 2;

          const generateBlock = (blockContent: {
            start: string;
            end: string;
            comment: string;
            intervalType: string;
          }): void => {
            let icon = careTimeIcon;
            if (intervalType === timeIntervalTypeMap.DISPOSAL) {
              icon = disposalTimeIcon;
            } else if (intervalType === timeIntervalTypeMap.IRREGULAR_DISPOSAL) {
              icon = irregularTimeIcon;
            }
            doc.addImage(icon, imageX, nextY, imageWidth, imageWidth);
            nextY += lineHeight * 2;
            doc.setFontSize(9);
            doc.text(`${blockContent.start} - ${blockContent.end}`, centerX, nextY, {
              align: 'center',
            });
            nextY += lineHeight;

            if (comment && !isEmpty(comment)) {
              doc.setFillColor('#FBCA32');
              doc.setFontSize(8);
              const splitText = doc.splitTextToSize(blockContent.comment, cellWidth - 2);

              const blockHeight = splitText.length * lineHeight;
              doc.rect(x + 1, nextY - lineHeight + 2, cellWidth - 2, blockHeight, 'F');
              doc.text(splitText, centerX, nextY, {
                align: 'center',
              });
            }
            nextY += lineHeight * 2.5;
          };

          generateBlock({
            comment,
            end,
            intervalType,
            start,
          });
        }
      }
    },
    didDrawPage(data: any) {
      xPos = data.cursor.x;
      yPos = data.cursor.y;
      const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
      nextYResult = yPos + lineHeight;
    },
    didParseCell(data: CellHookData) {
      // Calculate HEIGHT FOR INTERVALS
      if (
        data.row.index > 0 &&
        data.row.index <= maxIntervalCount &&
        data.cell.section === 'body'
      ) {
        data.cell.styles.lineWidth = {
          left: 0.5,
        };
        if (data.row.index === 1) {
          data.cell.styles.lineWidth.top = 0.5;
        }

        const currentDayData = mapData.week.find(
          (findDay) => findDay.dayOfWeek === dateOfWeek[data.column.index],
        );
        const currentIntervalData: Interval = get(
          currentDayData,
          `intervals[${data.row.index - 1}]`,
        ) as unknown as Interval;
        if (currentIntervalData) {
          const { comment, intervalType, start, end, translateIntervalType } = currentIntervalData;
          const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
          let splitText: any[] = [];
          if (comment && !isEmpty(comment)) {
            splitText = doc.splitTextToSize(comment, cellWidthDefine - 2);
          }

          const blockHeight = splitText.length * lineHeight;
          const sumHeight = 2 + lineHeight * 2 + lineHeight + blockHeight;
          data.cell.styles.minCellHeight = sumHeight;
        }
      }
    },
    head: [
      [
        {
          styles: {
            fillColor: '#05CE91',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            valign: 'middle',
          },
          title: `${translateObj.REPORT_WEEK} ${startDateWeek} ${translateObj.REPORT_UNTIL} ${endDateWeek}`,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_MONDAY,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_TUESDAY,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_WEDNESDAY,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_THURSDAY,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_FRIDAY,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_SATURDAY,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_SORT_SUNDAY,
        },
      ],
    ],

    margin: {
      // bottom: 5,
      left: 5,
      right: 5,
      top: 5,
    },
    rowPageBreak: 'avoid',

    startY: startYparam,
    // startY: 350,
    styles: {
      fontSize: 9,
      halign: 'center',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.5,
  });
  return { nextY: nextYResult, xPos, yPos };
}
