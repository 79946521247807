export interface ITranslationLabel {
  REPORT_DETAILED_HEADER: string;
  REPORT_MONTH_OVERVIEW: string;
  REPORT_OVERVIEW_HEADER: string;
  REPORT_ADMINISTRATOR: string;
  REPORT_NAME_EMPLOYEE: string;
  REPORT_PERIOD: string;
  REPORT_VACATION_DAYS: string;
  REPORT_VACATION_DAYS_SPECIAL: string;
  REPORT_VACATION_DAYS_REGENERATION: string;
  REPORT_VACATION_DAYS_CONVERSION: string;
  REPORT_VACATION_DAYS_LEFT: string;
  REPORT_VACATION_DAYS_SPECIAL_LEFT: string;
  REPORT_VACATION_DAYS_REGENERATION_LEFT: string;
  REPORT_VACATION_DAYS_CONVERSION_LEFT: string;
  REPORT_NUMBER_WORKDAYS_IN_THIS_MONTH: string;
  REPORT_VACATION_DAYS_TAKEN_IN_PERIOD: string;
  REPORT_VACATION_DAYS_SPECIAL_TAKEN_IN_PERIOD: string;
  REPORT_VACATION_DAYS_REGENERATION_TAKEN_IN_PERIOD: string;
  REPORT_VACATION_DAYS_CONVERSION_TAKEN_IN_PERIOD: string;
  REPORT_Print_Date: string;
  REPORT_SORT_MONDAY: string;
  REPORT_SORT_TUESDAY: string;
  REPORT_SORT_WEDNESDAY: string;
  REPORT_SORT_THURSDAY: string;
  REPORT_SORT_FRIDAY: string;
  REPORT_SORT_SATURDAY: string;
  REPORT_SORT_SUNDAY: string;
  REPORT_DATE: string;
  REPORT_INTERVALS: string;
  REPORT_KEYWORD: string;
  REPORT_PAUSE_TOTAL: string;
  REPORT_CHILD_ACTUAL: string;
  REPORT_CHILD_TARGET: string;
  REPORT_CHILD_BALANCE: string;
  REPORT_DISPOSAL_ACTUAL: string;
  REPORT_DISPOSAL_TARGET: string;
  REPORT_DISPOSAL_BALANCE: string;
  REPORT_IRREGULAR_DISPOSAL_ACTUAL: string;
  REPORT_IRREGULAR_DISPOSAL_TARGET: string;
  REPORT_IRREGULAR_DISPOSAL_BALANCE: string;
  REPORT_TOTAL_ACTUAL_DAY: string;
  REPORT_TOTAL_TARGET_DAY: string;
  REPORT_TOTAL_BALANCE_DAY: string;
  REPORT_TOTAL_ACTUAL: string;
  REPORT_TOTAL_TARGET: string;
  REPORT_TOTAL_BALANCE: string;
  REPORT_CHILD: string;
  REPORT_DISPOSAL: string;
  REPORT_IRREGULAR_DISPOSAL: string;
  REPORT_KEYWORDS_LIST: string;
  REPORT_PAYED_OUT_HOURS: string;
  REPORT_RECEIVED_HOURS: string;
  REPORT_SIGNATURE: string;
  REPORT_ACTUAL_MONTH: string;
  REPORT_TARGET_TOTAL_MONTH: string;
  REPORT_BALANCE_1_TOTAL_THIS_MONTH: string;
  REPORT_BALANCE_2_TOTAL_LAST_MONTH: string;
  REPORT_BALANCE_2_TOTAL_THIS_MONTH: string;
  REPORT_VACATION_DAYS_A_MONTH: string;
  REPORT_TAKEN_SPECIAL_VACATION_DAYS_A_MONTH: string;
  REPORT_SICK_DAYS_MONAT: string;
  REPORT_WORKING_DAY_BALANCE_TIME: string;
  REPORT_PAUSE: string;
  REPORT_ACTUAL: string;
  REPORT_TARGET: string;
  REPORT_BALANCE: string;
  REPORT_CONTRACT_HOURS: string;
  REPORT_FACILITY_DAYTYPE: string;
  REPORT_CONTRACT_DAY: string;
  REPORT_START: string;
  REPORT_END: string;
  REPORT_WEEKLY_WORKING_DAY: string;
  REPORT_WEEKLY_TIME_TOTAL: string;
  REPORT_LAST_CHANGE_ON: string;
  REPORT_EMPLOYEE_NAME: string;
  REPORT_DATE_NAME: string;
  REPORT_DAY: string;
  REPORT_WEEK: string;
  REPORT_UNTIL: string;
}

export default class TranslationLabel implements ITranslationLabel {
  REPORT_DETAILED_HEADER: string = '';

  REPORT_MONTH_OVERVIEW: string = '';

  REPORT_OVERVIEW_HEADER: string = '';

  REPORT_ADMINISTRATOR: string = '';

  REPORT_NAME_EMPLOYEE: string = '';

  REPORT_PERIOD: string = '';

  REPORT_VACATION_DAYS: string = '';

  REPORT_VACATION_DAYS_SPECIAL: string = '';

  REPORT_VACATION_DAYS_REGENERATION: string = '';

  REPORT_VACATION_DAYS_CONVERSION: string = '';

  REPORT_VACATION_DAYS_LEFT: string = '';

  REPORT_VACATION_DAYS_SPECIAL_LEFT: string = '';

  REPORT_VACATION_DAYS_REGENERATION_LEFT: string = '';

  REPORT_VACATION_DAYS_CONVERSION_LEFT: string = '';

  REPORT_VACATION_DAYS_TAKEN_IN_PERIOD: string = '';

  REPORT_VACATION_DAYS_SPECIAL_TAKEN_IN_PERIOD: string = '';

  REPORT_VACATION_DAYS_REGENERATION_TAKEN_IN_PERIOD: string = '';

  REPORT_VACATION_DAYS_CONVERSION_TAKEN_IN_PERIOD: string = '';

  REPORT_NUMBER_WORKDAYS_IN_THIS_MONTH: string = '';

  REPORT_Print_Date: string = '';

  REPORT_SORT_MONDAY: string = '';

  REPORT_SORT_TUESDAY: string = '';

  REPORT_SORT_WEDNESDAY: string = '';

  REPORT_SORT_THURSDAY: string = '';

  REPORT_SORT_FRIDAY: string = '';

  REPORT_SORT_SATURDAY: string = '';

  REPORT_SORT_SUNDAY: string = '';

  REPORT_DATE: string = '';

  REPORT_INTERVALS: string = '';

  REPORT_KEYWORD: string = '';

  REPORT_PAUSE_TOTAL: string = '';

  REPORT_CHILD_ACTUAL: string = '';

  REPORT_CHILD_TARGET: string = '';

  REPORT_CHILD_BALANCE: string = '';

  REPORT_DISPOSAL_ACTUAL: string = '';

  REPORT_DISPOSAL_TARGET: string = '';

  REPORT_DISPOSAL_BALANCE: string = '';

  REPORT_IRREGULAR_DISPOSAL_ACTUAL: string = '';

  REPORT_IRREGULAR_DISPOSAL_TARGET: string = '';

  REPORT_IRREGULAR_DISPOSAL_BALANCE: string = '';

  REPORT_TOTAL_ACTUAL_DAY: string = '';

  REPORT_TOTAL_TARGET_DAY: string = '';

  REPORT_TOTAL_BALANCE_DAY: string = '';

  REPORT_TOTAL_ACTUAL: string = '';

  REPORT_TOTAL_TARGET: string = '';

  REPORT_TOTAL_BALANCE: string = '';

  REPORT_CHILD: string = '';

  REPORT_DISPOSAL: string = '';

  REPORT_IRREGULAR_DISPOSAL: string = '';

  REPORT_KEYWORDS_LIST: string = '';

  REPORT_PAYED_OUT_HOURS: string = '';

  REPORT_RECEIVED_HOURS: string = '';

  REPORT_SIGNATURE: string = '';

  REPORT_ACTUAL_MONTH: string = '';

  REPORT_TARGET_TOTAL_MONTH: string = '';

  REPORT_BALANCE_1_TOTAL_THIS_MONTH: string = '';

  REPORT_BALANCE_2_TOTAL_LAST_MONTH: string = '';

  REPORT_BALANCE_2_TOTAL_THIS_MONTH: string = '';

  REPORT_VACATION_DAYS_A_MONTH: string = '';

  REPORT_TAKEN_SPECIAL_VACATION_DAYS_A_MONTH: string = '';

  REPORT_SICK_DAYS_MONAT: string = '';

  REPORT_WORKING_DAY_BALANCE_TIME: string = '';

  REPORT_PAUSE: string = '';

  REPORT_ACTUAL: string = '';

  REPORT_TARGET: string = '';

  REPORT_BALANCE: string = '';

  REPORT_CONTRACT_HOURS: string = '';

  REPORT_CONTRACT_DAY: string = '';

  REPORT_FACILITY_DAYTYPE: string = '';

  REPORT_START: string = '';

  REPORT_END: string = '';

  REPORT_WEEKLY_WORKING_DAY: string = '';

  REPORT_WEEKLY_TIME_TOTAL: string = '';

  REPORT_LAST_CHANGE_ON: string = '';

  REPORT_EMPLOYEE_NAME: string = '';

  REPORT_DATE_NAME: string = '';

  REPORT_DAY: string = '';

  REPORT_WEEK: string = '';

  REPORT_UNTIL: string = '';
}
