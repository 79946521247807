// AdditionCondition.ts
import { DateTime, Duration } from "luxon";
import { TimeRange, timeRangeLength, compareTimeRanges } from "./timeRange";

export class AdditionRange implements TimeRange {
    /**
     * AdditionRange represents a time range during which a specific time addition applies.
     *
     * @param {DateTime} start - The starting time when the addition starts to be applicable.
     * @param {DateTime} end - The ending time when the addition ceases to be applicable.
     * @param {number} fraction - A multiplier indicating how much time is given for time worked in the specified range.
     */
    constructor(
        public start: DateTime,
        public end: DateTime,
        public fraction: number
    ) {
        if (end <= start) {
            throw new Error(`End of additionCondition needs to be after start of additionCondition, was ${start} - ${end}`);
        }
    }

    /**
     * Calculates the length of the addition condition time range.
     *
     * @returns {Duration} - The duration between the start and end times of the addition condition.
     */
    length(): Duration {
        return timeRangeLength(this);
    }

    /**
     * Compares this addition condition to another addition condition.
     *
     * @param {TimeRange} other - The other addition condition.
     * @returns {number} -1 if this addition condition is before the other addition condition, 1 if this addition condition is after the other addition condition, 0 if this addition condition overlaps the other addition condition.
     */
    compareTo(other: TimeRange): number {
        return compareTimeRanges(this, other);
    }
}