import React from 'react';
import { Route } from 'react-router-dom';
import FurnishingDetails from './containers/FurnishingDetails';
import Plans from './containers/Plans';
import { useTranslation } from 'react-i18next';
import FacilitySickEntryViewComponent from './containers/FacilitySickEntryView';
import TopBar from "../Layout/containers/TopBar";

export default () => {
    const { t } = useTranslation();
  return (<>
    <Route exact path='/admin/furnishing-details' render={() => <FurnishingDetails />} />
    <Route exact path='/admin/plans' render={() => <Plans />} />
      <Route
          path='/admin/employees-sick/:facilityId?'
          render={( { match } ) => (<FacilitySickEntryViewComponent facilityId={match.params.facilityId}/>
          )}
      />
  </>
  );
}
