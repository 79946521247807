import React, { useCallback, useState } from 'react';
import { Button, Col, Row, DropdownButton, Dropdown, Alert } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { uuid } from 'uuidv4';
import moment from 'moment';
import runes from 'runes';
import { useTranslation } from 'react-i18next';
import { DailyIntervalType, TimeInterval } from './Availability';
import SaveNoteModal, { SaveNoteModalAttributes } from './SaveNoteModal';
import CommentImg from '../images/comment.png';

export type DayComponentProps = {
  key: string;
  dayStr: string;
  disabled: boolean;
  timeIntervals: TimeInterval[];
  // eslint-disable-next-line no-unused-vars
  onSubmit(timeIntervals: TimeInterval[]): void;
};

const normalizeTimeZoneDate = (date: Date) => {
  const momentDate = moment(date);
  const processed = new Date();
  processed.setFullYear(1970);
  processed.setMonth(0);
  processed.setDate(1);
  processed.setHours(momentDate.hours(), momentDate.minutes(), 0, 0);

  return processed;
};

const convertDayToTimestamp = (date: Date) => {
  // force
  const sideOne = date.getHours() * 60 * 60;
  const sideTwo = date.getMinutes() * 60;
  return sideOne + sideTwo;
};

const dummyInterval = (id: string): TimeInterval => ({
  endDate: undefined,
  id,
  startDate: undefined,
  type: DailyIntervalType.CARE_TIME,
});

const areIntersecting = (
  startDate1?: Date,
  endDate1?: Date,
  startDate2?: Date,
  endDate2?: Date,
): boolean => {
  if (!startDate1 || !endDate1 || !startDate2 || !endDate2) {
    return true;
  }

  const startTimestamp1 = convertDayToTimestamp(startDate1);
  const endTimestamp1 = convertDayToTimestamp(endDate1);

  const startTimestamp2 = convertDayToTimestamp(startDate2);
  const endTimestamp2 = convertDayToTimestamp(endDate2);

  return startTimestamp1 < endTimestamp2 && startTimestamp2 < endTimestamp1;
};

export const DaySection: React.FC<DayComponentProps> = ({ timeIntervals, onSubmit, disabled }) => {
  const { t: t18 } = useTranslation();
  const [alert, setAlert] = useState<undefined | string>(undefined);

  const [localTimeSlots, setLocalTimeSlots] = useState<TimeInterval[]>(
    timeIntervals?.map((oj) => ({
      ...oj,
      type: oj.type ?? DailyIntervalType.CARE_TIME,
    })),
  );
  const [forceRerender, setForceRerender] = useState(false);

  const [toggleModal, setToggleModal] = useState<SaveNoteModalAttributes | undefined>(undefined);

  const showAlert = (msg: string) => {
    setAlert(msg);
    setTimeout(() => {
      setAlert('');
    }, 3000);
  };

  const addFreeSlot = () => {
    setLocalTimeSlots([...localTimeSlots, dummyInterval(uuid())]);
  };

  const handleSubmit = useCallback(() => {
    // check if all info is valid

    const lastItem = localTimeSlots[localTimeSlots.length - 1];

    if (
      localTimeSlots.length === 1 &&
      !lastItem?.type &&
      !lastItem.startDate &&
      !lastItem.endDate
    ) {
      setLocalTimeSlots(localTimeSlots);

      // callback to store new state
      onSubmit(localTimeSlots);
      return;
    }

    if (!lastItem?.type) {
      showAlert(t18('INTERVAL_TYPE_WARNING'));
      return;
    }

    if (!lastItem.startDate || !lastItem.endDate) {
      showAlert('Please, finish defining your time interval');
      return;
    }

    // check if start date is smaller than end date for entry date
    if (convertDayToTimestamp(lastItem.startDate) - convertDayToTimestamp(lastItem.endDate) >= 0) {
      showAlert(t18('INTERVAL_BUILD_WARNING'));
      return;
    }

    // check if time intersecting

    let shouldReturn = false;
    if (localTimeSlots.length >= 2) {
      localTimeSlots.forEach((t) => {
        if (t.id === lastItem.id) {
          return;
        }
        if (areIntersecting(t.startDate, t.endDate, lastItem.startDate, lastItem.endDate)) {
          shouldReturn = true;
        }
      });
    }
    if (shouldReturn) {
      showAlert(t18('INTERVAL_INTERSECTION_WARNING'));
      return;
    }

    // sort by start date
    const duplicateTimeSlots = localTimeSlots;

    const sortedTimes = duplicateTimeSlots.sort((a, b) => {
      if (!a.startDate || !a.endDate || !b.startDate || !b.endDate) {
        return 0;
      }

      return convertDayToTimestamp(a.startDate) - convertDayToTimestamp(b.startDate);
    });

    setLocalTimeSlots(sortedTimes);

    // callback to store new state
    onSubmit(sortedTimes);

    // add time slot
    addFreeSlot();
  }, [localTimeSlots]);

  const uniqueKeys = React.useMemo(() => localTimeSlots.map(() => uuid()), [localTimeSlots]);

  return (
    <div>
      <Alert variant='danger' hidden={!alert}>
        {alert}
      </Alert>
      <Row>
        <Col>
          <p>
            {/* On
            {' '}
            {dayStr}
            , I'm available at */}
          </p>
        </Col>
      </Row>
      {localTimeSlots.map((interval, idx) => (
        <div key={uniqueKeys[idx]}>
          <Row>
            <Col className='d-flex justify-content-center' xs={2}>
              <div>
                <p className='m-0 text-start'>{t18('TYPE') as string}</p>
                <DropdownButton
                  disabled={disabled}
                  style={{ backgroundColor: 'transparent' }}
                  className='pb-2 mt-1'
                  id={`dropdown-button-drop-${idx}`}
                  size='sm'
                  variant='light'
                  title={interval?.type ? runes.substr(interval?.type, 0, 1) : '⚪️'}
                >
                  <Dropdown.Item
                    disabled={disabled}
                    onSelect={() => {
                      const copyIntervals = localTimeSlots;
                      const intervObj = interval;
                      intervObj.type = DailyIntervalType.CARE_TIME;
                      copyIntervals[idx] = intervObj;
                      setLocalTimeSlots(copyIntervals);
                      setForceRerender(!forceRerender);
                    }}
                  >
                    {t18('CARE_TIME') as string}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => {
                      const copyIntervals = localTimeSlots;
                      const intervObj = interval;
                      intervObj.type = DailyIntervalType.DISPOSAL_TIME;
                      copyIntervals[idx] = intervObj;
                      setLocalTimeSlots(copyIntervals);
                      setForceRerender(!forceRerender);
                    }}
                  >
                    {t18('DISPOSAL') as string}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => {
                      const copyIntervals = localTimeSlots;
                      const intervObj = interval;
                      intervObj.type = DailyIntervalType.IRREGULAR_DISPOSAL_TIME;
                      copyIntervals[idx] = intervObj;
                      setLocalTimeSlots(copyIntervals);
                      setForceRerender(!forceRerender);
                    }}
                  >
                    {t18('IRREGULAR') as string}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </Col>

            <Col className='d-flex justify-content-center' xs={4} md={3}>
              <div className='w-100'>
                <p className='m-0 text-start'>{t18('FROM') as string}</p>
                <Flatpickr
                  className='text-center w-100'
                  options={{
                    dateFormat: 'H:i',
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                  }}
                  disabled={localTimeSlots.length > idx + 1 || disabled}
                  defaultValue={
                    interval?.startDate && normalizeTimeZoneDate(interval.startDate).toISOString()
                  }
                  onChange={(d) => {
                    const copyIntervals = localTimeSlots;
                    const updatedInterval = localTimeSlots[localTimeSlots.length - 1];
                    const processedDate = d[0];
                    processedDate.setFullYear(1970);
                    processedDate.setMonth(0);
                    processedDate.setDate(1);
                    updatedInterval.startDate = processedDate;
                    copyIntervals[localTimeSlots.length - 1] = updatedInterval;

                    setLocalTimeSlots(copyIntervals);
                  }}
                />
              </div>
            </Col>

            <Col xs={4} md={3} className='d-flex  justify-content-center'>
              <div className='w-100'>
                <p className='m-0 text-start'>{t18('TO') as string} </p>
                <Flatpickr
                  className='text-center w-100'
                  options={{
                    dateFormat: 'H:i',
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                  }}
                  disabled={localTimeSlots.length > idx + 1 || disabled}
                  defaultValue={
                    interval?.endDate && normalizeTimeZoneDate(interval.endDate).toISOString()
                  }
                  onChange={(d) => {
                    const copyIntervals = localTimeSlots;
                    const updatedInterval = localTimeSlots[localTimeSlots.length - 1];
                    const processedDate = d[0];
                    processedDate.setFullYear(1970);
                    processedDate.setMonth(0);
                    processedDate.setDate(1);
                    updatedInterval.endDate = processedDate;
                    copyIntervals[localTimeSlots.length - 1] = updatedInterval;

                    setLocalTimeSlots(copyIntervals);
                  }}
                />
              </div>
            </Col>

            <Col className='d-flex align-items-center p-0'>
              <div className='pb-1 pl-2 '>
                <p className='text-start m-0'>‏</p>
                <Button
                  disabled={disabled}
                  variant='outline-dark px-2 py-0'
                  onClick={() => {
                    if (localTimeSlots.length > 1) {
                      const copyIntervals = localTimeSlots.filter((interv) => {
                        const bool =
                          interv.startDate?.getTime() !== interval.startDate?.getTime() &&
                          interv.endDate?.getTime() !== interval.endDate?.getTime();

                        return bool;
                      });

                      setLocalTimeSlots(copyIntervals);
                    } else {
                      const blankInterval = localTimeSlots[0];
                      blankInterval.endDate = undefined;
                      blankInterval.startDate = undefined;
                      blankInterval.id = uuid();
                      blankInterval.type = undefined;
                      blankInterval.note = undefined;
                      setLocalTimeSlots([blankInterval]);
                    }
                  }}
                >
                  X
                </Button>
              </div>
            </Col>

            <Col className='d-md-none' xs={7} />

            <Col className='d-flex justify-content-between align-items-end ' xs={5} md={2}>
              <Button
                className='mb-1'
                style={{ height: '30px', width: '30px' }}
                variant='outline-light px-0 py-0 mx-0'
                disabled={disabled}
                onClick={() => {
                  setToggleModal({
                    allTimeIntervals: localTimeSlots,
                    idxTimeInterval: idx,
                    isDisabled: localTimeSlots.length > idx + 1 || disabled,
                    setTimeIntervals: setLocalTimeSlots,
                    setToggleModal,
                    toggleModal,
                  });
                }}
              >
                <img style={{ height: '30px', width: '30px' }} alt='' src={CommentImg} />
              </Button>

              {localTimeSlots.length - 1 === idx && (
                <Button
                  disabled={disabled}
                  className='mt-4'
                  onClick={handleSubmit}
                  style={{
                    height: '37px',
                    paddingBottom: '0',
                    paddingTop: '0',
                  }}
                >
                  {t18('SAVE_INTERVAL') as string}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      ))}
      {!!toggleModal && <SaveNoteModal {...toggleModal} />}
      {forceRerender && <div />}
    </div>
  );
};

export default DaySection;
