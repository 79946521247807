import Interval from './Interval';
import TimeSummary from './TimeSummary';
import TimeTriplet from './TimeTriplet';
import DayReport from './DayReport';

class WeekReport extends TimeSummary {
  numberWorkingDays: number;

  week: DayReport[];

  constructor(
    numberWorkingDays: number,
    week: DayReport[],
    targetTimeDetailed: TimeTriplet,
    actualTimeDetailed: TimeTriplet,
    balanceDetailed: TimeTriplet,
    targetTimeTotal: string,
    actualTimeTotal: string,
    balanceTotal: string,
  ) {
    super(
      targetTimeDetailed,
      actualTimeDetailed,
      balanceDetailed,
      targetTimeTotal,
      actualTimeTotal,
      balanceTotal,
    );
    this.numberWorkingDays = numberWorkingDays;
    this.week = week;
  }
}

export default WeekReport;
