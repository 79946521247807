import React, { useEffect, useState } from 'react';
import { Paginated } from '@feathersjs/feathers';
import { observer } from 'mobx-react';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useObservable } from 'react-use-observable';
import { useAsyncFn } from 'react-use';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { FacilityYearSettings } from '../../../apis/attributes/facility-yearsettings';
import { social } from '../../../apis/social';
import InfoModal from './InfoModal';

import { TimeInterval } from '../../TimeInterval/containers/Availability';
import AdditionalFeastDaysLogic from './AdditionalFeastDaysLogic';
import V2ClosedDaysLogic from './ClosedDaysLogic';

const getEmptyFacilityYearSetting = (
  facilityId: string,
  targetYear = new Date().getFullYear(),
): FacilityYearSettings => ({
  additionalFeastDays: [],
  facilityClosedDays: [],
  facilityId,
  id: `${facilityId}_${targetYear}`,
  year: targetYear,
});

type ClosedDaysIntervals = TimeInterval[];
type FeastDaysIntervals = Pick<TimeInterval, 'startDate' | 'id' | 'note'>[];

const getClosedDaysBlankIntervals = (): ClosedDaysIntervals => [
  {
    endDate: undefined,
    id: uuid(),
    startDate: undefined,
  },
];
const getFeastDaysBlankIntervals = (): FeastDaysIntervals => [
  {
    id: uuid(),
    note: '',
    startDate: undefined,
  },
];

const convertClosedDaysToDbDate = (
  data: ClosedDaysIntervals | null | undefined,
): ClosedDaysIntervals => {
  if (!data) {
    return getClosedDaysBlankIntervals();
  }
  return data?.map((interval) => ({
    endDate: !interval?.endDate ? undefined : moment(interval?.endDate)?.toDate(),
    id: interval?.id,
    note: !interval?.note ? undefined : interval?.note,
    startDate: !interval?.startDate ? undefined : moment(interval?.startDate).toDate(),
  }));
};

const convertFeastDaysToDbDate = (
  data: FeastDaysIntervals | null | undefined,
): ClosedDaysIntervals => {
  if (!data) {
    return getFeastDaysBlankIntervals();
  }
  return data?.map((interval) => ({
    id: interval?.id,
    note: !interval?.note ? undefined : interval?.note,
    startDate: !interval?.startDate ? undefined : moment(interval?.startDate).toDate(),
  }));
};

type ClosedDaysIntervalType = TimeInterval[];

/**
 * fill a potentially fragmented YearSettingsList from DB with missing years and next year
 * @param yearSettings potentially non-existent DB YearSettings collection
 * @param employeeId id of employee to create empty entries for patch in DB
 */
const patchYearlySettings = (
  yearSettings: FacilityYearSettings[] | undefined,
  facilityId: string,
): FacilityYearSettings[] => {
  // the last year in the list is the current year plus one
  const lastYearInList = new Date().getFullYear() + 1;

  // if there are no yearly settings, create a new one for the current year and for the next year
  if (yearSettings === undefined) {
    return [
      getEmptyFacilityYearSetting(facilityId, lastYearInList - 1),
      getEmptyFacilityYearSetting(facilityId, lastYearInList),
    ];
  }
  // the first year is either the first year in the list or the current year
  const firstYearInList = yearSettings.length > 0 ? yearSettings[0].year : new Date().getFullYear();

  // edge case that can exist if someone fills out the next year before filling out this year
  if (yearSettings.length === 1 && firstYearInList === lastYearInList) {
    return [getEmptyFacilityYearSetting(facilityId, lastYearInList - 1), yearSettings[0]];
  }

  const returnValue: FacilityYearSettings[] = [];

  // go over all years from the first year in the list to this year plus one
  for (let year = firstYearInList; year <= lastYearInList; year++) {
    // search for year that could be in DB already, if not there, create empty
    if (!yearSettings.find((ys) => ys.year === year)) {
      returnValue.push(getEmptyFacilityYearSetting(facilityId, year));
      // if there, put in list
    } else {
      returnValue.push(yearSettings.find((ys) => ys.year === year) as FacilityYearSettings);
    }
  }
  return returnValue;
};

export const FacilityYearlyLogic: React.FC<{ facilityId: string }> = observer(({ facilityId }) => {

  const { t } = useTranslation();
  // year and month chosen by the user
  const [chosenYear, setChosenYear] = useState<number | undefined>(new Date()?.getFullYear());

  const [allYearsSettings] = useObservable<Paginated<FacilityYearSettings> | null>(
    () =>
      social
        .service('facility-yearly-settings')
        .watch()
        .find({
          query: {
            $sort: {
              year: 1,
            },
            facilityId,
          },
        }),
    [facilityId],
  );

  // generate padded list of years
  const paddedYearlySettings = patchYearlySettings(allYearsSettings?.data, facilityId);

  const [groupIntervalsClosedDays, setgroupIntervalsClosedDays] = useState<
    ClosedDaysIntervalType | undefined
  >(undefined);

  const [groupIntervalsFeastDays, setgroupIntervalsFeastDays] = useState<
    FeastDaysIntervals | undefined
  >(undefined);

  const [forceUpdate, setForceUpdate] = useState(false);

  const [_, retryFacility] = useAsyncFn(async () => {
    if (!facilityId) {
      return undefined;
    }

    const res = (await social
      .service('facility-yearly-settings')
      .find({
        query: {
          $limit: 1,
          facilityId,
          year: chosenYear,
        },
      })
      .catch(() => undefined)) as Paginated<FacilityYearSettings>;

    if (res.total) {
      setgroupIntervalsClosedDays(undefined);

      setForceUpdate(true);
      setTimeout(() => {
        setgroupIntervalsClosedDays(convertClosedDaysToDbDate(res?.data[0]?.facilityClosedDays));

        setgroupIntervalsFeastDays(convertFeastDaysToDbDate(res?.data[0]?.additionalFeastDays));
        setForceUpdate(false);
        // refetch
      }, 0);
    } else {
      setForceUpdate(true);
      setgroupIntervalsClosedDays(undefined);
      setgroupIntervalsFeastDays(undefined);

      setTimeout(() => {
        setgroupIntervalsClosedDays(getClosedDaysBlankIntervals());
        setgroupIntervalsFeastDays(getFeastDaysBlankIntervals());
        setForceUpdate(false);

        // refetch
      }, 0);
    }

    return res as any;
  }, [facilityId, chosenYear]);

  useEffect(() => {
    // reinit selected intervals and tags
    setgroupIntervalsClosedDays(getClosedDaysBlankIntervals());
    setgroupIntervalsFeastDays(getFeastDaysBlankIntervals());
    retryFacility();
  }, [facilityId, chosenYear]);

  const submitYearlySettings = async () => {
    const res = (await social
      .service('facility-yearly-settings')
      .find({
        query: {
          $limit: 1,
          facilityId,
          year: chosenYear,
        },
      })
      .catch(() => undefined)) as Paginated<FacilityYearSettings>;

    if (res.total) {
      await social.service('facility-yearly-settings').patch(res.data[0].id, {
        additionalFeastDays: convertFeastDaysToDbDate(groupIntervalsFeastDays),
        facilityClosedDays: convertClosedDaysToDbDate(groupIntervalsClosedDays),
      });
    } else {
      await social.service('facility-yearly-settings').create({
        additionalFeastDays: convertFeastDaysToDbDate(groupIntervalsFeastDays),
        facilityClosedDays: convertClosedDaysToDbDate(groupIntervalsClosedDays),
        facilityId,
        year: chosenYear,
      });
    }
  };

  return (
    <>
      <div style={{ height: '40px' }} />
      <div className='w-100 mt-4 mb-4 d-flex flex-row justify-content-start align-items-center'>
        <h4 className='  pr-3 h-100 my-0 font-weight-bold' style={{ minWidth: '200px' }}>
          {t('YEARLY_SETTINGS') as string}{' '}
          <InfoModal content={t('YEARLY_SETTINGS_MODAL') as string} />{' '}
        </h4>
      </div>

      <Container
        className='mt-4 shadow py-4 px-5 rounded'
        style={{
          minHeight: '376px',
        }}
      >
        <Row className='mb-4'>
          <Col>
            <DropdownButton style={{ width: '100%' }} menuAlign='left' title={chosenYear}>
              {paddedYearlySettings.map((y, idx: any) => (
                <Dropdown.Item
                  className='handStyle'
                  key={`${y.year}`}
                  eventKey={idx.toString()}
                  onClick={() => {
                    setChosenYear(undefined);
                    setTimeout(() => {
                      setChosenYear(y.year);
                    }, 0);
                  }}
                >
                  {y?.year}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>
        <Row className='mb-2'>
          <Col>
            <h5 className='mt-2'>{t('FACILITY_CLOSED_DAYS') as string}</h5>
          </Col>
        </Row>
        {!forceUpdate && groupIntervalsClosedDays && (
          <V2ClosedDaysLogic
            disabled={false}
            key='closeddays'
            dayStr=''
            timeIntervals={groupIntervalsClosedDays ?? []}
            onSubmit={(intervals) => {
              // const groupIntervalsArray =
              //   groupIntervalsClosedDays ?? ({} as any);
              // groupIntervalsArray.freeIntervals = intervals;
              setgroupIntervalsClosedDays(intervals);
            }}
            year={chosenYear as number}
          />
        )}
        <Row className='mt-5 mb-2'>
          <Col>
            <h5>{t('ADDITIONAL_FEAST_DAYS') as string}</h5>
          </Col>
        </Row>
        {!forceUpdate && groupIntervalsFeastDays && (
          <AdditionalFeastDaysLogic
            disabled={false}
            key='feastdays'
            timeIntervals={groupIntervalsFeastDays ?? []}
            onSubmit={(intervals) => {
              setgroupIntervalsFeastDays(intervals);
            }}
            year={chosenYear as number}
          />
        )}
      </Container>
      <Row className='mb-4'>
        <Col> </Col>
      </Row>
      <div className='w-100 d-flex justify-content-between align-items-center'>
        <p className='text-info'>{t('SAVE_BEFORE_NEW_YEAR_FACILITY') as string}</p>

        <Button
          className='mt-5 mb-5 py-2 '
          type='submit'
          onClick={() => {
            // submit
            submitYearlySettings();
          }}
        >
          {' '}
          {t('SAVE_YEAR_UPDATES') as string}
        </Button>
      </div>
    </>
  );
});

export default FacilityYearlyLogic;
