import {DateTime, Duration} from "luxon";
import { TimeRange, timeRangeLength, compareTimeRanges } from "./timeRange";
import {SickNoteDB} from "../apis/attributes/yearly-sick-notes";

export class SickNote implements TimeRange {
    constructor(
        public id: string,
        public start: DateTime,
        public end: DateTime,
        public sickChild: boolean,
        public followUp: boolean,
        public doctorsNote: boolean,
        public presentDayOne: boolean,
        public note: string
    ) {
        if (end < start) {
            throw new Error(`end argument smaller start argument: !${end} < ${start}`);
        }
    }

    compareTo(other: SickNote): number {
        return compareTimeRanges(this, other);
    }

    length(): Duration {
        return timeRangeLength(this);
    }
    static fromAttributes(attributes: SickNoteDB): SickNote {
        return new SickNote(
            attributes.id,
            DateTime.fromISO(attributes.start),
            DateTime.fromISO(attributes.end),
            attributes.sickChild || false,
            attributes.followUp || false,
            attributes.doctorsNote || false,
            attributes.presentDayOne || false,
            attributes.note || ""
        );
    }

    toAttributes(): SickNoteDB {
        return {
            id: this.id,
            start: this.start.toISO() as string,
            end: this.end.toISO() as string,
            sickChild: this.sickChild,
            followUp: this.followUp,
            doctorsNote: this.doctorsNote,
            presentDayOne: this.presentDayOne,
            note: this.note
        };
    }
}

function randomBool(): boolean {
    return Math.random() < 0.5;
}

function randomNote(): string {
    if (Math.random() < 0.5) return "";
    return `Random Note ${Math.floor(Math.random() * 1000)}`;
}

export function generateRandomSickNote(year: number = new Date().getFullYear()): SickNote {
    const randomMonth = Math.floor(Math.random() * 12) + 1; // 1-12
    const randomDayStart = Math.floor(Math.random() * 28) + 1; // 1-28, for simplicity avoiding days in month variation
    const randomDuration = Math.floor(Math.random() * 7) + 1; // 1-7 days, you can adjust this

    const start = DateTime.fromObject({ year, month: randomMonth, day: randomDayStart });
    const end = start.plus({ days: randomDuration });
    const id = `${start.toISODate()}-${end.toISODate()}`;

    return new SickNote(
        id,
        start,
        end,
        randomBool(),
        randomBool(),
        randomBool(),
        randomBool(),
        randomNote()
    );
}