import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CallInSickEntry from './CallInSickEntry';
import { Container, Row, Col, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { YearlySickNotesDB } from '../../../apis/attributes/yearly-sick-notes';
import { SickNoteCollection } from '../../../core/SickNoteCollection';
import InfoModal from '../../Facility/containers/InfoModal';
import { observer } from 'mobx-react';
import { useObservable } from 'react-use-observable';
import { Paginated } from '@feathersjs/feathers';
import { social } from '../../../apis/social';
import { DateTime } from 'luxon';
import { SickNote } from '../../../core/SickNote';
import LoadingPage from '../../Layout/containers/LoadingPage';

const getEmptySickNotesCollection = (
  employeeId: string,
  targetYear = DateTime.now().year,
): SickNoteCollection =>
  new SickNoteCollection(`${employeeId}_${targetYear}`, employeeId, targetYear, []);
const yearlySickNotesDBToSickNoteCollection = (ysn: YearlySickNotesDB): SickNoteCollection =>
  new SickNoteCollection(
    ysn.id,
    ysn.employeeId,
    ysn.year,
    ysn.sickNotes.map(
      (sn) =>
        new SickNote(
          sn.id,
          DateTime.fromISO(sn.start) ?? DateTime.now(),
          DateTime.fromISO(sn.end) ?? DateTime.now(),
          sn.sickChild ?? false,
          sn.followUp ?? false,
          sn.presentDayOne ?? false,
          sn.presentDayOne ?? false,
          sn.note ?? '',
        ),
    ),
  );

/**
 * Patch the yearly settings with empty settings for the current year and the next year
 * @param yearSickNotes the sick notes for the year
 * @param employeeId the employee id
 */
const patchYearlySickNotes = (
  yearSickNotes: YearlySickNotesDB[] | undefined,
  employeeId: string,
): SickNoteCollection[] => {
  // the last year in the list is the current year plus one
  const lastYearInList = new Date().getFullYear() + 1;

  // if there are no yearly settings, create a new one for the current year and for the next year
  if (yearSickNotes === undefined) {
    return [
      getEmptySickNotesCollection(employeeId, lastYearInList - 1),
      getEmptySickNotesCollection(employeeId, lastYearInList),
    ];
  }
  // the first year is either the first year in the list or the current year
  const firstYearInList =
    yearSickNotes.length > 0 ? yearSickNotes[0].year : new Date().getFullYear();

  // edge case that can exist if someone fills out the next year before filling out this year
  if (yearSickNotes.length === 1 && firstYearInList === lastYearInList) {
    return [
      getEmptySickNotesCollection(employeeId, lastYearInList - 1),
      yearlySickNotesDBToSickNoteCollection(yearSickNotes[0]),
    ];
  }

  const returnValue: SickNoteCollection[] = [];

  // go over all years from the first year in the list to this year plus one
  for (let year = firstYearInList; year <= lastYearInList; year++) {
    // search for year that could be in DB already, if not there, create empty
    if (!yearSickNotes.find((ys) => ys.year === year)) {
      returnValue.push(getEmptySickNotesCollection(employeeId, year));
      // if there, put in list
    } else {
      returnValue.push(
        yearlySickNotesDBToSickNoteCollection(
          yearSickNotes.find((ys) => ys.year === year) as YearlySickNotesDB,
        ),
      );
    }
  }
  return returnValue;
};

export const CallInSickComponent: React.FC<{ employeeId: string | undefined }> = observer(
  ({ employeeId }) => {
    const { t } = useTranslation();

    const currentYear = DateTime.now().year;
    const [chosenYear, setChosenYear] = useState<number>(currentYear);

    const [allSickNotes] = useObservable<Paginated<YearlySickNotesDB> | null>(
      () =>
        social
          .service('yearly-sick-notes')
          .watch()
          .find({
            query: {
              $sort: {
                year: 1,
              },
              employeeId,
            },
          }),
      [employeeId],
    );

    // generate padded list of sick notes, separated by year
    const [paddedSickNotes, setPaddedSickNotes] = useState<SickNoteCollection[]>();

    // State to hold the currently selected year's sick notes
    const [yearlySickNotesState, setYearlySickNotesState] = useState<
      SickNoteCollection | undefined
    >();

    // first update ever when allSickNotes goes from undefined to defined
    useEffect(() => {
      if (allSickNotes?.data) {
        const paddedDataToBeAdded = patchYearlySickNotes(allSickNotes?.data, employeeId ?? '');
        setPaddedSickNotes(paddedDataToBeAdded);
        setYearlySickNotesState(
          paddedDataToBeAdded.find((ysn) => ysn.year === chosenYear) ||
            getEmptySickNotesCollection(employeeId ?? '', new Date().getFullYear()),
        );
      }
    }, [allSickNotes && allSickNotes]);

    // Effect to update yearlySickNotesState if chosenYear changes
    useEffect(() => {
      const maybeYearlySickNotesState = paddedSickNotes?.find((ysn) => ysn.year === chosenYear);
      if (maybeYearlySickNotesState) {
        // Deep comparison then assignment
        const shouldUpdate =
          JSON.stringify(yearlySickNotesState) !== JSON.stringify(maybeYearlySickNotesState);

        if (shouldUpdate) {
          setYearlySickNotesState(undefined);
          setTimeout(() => {
            setYearlySickNotesState(maybeYearlySickNotesState);
            setPaddedSickNotes(
              // use maybeYearlySickNotesState
              paddedSickNotes?.map((ysn) => {
                if (ysn.year === chosenYear) {
                  return maybeYearlySickNotesState;
                }
                return ysn;
              }),
            );
          }, 0);
        }
      }
    }, [paddedSickNotes, chosenYear]);

    // Function to save the sick notes to the database
    const saveYearlySickNotes = useCallback(async () => {
      if (!yearlySickNotesState) return;
      try {
        const yearlySickNotesDB = yearlySickNotesState.toAttributes();

        const existingRecord = await social
          .service('yearly-sick-notes')
          .get(yearlySickNotesState.id)
          .catch(() => {});

        if (!existingRecord?.id) {
          await social.service('yearly-sick-notes').create(yearlySickNotesDB);
        } else {
          const modifiedData = { ...yearlySickNotesDB };
          delete (modifiedData as any).id;
          delete (modifiedData as any).employeeId;
          await social.service('yearly-sick-notes').patch(yearlySickNotesDB.id, modifiedData);
        }
      } catch (err) {
        console.error(err);
        alert('Error: please reload the page');
      }
    }, [yearlySickNotesState]);

    return (
      <>
        <div style={{ height: '40px' }} />

        <Container>
          <div className='w-100 mt-4 mb-4 d-flex flex-row justify-content-start align-items-center'>
            <h4 className='pr-3 h-100 my-0 font-weight-bold' style={{ minWidth: '200px' }}>
              {t('CALL_IN_SICK') as string}{' '}
              <InfoModal content={t('CALL_IN_SICK_MODAL') as string} />{' '}
            </h4>
          </div>
          <Container
            className='mt-4 shadow py-4 px-5 rounded'
            style={{
              minHeight: '376px',
            }}
          >
            <Row className='mb-4'>
              <Col>
                <DropdownButton style={{ width: '100%' }} menuAlign='left' title={chosenYear}>
                  {paddedSickNotes?.map((y, idx: any) => (
                    <Dropdown.Item
                      className='handStyle'
                      key={`${y.year}`}
                      eventKey={idx.toString()}
                      onClick={() => {
                        setChosenYear(y.year);
                      }}
                    >
                      {y?.year}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col>
                <h5 className='mt-2'>{t('CALL_IN_SICK') as string}</h5>
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col>
                {paddedSickNotes ? (
                  <CallInSickEntry
                    disabled={false}
                    year={chosenYear}
                    sickNotes={paddedSickNotes ?? []}
                    onSickNotesChange={() => {}}
                  />
                ) : (
                  <LoadingPage />
                )}
              </Col>
            </Row>
          </Container>
        </Container>

        <div className='w-100 d-flex justify-content-between align-items-center'>
          <p className='text-info'>{t('SAVE_BEFORE_NEW_YEAR_SICK') as string}</p>

          <Button
            className='mt-5 mb-5 py-2 '
            type='submit'
            onClick={() => {
              saveYearlySickNotes();
            }}
          >
            {' '}
            {t('SAVE_SICK_NOTES') as string}
          </Button>
        </div>
      </>
    );
  },
);
export default CallInSickComponent;
