import { observer } from 'mobx-react';
import { useLocalObservable } from 'mobx-react-lite';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { useTranslation } from 'react-i18next';
import { social, Pending } from '../../../apis/social';
import { delimiters, ReactTagsType } from '../../TariffCreation/containers/tariffSettings';
import { EmployeeProps } from './CreateEmployee';

export const WorkingHours: React.FC<EmployeeProps> = observer(({ employeeId }) => {
  const { t } = useTranslation();
  const [enableSubmit, setEnableSubmit] = useState(true);
  // eslint-disable-next-line max-len
  const [localWorkingDays, setLocalWorkingDays] = useState<ReactTagsType[]>([]);
  // eslint-disable-next-line max-len
  const [localWeeklyHours, setLocalWeeklyHours] = useState<number | undefined>(undefined);
  // eslint-disable-next-line max-len
  const [localSpecialHours, setLocalSpecialHours] = useState<number | undefined>(undefined);

  const history = useHistory();

  const store = useLocalObservable(() => ({
    get facility() {
      return fromPromise(social.service('employee').get(employeeId));
    },
  }));

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}/:employeeId`, { employeeId }),
    });
  }, []);

  const updateDB = async () => {
    setEnableSubmit(false);

    try {
      // await social.service('employee').patch(employeeId, {
      //   specialWorkingHours: localSpecialHours,
      //   weeklyWorkingHours: localWeeklyHours!,
      //   workingDaysOfWeek: localWorkingDays.map((t) => (parseInt(t.id, 10))),
      // });

      navigateTo('/admin/employee/availability');
    } catch (error) {
      setEnableSubmit(true);

      // eslint-disable-next-line no-alert
      alert('Error: please reload the page');
    }
  };

  const handleDelete = (i: any) =>
    setLocalWorkingDays(localWorkingDays.filter((_, index) => index !== i));

  const handleAddition = () => {
    // if (duplicateExists.length > 0) { return; }
    // const tagExists = daysSuggestion.fil
    // ter((e) => e.text.toLowerCase() === tag.text.toLowerCase());
    // if (tagExists.length === 0) { return; }
    // if (localWorkingDays.length < 7) setLocalWorkingDays([...localWorkingDays, tagExists[0]]);
  };

  const tagsValid = () => localWorkingDays.length > 0;
  const handleDrag = (tag: ReactTagsType, currPos: number, newPos: number) => {
    const tags = [...localWorkingDays];
    const draggedTags = tags.slice();
    draggedTags.splice(currPos, 1);
    draggedTags.splice(newPos, 0, tag);
    // re-render
    setLocalWorkingDays(draggedTags);
  };

  return (
    <Container className='pt-5 px-4 pb-4'>
      {store.facility.case({
        fulfilled: () => (
          <>
            {!localWeeklyHours && setLocalWeeklyHours(0)}
            {!localSpecialHours && setLocalSpecialHours(0)}

            <strong className='creatorTitle colorBodyText'>
              {t('WEEKLY_WORKING_DAYS') as string}
            </strong>

            <br />

            <ReactTags
              allowUnique
              tags={localWorkingDays}
              // suggestions={daysSuggestion}
              inputFieldPosition='inline'
              placeholder=''
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              delimiters={delimiters}
            />

            <p className='mt-5 mb-1'>
              <strong>{t('WEEKLY_WORKING_HOURS') as string}</strong>
            </p>
            <input
              className='w-100 p-3 border'
              placeholder=''
              defaultValue={localWeeklyHours || ''}
              onChange={(e) => setLocalWeeklyHours(parseInt(e.currentTarget.value, 10))}
            />

            <p className='mt-5 mb-1'>
              <strong>{t('SPECIAL_HOURS') as string}</strong>
            </p>

            <input
              className='w-100 p-3 border'
              placeholder=''
              defaultValue={localSpecialHours || ''}
              onChange={(e) => setLocalSpecialHours(parseInt(e.currentTarget.value, 10))}
            />
          </>
        ),
        pending: () => <Pending />,
        rejected: () => <> </>,
      })}

      <Button
        className='py-3 px-3 w-100'
        style={{ marginTop: '200px' }}
        variant='primary'
        disabled={!enableSubmit || !localSpecialHours || !localWeeklyHours || !tagsValid()}
        onClick={() => updateDB()}
      >
        {t('SAVE') as string}
      </Button>
    </Container>
  );
});

export default WorkingHours;
