export default `<h2 id="lizenzvertrag">Lizenzvertrag</h2>
<h3 id="browserbasiertes-arbeitszeit-">Browserbasiertes Arbeitszeit-</h3>
<h3 id="erfassungsprogramm-kidig-az">erfassungsprogramm: KiDig-AZ</h3>
<p>Zwischen der</p>
<ol>
<li>Firma ictt system-consulting Inhaber Karl Cziumplik Eschholzstraße 94 79115 Freiburg</li>
</ol>
<p>im Folgenden &quot;Hauptlizenznehmer&quot; bzw. &quot;Lizenzgeber&quot; genannt</p>
<p>und</p>
<ol>
<li>...................................... <em>Bezeichnung der Institution etc.</em></li>
</ol>
<p>Vertreten durch </p>
<p>......................................... <em>(Straße und Hausnummer)</em></p>
<p>........................... ............. <em>(Postleitzahl und Ort)</em></p>
<p>dem Käufer der Lizenz, im Folgenden &quot;Unterlizenznehmer&quot; bzw. &quot;Lizenznehmer&quot; genannt,</p>
<p>wird folgender</p>
<h3 id="l-i-z-e-n-z-v-e-r-t-r-a-g">L i z e n z v e r t r a g</h3>
<p>abgeschlossen:</p>
<h3 id="pr-ambel">Präambel</h3>
<p>Dieser Lizenzvertrag betrifft die zeitweilige Überlassung des browserbasierten<br>Arbeitszeiterfassungsprogramms: <code>KiDig-AZ</code> in der jeweils aktuellen Version durch den Lizenzgeber, 
insbesondere an juristische Personen des öffentlichen Rechts, oder an ein öffentlich-rechtliches Sondervermögen, 
oder an private Träger von Kindergärten, an Vereine, Verbände, Unternehmer und ggf. auch an Privatpersonen. 
Der Lizenzgeber gewährt dem Lizenznehmer auf der Grundlage dieses Vertrags für einen begrenzten Zeitraum den 
Gebrauch des Softwarepakets KiDig-Arbeitszeiterfassungsprogramm und überlässt dieses Programm dem Lizenznehmer 
in der jeweils aktuellen Version.</p>
<h3 id="-1-allgemeine-bestimmungen">§ 1 Allgemeine Bestimmungen</h3>
<ol>
<li>Die Vertragsbeziehungen zwischen den Parteien dieser Vereinbarung werden in diesem Lizenzvertrag abschließend
geregelt. Der Vorrang irgendwelcher individuellen Vereinbarungen zwischen dem Lizenzgeber und dem Lizenznehmer bleibt 
hiervon unberührt. Die jeweils aktuelle Fassung dieses Lizenzvertrags wird dem Lizenznehmer durch den Lizenzgeber 
vor Vertragsschluss zur Verfügung gestellt.</li>
</ol>
<ol>
<li>Mit Abschluss dieses Lizenzvertrags erkennt der Lizenznehmer die hierin getroffenen Bestimmungen an. 
Abweichende oder ergänzende Allgemeine Geschäftsbedingungen des Lizenznehmers finden keine Anwendung. 
Ihrer Geltung wird hiermit ausdrücklich widersprochen, auch für den Fall, dass der Lizenzgeber in Kenntnis solcher
Allgemeinen Geschäftsbedingungen des Lizenznehmers mit diesem den Lizenzvertrag abschließt.</li>
</ol>
<ol>
<li>Ist der Lizenznehmer kein &quot;Verbraucher&quot; im Sinne des § 13 BGB, dann finden die Bestimmungen 
der §§ 312 g Abs. 1 Satz 1 Nr. 1-3 Satz 2, 343 BGB im Verhältnis zwischen dem Lizenzgeber und dem Lizenznehmer 
keine Anwendung.</li>
</ol>
<h3 id="-2-vertragsgegenstand">§ 2 Vertragsgegenstand</h3>
<ol>
<li>Gegenstand dieses Lizenzvertrags ist die auf die Laufzeit dieses Lizenzvertrags begrenzte, entgeltliche Überlassung
des browserbasierten Arbeitszeiterfassungsprogramms: <code>KiDig-AZ</code> durch den Lizenzgeber zur Nutzung gem. § 5 dieser 
Vereinbarung an den Lizenznehmer.</li>
</ol>
<ol>
<li>Bestandteil der Software KiDig ist eine browserbasierte Kindergarten-Arbeitszeiterfassung <code>KiDig-AZ</code> 
zur Verwendung in Kindergärten. Eine nähere Beschreibung der Funktionalitäten der Software kann auf unserer 
Homepage <a href="https://www.kidig-online.de">https://www.kidig-online.de</a> eingesehen werden. Eine gedruckte Dokumentation gehört nicht zum Lieferumfang. 
Vielmehr besteht die Dokumentation nur aus den vom Lizenzgeber bereitgestellten elektronischen Dokumenten, die, 
wie die Software selbst, online vom Lizenzgeber zur Verfügung gestellt werden. 
Der Lizenznehmer hat keinen darüberhinausgehenden Anspruch auf Dokumentation oder Hilfestellung, also insbesondere 
keinen Anspruch auf die Bereitstellung einer Dokumentation in einem bestimmten Umfang oder einer bestimmten Qualität 
oder auf individuelle Hilfestellungen durch den Lizenzgeber. Wird dergleichen vom Lizenznehmer gleichwohl gewünscht, 
bedarf es einer separaten Vereinbarung über diese gesonderten vergütungspflichtigen Tätigkeiten zwischen dem 
Lizenzgeber und dem Lizenznehmer.</li>
</ol>
<ol>
<li>Für die Beschaffenheit und Funktionalität der vom Lizenzgeber zur Verfügung gestellte 
browserbasierte Software ist die im Zeitpunkt des Vertragsschlusses gültige und online 
jederzeit einsehbare Leistungsbeschreibung der Software abschließend maßgeblich.
Eine über die Leistungsbeschreibung hinausgehende Beschaffenheit der Software schuldet der Lizenzgeber nicht. 
Eine solche Verpflichtung kann der Lizenznehmer insbesondere nicht aus anderen Darstellungen des Softwarepakets 
in öffentlichen Äußerungen oder in der Werbung Dritter herleiten, es sei denn, der Lizenzgeber hat dem Lizenznehmer 
eine über die Leistungsbeschreibung hinausgehende Beschaffenheit ausdrücklich zugesichert. Als durch den Lizenzgeber 
zugesichert gelten insoweit nur solche Eigenschaften der Software, die vom Lizenzgeber vor dem Abschluss dieses 
Lizenzvertrags ausdrücklich gegenüber dem Lizenznehmer schriftlich geäußert und als zugesicherte Eigenschaften 
bezeichnet worden sind.
Die Interoperabilität mit der beim Lizenznehmer vorhandenen Hard- und Software ist keine 
geschuldete Beschaffenheit der lizenzierten Software, soweit in der Leistungsbeschreibung nicht ausdrücklich kompatible
Hard- und Software ausgewiesen ist.</li>
</ol>
<ol>
<li>Der Lizenzgeber weist den Lizenznehmer ausdrücklich darauf hin, 
dass das lizenzierte browserbasierte Arbeitszeiterfassungsprogramm nur dann und in dem Umfang genutzt werden kann,
soweit der Lizenznehmer einen Zugang zum Internet gewährleistet.</li>
</ol>
<h3 id="-3-vertragsdauer">§ 3 Vertragsdauer</h3>
<ol>
<li><p>Der Lizenzvertrag läuft auf unbestimmte Zeit, beginnend ab der Entrichtung des bei Bestellung vereinbarten Entgelts.</p>
</li>
<li><p>Die Mindestvertragslaufzeit beträgt ein Jahr. Die ordentliche Kündigung ist erstmals zum Ende der Mindestlaufzeit 
möglich, hiernach zum Ende eines jeden Jahres, dies jeweils unter Einhaltung einer Kündigungsfrist von drei Monaten.</p>
</li>
</ol>
<ol>
<li>Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt. 
Ein wichtiger Grund, der den Lizenzgeber zur außerordentlichen Kündigung berechtigt, liegt insbesondere auch dann vor,
wenn der Lizenznehmer mit seinen Zahlungsverpflichtungen in Verzug gerät bzw. wenn zu Lasten des Lizenznehmers eine
Vertragsstrafe gem. § 7 dieser Vereinbarung fällig wird. Darüber hinaus, wenn durch den Lizenznehmer einer Verstoß des 
Lizenznehmers gegen gesetzliche Verbote, insbesondere die Verletzung urheberrechtlicher, wettbewerbsrechtlicher, 
namensrechtlicher oder datenschutzrechtlicher Bestimmungen vorliegt, der Lizenznehmer nationalsozialistische, 
rassistische, extremistische, gewaltverherrlichende, pornographische oder in anderer Form illegale Inhalte 
veröffentlicht, der Lizenznehmer trotz Abmahnung durch den Lizenzgeber weiterhin gegen die Bestimmungen des 
geschlossenen Vertrages verstößt, die Lizenznehmer rechtwidrige Kopien des browserbasierten 
Arbeitszeiterfassungsprogramms erstellt, sich die technischen oder sonstigen Standards grundlegend ändern.</li>
</ol>
<ol>
<li>Jede Kündigung bedarf zu ihrer Wirksamkeit der Schriftform, die auch dann eingehalten ist, wenn die Kündigung
per E-Mail oder per Fax erfolgt.</li>
</ol>
<ol>
<li>Im Falle der außerordentlichen Kündigung ist der Lizenzgeber zur Löschung sämtlicher beim Lizenzgeber
vorhandenen vom Lizenznehmer abgelegten Daten innerhalb von drei Monaten verpflichtet.</li>
</ol>
<h3 id="-4-bereitstellung-der-software">§ 4 Bereitstellung der Software</h3>
<ol>
<li>Die Überlassung des browserbasierten Arbeitszeiterfassungsprogramms erfolgt online - auf Wunsch drei Monate 
zu Testzwecken kostenlos - jedoch in der Regel unverzüglich nach Vertragsschluss und Entrichtung
der fälligen Lizenzgebühr seitens des Lizenznehmers.</li>
</ol>
<ol>
<li>Überlässt der Lizenzgeber dem Lizenznehmer Patches oder Updates, die frühere Versionen des Softwarepakets ersetzen 
(&quot;Altsoftware&quot;), unterliegen einerseits die Patches und Updates ebenfalls den Bedingungen dieses Lizenzvertrags und 
erlöschen andererseits die Rechte des Lizenznehmers an der Altsoftware auch ohne ein ausdrückliches Rückgabeverlangen 
des Lizenzgebers, wenn der Lizenznehmer diese neue Version installiert und in Betrieb nimmt. 
Für Altsoftware gilt der nachstehende Absatz 5 entsprechend mit der Maßgabe, dass an die Stelle des Vertragsendes 
der Zeitpunkt der Inbetriebnahme der Patches oder Updates tritt.</li>
</ol>
<ol>
<li>Für die Computer Hardware ist ausschließlich der Lizenznehmer verantwortlich. Für die Einrichtung der Software
ist ebenfalls ausschließlich der Lizenznehmer verantwortlich.</li>
</ol>
<ol>
<li>Der Lizenzgeber stellt dem Lizenznehmer online einen vollständigen Zugang per E-Mail Adresse und Passwort zum 
browserbasierten Arbeitszeiterfassungsprogramm nach Vertragsbeginn und vorheriger Entrichtung der aktuellen Lizenzgebühr 
zur verfügung. Der Lizenznehmer sichert 
dem Lizenzgeber hiermit zu, dass er auch innerhalb seines Unternehmens/Betriebs durch geeignete Maßnahmen dafür Sorge 
trägt, dass der Zugang nur denjenigen Beschäftigten zugänglich ist, die diesen für die Inbetriebnahme der
Software benötigen. Zudem wird der Lizenznehmer dafür Sorge tragen, dass diese Beschäftigten die Zugangsdaten 
nicht an andere Beschäftigte oder Dritte weitergeben werden, und die Zugangsdaten von diesen Beschäftigten zu keinem 
anderen Zweck als der Inbetriebnahme der Software eingesetzt wird.</li>
</ol>
<ol>
<li>Nach Ablauf der Vertragslaufzeit ist der Lizenzgeber berechtigt, die vom Lizenznehmer abgelegten Daten nach einem
Zeitraum von drei Monaten vollständig zu löschen.</li>
</ol>
<h3 id="-5-nutzungsrechte">§ 5 Nutzungsrechte</h3>
<ol>
<li>Der Lizenzgeber räumt dem Lizenznehmer unter der aufschiebenden Bedingung der vorherigen vollständigen Zahlung 
der für einen einzelnen Vergütungsabschnitt vereinbarten Vergütung jeweils für die Dauer dieses Vergütungsabschnitts 
ein einfaches, nicht ausschließliches, räumlich unbeschränktes, nicht übertragbares, nicht unterlizenzierbares und 
vom Lizenzgeber nicht widerrufliches Recht zur eigenen Nutzung der Software im Rahmen ihrer bestimmungsgemäßen 
Funktionen für ausschließlich eigene Zwecke des Lizenznehmers ein. Der Lizenznehmer erwirbt das browserbasierte 
Arbeitszeiterfassungsprogramm nicht etwa durch Kauf, Gegenstand dieses Vertrags ist ausschließlich die 
zeitlich begrenzte entgeltliche Nutzung (&quot;Miete&quot;).</li>
</ol>
<ol>
<li>Die Nutzung ist auf eine vom Lizenzgeber angebotene, gestaffelte Anzahl von Mitarbeiter*innen der vom Lizenznehmer 
betreuten Einrichtung beschränkt.</li>
</ol>
<ol>
<li>Auf eine Überlassung des Quellcodes oder die Einräumung von Nutzungsrechten hieran hat der Lizenznehmer keinen 
Anspruch.</li>
</ol>
<ol>
<li>Jede Nutzung des browserbasierten Arbeitszeiterfassungsprogramms über den für die Lizenz vereinbarten Umfang hinaus 
(&quot;Übernutzung&quot;) ist eine Vertragsverletzung. Für den Zeitraum einer solchen Übernutzung, d.h. bis zum Erwerb der 
erforderlichen Lizenz oder die Einstellung der Übernutzung durch den Lizenznehmer, ist der Lizenznehmer verpflichtet, 
an den Lizenzgeber die Vergütung für eine hinreichende Lizenz nach dem für die Zeit der Übernutzung geltenden Preis- und 
Leistungsverzeichnis des Lizenzgebers zu bezahlen. Das jeweils aktuelle Preis- und Leistungsverzeichnis des Lizenzgebers 
kann jederzeit online eingesehen werden.</li>
</ol>
<h3 id="-6-verg-tung">§ 6 Vergütung</h3>
<ol>
<li>Die Lizenzgebühr wird in Abhängigkeit von der Anzahl der Mitarbeiter*innen der Einrichtung, durch
den Lizenzgeber gestaffelt, erhoben.
Der Lizenznehmer hat eine Lizenzgebühr in Höhe von derzeit monatlich 45, 50, 55 oder 60 € zu entrichten. Die Höhe des
Beitrags ermisst sich dabei gestaffelt an der Anzahl der genutzten Mitarbeiter Zugänge.
Diese Lizenzgebühr ist in Höhe einer Jahres-Lizenzgebühr (derzeit also 540, 600, 660 oder 720 €) im Voraus zur Zahlung 
fällig, und zwar erstmals bei Abschluss dieses Vertrags, und sodann jährlich. Der Lizenzgeber weist ausdrücklich darauf 
hin, dass die Nutzung der Software nur dann vollständig möglich ist, wenn er zuvor die Jahres-Lizenzgebühr entrichtet 
hat.</li>
</ol>
<ol>
<li>Für die Höhe der Vergütung in künftigen Jahren ist die jeweils gültige Preis- und Konditionsliste des Lizenzgebers,
die online zur Verfügung gestellt wird, maßgebend. Hierbei ist eine Preiserhöhung auf maximal 
10 % pro Jahr begrenzt.</li>
</ol>
<ol>
<li>Die Vergütung beinhaltet die Umsatzsteuer. Der Lizenzgeber wird dem Lizenznehmer zeitnah zum Einzug eine Rechnung 
im PDF-Format per E-Mail zukommen lassen.</li>
</ol>
<ol>
<li>Der Lizenzgeber ist berechtigt, die jeweils fällige Vergütung im Wege des Lastschriftverfahrens einzuziehen. 
Der Lizenznehmer ermächtigt den Lizenzgeber, den jeweiligen Rechnungsbetrag von dem vom Lizenznehmer zu benennenden 
Bankkonto einzuziehen. Diese Einzugsermächtigung ist jederzeit frei widerruflich. Sollte die Einzugsermächtigung vom 
Lizenznehmer widerrufen werden oder sonst unwirksam sein, behält sich der Lizenzgeber vor, für jede Zahlung 
eine Bearbeitungsgebühr nach Maßgabe des jeweils aktuellen Preis- und Leistungsverzeichnisses zu berechnen.</li>
</ol>
<ol>
<li>Der Lizenznehmer ist nicht berechtigt, gegenüber dem Lizenzgeber mit Forderungen aufzurechnen, die nicht 
rechtskräftig festgestellt bzw. von dem Lizenzgeber anerkannt wurden oder unstrittig sind. Dies gilt nicht,
wenn es sich um Mängelansprüche des Lizenznehmers auf der Grundlage dieses Vertrags handelt.</li>
</ol>
<ol>
<li>Sollte der Lizenznehmer bereits einen Vertrag über die Nutzung des Dienstplangestalltungsprogramms <code>KiDig-DP</code> und des
Arbeitszeiterfassungsprogramms <code>KiDig-AZ</code> abgeschlossen haben, gilt die Vergütung gemäß der dort festgesetzen 
Konditionen anstatt der oben genannten.</li>
</ol>
<h3 id="-7-pflichten-des-lizenznehmers">§ 7 Pflichten des Lizenznehmers</h3>
<ol>
<li>Der Lizenznehmer verpflichtet sich, individuelle Zugänge der einzelnen Mitarbeiter nicht an Dritte weiterzugeben.
Ebenfalls ist ein &quot;Account-sharing&quot; ist dem Lizenznehmer ausdrücklich untersagt.</li>
</ol>
<ol>
<li>Der Lizenznehmer verpflichtet sich, Betriebs- und Geschäftsgeheimnisse des Lizenzgebers zu wahren und die Unterlagen,
soweit sie sich auf diese und das Vertragsverhältnis beziehen, so aufzubewahren, dass die Dritten nicht zugänglich sind. 
Er hat über alle während der Vertragsdauer erworbenen Kenntnisse, über Geschäftsvorgänge und interne, 
insbesondere vertrauliche Angelegenheiten auch nach Vertragsende Stillschweigen zu bewahren.</li>
</ol>
<ol>
<li>Der Lizenzgeber bleibt Inhaber aller Rechte an den vom Lizenznehmer überlassenen Programm einschließlich des 
jeweils dazugehörigen Materials, auch wenn der Lizenznehmer diese verändert oder mit seinen eigenen Programmen oder 
denjenigen eines Dritten verbindet. Bei Änderungen oder Verbindungen dieser Art sowie bei der Erstellung von Kopien
bringt der Lizenznehmer einen entsprechenden Urhebervermerk an und stellt dem Lizenzgeber unentgeltlich eine Kopie zur 
Verfügung.</li>
</ol>
<ol>
<li>Der Lizenznehmer räumt dem Lizenzgeber an seinen Daten und übermittelten Inhalten wie bspw. Logos) diejenigen 
urheberrechtlichen Nutzungsrechte und sonstige Befugnisse ein, die zur Durchführung des Vertragsverhältnisses 
erforderlich sind.</li>
</ol>
<h3 id="-8-pflichten-des-lizenzgebers">§ 8 Pflichten des Lizenzgebers</h3>
<ol>
<li>Der Lizenzgeber stellt dem Lizenznehmer Hilfe bei der Einrichtung und bei Fragen zum Einsatz und 
der Anwendung des browserbasierten Arbeitszeiterfassungsprogramms in seinem Bereich mittels eines online-Handbuchs
zur Verfügung.
Die Beseitigung von Mängeln erfolgt durch den Lizenzgeber kostenfrei.</li>
</ol>
<ol>
<li>Für die jeweiligen Endanwender des Lizenznehmers besteht keine kostenlose Unterstützung (Support) durch den 
Lizenzgeber im Rahmen dieser Vereinbarung.</li>
</ol>
<h3 id="-9-vertragsstrafe">§ 9 Vertragsstrafe</h3>
<ol>
<li>Verstößt der Lizenznehmer gegen seine Vertragspflichten gem. § 4 Abs. 4, § 4 Abs. 5 oder § 5 Abs. 1, wird für jeden 
Verstoß eine an den Lizenzgeber zu zahlende Vertragsstrafe in Höhe von € 1.000, -- fällig. Die Einreden der natürlichen 
oder juristischen Handlungseinheit, der mangelnden Haftung für Erfüllungsgehilfen sowie des Gesamtvorsatzes sind 
ausgeschlossen. Die Vertragsstrafe wird nicht fällig, wenn der Lizenznehmer die Zuwider-handlung nicht zu vertreten hat. 
Zudem bleibt dem Lizenznehmer der Nachweis vorbehalten, dass dem Lizenzgeber durch die Zuwiderhandlung kein oder nur 
ein wesentlich geringerer Schaden entstanden ist.</li>
</ol>
<ol>
<li>Fällige Vertragsstrafen werden auf den Schadensersatzanspruch des Lizenzgebers aufgrund derselben Pflichtverletzung
angerechnet. Der Nach-weis eines weitergehenden Schadens seitens des Lizenzgebers bleibt vorbehalten.</li>
</ol>
<h3 id="-10-sachm-ngel">§ 10 Sachmängel</h3>
<ol>
<li>Das browserbasierte Arbeitszeiterfassungsprogramm hat die vereinbarte Beschaffenheit und eignet sich für die 
vertraglich vorausgesetzte, bei fehlender Vereinbarung für die gewöhnliche Verwendung. Sie genügt dem Kriterium
praktischer Tauglichkeit und hat die bei Software dieser Art übliche Qualität. Sie ist jedoch – wie jede Software – 
nicht absolut fehlerfrei. Eine Funktionsbeeinträchtigung des Programms, die aus Hardwaremängeln, Umgebungsbedingungen, 
Fehlbedienung o.ä. resultiert, ist kein Mangel. Darüber hinaus bleibt eine unerhebliche Minderung der Qualität 
unberücksichtigt.</li>
</ol>
<ol>
<li>Zeigt sich ein Mangel, so ist der Lizenzgeber hierüber unverzüglich zu informieren. Bei Sachmängeln kann der
Lizenzgeber zunächst nacherfüllen. Die Nacherfüllung erfolgt nach Wahl des Lizenzgebers durch Beseitigung des Mangels, 
durch Lieferung von Software, die den Mangel nicht hat, oder dadurch, dass der Lizenzgeber Möglichkeiten aufzeigt, 
die Auswirkungen des Mangels zu vermeiden. Wegen des Mangels sind zumindest drei Nachbesserungsversuche hinzunehmen. 
Eine gleichwertige neue Programmversion oder die gleichwertige vorhergehende Programmversion ohne den Fehler ist 
vom Lizenznehmer zu übernehmen, wenn dies für ihn zumutbar ist.</li>
</ol>
<ol>
<li>Der Lizenzgeber kann die Mangelbeseitigung nach seiner Wahl vor Ort oder in den eigenen Geschäftsräumen durchführen 
oder Leistungen auch durch Fernwartung erbringen.</li>
</ol>
<ol>
<li>Der Lizenzgeber kann Mehrkosten daraus verlangen, dass die Software verändert, außerhalb der vorgesehenen Umgebung 
eingesetzt oder falsch bedient wurde. Er kann Aufwendungsersatz auch dann verlangen, wenn kein Mangel gefunden wird und
der Lizenznehmer die Mangelrüge nicht ohne Fahrlässigkeit erhoben hatte. Die Beweislast hierfür liegt beim Lizenznehmer.</li>
</ol>
<ol>
<li>Wenn der Lizenzgeber die Nacherfüllung endgültig verweigert oder diese endgültig fehlschlägt oder dem Lizenznehmer 
nicht zumutbar ist, kann der Lizenznehmer entweder vom Vertrag zurücktreten oder die Vergütung angemessen
herabsetzen oder – unter den nachstehenden Voraussetzungen – Schadensersatz oder Aufwendungsersatz verlangen. Auf die 
diesbezüglichen Verjährungsfristen gem. § 11 dieses Vertrags sei verwiesen.</li>
</ol>
<ol>
<li>Sachmängelhaftungsansprüche des Lizenznehmers entfallen, wenn dieser ohne vorherige Zustimmung des Lizenzgebers 
Änderungen an der Software vorgenommen hat oder durch einen Dritten hat vornehmen lassen oder die Software vom
Lizenznehmer zu einem nicht vom Lizenzvertrag gedeck-ten Zweck eingesetzt wird, und wenn die Änderung oder die 
vertragswidrige Nutzung für das Auftreten des Mangels allein verantwortlich ist.</li>
</ol>
<ol>
<li>Die verschuldensunabhängige Haftung des Lizenzgebers für im Zeitpunkt der Zurverfügungstellung der Software 
vorhandene Mängel ist ausgeschlossen.</li>
</ol>
<h3 id="-11-rechtsm-ngel">§ 11 Rechtsmängel</h3>
<ol>
<li>Der Lizenzgeber gewährleistet, dass der vertragsgemäßen Nutzung der Software durch den Lizenznehmer keine Rechte
Dritter entgegenstehen. Bei Rechtsmängeln leistet der Lizenzgeber dadurch Gewähr, dass er dem Lizenznehmer nach 
seiner Wahl eine rechtlich einwandfreie Nutzungsmöglichkeit an der Software verschafft.</li>
</ol>
<ol>
<li>Der Lizenznehmer unterrichtet den Lizenzgeber unverzüglich schriftlich, falls Dritte Schutzrechte
(z.B. Urheber- oder Patentrechte) an der Soft-ware geltend machen. Der Lizenznehmer ermächtigt den Lizenzgeber,
die Auseinandersetzung mit dem Dritten allein zu führen. Solange der Lizenzgeber von dieser Ermächtigung Gebrauch 
macht, darf der Lizenznehmer von sich aus die Ansprüche des Dritten nicht ohne Zustimmung des Lizenzgebers anerkennen; 
der Lizenzgeber wehrt dann die Ansprüche des Dritten auf eigene Kosten ab und stellt den Lizenznehmer von allen mit der 
Abwehr dieser Ansprüche verbundenen Kosten frei, soweit diese nicht auf pflichtwidrigem Verhalten des Lizenznehmers 
(z.B. der vertragswidrigen Nutzung des browserbasierte Arbeitszeiterfassungsprogramms) beruhen.</li>
</ol>
<h3 id="-12-haftung-und-haftungsbeschr-nkung">§ 12 Haftung und Haftungsbeschränkung</h3>
<ol>
<li><p>Der Lizenzgeber leistet Schadensersatz oder Ersatz vergeblicher Aufwendungen, gleich aus welchem Rechtsgrund 
(z.B. aus rechtsgeschäftlichen und rechtsgeschäftsähnlichen Schuldverhältnissen, Sach- und Rechts-mängel, 
Pflichtverletzung und unerlaubte Handlung) nur in folgendem Umfang:</p>
<p>   a)    Die Haftung bei Vorsatz und aus Garantie ist unbeschränkt.</p>
<p>   b)    Bei grober Fahrlässigkeit haftet der Lizenzgeber in Höhe des typischen und bei Vertragsabschluss 
vorhersehbaren Schadens.</p>
<p>   c)    Bei nicht grob fahrlässiger Verletzung einer so wesentlichen Pflicht, dass die Erreichung des Vertragszwecks 
   gefährdet ist, haftet der Lizenzgeber in Höhe des typischen und bei Vertragsabschluss vorhersehbaren Schadens, 
   höchstens jedoch mit € 10.000,-- je Schadensfall und € 50.000,-- für alle Schadensfälle aus und im Zusammenhang 
   mit dem Vertrag insgesamt.</p>
</li>
</ol>
<ol>
<li>Dem Lizenzgeber bleibt der Einwand des Mitverschuldens offen. Der Lizenznehmer hat insbesondere die Pflicht zur 
Datensicherung und zur Ab-wehr von Schadsoftware jeweils nach dem aktuellen Stand der Technik.</li>
</ol>
<ol>
<li>Bei Arglist, Verletzung von Leben, Körper und Gesundheit und bei An-sprüchen aus dem Produkthaftungsgesetz gelten 
die gesetzlichen Rege-lungen ohne Beschränkungen.</li>
</ol>
<ol>
<li>Für Leistungsstörungen ist der Lizenzgeber nur verantwortlich soweit diese die von ihm zu erbringenden 
Leistungen betreffen. Störungen hat der Lizenzgeber im Rahmen der technischen und betrieblichen Möglichkeiten 
unverzüglich zu beseitigen. Der Lizenznehmer ist verpflichtet, dem Lizenzgeber für ihn erkennbare Störungen 
unverzüglich anzuzeigen. Erfolgt die Beseitigung der Störung nicht innerhalb eines angemessenen Zeitraums,
hat der Lizenz-nehmer dem Lizenzgeber eine angemessene Nachfrist zu setzen.</li>
</ol>
<ol>
<li>Der Lizenzgeber übernimmt keine Haftung für direkte oder indirekte Schäden aufgrund technischer Probleme, 
Serverausfall, Datenverlust, Übertragungsfehler, Datenunsicherheit oder sonstiger Gründe, es sei denn, ihm können 
Vorsatz oder grobe Fahrlässigkeit nachgewiesen wer-den. Alle Ansprüche des Lizenznehmers sind auf den Auftragswert 
beschränkt, sofern gesetzlich zulässig.</li>
</ol>
<ol>
<li>Der Lizenzgeber haftet dem Kunden für Schäden unbegrenzt, die von ihm oder einem seiner Erfüllungsgehilfen 
oder gesetzlichen Vertreter vorsätzlich oder grob fahrlässig verursacht werden. Für leichte Fahrlässigkeit haftet der 
Lizenzgeber nur, soweit eine Pflicht verletzt wird, deren Einhaltung für die Erreichung des Vertragszwecks von
besonderer Bedeutung ist (Kardinalpflicht). In diesem Fall jedoch der Höhe nach begrenzt auf den vertragstypischen 
und vorhersehbaren Schaden. Darüber hinaus haftet der Lizenzgeber für Ansprüche nach dem Produkthaftungsgesetz, 
sowie aufgrund sonstiger zwingender gesetzlicher Haftungsvorschriften. Bei Schäden aus der Verletzung des Lebens, 
des Körpers oder der Gesundheit ist die Haftung auch bei einer einfachen Pflichtverletzung des Lizenzgebers oder 
eines seiner gesetzlichen Vertreter oder Erfüllungsgehilfen der Höhe nach unbegrenzt.</li>
</ol>
<ol>
<li>Der Lizenzgeber ist von der Leistungspflicht in Fällen höherer Gewalt befreit. Als höhere Gewalt gelten alle 
unvorhergesehenen Ereignisse sowie solche Ereignisse, deren Auswirkungen auf die Vertragserfüllung von keiner Partei
zu vertreten sind. Zu diesen Ereignissen zählen insbesondere rechtmäßige Arbeitskampfmaßnahmen, auch in Drittbetrieben, 
behördliche Maßnahmen, Ausfall von Kommunikationsnetzen und Gateways an-derer Betreiber, Störungen im Bereich von</li>
</ol>
<ol>
<li>Leitungsgebern, sonstige tech-nische Störungen, auch wenn diese Umstände im Bereich von Unterauftragnehmern, 
Unterlieferanten oder deren Subunternehmern auftreten. Der Lizenznehmer stellt den Lizenzgeber diesbezüglich von 
sämtlichen Ansprüchen Dritter frei.</li>
</ol>
<ol>
<li>Es ergeben sich keine Schadensersatz- oder sonstige Ansprüche für den Lizenznehmer bei nicht durch den Lizenzgeber 
zu verantwortenden Ausfällen.</li>
</ol>
<h3 id="-13-datenschutz">§ 13 Datenschutz</h3>
<ol>
<li>Der Lizenznehmer wird hiermit gemäß Europäischer Datenschutz-Grundverordnung (DSGVO) darüber unterrichtet, dass der
Lizenzgeber seine Daten in maschinenlesbarer Form erfasst und für sich aus dem Vertrag ergebende Aufgaben maschinell 
verarbeitet. Zudem werden Login- und Zugriffsdaten für Beweiszwecke gespeichert. Der Lizenznehmer stimmt dem 
ausdrücklich zu. Soweit sich der Lizenzgeber zur Erbringung der vertraglich geschuldeten Leistungen Dritter bedient, 
ist er berechtigt, die Teilnehmerdaten offenzulegen, wenn dies für die Leistungserbringung erforderlich ist.</li>
</ol>
<ol>
<li>Der Lizenznehmer stimmt mit Unterschrift dieses Vertrages der beiliegenden Vereinbarung zur Auftragsverarbeitung 
des browserbasierten Arbeitszeiterfassungsprogramms KiDig-AZ gemäß Art. § 28 DSGVO 
sowie deren Anlage 1 und 2 TOMs (Technisch-Organisatorische Maßnahmen) zu.</li>
</ol>
<ol>
<li><p>Nachfolgende personenbezogene Daten werden im Rahmen des Vertrages erhoben und gespeichert:</p>
<p> a) Datenkategorien von AZ-Listen Nutzern:
 Bestandsdaten: Vor- und Nachname des AZ-Liste -Nutzers, Unterschrift (gesichert),
 Kontaktdaten: E-Mail-Adresse und Passwort (gesichert)
 Protokolldaten und</p>
<p> b) Datenkategorien von Verantwortlichen bei den Trägern / Einrichtungen:
 Bestandsdaten: Vor- und Nachname; Adressdaten der Einrichtung (Straße, Hausnummer, PLZ, Ort)
 Kontaktdaten: E-Mail-Adresse und Passwort (gesichert), Telefonnummer der Einrichtung
 Vertragsdaten: Zeitpunkt, Inhalt, Zahlungsinformationen Protokolldaten</p>
</li>
</ol>
<h3 id="-14-aufrechnung-zur-ckbehaltungsrecht">§ 14 Aufrechnung, Zurückbehaltungsrecht</h3>
<ol>
<li>Das Recht zur Aufrechnung steht dem Lizenznehmer nur zu, wenn sei-ne Gegenansprüche vom Lizenzgeber anerkannt oder 
rechtskräftig festgestellt sind.</li>
</ol>
<ol>
<li>Zur Ausübung eines Zurückbehaltungsrechts ist der Lizenznehmer nur insoweit befugt, als sein Gegenanspruch auf 
demselben Vertragsverhältnis beruht.</li>
</ol>
<h3 id="-15-anwendbares-recht-gerichtsstand-erf-llungsort">§ 15 Anwendbares Recht, Gerichtsstand, Erfüllungsort</h3>
<ol>
<li><p>Auf den vorliegenden Vertrag ist ausschließlich das Recht der Bundes-republik Deutschland unter Ausschluss des 
UN-Kaufrechts anwendbar.</p>
</li>
<li><p>Gerichtsstand für alle sich aus diesem oder im Zusammenhang mit diesem Vertrag ergebenden Streitigkeiten ist Freiburg 
im Breisgau, soweit der Kunde Kaufmann oder eine juristische Person des öffentlichen Rechts oder 
öffentlich-rechtliches Sondervermögen ist. Erfüllungsort für alle aus diesem oder im Zusammenhang mit diesem Vertrag 
sich ergebenden Verbindlichkeiten, ist ausschließlich Freiburg im Breisgau.</p>
</li>
</ol>
<h3 id="-16-verj-hrung">§ 16 Verjährung</h3>
<ol>
<li><p>Die Verjährungsfrist beträgt</p>
<p> a)    für Ansprüche auf Rückzahlung von Vergütungen aus der Geltendmachung eines Rücktrittsrechts oder aus 
Minderung ein Jahr ab Liefe-rung der Software, jedoch für ordnungsgemäß gerügte Mängel nicht weniger als drei Monate ab 
Abgabe der wirksamen Rücktritts- oder Minderungserklärung;</p>
<p> b) bei anderen Ansprüchen aus Sachmängeln ebenfalls ein Jahr;</p>
<p> c)    bei Ansprüchen aus Rechtsmängeln zwei Jahre, wenn der Rechtsmangel nicht bei einem Recht eines Dritten
liegt, aufgrund dessen der Dritte die gelieferte Software herausverlangen oder die Unterlassung der Nutzung des 
Softwarepakets verlangen kann;</p>
<p> d)    bei nicht auf Sach- oder Rechtsmängeln beruhenden Ansprüchen auf Schadensersatz oder Ersatz vergeblicher 
Aufwendungen zwei Jahre; die Frist beginnt mit dem Zeitpunkt, in dem der Lizenznehmer von den anspruchsbegründenden 
Umständen Kenntnis erlangt, oder ohne gro-be Fahrlässigkeit erlangen konnte.</p>
<p> e)    Die Verjährung tritt spätestens mit Ablauf der in § 199 BGB bestimmten Höchstfristen ein.</p>
</li>
</ol>
<ol>
<li>Bei Schadens- und Aufwendungsersatz aus Vorsatz, grober Fahrlässigkeit, Garantie, Arglist und den in § 10 Abs. 3 
genannten Fällen gelten jedoch stets die gesetzlichen Verjährungsfristen.</li>
</ol>
<h3 id="-17-programmpflege">§ 17 Programmpflege</h3>
<p>Der Lizenzgeber wird eine Programmpflege dahingehend betreiben, dass er Gesetzesänderungen, die sich auf den Inhalt 
des Softwareprodukts auswirken könnten, einpflegt. Der Lizenznehmer hat im Herbst eines jeden Jahres Anspruch auf 
Zurverfügungstellung der für das nächste Jahr geltenden Folgeversion des Softwarepakets – dies aber nur dann, wenn er 
die in § 6 Abs. 2 fixierte Lizenzgebühr fristgerecht für ein Jahr im Voraus entrichtet. Im Übrigen ist der Lizenzgeber 
zu einem Support außerhalb der Gewährleistungshaftung nicht verpflichtet.</p>
<h3 id="-18-schlussbestimmungen">§ 18 Schlussbestimmungen</h3>
<ol>
<li>Ist der Lizenznehmer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, 
ist ausschließlicher Gerichtsstand für alle sich aus der Vertragsbeziehung unmittelbar oder mittelbar ergebenden 
Streitigkeiten der Sitz des Lizenzgebers. Diese Bestimmung findet dann keine Anwendung, wenn die Streitigkeit andere 
als vermögensrechtlicher Ansprüche betrifft, oder wenn für die Streitigkeit ein anderer ausschließlicher Gerichtsstand 
nach dem Gesetz begründet ist.</li>
</ol>
<ol>
<li>Die Abtretung von Ansprüchen des Lizenznehmers aus diesem Lizenzvertrag bedarf der vorherigen schriftlichen 
Zustimmung seitens des Lizenzgebers.</li>
</ol>
<ol>
<li>Mündliche Nebenabreden zu diesen Bedingungen und dem Lizenzvertrag wurden von den Parteien nicht getroffen. 
Änderungen oder Ergänzungen des Lizenzvertrags sowie vertragsbezogene Erklärungen der einen gegenüber der anderen Partei 
bedürfen der Schriftform. Dies gilt auch für die Aufhebung des Schriftformerfordernisses. 
Die Schriftform wird auch durch die Textform (§ 126 b BGB) gewahrt, insbesondere durch E-Mail und Fax.</li>
</ol>
<ol>
<li>Sollte eine Bestimmung dieses Vertrages vollständig oder teilweise nichtig, unwirksam oder undurchführbar sein oder 
werden, berührt dies die Gültigkeit der übrigen Vertragsbestimmungen nicht. Anstelle der nichtigen, unwirksamen oder 
undurchführbaren Bestimmung soll eine Bestimmung in Kraft treten, die dem am nächsten kommt, was die Parteien nach dem 
Sinn und Zweck dieses Vertrages gewollt hätten, hätten sie dies im Lichte der Nichtigkeit, Unwirksamkeit oder 
Undurchführbarkeit bedacht. Dies gilt auch im Falle der Nichtigkeit, Unwirksamkeit oder Undurchführbarkeit einer in 
diesem Vertrag enthaltenen Leistungs- oder Zeitbestimmung. In diesem Fall gilt die gesetzlich zulässige Leistungs- oder 
Zeitbestimmung als vereinbart, die der Vereinbarten am nächsten kommt. Die Sätze 1 und 2 gelten entsprechend für 
Lücken dieses Vertrags.</li>
</ol>
<p>Freiburg, den ...............................................</p>
<p>Ort, ........................................................</p>
<p>.............................................................</p>
<p>(Lizenzgeber)    </p>
<p>............................................................
(Lizenznehmer)</p>
<p>Stand 05/2022, Version 1.1.6</p>
`;
