import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { uuid } from 'uuidv4';
import { EmploymentAttributes } from '../../../apis/attributes';
import CloseWorkdaydefiner from '../images/delete-workdays.png';
import InfoModal from '../../Facility/containers/InfoModal';

const freeMultiWorkdays = () => {
  const multiWorkdaysEmpty: EmploymentAttributes['multiWorkDayDefiner'] = [
    {
      desc: '',
      id: uuid(),
      workDayDefiner: [],
    },
  ];

  return multiWorkdaysEmpty;
};
const options = (t: any) => [
  { label: t('Sunday'.toUpperCase()), value: 0 },
  { label: t('Monday'.toUpperCase()), value: 1 },
  { label: t('Tuesday'.toUpperCase()), value: 2 },
  { label: t('Wednesday'.toUpperCase()), value: 3 },
  { label: t('Thursday'.toUpperCase()), value: 4 },
  { label: t('Friday'.toUpperCase()), value: 5 },
  { label: t('Saturday'.toUpperCase()), value: 6 },
];
export const daysStrings = (t: any) => [
  t('Sunday'.toUpperCase()),
  t('Monday'.toUpperCase()),
  t('Tuesday'.toUpperCase()),
  t('Wednesday'.toUpperCase()),
  t('Thursday'.toUpperCase()),
  t('Friday'.toUpperCase()),
  t('Saturday'.toUpperCase()),
];

const convertWorkDayDefiners = (workDays: any, t: any) =>
  workDays.length
    ? workDays?.map((day: any) => ({
        label: daysStrings(t)[day],
        value: day,
      })) ?? []
    : [];

const convertMultiWorkDayDefinersToSelect = (
  multiWorkDays: EmploymentAttributes['multiWorkDayDefiner'],
  t: any,
) =>
  multiWorkDays.map((workDayDefiner) => ({
    desc: workDayDefiner.desc,
    id: workDayDefiner.id,
    workDayDefiner: convertWorkDayDefiners(workDayDefiner.workDayDefiner, t),
  }));

const convertSelectToMultiWorkDayDefiners = (
  multiWorkDays: any,
): EmploymentAttributes['multiWorkDayDefiner'] =>
  multiWorkDays.map((workDayDefiner: any) => ({
    desc: workDayDefiner.desc,
    id: workDayDefiner.id,
    workDayDefiner: workDayDefiner.workDayDefiner.map((day: any) => day.value),
  }));

export const MultiWorkdayDefiner: React.FC<{
  multiWorkDayDefiner?: EmploymentAttributes['multiWorkDayDefiner'];
  latestResult: (multiWorkDayDefiner: EmploymentAttributes['multiWorkDayDefiner']) => void;
  disabled?: boolean;
}> = ({ multiWorkDayDefiner = freeMultiWorkdays(), latestResult, disabled = false }) => {
  const { t } = useTranslation();

  const [workDaysError, setWorkDaysError] = useState('');

  const [localMultiWorkDayDefiner, setLocalMultiWorkDayDefiner] = useState(
    convertMultiWorkDayDefinersToSelect(multiWorkDayDefiner, t),
  );

  return (
    <Container className='mt-4 shadow py-4 px-5 rounded'>
      <h4 className='mb-1 mt-3'>
        <strong>{t('WORK_DAYS') as string}</strong>{' '}
        <InfoModal content={t('WORKDAY_DEFINER_MODAL') as string} />{' '}
      </h4>

      {localMultiWorkDayDefiner?.map((workdayDefiner, index) => (
        <Row className='mt-3' key={workdayDefiner.id}>
          <Col xs={3} className='d-flex align-items-center justify-content-center'>
            <h5 className='m-0 mt-4'>
              {localMultiWorkDayDefiner.length > 1 ? t('WEEK') : ''}{' '}
              {localMultiWorkDayDefiner.length > 1 ? index + 1 : ''}
            </h5>
          </Col>

          <Col xs={7}>
            <strong>{t('WORK_DAYS') as string}</strong>
            <MultiSelect
              disabled={disabled}
              className='mt-2 rounded'
              options={options(t)}
              value={workdayDefiner.workDayDefiner}
              onChange={(e: any) => {
                // setSelected(e);
                const localMultiCopy = [...localMultiWorkDayDefiner];
                localMultiCopy[index].workDayDefiner = e;
                setLocalMultiWorkDayDefiner(localMultiCopy);
                //   props.setFieldValue('workDayDefiner', (e as any[])?.map((val) => val.value));
                latestResult(convertSelectToMultiWorkDayDefiners(localMultiCopy));
              }}
              labelledBy='Select'
            />
          </Col>

          <Col xs={2} className='d-flex align-items-center justify-content-start'>
            <Button
              className='mt-4 pb-0'
              variant='outline-light'
              disabled={disabled}
              onClick={() => {
                const newMultiWorkDayDefiner = localMultiWorkDayDefiner.filter(
                  (_, i) => i !== index,
                );
                setLocalMultiWorkDayDefiner(newMultiWorkDayDefiner);

                // if localMultiWorkDayDefiner is empty, add a new one
                if (newMultiWorkDayDefiner.length === 0) {
                  setLocalMultiWorkDayDefiner(freeMultiWorkdays());
                }
                latestResult(convertSelectToMultiWorkDayDefiners(newMultiWorkDayDefiner));
              }}
            >
              <Image src={CloseWorkdaydefiner} alt='' width='36px' height='36px' />
            </Button>
          </Col>
        </Row>
      ))}

      <div className='mt-5 d-flex flex-column justify-content-center align-items-end'>
        <Button
          disabled={disabled}
          onClick={() => {
            if (
              localMultiWorkDayDefiner[localMultiWorkDayDefiner.length - 1].workDayDefiner
                .length === 0
            ) {
              setWorkDaysError(t('FILL_OUT_WORK_DAY_WARNING'));
              return;
            }
            setWorkDaysError('');

            const newMultiWorkDayDefiner = [...localMultiWorkDayDefiner];
            newMultiWorkDayDefiner.push({
              desc: '',
              id: uuid(),
              workDayDefiner: [],
            });
            setLocalMultiWorkDayDefiner(newMultiWorkDayDefiner);
          }}
        >
          {t('ADD_WORKDAY') as string}
        </Button>
        {Boolean(workDaysError) && <p className='text-danger'> {workDaysError} </p>}
      </div>
    </Container>
  );
};

export default MultiWorkdayDefiner;
