import { WEEKDAY } from "./enums";
import { Duration } from "luxon";
import { numericDayOfWeekIndexToDayOfWeek } from "./utility";

/**
 * WorkdayDefiner class represents the definition of workdays.
 */
export class WorkdayDefiner {
    /**
     * Creates a new WorkdayDefiner.
     * @param {WEEKDAY[]} workingDays - Array of workdays.
     */
    constructor(
        public workingDays: WEEKDAY[] = [
            WEEKDAY.MONDAY,
            WEEKDAY.TUESDAY,
            WEEKDAY.WEDNESDAY,
            WEEKDAY.THURSDAY,
            WEEKDAY.FRIDAY,
        ]
    ) {}

    /**
     * Determines if a given day is a workday.
     * @return {boolean}: true if the given day is a workday, false otherwise.
     * @param day
     */
    isWorkingDay(day: WEEKDAY): boolean {
        return this.workingDays.includes(day);
    }

    /**
     * Calculates the number of workdays per week.
     * @return {number}: number of workdays per week.
     */
    getNumberOfWorkDays(): number {
        return this.workingDays.length;
    }

    /**
     * @return {string} -  Returns a string representation of the workday definer.
     */
    toString(): string {
        return this.workingDays.toString();
    }

    /**
     * Creates a new WorkdayDefiner from an array of numbers.
     * @param attributes - Array of numbers.
     * @returns {WorkdayDefiner} - Returns a new WorkdayDefiner.
     */
    static fromDayOfWeekIndices(attributes: number[]): WorkdayDefiner {
        return parseWorkdayDefiner(attributes);
    }
}

/**
 * Creates a duration from a number of hours.
 * @param hours - Number of hours as a floating point number.
 * @returns {Duration}
 */
export function createDurationFromHours(hours: number): Duration {
    const wholeHours = Math.floor(hours);
    const fractionalHours = hours - wholeHours;
    const minutes = Math.round(fractionalHours * 60);

    return Duration.fromObject({ hours: wholeHours, minutes: minutes });
}

/**
 * Parses a workday definer from an array of numbers.
 * @param definer - Array of numbers.
 * @returns {WorkdayDefiner}
 */
export function parseWorkdayDefiner(definer: number[]): WorkdayDefiner {
    const mappedDays = definer.map((i) => numericDayOfWeekIndexToDayOfWeek(i));
    return new WorkdayDefiner(mappedDays);
}
