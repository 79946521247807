import * as React from 'react';
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import privacy_de from '../../apis/privacy_de';
import privacy_en from '../../apis/privacy_en';
import i18n from '../../apis/i18n';

export const PrivacyPolicy = () => {
  return (
    <Container className='p-5'>
      {i18n.language === 'en' ? parse(privacy_en) : parse(privacy_de)}
    </Container>
  );
};
export default PrivacyPolicy;
