import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopBar from '../Layout/containers/TopBar';
import ReadTimeAddition from './container/ReadTimeAddition';
import ReadPauseSettings from './container/ReadPauseSettings';
import ReadTariffSettings from './container/ReadTariffSettings';

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Route
        exact
        path='/read-tariff-time/:tariffId'
        render={({ match }) => (
          <TopBar progress={100} title={t('TIME_ADDITION_SETTINGS') as string}>
            <ReadTimeAddition tariffId={match.params.tariffId} />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/read-tariff-pause/:tariffId'
        render={({ match }) => (
          <TopBar progress={100} title={t('PAUSE_SETTINGS') as string}>
            <ReadPauseSettings tariffId={match.params.tariffId} />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/read-tariff-settings/:tariffId'
        render={({ match }) => (
          <TopBar progress={100} title={t('CREATE_TARIFFS') as string}>
            <ReadTariffSettings tariffId={match.params.tariffId} />
          </TopBar>
        )}
      />
    </>
  );
};
