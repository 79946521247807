import * as React from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import social, { authenticatedFacility } from '../../../apis/social';
import PaymentDeclinedModal from './PaymentDeclinedModal';
import { KIDIG_PLAN, PLAN_CTA } from './Plans';

type PlanConfirmModalProps = {
  show: boolean;
  handleClose: any;
  shownPlan: KIDIG_PLAN;
};

const PlanConfirmModal: React.FC<PlanConfirmModalProps> = ({ show, handleClose, shownPlan }) => {
  const { t } = useTranslation();

  const [disable, setDisable] = React.useState(false);

  const { value } = usePromise(authenticatedFacility());

  // handlers for payment failure modal
  const [retryModal, setRetryModalShow] = useState(false);
  const handleRetryModalClose = () => setRetryModalShow(false);
  const handleRetryModalShow = () => setRetryModalShow(true);

  const handlePayment = async () => {
    const { planCTA } = shownPlan;

    if (planCTA === PLAN_CTA.ACTIVATE) {
      const payload = {
        createSubscription: true,
        customer: (value as any)?.user?.facility.stripeCustomerId,
        kidigPlanId: shownPlan.id,
        price: shownPlan.priceId,
      };

      try {
        await social.service('facility').get((value as any)?.user?.facility?.id, {
          query: payload,
        });
        window.location.reload();
      } catch (error) {
        // handleRetryModalShow();
      }

      //
    } else if (planCTA === PLAN_CTA.UPGRADE) {
      const payload = {
        kidigPlanId: shownPlan.id,
        price: shownPlan.priceId,
        subscriptionId: (value as any)?.user?.facility.subscriptionId,
        upgradeSubscription: true,
      };
      try {
        await social.service('facility').get((value as any)?.user?.facility?.id, {
          query: payload,
        });
        // reload page
        window.location.reload();
      } catch (error) {
        handleRetryModalShow();
      }

      // s
    } else if (planCTA === PLAN_CTA.DOWNGRADE) {
      const payload = {
        downgradeSubscription: true,
        kidigPlanId: shownPlan.id,
        price: shownPlan.priceId,
        subscriptionId: (value as any)?.user?.facility.subscriptionId,
      };
      await social.service('facility').get((value as any)?.user?.facility?.id, { query: payload });
      // reload page
      window.location.reload();

      //
    } else if (planCTA === PLAN_CTA.CANCEL) {
      const payload = {
        cancelSubscription: true,
        subscriptionId: (value as any)?.user?.facility.subscriptionId,
      };
      await social.service('facility').get((value as any)?.user?.facility?.id, { query: payload });

      // reload page
      window.location.reload();
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='contained-modal-title-hcenter'>
            {' '}
            {t('PAYMENT_OVERVIEW') as string}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-4'>
          {t('PAYMENT_CONFIRMATION') as string} {shownPlan.priceCents / 100}€
        </Modal.Body>
        <Modal.Footer className='d-flex justif-content-center'>
          <Button variant='outline-dark' onClick={handleClose}>
            {t('CANCEL') as string}
          </Button>
          <Button
            className='px-5'
            variant='primary'
            disabled={disable}
            onClick={async () => {
              setDisable(true);
              await handlePayment();
              setDisable(false);
              handleClose();
            }}
          >
            {t('CONFIRM') as string}
          </Button>
        </Modal.Footer>
      </Modal>
      <PaymentDeclinedModal
        show={retryModal}
        handleClose={handleRetryModalClose}
        facilityId={(value as any)?.user?.facility?.id ?? ''}
        stripeCustomerId={(value as any)?.user?.facility?.stripeCustomerId ?? ''}
      />
    </>
  );
};

export default PlanConfirmModal;
