import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import React from 'react';

export type DateInputCustomProps = {
  onChange: any;
  disabled?: boolean;
  defaultStrDuration: string;
};

export const DateInputCustom: React.FC<DateInputCustomProps> = ({
  onChange,
  disabled = false,
  defaultStrDuration,
}) => {
  // st

  const convertDateToISODuration = (date: Date) => {
    const du = moment.duration({
      hours: date.getHours(),
      minutes: date.getMinutes(),
    });
    return du.toISOString();
  };

  const convertISODurationToDate = (strDate: string) => {
    const du = moment.duration(strDate);

    const processedDate = new Date();
    processedDate.setFullYear(1970);
    processedDate.setMonth(0);
    processedDate.setDate(1);
    processedDate.setHours(du.hours());
    processedDate.setMinutes(du.minutes());

    return processedDate;
  };

  return (
    <>
      <Flatpickr
        className='text-start w-100'
        options={{
          dateFormat: 'H:i',
          enableTime: true,
          noCalendar: true,
          time_24hr: true,
        }}
        disabled={disabled}
        defaultValue={convertISODurationToDate(defaultStrDuration).toISOString()}
        onChange={(d) => {
          const processedDate = d[0];
          processedDate.setFullYear(1970);
          processedDate.setMonth(0);
          processedDate.setDate(1);
          const isoDuration = convertDateToISODuration(processedDate);
          onChange(isoDuration);
        }}
      />
    </>
  );
};

export default DateInputCustom;
