class Interval {
  start: string;

  end: string;

  intervalType: string;

  comment: string;

  constructor(start: string, end: string, intervalType: string, comment: string) {
    this.start = start;
    this.end = end;
    this.intervalType = intervalType;
    this.comment = comment;
  }

  translateIntervalType = (targetLanguage: string = 'de'): string => {
    switch (targetLanguage) {
      case 'en':
        switch (this.intervalType) {
          case 'CARE':
            return 'child';
          case 'DISPOSAL':
            return 'disposal';
          case 'IRREGULAR_DISPOSAL':
            return 'irregular';
          default:
            return 'interval unknown.';
        }
      default:
        switch (this.intervalType) {
          case 'CARE':
            return 'Kind';
          case 'DISPOSAL':
            return 'Verfügung';
          case 'IRREGULAR_DISPOSAL':
            return 'Unregelmäßig';
          default:
            return 'Interval unbekannt.';
        }
    }
  };
}
export default Interval;
