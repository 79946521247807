import { Paginated } from '@feathersjs/feathers';
import { Form, Formik, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useObservable } from 'react-use-observable';
import * as Yup from 'yup';
import { YearlySettings } from '../../../apis/attributes';
import { social } from '../../../apis/social';
import {
  getFloatFromHoursMinutesSeconds,
  getHoursMinutesSecondsFromFloat,
} from '../../../utility/time';
import InfoModal from '../../Facility/containers/InfoModal';

const freeSlotMonthSetting = () => {
  const balances: YearlySettings['balance'] = [];
  for (let monIdx = 0; monIdx < 12; monIdx++) {
    balances.push({
      canceled: {
        importCareTime: 'PT0H',
        importDisposalTimeIrregular: 'PT0H',
        importDisposalTimeRegular: 'PT0H',
      },
      monthNumber: monIdx,
      paidOut: {
        importCareTime: 'PT0H',
        importDisposalTimeIrregular: 'PT0H',
        importDisposalTimeRegular: 'PT0H',
      },
    });
  }

  return balances;
};
const getEmptyYearSetting = (
    employeeId: string,
    targetYear = new Date().getFullYear(),
): YearlySettings => ({
  balance: freeSlotMonthSetting(),
  employeeId,
  id: `${employeeId}_${targetYear}`,
  importCareTime: 'PT0H',
  importDisposalTimeIrregular: 'PT0H',
  importDisposalTimeRegular: 'PT0H',
  numberHolidaySpecial: 0,
  numberHolidays: 0.0,
  numberRegenerationDays: 0,
  numberConversionDays: 0,
  oldVacation: 0,
  year: targetYear,
});

/**
 * fill a potentially fragmented YearSettingsList from DB with missing years and next year
 * @param yearSettings potentially non-existent DB YearSettings collection
 * @param employeeId id of employee to create empty entries for patch in DB
 */
const patchYearlySettings = (
    yearSettings: YearlySettings[] | undefined,
    employeeId: string,
): YearlySettings[] => {

  // the yearly settings need to always at least include the last year in the list, the current year, and the next year
  const lastYear = new Date().getFullYear() - 1;
  const thisYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;

  const settingsToPad = [
    getEmptyYearSetting(employeeId, lastYear),
    getEmptyYearSetting(employeeId, thisYear),
    getEmptyYearSetting(employeeId, nextYear),
  ];

  // if there are no yearly settings, create a new one for the last, current, and next year
  if (yearSettings === undefined) {
    return settingsToPad;
  }

  // if there are yearly settings, sort them and then pad them
  for(const yearSetting of yearSettings.sort((a, b) => a.year - b.year)) {
    const maybeYearSetting = settingsToPad.find((ys) => ys.year === yearSetting.year);
    // case where the year is already in the list
    if (maybeYearSetting) {
      // get index of yearSetting in settingsToPad
      const idx = settingsToPad.indexOf(maybeYearSetting);
      // replace the yearSetting with the yearSetting from the DB
      settingsToPad[idx] = yearSetting;
    }
    // case where the year is not in the list
    else {
      settingsToPad.push(yearSetting);
    }
  }

  settingsToPad.sort((a, b) => a.year - b.year);
  const firstYearInList = settingsToPad[0].year;
  const lastYearInList = settingsToPad[settingsToPad.length - 1].year;

  for (let year = firstYearInList; year <= lastYearInList; year++) {
    // search for year that could be in DB already, if not there, create empty
    if (!settingsToPad.find((ys) => ys.year === year)) {
      settingsToPad.push(getEmptyYearSetting(employeeId, year));
    }
  }

  return settingsToPad.sort((a, b) => a.year - b.year);
};

const buildFormikDefaultValues = (
    employeeId: string,
    yearlySettings: YearlySettings | undefined,
) => {
  const p = {
    balance: yearlySettings?.balance,
    employeeId,
    id: `${employeeId}_${yearlySettings?.year}`,
    importCareTime: `${getFloatFromHoursMinutesSeconds(yearlySettings?.importCareTime)}`,
    importDisposalTimeIrregular: `${getFloatFromHoursMinutesSeconds(
        yearlySettings?.importDisposalTimeIrregular,
    )}`,
    importDisposalTimeRegular: `${getFloatFromHoursMinutesSeconds(
        yearlySettings?.importDisposalTimeRegular,
    )}`,
    numberHolidaySpecial: yearlySettings?.numberHolidaySpecial,
    numberHolidays: yearlySettings?.numberHolidays,
    numberRegenerationDays: yearlySettings?.numberRegenerationDays,
    numberConversionDays: yearlySettings?.numberConversionDays,
    oldVacation: yearlySettings?.oldVacation,
    year: yearlySettings?.year,
  };
  return p;
};

export const YearlyLogic: React.FC<{
  employeeId: string;
  shouldReadOnly: boolean;
}> = observer(({ employeeId, shouldReadOnly }) => {
  const { t } = useTranslation();
  // year and month chosen by the user
  const [chosenYear, setChosenYear] = useState<number | undefined>(new Date()?.getFullYear());
  const [chosenMonth, setChosenMonth] = useState<number | undefined>(new Date()?.getMonth());

  const [allYearsSettings] = useObservable<Paginated<YearlySettings> | null>(
      () =>
          social
              .service('yearly-settings')
              .watch()
              .find({
                query: {
                  $sort: {
                    year: 1,
                  },
                  employeeId,
                },
              }),
      [employeeId],
  );

  // generate padded list of years
  const paddedYearlySettings = patchYearlySettings(allYearsSettings?.data, employeeId);
  const [yearlyLogicState, setYearlyLogicState] = useState<YearlySettings | undefined>(
      paddedYearlySettings.find((ys) => ys.year === chosenYear) ||
      getEmptyYearSetting(employeeId, new Date().getFullYear()),
  );

  // update yearlyLogicState if chosenYear changes
  useEffect(() => {
    const maybeYearlyLogicState = paddedYearlySettings.find((ys) => ys.year === chosenYear);
    if (maybeYearlyLogicState) {
      // deep comparison then assignment

      // compare
      const shouldUpdate =
          JSON.stringify(yearlyLogicState) !== JSON.stringify(maybeYearlyLogicState);

      if (shouldUpdate) {
        setYearlyLogicState(undefined);
        setChosenMonth(undefined);
        setTimeout(() => {
          setYearlyLogicState(maybeYearlyLogicState);
          setChosenMonth(new Date().getMonth());
        }, 0);
      }
    }
  }, [paddedYearlySettings && allYearsSettings]);

  const updateDB = useCallback(
      async (data: any) => {
        const processedData = { ...data };

        processedData.importCareTime = getHoursMinutesSecondsFromFloat(data.importCareTime);
        processedData.importDisposalTimeRegular = getHoursMinutesSecondsFromFloat(
            data.importDisposalTimeRegular,
        );
        processedData.importDisposalTimeIrregular = getHoursMinutesSecondsFromFloat(
            data.importDisposalTimeIrregular,
        );

        const yearlySettings = await social
            .service('yearly-settings')
            .get(`${employeeId}_${chosenYear}`)
            .catch(() => {});

        try {
          if (!yearlySettings?.id) {
            await social.service('yearly-settings').create({
              ...processedData,
            });
          } else {
            const newData: any = { ...processedData };
            delete newData?.id;
            delete newData?.employeeId;
            await social.service('yearly-settings').patch(`${employeeId}_${chosenYear}`, {
              ...newData,
            });
          }
        } catch (err) {
          alert('Error: please reload the page');
        }
      },
      [employeeId, chosenYear, chosenMonth, yearlyLogicState],
  );

  if (allYearsSettings === null) return <div />;

  return (
      <>
        <Formik
            enableReinitialize
            initialValues={buildFormikDefaultValues(employeeId, yearlyLogicState)}
            validationSchema={Yup.object({
              importCareTime: Yup.number().required(t('REQUIRED')),
              importDisposalTimeIrregular: Yup.number().required(t('REQUIRED')),
              importDisposalTimeRegular: Yup.number().required(t('REQUIRED')),
              numberHolidaySpecial: Yup.number().integer().required(t('REQUIRED')),
              numberHolidays: Yup.number().required(t('REQUIRED')),
              numberRegenerationDays: Yup.number().integer().required(t('REQUIRED')),
              numberConversionDays: Yup.number().integer().required(t('REQUIRED')),
              oldVacation: Yup.number().integer().required(t('REQUIRED')),
              year: Yup.number().integer().required(t('REQUIRED')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                updateDB(values);
                setSubmitting(false);
              }, 100);
            }}
        >
          {(props: FormikValues) => (
              <Form>
                <div style={{ height: '40px' }} />
                <div className='w-100 mt-4 mb-4 d-flex flex-row justify-content-start align-items-center'>
                  <h4 className='  pr-3 h-100 my-0 font-weight-bold' style={{ minWidth: '200px' }}>
                    {t('YEARLY_SETTINGS') as string}
                  </h4>
                  <DropdownButton title={chosenYear ?? ""} style={{ width: '100%' }} menuAlign='left' >
                    {paddedYearlySettings.map((y, idx: any) => (
                        <Dropdown.Item
                            className='handStyle'
                            key={`_${idx}`}
                            eventKey={idx.toString()}
                            onClick={() => {
                              setChosenYear(undefined);
                              setChosenMonth(undefined);
                              setYearlyLogicState(undefined);
                              setTimeout(() => {
                                setChosenYear(y.year);
                                setChosenMonth(new Date().getMonth());
                                setYearlyLogicState(y);
                              }, 100);
                            }}
                        >
                          {y?.year}
                        </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                {/* ------------------------- IMPORT HOURS ------------------------- */}
                <h5>
                  <strong>{t('IMPORT_OLD_HOURS') as string}</strong>
                  <InfoModal content={t('IMPORT_HOURS') as string} />
                </h5>{' '}
                <br />
                <strong>{t('IMPORT_CARETIME') as string} </strong>
                {yearlyLogicState?.importCareTime && (
                    <input
                        disabled={shouldReadOnly}
                        className='mt-2 mb-4 w-100'
                        placeholder=''
                        defaultValue={getFloatFromHoursMinutesSeconds(yearlyLogicState?.importCareTime)}
                        type='number'
                        step='0.01'
                        min='-1000.00'
                        max='1000.00'
                        name='importCareTime'
                        onChange={props.handleChange}
                    />
                )}
                {props?.errors?.importCareTime && (
                    <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                      {' '}
                      {props?.errors?.importCareTime}{' '}
                    </p>
                )}
                <strong>{t('DISPOSAL_TIME') as string}</strong>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          className='mt-2 mb-4 w-100'
                          placeholder=''
                          defaultValue={getFloatFromHoursMinutesSeconds(
                              yearlyLogicState?.importDisposalTimeRegular,
                          )}
                          type='number'
                          step='0.01'
                          min='-1000.00'
                          max='1000.00'
                          name='importDisposalTimeRegular'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.importDisposalTimeRegular && (
                    <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                      {' '}
                      {props?.errors?.importDisposalTimeRegular}{' '}
                    </p>
                )}
                <strong>{t('IRREGULAR_DISPOSAL') as string}</strong>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          className='mt-2 mb-4 w-100'
                          placeholder=''
                          defaultValue={getFloatFromHoursMinutesSeconds(
                              yearlyLogicState.importDisposalTimeIrregular,
                          )}
                          type='number'
                          step='0.01'
                          min='-1000.00'
                          max='1000.00'
                          name='importDisposalTimeIrregular'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.importDisposalTimeIrregular && (
                    <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                      {' '}
                      {props?.errors?.importDisposalTimeIrregular}{' '}
                    </p>
                )}
                {/* ------------------------- VACATION SETTINGS ------------------------- */}
                <h5 className='mt-5'>
                  <strong>{t('VACATION_SETTINGS') as string}</strong>
                  <InfoModal content={t('VACATION_MODAL') as string} />
                </h5>{' '}
                <br />
                <p className='mb-1 '>
                  {' '}
                  <strong>{t('VACATION_DAYS_YEAR') as string}</strong>{' '}
                </p>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          name='numberHolidays'
                          className='w-100 '
                          placeholder=''
                          defaultValue={yearlyLogicState?.numberHolidays}
                          type='number'
                          step='0.5'
                          max='1000'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.numberHolidays && (
                    <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                      {' '}
                      {props?.errors?.numberHolidays}{' '}
                    </p>
                )}
                <p className='mb-1 mt-3'>
                  {' '}
                  <strong>{t('SPECIAL_LEAVE') as string}</strong>{' '}
                </p>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          name='numberHolidaySpecial'
                          className='w-100 '
                          placeholder=''
                          defaultValue={yearlyLogicState?.numberHolidaySpecial}
                          type='number'
                          max='1000'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.numberHolidaySpecial && (
                    <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                      {' '}
                      {props?.errors?.numberHolidaySpecial}{' '}
                    </p>
                )}
                <p className='mb-1 mt-3'>
                  {' '}
                  <strong>{t('OLD_VACATION') as string}</strong>{' '}
                </p>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          name='oldVacation'
                          className='w-100 '
                          placeholder=''
                          defaultValue={yearlyLogicState?.oldVacation}
                          type='number'
                          step='0.5'
                          max='1000'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.oldVacation && (
                    <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                      {' '}
                      {props?.errors?.oldVacation}{' '}
                    </p>
                )}
                <p className='mb-1 mt-3'>
                  {' '}
                  <strong>{t('REGENERATION_DAYS') as string}</strong>{' '}
                </p>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          name='numberRegenerationDays'
                          className='w-100 '
                          placeholder=''
                          defaultValue={yearlyLogicState?.numberRegenerationDays}
                          type='number'
                          max='1000'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.numberRegenerationDays && (
                    <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                      {' '}
                      {props?.errors?.numberRegenerationDays}{' '}
                    </p>
                )}
                <p className='mb-1 mt-3'>
                  {' '}
                  <strong>{t('CONVERSION_DAYS') as string}</strong>{' '}
                </p>
                {yearlyLogicState?.importCareTime && (
                    <>
                      <input
                          disabled={shouldReadOnly}
                          name='numberConversionDays'
                          className='w-100 '
                          placeholder=''
                          defaultValue={yearlyLogicState?.numberConversionDays}
                          type='number'
                          max='1000'
                          onChange={props.handleChange}
                      />
                    </>
                )}
                {props?.errors?.numberRegenerationDays && (
                    <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                      {' '}
                      {props?.errors?.numberConversionDays}{' '}
                    </p>
                )}
                {/* ------------------------- PAID OUT HORS ------------------------- */}
                <h5 className='mt-5'>
                  <strong>{t('PAIDOUT_HOURS') as string}</strong>
                  <InfoModal content={t('PAID_OUT_HOURS_MODAL') as string} />
                </h5>{' '}
                <br />
                <div className='shadow py-4 px-5 rounded d-flex flex-column justify-content-start align-items-end'>
                  <DropdownButton
                      className=' mb-5'
                      style={{ width: '100%' }}
                      menuAlign='left'
                      title={t(`${chosenMonth}_LAN`)}
                  >
                    {yearlyLogicState?.balance?.map(({ monthNumber }, idx: any) => (
                        <Dropdown.Item
                            className='handStyle'
                            key={monthNumber}
                            eventKey={idx.toString()}
                            onClick={() => {
                              setChosenMonth(undefined);
                              setTimeout(() => {
                                setChosenMonth(monthNumber);
                              }, 0);
                            }}
                        >
                          {t(`${monthNumber}_LAN`)}
                        </Dropdown.Item>
                    ))}
                  </DropdownButton>

                  {chosenMonth !== undefined && (
                      <Row>
                        <Col xs={12} sm={6}>
                          <h4 className='font-weight-bold'>{t('PAID_OUT') as string} </h4>

                          <br />
                          <strong> {t('PAYOUT_CARETIME') as string}</strong>
                          <input
                              disabled={shouldReadOnly}
                              className='w-100 '
                              placeholder=''
                              defaultValue={getFloatFromHoursMinutesSeconds(
                                  yearlyLogicState?.balance[chosenMonth].paidOut.importCareTime,
                              )}
                              type='number'
                              max='1000'
                              step='0.01'
                              onChange={(e) => {
                                const copyMonth = yearlyLogicState
                                    ? {
                                      ...yearlyLogicState.balance[chosenMonth],
                                    }
                                    : {
                                      ...getEmptyYearSetting(employeeId).balance[chosenMonth],
                                    };
                                if (yearlyLogicState) {
                                  copyMonth.paidOut.importCareTime = getHoursMinutesSecondsFromFloat(
                                      +e.target.value,
                                  );
                                  const copyBalances = [...(yearlyLogicState?.balance ?? [])];
                                  copyBalances[chosenMonth] = copyMonth;
                                  const copyYearlyLogicState = {
                                    ...yearlyLogicState,
                                  };
                                  copyYearlyLogicState.balance = copyBalances;
                                  setYearlyLogicState(copyYearlyLogicState);
                                }
                              }}
                          />
                          <br />
                          <br />
                          <strong> {t('PAYOUT_DISPOSALTIME') as string}</strong>
                          <input
                              disabled={shouldReadOnly}
                              className='w-100 '
                              placeholder=''
                              defaultValue={getFloatFromHoursMinutesSeconds(
                                  yearlyLogicState?.balance[chosenMonth].paidOut.importDisposalTimeRegular,
                              )}
                              type='number'
                              max='1000'
                              step='0.01'
                              onChange={(e) => {
                                const copyMonth = yearlyLogicState
                                    ? {
                                      ...yearlyLogicState.balance[chosenMonth],
                                    }
                                    : {
                                      ...getEmptyYearSetting(employeeId).balance[chosenMonth],
                                    };
                                if (yearlyLogicState) {
                                  copyMonth.paidOut.importDisposalTimeRegular =
                                      getHoursMinutesSecondsFromFloat(+e.target.value);
                                  const copyBalances = [...(yearlyLogicState?.balance ?? [])];
                                  copyBalances[chosenMonth] = copyMonth;
                                  const copyYearlyLogicState = {
                                    ...yearlyLogicState,
                                  };
                                  copyYearlyLogicState.balance = copyBalances;
                                  setYearlyLogicState(copyYearlyLogicState);
                                }
                              }}
                          />

                          <br />
                          <br />
                          <strong> {t('PAYOUT_IRREGULAR_DISPOSALTIME') as string}</strong>
                          <input
                              disabled={shouldReadOnly}
                              className='w-100 '
                              placeholder=''
                              defaultValue={getFloatFromHoursMinutesSeconds(
                                  yearlyLogicState?.balance[chosenMonth].paidOut.importDisposalTimeIrregular,
                              )}
                              type='number'
                              max='1000'
                              step='0.01'
                              onChange={(e) => {
                                const copyMonth = yearlyLogicState
                                    ? {
                                      ...yearlyLogicState.balance[chosenMonth],
                                    }
                                    : {
                                      ...getEmptyYearSetting(employeeId).balance[chosenMonth],
                                    };
                                if (yearlyLogicState) {
                                  copyMonth.paidOut.importDisposalTimeIrregular =
                                      getHoursMinutesSecondsFromFloat(+e.target.value);
                                  const copyBalances = [...(yearlyLogicState?.balance ?? [])];
                                  copyBalances[chosenMonth] = copyMonth;
                                  const copyYearlyLogicState = {
                                    ...yearlyLogicState,
                                  };
                                  copyYearlyLogicState.balance = copyBalances;
                                  setYearlyLogicState(copyYearlyLogicState);
                                }
                              }}
                          />
                        </Col>

                        <Col xs={12} sm={6}>
                          <h4 className='font-weight-bold'>{t('CANCELED') as string} </h4>
                          <br />

                          <strong> {t('CANCEL_CARETIME') as string}</strong>
                          <input
                              disabled={shouldReadOnly}
                              className='w-100 '
                              placeholder=''
                              defaultValue={getFloatFromHoursMinutesSeconds(
                                  yearlyLogicState?.balance[chosenMonth].canceled.importCareTime,
                              )}
                              type='number'
                              max='1000'
                              step='0.01'
                              onChange={(e) => {
                                const copyMonth = yearlyLogicState
                                    ? {
                                      ...yearlyLogicState.balance[chosenMonth],
                                    }
                                    : {
                                      ...getEmptyYearSetting(employeeId).balance[chosenMonth],
                                    };
                                if (yearlyLogicState) {
                                  copyMonth.canceled.importCareTime = getHoursMinutesSecondsFromFloat(
                                      +e.target.value,
                                  );
                                  const copyBalances = [...(yearlyLogicState?.balance ?? [])];
                                  copyBalances[chosenMonth] = copyMonth;
                                  const copyYearlyLogicState = {
                                    ...yearlyLogicState,
                                  };
                                  copyYearlyLogicState.balance = copyBalances;
                                  setYearlyLogicState(copyYearlyLogicState);
                                }
                              }}
                          />
                          <br />
                          <br />
                          <strong> {t('CANCEL_DISPOSALTIME') as string}</strong>
                          <input
                              disabled={shouldReadOnly}
                              className='w-100 '
                              placeholder=''
                              defaultValue={getFloatFromHoursMinutesSeconds(
                                  yearlyLogicState?.balance[chosenMonth].canceled.importDisposalTimeRegular,
                              )}
                              type='number'
                              max='1000'
                              step='0.01'
                              onChange={(e) => {
                                const copyMonth = yearlyLogicState
                                    ? {
                                      ...yearlyLogicState.balance[chosenMonth],
                                    }
                                    : {
                                      ...getEmptyYearSetting(employeeId).balance[chosenMonth],
                                    };
                                if (yearlyLogicState) {
                                  copyMonth.canceled.importDisposalTimeRegular =
                                      getHoursMinutesSecondsFromFloat(+e.target.value);
                                  const copyBalances = [...(yearlyLogicState?.balance ?? [])];
                                  copyBalances[chosenMonth] = copyMonth;
                                  const copyYearlyLogicState = {
                                    ...yearlyLogicState,
                                  };
                                  copyYearlyLogicState.balance = copyBalances;
                                  setYearlyLogicState(copyYearlyLogicState);
                                }
                              }}
                          />
                          <br />
                          <br />
                          <strong> {t('CANCEL_IRREGULAR_DISPOSALTIME') as string}</strong>
                          <input
                              disabled={shouldReadOnly}
                              className='w-100 '
                              placeholder=''
                              defaultValue={getFloatFromHoursMinutesSeconds(
                                  yearlyLogicState?.balance[chosenMonth].canceled.importDisposalTimeIrregular,
                              )}
                              type='number'
                              max='1000'
                              step='0.01'
                              onChange={(e) => {
                                const copyMonth = yearlyLogicState
                                    ? {
                                      ...yearlyLogicState.balance[chosenMonth],
                                    }
                                    : {
                                      ...getEmptyYearSetting(employeeId).balance[chosenMonth],
                                    };
                                if (yearlyLogicState) {
                                  copyMonth.canceled.importDisposalTimeIrregular =
                                      getHoursMinutesSecondsFromFloat(+e.target.value);
                                  const copyBalances = [...(yearlyLogicState?.balance ?? [])];
                                  copyBalances[chosenMonth] = copyMonth;
                                  const copyYearlyLogicState = {
                                    ...yearlyLogicState,
                                  };
                                  copyYearlyLogicState.balance = copyBalances;
                                  setYearlyLogicState(copyYearlyLogicState);
                                }
                              }}
                          />
                        </Col>
                      </Row>
                  )}
                </div>
                <div className='w-100 d-flex justify-content-between align-items-center'>
                  <p className='text-info'>{t('SAVE_BEFORE_NEW_YEAR') as string}</p>

                  <Button disabled={shouldReadOnly} className='mt-5 mb-5 py-2 ' type='submit'>
                    {' '}
                    {t('SAVE_YEAR_UPDATES') as string}
                  </Button>
                </div>
                {/* ========================================================================================== */}
              </Form>
          )}
        </Formik>
      </>
  );
});

export default YearlyLogic;