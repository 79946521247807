import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

type OverviewSectionProps = {
  labelLeft: string;
  labelLeftVal: string;
  labelRight: string;
  labelRightVal: string;
};

export const OverviewSection: React.FC<OverviewSectionProps> = ({
  labelLeft,
  labelLeftVal,
  labelRight,
  labelRightVal,
}) => {
  return (
    <Container>
      <Row
        className='border py-3 px-2'
        style={{
          backgroundColor: 'rgba(28,163,250,0.05)',
          borderRadius: '8px',
        }}
      >
        <Col xs={6}>
          <div className='h-100 w-100 text-dark'>
            <p className='mb-1 text-muted'>{labelLeft}</p>
            <strong>
              {labelLeftVal}
              <small>h</small>
            </strong>
          </div>
        </Col>

        <Col xs={6}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{labelRight}</p>
            <strong style={{ color: '#ED4B9E' }}>
              {labelRightVal}
              <small>h</small>
            </strong>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OverviewSection;
