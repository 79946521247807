import Interval from './Interval';
import WeekReport from './WeekReport';
import TimeReport from './TimeReport';
import DayReport from './DayReport';
import TimeTriplet from './TimeTriplet';
import { formatDuration } from './time';

const buildInterval = (interval: any): Interval => {
  const start = interval?.start;
  const end = interval?.end;
  const intervalType = interval?.intervalType;
  const comment = interval?.comment;
  return new Interval(start, end, intervalType, comment);
};

const buildDayReport = (day: any): DayReport => {
  const rawIntervals = day?.intervals;
  const intervals = rawIntervals?.map((i: any) => buildInterval(i));
  const date = day?.date;
  const dayOfWeek = day?.dayOfWeek;
  const lastChangedOn = day?.lastChangedOn;
  const facilityDayType = day?.facilityDayType;
  const isWorkDay = day?.isWorkDay;
  const keyWord = day?.keyWord;
  const pause = formatDuration(day?.pause);
  const contractDays = day?.contractDays;
  const contractHours = formatDuration(day?.contractHours);
  const targetTimeDetailed = new TimeTriplet(
    formatDuration(day?.targetTimeDetailed?.careTimePart),
    formatDuration(day?.targetTimeDetailed?.disposalTimeRegularPart),
    formatDuration(day?.targetTimeDetailed?.disposalTimeIrregularPart),
  );
  const actualTimeDetailed = new TimeTriplet(
    formatDuration(day?.actualTimeDetailed?.careTimePart),
    formatDuration(day?.actualTimeDetailed?.disposalTimeRegularPart),
    formatDuration(day?.actualTimeDetailed?.disposalTimeIrregularPart),
  );
  const balanceDetailed = new TimeTriplet(
    formatDuration(day?.balanceDetailed?.careTimePart),
    formatDuration(day?.balanceDetailed?.disposalTimeRegularPart),
    formatDuration(day?.balanceDetailed?.disposalTimeIrregularPart),
  );
  const targetTimeTotal = formatDuration(day?.targetTimeTotal);
  const actualTimeTotal = formatDuration(day?.actualTimeTotal);
  const balanceTotal = formatDuration(day?.balanceTotal);
  return new DayReport(
    date,
    dayOfWeek,
    lastChangedOn,
    intervals,
    facilityDayType,
    isWorkDay,
    keyWord,
    targetTimeDetailed,
    actualTimeDetailed,
    balanceDetailed,
    targetTimeTotal,
    actualTimeTotal,
    balanceTotal,
    pause,
    contractDays,
    contractHours,
  );
};

const buildWeekReport = (rawWeek: any): WeekReport => {
  const numberWorkingDays = rawWeek?.numberWorkingDays;
  const tmpWeek: any[] = rawWeek?.week;
  const week = tmpWeek.map((d: any) => buildDayReport(d));
  const targetTimeDetailed = new TimeTriplet(
    formatDuration(rawWeek?.targetTimeDetailed?.careTimePart),
    formatDuration(rawWeek?.targetTimeDetailed?.disposalTimeRegularPart),
    formatDuration(rawWeek?.targetTimeDetailed?.disposalTimeIrregularPart),
  );
  const actualTimeDetailed = new TimeTriplet(
    formatDuration(rawWeek?.actualTimeDetailed?.careTimePart),
    formatDuration(rawWeek?.actualTimeDetailed?.disposalTimeRegularPart),
    formatDuration(rawWeek?.actualTimeDetailed?.disposalTimeIrregularPart),
  );
  const balanceDetailed = new TimeTriplet(
    formatDuration(rawWeek?.balanceDetailed?.careTimePart),
    formatDuration(rawWeek?.balanceDetailed?.disposalTimeRegularPart),
    formatDuration(rawWeek?.balanceDetailed?.disposalTimeIrregularPart),
  );
  const targetTimeTotal = formatDuration(rawWeek?.targetTimeTotal);
  const actualTimeTotal = formatDuration(rawWeek?.actualTimeTotal);
  const balanceTotal = formatDuration(rawWeek?.balanceTotal);
  return new WeekReport(
    numberWorkingDays,
    week,
    targetTimeDetailed,
    actualTimeDetailed,
    balanceDetailed,
    targetTimeTotal,
    actualTimeTotal,
    balanceTotal,
  );
};

const buildTimeReport = (timeReport: any): TimeReport => {
  const targetTimeDetailed = new TimeTriplet(
    formatDuration(timeReport?.targetTimeDetailed?.careTimePart),
    formatDuration(timeReport?.targetTimeDetailed?.disposalTimeRegularPart),
    formatDuration(timeReport?.targetTimeDetailed?.disposalTimeIrregularPart),
  );
  const actualTimeDetailed = new TimeTriplet(
    formatDuration(timeReport?.actualTimeDetailed?.careTimePart),
    formatDuration(timeReport?.actualTimeDetailed?.disposalTimeRegularPart),
    formatDuration(timeReport?.actualTimeDetailed?.disposalTimeIrregularPart),
  );
  const balanceDetailed = new TimeTriplet(
    formatDuration(timeReport?.balanceDetailed?.careTimePart),
    formatDuration(timeReport?.balanceDetailed?.disposalTimeRegularPart),
    formatDuration(timeReport?.balanceDetailed?.disposalTimeIrregularPart),
  );
  const targetTimeTotal = formatDuration(timeReport?.targetTimeTotal);
  const actualTimeTotal = formatDuration(timeReport?.actualTimeTotal);
  const balanceTotal = formatDuration(timeReport?.balanceTotal);
  const dependentBalanceTotal = formatDuration(timeReport?.dependentBalanceTotal);
  const dependentBalanceTotalLastMonth = formatDuration(timeReport?.dependentBalanceTotalLastMonth);
  const rawWeeks = timeReport?.weeks;
  const weeks = rawWeeks?.map((w: any) => buildWeekReport(w));
  return new TimeReport(
    timeReport.startDate.localDate,
    timeReport.endDate.localDate,
    timeReport.nameAdministration,
    timeReport.nameFacility,
    timeReport.firstNameEmployee,
    timeReport.lastNameEmployee,
    timeReport.statementOfCopyRight,
    timeReport.versionNumber,
    timeReport.printDate,
    formatDuration(timeReport.paidOutHoursTotal),
    formatDuration(timeReport.receivedHoursTotal),
    timeReport.numberWorkdaysInPeriod,
    timeReport.vacationDays,
    timeReport.vacationDaysTakenInPeriod,
    timeReport.vacationDaysLeft,
    timeReport.specialVacationDays,
    timeReport.specialVacationDaysTakenInPeriod,
    timeReport.specialVacationDaysLeft,
    timeReport.regenerationDays,
    timeReport.regenerationDaysTakenInPeriod,
    timeReport.regenerationDaysLeft,
    timeReport.conversionDays,
    timeReport.conversionDaysTakenInPeriod,
    timeReport.conversionDaysLeft,
    timeReport.sickDays,
    timeReport.workTimeCompensationDays,
    weeks,
    dependentBalanceTotal,
    dependentBalanceTotalLastMonth,
    targetTimeDetailed,
    actualTimeDetailed,
    balanceDetailed,
    targetTimeTotal,
    actualTimeTotal,
    balanceTotal,
  );
};

export default buildTimeReport;
