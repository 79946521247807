import {compareTimeRanges, TimeRange, timeRangeLength} from "./timeRange";
import {DateTime, Duration} from "luxon";
import {dateToWeekday, TARIFFTYPE} from "./enums";
import {PauseCondition} from "./pauseCondition";
import {AdditionConfiguration, getTVOEDAdditions} from "./additionConfiguration";
import {WorkdayDefiner} from "./workdayDefiner";
import {TariffAttributes} from "../apis/attributes";
import {createDurationFromHours, parseWorkdayDefiner} from "./workdayDefiner";

export class Tariff implements TimeRange {

    constructor(public start: DateTime,
                public end: DateTime = DateTime.fromObject({ year: 2100, month: 1, day: 1 }),
                public tariffType: TARIFFTYPE = TARIFFTYPE.AVERAGE_DAILY,
                public hasTimeAddition: boolean = true,
                public hasPauseDeduction: boolean = false,
                public pauseCondition: PauseCondition = new PauseCondition(),
                public timeAdditions: AdditionConfiguration[] = getTVOEDAdditions(),
                public weeklyHoursFullTimeEmployed: Duration = Duration.fromObject({hours: 39}),
                public workdayDefiner: WorkdayDefiner = new WorkdayDefiner(),
                public dec24NotWorking: boolean = false,
                public dec31NotWorking: boolean = false,
    )
    {
        if (end < start) {
            throw new Error(`end argument smaller start argument: !${end} < ${start}`);
        }
    }

    compareTo(other: Tariff): number {
        return compareTimeRanges(this, other);
    }

    length(): Duration {
        return timeRangeLength(this);
    }
    getNumberOfWorkDaysPerWeek(): number {
        return this.workdayDefiner.getNumberOfWorkDays();
    }
    getAverageDailyWorkingTime(): Duration {
        return Duration.fromMillis(this.weeklyHoursFullTimeEmployed.toMillis() / this.getNumberOfWorkDaysPerWeek());
    }

    toString(): string {
        return `Tariff: ${this.start.toISODate()} - ${this.end.toISODate()}, 
    type: ${this.tariffType}, 
    hasTimeAddition: ${this.hasTimeAddition}, 
    hasPauseDeduction: ${this.hasPauseDeduction}, 
    pauseSettings: ${this.pauseCondition}, 
    timeAdditions: ${this.timeAdditions}, 
    hoursFullTimeEmployed: ${this.weeklyHoursFullTimeEmployed}, 
    workDays: ${this.workdayDefiner}, 
    dec24NotWorking: ${this.dec24NotWorking}, 
    dec31NotWorking: ${this.dec31NotWorking}`;
    }

    isWorkingDay(date: DateTime): boolean {
        return this.workdayDefiner.isWorkingDay(dateToWeekday(date));
    }

    /**
     * Creates a new Tariff from the given attributes (e.g. from the database).
     * @param attributes - Tariff attributes.
     * @returns {Tariff} - Returns a new Tariff.
     */
    static fromAttributes(attributes: TariffAttributes): Tariff {
        return parseTariffAttributes(attributes);
    }
}

// TODO: Add parsing of pauseCondition and timeAdditions
/**
 * Parses the attributes of a tariff.
 * @param attributes - Tariff attributes (e.g. from the database).
 * @returns {Tariff} - Returns a new Tariff.
 */
export function parseTariffAttributes(attributes: TariffAttributes): Tariff {
    const start = DateTime.fromISO(attributes.startDate);
    let end = start.plus({ years: 100 });
    if (attributes.endDate !== null) {
        end = DateTime.fromISO(attributes.endDate);
    }
    let tariffType = TARIFFTYPE.AVERAGE_DAILY;
    if (attributes.frameworkDutyPlan) {
        tariffType = TARIFFTYPE.FIXED;
    }
    const workDayDefiner = parseWorkdayDefiner(attributes.workDayDefiner);

    return new Tariff(
        start,
        end,
        tariffType,
        attributes.timeAdditionActive,
        attributes.pauseDeductionActive,
        new PauseCondition(),
        getTVOEDAdditions(),
        createDurationFromHours(attributes.weeklyWorkingHoursFullyEmployed),
        workDayDefiner,
    );
}