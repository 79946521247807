import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router';
import usePromise from 'react-promise';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { authenticatedFacility, feathersAuthenticateFacility } from '../../../apis/social';
import LanguageSwitcher from '../../LanguageSwitcher';
import { togglePassword } from '../../../utility/common';

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const [pwd, setPwd] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [disableBtn, setDisableBtn] = useState(!localStorage.getItem('terms_of_service_faci'));
  const [alert, setAlert] = useState<undefined | string>(undefined);

  const history = useHistory();

  const auth = usePromise(authenticatedFacility());

  const navigateTo = useCallback(
    (pathName: string) => {
      history.push({
        pathname: generatePath(`${pathName}`),
      });
    },
    [auth, auth?.value, (auth?.value as any)?.user],
  );

  useEffect(() => {
    if ((auth?.value as any)?.user && (auth?.value as any)?.user?.role === 'ADMIN') {
      const { country, name } = (auth?.value as any)?.user?.facility;

      if (!country || !name) {
        navigateTo('/admin/onboarding/country');
      } else {
        navigateTo('/admin/employees-overview');
      }
    }
  }, [(auth?.value as any)?.user]);
  const showAlert = useCallback(
    (msg: string) => {
      setAlert(msg);
      setTimeout(() => {
        setAlert(undefined);
      }, 3000);
    },
    [alert],
  );

  return (
    <div
      style={{
        backgroundColor: '#f2fcff',
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: 100,
      }}
    >
      <Alert className='text-center' variant='danger' hidden={!alert}>
        {alert}
      </Alert>
      <Container style={{ paddingTop: '80px' }}>
        <h4 className='text-center'>{t('WELCOME') as string}</h4>
        <h5 className='text-center'>{t('FACILITY_LOGIN') as string} </h5>
        <br />
        <Form.Group className='mt-5 pt-3' controlId='formBasicEmail'>
          <Form.Label>{t('EMAIL_ADDRESS') as string}</Form.Label> <br />
          <input
            className='w-100'
            onChange={(e) => {
              setEmail(e.currentTarget.value.toLowerCase());
            }}
            type='email'
            placeholder=''
          />
          <br />
          <br />
          <Form.Label>{t('PASSWORD') as string}</Form.Label> <br />
          <input
            className='w-100'
            id='passwordInput'
            onChange={(e) => {
              setPwd(e.currentTarget.value);
            }}
            type='password'
            placeholder=''
          />
          <input className='mt-3 mr-2' type='checkbox' onClick={togglePassword} />
          <span>{t('SHOW_PASSWORD') as string}</span>
        </Form.Group>
        <Form.Group
          hidden={localStorage.getItem('terms_of_service_faci') === 'true'}
          controlId='formBasicCheckbox'
        >
          <Form.Check
            type='checkbox'
            label={t('ACCEPT_TERMS_PRIVACY') as string}
            onChange={(e) => setDisableBtn(!e.currentTarget.checked)}
          />
        </Form.Group>
        <LinkContainer to='/facility/signup' className='pl-0'>
          <Button variant='link'>{t('SIGN_UP') as string}</Button>
        </LinkContainer>{' '}
        <br />
        <LinkContainer to='/reset_email' className='pl-0'>
          <Button variant='link'>{t('FORGOT_PASSWORD') as string}</Button>
        </LinkContainer>
        <div style={{ height: '100px' }} />
        <Button
          disabled={disableBtn || !email || !pwd}
          type='submit'
          className='py-2 w-100 mt-5'
          onClick={async () => {
            try {
              await feathersAuthenticateFacility(email, pwd);
              localStorage.setItem('terms_of_service_faci', 'true');

              const auth_new = usePromise(authenticatedFacility());

              if (
                (auth_new?.value as any)?.user &&
                (auth_new?.value as any)?.user?.role === 'ADMIN'
              ) {
                const { country, name } = (auth_new?.value as any)?.user?.facility;

                if (!country || !name) {
                  navigateTo('/admin/onboarding/country');
                } else {
                  navigateTo('/admin/employees-overview');
                }
              }
            } catch (error) {
              showAlert(t('LOGIN_ALERT'));
            }
          }}
        >
          {t('SIGN_IN') as string}
        </Button>
      </Container>

      <div
        style={{
          bottom: '100px',
          position: 'absolute',
          right: '10px',
        }}
      >
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Login;
