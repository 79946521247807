import React, { useCallback } from 'react';

import { Container, Button } from 'react-bootstrap';
import usePromise from 'react-promise';
import { generatePath, useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import social, { authenticatedFacility } from '../../../apis/social';
import { UserAttributes } from '../../../apis/attributes/user';
import { EmployeeAttributes } from '../../../apis/attributes';

export type EmployeeProps = {
  employeeId: string;
};

export const CreateEmployee: React.FC<EmployeeProps> = ({ employeeId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { value } = usePromise(authenticatedFacility());
  const [employee] = useObservable<EmployeeAttributes | null>(
    () => social.service('employee').watch().get(employeeId) as any,
    [value],
  );
  const [employeeInUser] = useObservable<UserAttributes | null>(
    () =>
      social
        .service('user')
        .watch()
        .get(employee?.userId ?? '') as any,
    [employee?.userId],
  );

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}/:employeeId`, {
        employeeId,
      }),
    });
  }, []);

  const updateDB = useCallback(
    async (data: { email: string; firstName: string; lastName: string }) => {
      try {
        const dataAndEmail: any = {
          ...data,
          email: data.email.toLowerCase(),
          emailEmployeePassword:
            employeeInUser?.email === undefined || employeeInUser?.email === null,
        };
        await social.service('employee').patch(employeeId, {
          ...dataAndEmail,
        });
        navigateTo('/admin/employee-data');
      } catch (error) {
        alert('Error: please reload the page');
      }
    },
    [employeeId, employeeInUser?.email, employee],
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          email: employeeInUser?.email ?? '',
          firstName: employee?.firstName ?? '',
          lastName: employee?.lastName ?? '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(t('EMAIL_ADDRESS_WARNING')).required(t('REQUIRED')),
          firstName: Yup.string().required(t('REQUIRED')),
          lastName: Yup.string().required(t('REQUIRED')),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            updateDB(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {(props: FormikValues) => (
          <Form>
            <div style={{ height: '10px' }} />

            <h4>{t('GENERAL_DATA') as string} </h4>

            <strong>{t('FIRST_NAME') as string}</strong>
            <input
              className='mt-2 mb-4 w-100'
              placeholder=''
              name='firstName'
              defaultValue={employee?.firstName || ''}
              onChange={props.handleChange}
            />
            {props?.errors?.firstName && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.firstName}{' '}
              </p>
            )}

            <strong>{t('LAST_NAME') as string}</strong>
            <input
              className='mt-2 mb-4 w-100'
              placeholder=''
              defaultValue={employee?.lastName || ''}
              name='lastName'
              onChange={props.handleChange}
            />
            {props?.errors?.lastName && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.lastName}{' '}
              </p>
            )}

            <strong>{t('EMAIL_ADDRESS') as string}</strong>
            <input
              className='mt-2 mb-4 w-100'
              placeholder=''
              defaultValue={employeeInUser?.email || ''}
              name='email'
              disabled={employeeInUser?.email !== undefined && employeeInUser?.email !== null}
              onChange={props.handleChange}
            />
            {props?.errors?.email && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.email}{' '}
              </p>
            )}

            <div style={{ height: '30px' }} />

            <Button className='mt-5 mb-5 py-2' block type='submit'>
              {' '}
              {t('SAVE') as string}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CreateEmployee;
