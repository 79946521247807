import jsPDF from 'jspdf';
import TimeReport from '../../utility/TimeReport';
import ReportFooter from './model/ReportFooter';
import ReportHeader from './model/ReportHeader';
import { ITranslationLabel } from './model/TranslationLabel';
import WeekTableLastPos, { IWeekTableLastPos } from './model/WeekTableLastPos';
import generateFooterReport from './utils/generateFooterReport';
import generateHeaderReport from './utils/generateHeaderReport';
import generateOverviewWeekTable from './utils/generateOverviewWeekTable';

const MonthReportOverview = async (
  timeReport: TimeReport,
  lang: string,
  translateObj: ITranslationLabel,
) => {
  const doc = new jsPDF('p', 'px', 'a4');
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  const headerData = new ReportHeader(timeReport);
  const bodyStartY = generateHeaderReport(doc, headerData, translateObj);

  // BODY ==============================================================================
  // ===================================================================================
  let bodyRes: IWeekTableLastPos = new WeekTableLastPos();

  if (timeReport.weeks.length === 1) {
    const week = timeReport.weeks[0];
    bodyRes = generateOverviewWeekTable(doc, week, lang, translateObj, bodyStartY);
  } else {
    for (let weekIndex = 0; weekIndex < timeReport.weeks.length; weekIndex++) {
      const week = timeReport.weeks[weekIndex];
      if (weekIndex === 0) {
        bodyRes = generateOverviewWeekTable(doc, week, lang, translateObj, bodyStartY);
      } else {
        bodyRes = generateOverviewWeekTable(doc, week, lang, translateObj, bodyRes.nextY);
      }
    }
  }
  const { xPos, yPos, nextY } = bodyRes;

  // MONTH OVERVIEW =================================================================
  // add page for month overview

  const sigOnCurrentPageCalculate = nextY + 150;

  if (sigOnCurrentPageCalculate > pageHeight) {
    doc.addPage();
  }

  // SIGNATURE ======================================================================
  const footerData = new ReportFooter(timeReport);
  generateFooterReport(doc, pageHeight, pageWidth, footerData, translateObj);

  doc.save(`${headerData.period}_MONTH-OVERVIEW.pdf`);
};

export default MonthReportOverview;
