import { SickNote } from './SickNote';
import { buildTimeRangeCollection } from './utility';
import { YearlySickNotesDB } from '../apis/attributes/yearly-sick-notes';

/**
 * A collection of sick notes for a given year.
 */
export class SickNoteCollection {
  public id: string;
  public employeeId: string;
  public year: number;
  public sickNotes: SickNote[];

  constructor(id: string, employeeId: string, year: number, sickNotes: SickNote[]) {
    this.id = id;
    this.employeeId = employeeId;
    this.year = year;
    this.sickNotes = buildTimeRangeCollection(sickNotes) as SickNote[];
  }

  /**
   * Returns a new SickNoteCollection with the given sick note added.
   * @param attributes The attributes of the sick note to add.
   * @returns A new SickNoteCollection with the given sick note added.
   */
  static fromAttributes(attributes: YearlySickNotesDB): SickNoteCollection {
    const sickNotes = attributes.sickNotes.map((sickNote) => SickNote.fromAttributes(sickNote));
    return new SickNoteCollection(attributes.id, attributes.employeeId, attributes.year, sickNotes);
  }

  toAttributes(): YearlySickNotesDB {
    return {
      id: this.id,
      employeeId: this.employeeId,
      year: this.year,
      sickNotes: this.sickNotes.map((sickNote) => sickNote.toAttributes()),
    };
  }
}
