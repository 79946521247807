import React from 'react';
import { Route } from 'react-router';
import PrivacyPolicy from './PrivacyPolicy';
import TermsService from './TermsService';

export default () => (
  <>
    <Route exact path='/terms-of-service' render={() => <TermsService />} />
    <Route exact path='/privacy-policy' render={() => <PrivacyPolicy />} />
  </>
);
