import 'flatpickr/dist/themes/material_green.css';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { TariffTimeInterval, TimeInterval } from './Availability';

export type TariffTimeSectionProps = {
  enabled: boolean;
  key: string;
  dayStr: string;
  disableView?: boolean;
  timeIntervals: TariffTimeInterval[];
  // eslint-disable-next-line no-unused-vars
  onSubmit(timeIntervals: TariffTimeInterval[], enabled: boolean): void;
};

const normalizeTimeZoneDate = (date: Date) => {
  /*

  const momentDate = moment(date);
  const processed = new Date();
  processed.setFullYear(1970);
  processed.setMonth(0);
  processed.setDate(1);
  processed.setHours(momentDate.hours(), momentDate.minutes(), 0, 0);

  return processed;
  */
  return moment(date);
};

const convertDayToTimestamp = (date: Date) => date.getHours() * 60 * 60 + date.getMinutes() * 60;

const dummyInterval = (id: string): TimeInterval => ({
  endDate: undefined,
  id,
  percentage: 0,
  startDate: undefined,
});

const dayEnumToStr = [
  { key: 'NIGHT', val: 'Wochentag' },
  { key: 'SATURDAY', val: 'Samstag' },
  { key: 'SUNDAY', val: 'Sonntag' },
  { key: 'FEAST', val: 'Feiertag' },
  {
    key: 'FEIERTAG_FULL_COMPENSATION',
    val: 'Feiertag Lohnausgleich',
  },
  { key: 'DEC_24', val: '24. Dezember' },
  { key: 'DEC_31', val: '31. Dezember' },
];
const getDayStrFromEnum = (str: string) => dayEnumToStr.find((d) => d.key === str)?.val;

export const convertTariffHourMinToDate = (hour: number, min: number) =>
  new Date((hour * 60 * 60 + min * 60) * 1000);

const areIntersecting = (
  startDate1?: Date,
  endDate1?: Date,
  startDate2?: Date,
  endDate2?: Date,
): boolean => {
  if (!startDate1 || !endDate1 || !startDate2 || !endDate2) {
    return true;
  }

  const startTimestamp1 = convertDayToTimestamp(startDate1);
  const endTimestamp1 = convertDayToTimestamp(endDate1);

  const startTimestamp2 = convertDayToTimestamp(startDate2);
  const endTimestamp2 = convertDayToTimestamp(endDate2);

  return startTimestamp1 < endTimestamp2 && startTimestamp2 < endTimestamp1;
};

export const TariffTimeSection: React.FC<TariffTimeSectionProps> = ({
  dayStr,
  timeIntervals,
  key,
  onSubmit,
  enabled,
  disableView = false,
}) => {
  const { t: t18 } = useTranslation();
  const [alert, setAlert] = useState<undefined | string>(undefined);
  const [localTimeSlots, setLocalTimeSlots] = useState<TariffTimeInterval[]>(timeIntervals);
  const [enableTimeAddition, setEnableTimeAddition] = useState(enabled);
  const [successAlert, setSuccessAlert] = useState<undefined | string>(undefined);

  const showAlert = useCallback(
    (msg: string) => {
      setAlert(msg);
      setTimeout(() => {
        setAlert(undefined);
      }, 3000);
    },
    [alert],
  );
  const showSuccessAlert = useCallback(
    (msg: string) => {
      setSuccessAlert(msg);
      setTimeout(() => {
        setSuccessAlert(undefined);
      }, 3000);
    },
    [alert],
  );
  const addFreeSlot = () => {
    setLocalTimeSlots([...localTimeSlots, dummyInterval(uuid())]);
  };

  const handleSubmit = useCallback(
    (addBlankInterval = true) => {
      // check if all info is valid

      const lastItem = localTimeSlots[localTimeSlots.length - 1];
      if (!lastItem.startDate || !lastItem.endDate) {
        showAlert('Please, finish defining your time interval');
        return;
      }

      if ((lastItem?.percentage ?? -1) < 0) {
        showAlert(t18('PERCENTILE_WARNING'));
        return;
      }

      // check if start date is smaller than end date for entry date
      if (convertDayToTimestamp(lastItem.startDate) - convertDayToTimestamp(lastItem.endDate) > 0) {
        showAlert("Start date can't be later than end date");
        return;
      }

      // check if time intersecting

      let shouldReturn = false;
      if (localTimeSlots.length >= 2) {
        localTimeSlots.forEach((t) => {
          if (t.id === lastItem.id) {
            return;
          }
          if (areIntersecting(t.startDate, t.endDate, lastItem.startDate, lastItem.endDate)) {
            shouldReturn = true;
          }
        });
      }
      if (shouldReturn) {
        showAlert('Your intervals are intersecting');
        return;
      }

      // sort by start date
      const duplicateTimeSlots = localTimeSlots;

      const sortedTimes = duplicateTimeSlots.sort((a, b) => {
        if (!a.startDate || !a.endDate || !b.startDate || !b.endDate) {
          return 0;
        }

        return convertDayToTimestamp(a.startDate) - convertDayToTimestamp(b.startDate);
      });

      setLocalTimeSlots(sortedTimes);

      // callback to store new state
      onSubmit(sortedTimes, enabled);

      if (addBlankInterval) {
        addFreeSlot();
      } else showSuccessAlert('saved');
    },
    [localTimeSlots, enabled],
  );

  return (
    <div key={key}>
      <Alert variant='danger' hidden={!alert}>
        {alert}
      </Alert>
      <Alert className='text-center' variant='success' hidden={!successAlert}>
        {successAlert}
      </Alert>

      <Row key={key}>
        <Col>
          <div className='w-100 d-flex justify-content-start align-items-center'>
            <Form.Check
              disabled={disableView}
              defaultChecked={enableTimeAddition}
              type='checkbox'
              onChange={() => setEnableTimeAddition(!enableTimeAddition)}
            />
            <p className='mt-3 ml-4'>{dayStr && getDayStrFromEnum(dayStr)}</p>
          </div>
        </Col>
      </Row>
      {localTimeSlots.map((interval, idx) => (
        <>
          <Row key={interval.id}>
            <Col className='d-flex align-items-center ' xs={4}>
              <div className='w-100'>
                <p className='text-start  m-0'>{t18('FROM') as string} </p>
                <Flatpickr
                  className='text-center w-100'
                  options={{
                    dateFormat: 'H:i',
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                  }}
                  disabled={disableView || !enableTimeAddition || localTimeSlots.length > idx + 1}
                  defaultValue={
                    interval?.startDate && normalizeTimeZoneDate(interval.startDate).toISOString()
                  }
                  onChange={(d) => {
                    const copyIntervals = localTimeSlots;
                    const updatedInterval = localTimeSlots[localTimeSlots.length - 1];
                    const processedDate = d[0];
                    processedDate.setFullYear(1970);
                    processedDate.setMonth(0);
                    processedDate.setDate(1);
                    updatedInterval.startDate = processedDate;
                    copyIntervals[localTimeSlots.length - 1] = updatedInterval;

                    setLocalTimeSlots(copyIntervals);
                  }}
                />
              </div>
            </Col>

            <Col xs={4} className=' d-flex align-items-center pl-0'>
              <div className='w-100'>
                <p className='text-start m-0'>{t18('TO') as string} </p>

                <Flatpickr
                  className='text-center w-100'
                  options={{
                    dateFormat: 'H:i',
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                  }}
                  disabled={disableView || !enableTimeAddition || localTimeSlots.length > idx + 1}
                  defaultValue={
                    interval?.endDate && normalizeTimeZoneDate(interval.endDate).toISOString()
                  }
                  onChange={(d) => {
                    const copyIntervals = localTimeSlots;
                    const updatedInterval = localTimeSlots[localTimeSlots.length - 1];
                    const processedDate = d[0];
                    processedDate.setFullYear(1970);
                    processedDate.setMonth(0);
                    processedDate.setDate(1);
                    updatedInterval.endDate = processedDate;
                    copyIntervals[localTimeSlots.length - 1] = updatedInterval;

                    setLocalTimeSlots(copyIntervals);
                  }}
                />
              </div>
            </Col>
            <Col xs={2} className='pl-0'>
              <span className='text-start'>
                % <br />
                <input
                  className='w-100'
                  placeholder=''
                  type='number'
                  min='0'
                  defaultValue={(interval?.percentage ?? 1) * 100}
                  disabled={disableView || !enableTimeAddition || localTimeSlots.length > idx + 1}
                  onChange={(d) => {
                    const copyIntervals = localTimeSlots;
                    const updatedInterval = localTimeSlots[localTimeSlots.length - 1];
                    updatedInterval.percentage = parseInt(d.currentTarget.value ?? '-1', 10) / 100;
                    copyIntervals[localTimeSlots.length - 1] = updatedInterval;
                    setLocalTimeSlots(copyIntervals);
                  }}
                />
              </span>
            </Col>

            <Col className='d-flex align-items-center p-0'>
              <div className='pb-1'>
                <p className='text-start m-0'>‏</p>
                <Button
                  variant='outline-dark px-2 py-0'
                  disabled={disableView}
                  onClick={() => {
                    if (localTimeSlots.length > 1) {
                      const copyIntervals = localTimeSlots.filter((interv) => {
                        const bool = interv.startDate?.getTime();
                        interval.startDate?.getTime() &&
                          interv.endDate?.getTime() !== interval.endDate?.getTime();
                        return bool;
                      });
                      setLocalTimeSlots(copyIntervals);
                    } else {
                      const blankInterval = localTimeSlots[0];
                      blankInterval.endDate = undefined;
                      blankInterval.startDate = undefined;
                      blankInterval.id = uuid();
                      blankInterval.percentage = undefined;
                      setLocalTimeSlots([blankInterval]);
                    }
                  }}
                >
                  X
                </Button>
              </div>
            </Col>
          </Row>
        </>
      ))}

      <Row>
        <Col xs={9} />

        <Col className=''>
          <div className='w-100'>
            <Button disabled={disableView} className='mt-4 ml-3' onClick={() => handleSubmit(true)}>
              +
            </Button>
            <Button
              disabled={disableView}
              className='mt-4 ml-2'
              onClick={() => handleSubmit(false)}
            >
              {t18('SAVE') as string}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TariffTimeSection;
