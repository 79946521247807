import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import usePromise from 'react-promise';
import { useLocation, useParams } from 'react-router';
import { useAsyncFn } from 'react-use';
import social, { authenticatedEmployee } from '../../../apis/social';
import MonthReport from '../../../components/MonthReport';
import buildTimeReport from '../../../utility/reportbuild';
import { calculatePauseStat, calculateStat } from '../../../utility/time';
import StatisticsSection from './StatisticsSections';
import TopInlineOverviewSection from './TopInlineOverviewSection';

export const MonthView: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dd = query.get('dd');

  const [startDate, setStartDate] = useState(dd ? new Date(dd) : new Date());
  const { value } = usePromise(authenticatedEmployee());

  const employeeId = useParams<{ employeeId: string }>().employeeId;

  const [monthEmployeeStatistics, retryMonthEmployment] = useAsyncFn(async () => {
    const res: any = (await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: moment(startDate).endOf('month').toISOString(),
      startDate: moment(startDate).startOf('month').toISOString(),
      statistics: true,
      buildMonthReport: true,
      buildYearReport: false,
    })) as any;
    return res as any;
  }, [value, (value as any)?.user, startDate?.toString()]);
  useEffect(() => {
    retryMonthEmployment();
  }, [value, (value as any)?.user, employeeId, startDate?.toString()]);

  return (
    <Container>
      <DatePicker
        className='mt-3 ml-2 monthDatePicker'
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        dateFormat='MM/yyyy'
        showMonthYearPicker
      />
      <div style={{ height: '10px' }} />
      {monthEmployeeStatistics?.value?.timeReport && (
        <MonthReport timeReport={buildTimeReport(monthEmployeeStatistics?.value?.timeReport)} />
      )}
      {monthEmployeeStatistics ? (
        <TopInlineOverviewSection
          totalTimeVal={calculateStat(monthEmployeeStatistics?.value?.targetTime)}
          timeWorkedVal={calculateStat(
            monthEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
          )}
          balanceVal={calculateStat(monthEmployeeStatistics?.value?.balance)}
          overtimeVal={calculatePauseStat(monthEmployeeStatistics?.value?.actualTime)}
          breakVal={1}
        />
      ) : (
        <TopInlineOverviewSection
          totalTimeVal=''
          timeWorkedVal=''
          balanceVal=''
          overtimeVal=''
          breakVal={1}
        />
      )}
      <div style={{ height: '10px' }} />

      <hr
        style={{
          backgroundColor: 'white',
          border: 'none',
          height: '8px',
        }}
      />

      {monthEmployeeStatistics && (
        <StatisticsSection stats={monthEmployeeStatistics?.value as any} />
      )}

      <div style={{ height: '20px' }} />
    </Container>
  );
};

export default MonthView;
