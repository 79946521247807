import {AdditionRange} from "./additionRange";
import {TimeRange} from "./timeRange";
import {buildTimeRangeCollection} from "./utility";
import {DateTime, Duration} from "luxon";
import {Interval} from "./interval";
import {ADDITION, INTERVALTYPE} from "./enums";

export class AdditionConfiguration {
    public additionType: ADDITION;
    public additionRanges: AdditionRange[];

    /**
     * Constructs an AdditionConfiguration object.
     * @param additionType
     * @param {AdditionRange[]} additionRanges
     */
    constructor(additionType: ADDITION, additionRanges: TimeRange[]) {
        this.additionType = additionType;
        this.additionRanges = buildTimeRangeCollection(additionRanges) as AdditionRange[];
    }
}

/**
 * @param {} intervals
 * @param {} additions
 */
export function calculateAdditionTime(
    intervals: Interval[], additions: AdditionRange[]): Array<[Duration, INTERVALTYPE]> {

    const additionList: Array<[Duration, INTERVALTYPE]> = [];

    const sameDateIntervals = intervals.map(interval => intervalToFixedDate(interval));
    const sameDateAdditions = additions.map(addition => additionToFixedDate(addition));

    for (const event of sameDateIntervals) {
        for (const addition of sameDateAdditions) {
            additionList.push(getAdditionAtInterval(event, addition));
        }
    }

    return additionList;
}

/**
 * @param {Interval} interval
 * @param {AdditionConfiguration} addition
 */
function getAdditionAtInterval(interval: Interval, addition: AdditionRange): [Duration, INTERVALTYPE] {
    // left outside
    if (interval.end <= addition.start) {
        return [Duration.fromMillis(0), interval.intervalType];
    }

    // right outside
    else if (addition.end <= interval.start) {
        return [Duration.fromMillis(0), interval.intervalType];
    }

    // left overlap
    else if (interval.end <= addition.end && interval.end > addition.start && interval.start < addition.start) {
        const add = Duration.fromMillis(Math.round(interval.end.diff(addition.start).as(
            'milliseconds') * addition.fraction));
        return [add, interval.intervalType];
    }

    // right overlap
    else if (interval.start >= addition.start && interval.start < addition.end && interval.end > addition.end) {
        const add = Duration.fromMillis(Math.round(addition.end.diff(interval.start).as(
            'milliseconds') * addition.fraction));
        return [add, interval.intervalType];
    }

    // event inside addition
    else if (interval.start >= addition.start && interval.end <= addition.end) {
        const add = Duration.fromMillis(Math.round(interval.length().as(
            'milliseconds') * addition.fraction));
        return [add, interval.intervalType];
    }

    // addition inside event
    const add = Duration.fromMillis(Math.round(addition.length().as(
        'milliseconds') * addition.fraction));
    return [add, interval.intervalType];
}

/**
 *
 * @param {Interval} interval
 * @param {DateTime} targetDate
 */
function intervalToFixedDate(interval: Interval,
                             targetDate = DateTime.fromObject({year: 2023, month: 1, day: 1})){

    const start = DateTime.fromMillis(interval.start.toMillis()).set(
        {year: targetDate.year, month: targetDate.month, day: targetDate.day});

    const end = DateTime.fromMillis(interval.end.toMillis()).set(
        {year: targetDate.year, month: targetDate.month, day: targetDate.day});

    return new Interval(start, end, interval.intervalType, interval.comment);
}

/**
 *
 * @param addition
 * @param targetDate
 */
function additionToFixedDate(addition: AdditionRange,
                             targetDate = DateTime.fromObject({year: 2023, month: 1, day: 1})){

    const start = DateTime.fromMillis(addition.start.toMillis()).set(
        {year: targetDate.year, month: targetDate.month, day: targetDate.day});

    const end = DateTime.fromMillis(addition.end.toMillis()).set(
        {year: targetDate.year, month: targetDate.month, day: targetDate.day});

    return new AdditionRange(start, end, addition.fraction);
}

export function getTVOEDAdditions(): AdditionConfiguration[] {

    const tvoedAdditions: AdditionConfiguration[] = [];
    const baseDate = DateTime.fromObject({ year: 2023, month: 1, day: 1 });

    // NIGHT
    const weekDay1 = new AdditionRange(baseDate.set(
        { hour: 0, minute: 0 }), baseDate.set({ hour: 6, minute: 0 }), 0.20);
    const weekDay2 = new AdditionRange(baseDate.set(
        { hour: 21, minute: 0 }), baseDate.set({ hour: 23, minute: 59, second: 59 }), 0.20);
    const additionConditionCollectionWd = new AdditionConfiguration(
        ADDITION.NIGHT, [weekDay1, weekDay2]);
    tvoedAdditions.push(additionConditionCollectionWd);

    // SATURDAY
    const saturday1 = new AdditionRange(baseDate.set(
        { hour: 13, minute: 0 }), baseDate.set({ hour: 21, minute: 0 }), 0.20);
    const additionConditionCollectionSa = new AdditionConfiguration(
        ADDITION.SATURDAY, [saturday1]);
    tvoedAdditions.push(additionConditionCollectionSa);

    // SUNDAY
    const sunday = new AdditionRange(baseDate.set(
        { hour: 0, minute: 0 }), baseDate.set({ hour: 23, minute: 59, second: 59 }), 0.25);
    const additionConditionCollectionSu = new AdditionConfiguration(
        ADDITION.SUNDAY, [sunday]);
    tvoedAdditions.push(additionConditionCollectionSu);

    // FEAST
    const feast = new AdditionRange(baseDate.set(
        { hour: 0, minute: 0 }), baseDate.set({ hour: 23, minute: 59, second: 59 }), 0.35);
    const additionConditionCollectionFeast = new AdditionConfiguration(
        ADDITION.FEAST, [feast]);
    tvoedAdditions.push(additionConditionCollectionFeast);

    // FEAST FULL COMPENSATION
    const feastFullCompensation = new AdditionRange(baseDate.set(
        { hour: 0, minute: 0 }), baseDate.set({ hour: 23, minute: 59, second: 59 }), 1.35);
    const additionConditionCollectionFeastFullCompensation = new AdditionConfiguration(
        ADDITION.FULL_COMPENSATION, [feastFullCompensation]);
    tvoedAdditions.push(additionConditionCollectionFeastFullCompensation);

    // DEC_24_WEEKDAY
    const dec24 = new AdditionRange(baseDate.set(
        { hour: 6, minute: 0 }), baseDate.set({ hour: 23, minute: 59, second: 59 }), 0.35);
    const additionConditionCollectionDec24 = new AdditionConfiguration(
        ADDITION.DEC_24, [dec24]);
    tvoedAdditions.push(additionConditionCollectionDec24);

    // DEC_31_WEEKDAY
    const dec31 = new AdditionRange(baseDate.set(
        { hour: 6, minute: 0 }), baseDate.set({ hour: 23, minute: 59, second: 59 }), 0.35);
    const additionConditionCollectionDec31 = new AdditionConfiguration(
        ADDITION.DEC_31, [dec31]);
    tvoedAdditions.push(additionConditionCollectionDec31);

    return tvoedAdditions;
}

function reduceByKey(a: Array<[Duration, INTERVALTYPE]>,
                     b: Array<[Duration, INTERVALTYPE]>): Array<[Duration, INTERVALTYPE]> {

    const both = a.concat(b);
    const types = new Map<INTERVALTYPE, Duration>();

    for (const [duration, intervalType] of both) {
        if (!types.has(intervalType)) {
            types.set(intervalType, Duration.fromMillis(0));
        }
    }

    for (const intervalType of types.keys()) {
        const kt = both.filter(([_, it]) => it === intervalType);
        for (const [duration] of kt) {
            types.set(intervalType, types.get(intervalType)!.plus(duration));
        }
    }

    return Array.from(types.entries()).map(([intervalType, duration]) => [duration, intervalType]);
}
