// disable eslint file
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Image, Nav, NavItem, NavLink, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { generatePath, useHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import social, { Pending, authenticatedFacility } from '../../../apis/social';
import logo from '../images/logo.png';
import SelectDayPng from '../images/select_day.png';
import SelectMonthPng from '../images/select_month.png';
import SelectWeekPng from '../images/select_week.png';
import SelectYearPng from '../images/select_year.png';
import { ReactComponent as MonetizationSvg } from '../images/monetizationPlans.svg';
import { ReactComponent as AccountSettingsSvg } from '../images/profileSettings.svg';
import { ReactComponent as CardSvg } from '../images/card.svg';
import ContractPng from '../images/contract.png';
import CallInSick from '../images/callInSick.png';
import PersonalSettings from '../images/personalSettings.png';
import { ReactComponent as CopyrightsSvg } from '../images/copyrights.svg';
import EmployeesPng from '../images/employees.png';
import { ReactComponent as LogoutSvg } from '../images/logout.svg';
import { ReactComponent as MenuSvg } from '../images/menu.svg';

import LanguageSwitcher from '../../LanguageSwitcher';

const itemStyle = {
  borderRadius: '20px',
  opacity: 0.7,
  padding: '11px 10px',
};

const getEmployeeNameForNavigation = (displayName: string) => (
  <Nav className='ml-auto d-none d-sm-block'>
    <h2 className='mr-4 text-primary'>{displayName}</h2>
  </Nav>
);

const LiNavItem: React.FC<object> = ({ children, ...props }) => (
  <NavItem as='li' {...props}>
    {children}
  </NavItem>
);

export const Layout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { value } = usePromise(authenticatedFacility());

  const [name, setName] = useState<string>();
  const navigateTo = useCallback((pathName: string) => {
    history?.push({
      pathname: generatePath(`${pathName}`),
    });
    // window.location.href = generatePath(`${pathName}/`);
  }, []);

  const employerLogout = useCallback(async () => {
    await social?.authentication?.logout();
    navigateTo('/');
  }, [social, social?.authentication]);

  const employeeLogout = useCallback(async () => {
    await social?.authentication?.logout();
    navigateTo('/');
  }, [social, social?.authentication]);

  const [prevEmployment] = useObservable<any | null>(
    () =>
      social
        .service('employment')
        .watch()
        .find({
          query: {
            $limit: 1,
            $sort: { createdAt: -1 },
            employeeId: (value as any)?.user?.employee?.id,
            facilityId: (value as any)?.user?.employee?.facilityId,
            finalSubmission: true,
          },
        }) as any,
    [(value as any)?.user?.role, (value as any)?.user, value],
  );

  const [prevTariff] = useObservable<any | null>(
    () =>
      social
        .service('tariff')
        .watch()
        .find({
          query: {
            $limit: 1,
            $sort: { createdAt: -1 },
            facilityId: (value as any)?.user?.employee?.facilityId,
            finalSubmission: true,
          },
        }) as any,
    [(value as any)?.user?.role, (value as any)?.user, value],
  );

  const goToPaymentMethodPortal = async () => {
    try {
      const { url }: any = (await social
        .service('facility')
        .get(`${(value as any)?.user?.facility?.id}`, {
          query: {
            customerPortal: true,
            stripeCustomerId: (value as any)?.user?.facility?.stripeCustomerId,
          },
        })) as any;

      window.location.href = url as any;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    const employeeId = location.pathname.split('/employee/')?.[1]?.split('/')?.[0];

    if (employeeId) {
      (async () => {
        const employee = await social.service('employee').get(employeeId);
        setName(`${employee?.firstName} ${employee?.lastName}`);
      })();
    } else {
      // set facility
      setName((value as any)?.user?.facility?.name);
    }
  }, [value, location.pathname]);

  if ((value as any)?.user === null) return <Pending />;
  return (
    <>
      {(value as any)?.user === null && <Pending />}

      <Navbar
        variant='light'
        bg='white'
        expand
        className='justify-content-between shadow-sm'
        style={{ maxWidth: '100%', width: '100%', zIndex: 1 }}
      >
        <Link to='/'>
          <Navbar.Brand>
            <img src={logo} alt={t('navbarBrand') as string} style={{ width: '100px' }} />
          </Navbar.Brand>
        </Link>

        {getEmployeeNameForNavigation(name ?? '')}

        <Nav as='ul' className='flex-row'>
          <Dropdown as={LiNavItem} drop='down'>
            <Dropdown.Toggle as={NavLink} bsPrefix='--not-existing'>
              <MenuSvg width='24px' height='24px' />
              {/* <FontAwesomeIcon icon={faBars} /> */}
              {/* <span className="d-none d-sm-inline">
                {' '}
                {t('more') as string}
              </span> */}
            </Dropdown.Toggle>

            <Dropdown.Menu
              align='right'
              style={{ maxWidth: '370px', width: '90vw' }}
              className='px-2'
            >
              {/*
              <div className="w-100 d-flex justify-content-end mb-4">
                <Button variant="link">
                  <CloseSvg />
                </Button>

              </div> */}

              {(value as any)?.user?.role !== 'EMPLOYEE' && (
                <>
                  {(value as any)?.user && (
                    <LinkContainer
                      to='/admin/furnishing-details'
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <AccountSettingsSvg width='22px' height='22px' />
                        {'    '}
                        {t('SETUP_SETTINGS') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <LinkContainer
                      to='/admin/employees-overview'
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={EmployeesPng} alt='' width='25px' height='25px' />
                        {'    '}
                        {t('EMPLOYEES') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}
                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/admin/employees-sick/${(value as any)?.user?.facility?.id}`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={CallInSick} alt='' width='25px' height='25px' />
                        {'    '}
                        {t('EMPLOYEES_SICK') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <LinkContainer
                      to='/admin/tariffs/overview'
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={ContractPng} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('TARIFF_SETTINGS') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}
                  {/*
                  {(value as any)?.user && (
                      <LinkContainer
                        to="/admin/plans"
                        exact
                        className="mb-2"
                        style={itemStyle}
                      >
                        <Dropdown.Item>
                          <Image src={ContractPng} alt="" width="22px" height="22px" />
                          {'    '}
                          {t('PLANS') as string}

                        </Dropdown.Item>
                      </LinkContainer>
                      )}
                   */}
                  {(value as any)?.user && (
                    <LinkContainer to='/admin/plans' exact className='mb-1' style={itemStyle}>
                      <Dropdown.Item>
                        <MonetizationSvg width='22px' height='22px' />{' '}
                        {t('PAYMENT_DETAILS') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <Dropdown.Item onClick={() => goToPaymentMethodPortal()} style={itemStyle}>
                      <CardSvg width='22px' height='22px' />
                      {'    '}
                      {t('PAYMENT_METHOD') as string}
                    </Dropdown.Item>
                  )}

                  {!(value as any)?.user && (
                    <LinkContainer to='/' exact className='mb-2' style={itemStyle}>
                      <Dropdown.Item>
                        <CopyrightsSvg width='22px' height='22px' />
                        {'    '}
                        {t('LOGIN') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <Dropdown.Item onClick={employerLogout} style={itemStyle}>
                      <LogoutSvg width='22px' height='22px' />
                      {'    '}
                      {t('LOGOUT') as string}
                    </Dropdown.Item>
                  )}

                  <LinkContainer to='/terms-of-service' exact className='mb-2' style={itemStyle}>
                    <Dropdown.Item>
                      <CopyrightsSvg width='22px' height='22px' />
                      {'    '}
                      {t('TERMS_SERVICE') as string}
                    </Dropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/privacy-policy' exact className='mb-2' style={itemStyle}>
                    <Dropdown.Item>
                      <CopyrightsSvg width='22px' height='22px' />
                      {'    '}
                      {t('PRIVACY_POLICY') as string}
                    </Dropdown.Item>
                  </LinkContainer>

                  <div className='w-100 d-flex justify-content-end'>
                    <LanguageSwitcher />
                  </div>
                </>
              )}

              {(value as any)?.user?.role === 'EMPLOYEE' && (
                <>
                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/employee/${(value as any)?.user?.employee?.id}/dashboard`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <AccountSettingsSvg width='22px' height='22px' />
                        {'    '}
                        {t('DASHBOARD') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/employee/${(value as any)?.user?.employee?.id}/year`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={SelectYearPng} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('YEAR_OVERVIEW') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}
                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/employee/${(value as any)?.user?.employee?.id}/month`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={SelectMonthPng} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('MONTH_OVERVIEW') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/employee/${(value as any)?.user?.employee?.id}/week`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={SelectWeekPng} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('WEEK_OVERVIEW') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/employee/${(value as any)?.user?.employee?.id}/day`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={SelectDayPng} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('DAY_OVERVIEW') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}
                  {(value as any)?.user && (
                    <LinkContainer
                      to={`/employee/${(value as any)?.user?.employee?.id}/call-in-sick`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={CallInSick} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('CALL_IN_SICK') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user &&
                    prevEmployment &&
                    Boolean(prevEmployment?.data?.length) && (
                      <LinkContainer
                        to={`/read-employment/${prevEmployment?.data[0]?.id}`}
                        exact
                        className='mb-2'
                        style={itemStyle}
                      >
                        <Dropdown.Item>
                          <Image src={ContractPng} alt='' width='22px' height='22px' />
                          {'    '}
                          {t('MY_EMPLOYMENT_CONTRACT') as string}
                        </Dropdown.Item>
                      </LinkContainer>
                    )}

                  {(value as any)?.user && prevTariff && Boolean(prevTariff?.data?.length) && (
                    <LinkContainer
                      to={`/read-tariff-settings/${prevTariff?.data[0]?.id}`}
                      exact
                      className='mb-2'
                      style={itemStyle}
                    >
                      <Dropdown.Item>
                        <Image src={ContractPng} alt='' width='22px' height='22px' />
                        {'    '}
                        {t('MY_TARIFF') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {!(value as any)?.user && (
                    <LinkContainer to='/' exact className='mb-2' style={itemStyle}>
                      <Dropdown.Item>
                        <CopyrightsSvg width='22px' height='22px' />
                        {'    '}
                        {t('LOGIN') as string}
                      </Dropdown.Item>
                    </LinkContainer>
                  )}

                  {(value as any)?.user &&
                    prevEmployment &&
                    Boolean(prevEmployment?.data?.length) && (
                      <LinkContainer
                        to={`/employee/${(value as any)?.user?.employee?.id}/personal-settings`}
                        exact
                        className='mb-2'
                        style={itemStyle}
                      >
                        <Dropdown.Item>
                          <Image src={PersonalSettings} alt='' width='22px' height='22px' />
                          {'    '}
                          {t('PERSONAL_SETTINGS') as string}
                        </Dropdown.Item>
                      </LinkContainer>
                    )}

                  {(value as any)?.user && (
                    <Dropdown.Item onClick={employeeLogout} style={itemStyle}>
                      <LogoutSvg width='22px' height='22px' />
                      {'    '}
                      {t('LOGOUT') as string}
                    </Dropdown.Item>
                  )}
                  <LinkContainer to='/terms-of-service' exact className='mb-2' style={itemStyle}>
                    <Dropdown.Item>
                      <CopyrightsSvg width='22px' height='22px' />
                      {'    '}
                      {t('TERMS_SERVICE') as string}
                    </Dropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/privacy-policy' exact className='mb-2' style={itemStyle}>
                    <Dropdown.Item>
                      <CopyrightsSvg width='22px' height='22px' />
                      {'    '}
                      {t('PRIVACY_POLICY') as string}
                    </Dropdown.Item>
                  </LinkContainer>
                  <div className='w-100 d-flex justify-content-end'>
                    <LanguageSwitcher />
                  </div>
                </>
              )}

              {/* {(value as any)?.user && (
              <LinkContainer
                to={generatePath('/nsfw-rules') as string}
                exact
                className="mb-2"
                style={itemStyle}
              >
                <Dropdown.Item>
                  <NsfwRulesSvg width="22px" height="22px" />
                  {' '}
                  {t('NSFW rules') as string}
                </Dropdown.Item>
              </LinkContainer>
              )} */}
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar>

      {children}
    </>
  );
};

export default Layout;
