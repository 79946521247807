import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router';
import usePromise from 'react-promise';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { authenticatedEmployee, feathersAuthenticateEmployee } from '../../../apis/social';
import LanguageSwitcher from '../../LanguageSwitcher';

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [disableBtn, setDisableBtn] = useState(!localStorage.getItem('terms_of_service_emp'));
  const [alert, setAlert] = useState<undefined | string>(undefined);
  const [passwordShown, setPasswordShown] = useState(false);

  const history = useHistory();

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const auth = usePromise(authenticatedEmployee());

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  const redirectToDashboard = () => {
    if ((auth?.value as any)?.user && (auth?.value as any)?.user?.role === 'EMPLOYEE') {
      const employeeId = (auth?.value as any)?.user?.employee?.id;
      navigateTo(`/employee/${employeeId}/dashboard`);
    }
  };

  useEffect(() => {
    redirectToDashboard();
  }, [(auth?.value as any)?.user]);

  const showAlert = (msg: string) => {
    setAlert(msg);
    setTimeout(() => {
      setAlert('');
    }, 3000);
  };

  return (
    <div
      style={{
        backgroundColor: '#f2fcff',
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: 100,
      }}
    >
      <Alert className='text-center' variant='danger' hidden={!alert}>
        {alert}
      </Alert>
      <Container style={{ paddingTop: '80px' }}>
        <h4 className='text-center'>{t('WELCOME') as string}</h4>
        <h5 className='text-center'>{t('EMPLOYEE_LOGIN') as string} </h5>

        <br />
        <Form.Group className='mt-5 pt-3' controlId='formBasicEmail'>
          <Form.Label>{t('EMAIL_ADDRESS') as string}</Form.Label> <br />
          <input
            className='w-100'
            onChange={(e) => {
              setEmail(e.currentTarget.value.toLowerCase());
            }}
            type='email'
            placeholder=''
          />
          <br />
          <br />
          <Form.Label>{t('PASSWORD') as string}</Form.Label> <br />
          <input
            className='w-100'
            id='passwordInput'
            onChange={(e) => {
              setPwd(e.currentTarget.value);
            }}
            type={passwordShown ? 'text' : 'password'}
            placeholder=''
          />
          <input className='mt-3 mr-2' type='checkbox' onClick={togglePassword} />
          <span>{t('SHOW_PASSWORD') as string}</span>
        </Form.Group>
        <Form.Group
          hidden={localStorage.getItem('terms_of_service_emp') === 'true'}
          controlId='formBasicCheckbox'
        >
          <Form.Check
            type='checkbox'
            label={t('ACCEPT_TERMS_PRIVACY') as string}
            onChange={(e) => {
              setDisableBtn(!e.currentTarget.checked);
            }}
          />
        </Form.Group>

        <LinkContainer to='/reset_email' className='pl-0'>
          <Button variant='link'>{t('FORGOT_PASSWORD') as string}</Button>
        </LinkContainer>
        <div style={{ height: '100px' }} />
        <Button
          disabled={disableBtn || !email || !pwd}
          type='submit'
          className='py-2 w-100 mt-5'
          onClick={async () => {
            try {
              await feathersAuthenticateEmployee(email, pwd);
              localStorage.setItem('terms_of_service_emp', 'true');
              redirectToDashboard();
            } catch (error) {
              showAlert(t('LOGIN_ALERT'));
            }
          }}
        >
          {t('SIGN_IN') as string}
        </Button>
      </Container>
      <div
        style={{
          bottom: '100px',
          position: 'absolute',
          right: '10px',
        }}
      >
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Login;
