import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router';
import usePromise from 'react-promise';
import { useTranslation } from 'react-i18next';
import social, { authenticatedEmployee } from '../../../apis/social';

export const RecoverPwdEmail: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');

  const history = useHistory();

  const auth = usePromise(authenticatedEmployee());

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  const requestEmailPwdReset = async (resEmail: string) => {
    await (social.service('user') as any).create({
      email: resEmail,
      shouldSendResetEmail: true,
    });
  };

  useEffect(() => {
    if ((auth?.value as any)?.user && (auth?.value as any)?.user?.role === 'EMPLOYEE') {
      navigateTo('/employee/dashboard');
    }
  }, [(auth?.value as any)?.user]);

  return (
    <div
      style={{
        backgroundColor: '#f2fcff',
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: 100,
      }}
    >
      <Container style={{ paddingTop: '80px' }}>
        <h4 className='text-center'>{t('FORGOT_PASSWORD') as string}</h4>

        <p className='text-center mb-3'>{t('ENTER_EMAIL') as string}</p>

        <br />
        <Form.Group className='mt-5 pt-3' controlId='formBasicEmail'>
          <Form.Label>{t('EMAIL') as string}</Form.Label> <br />
          <input
            className='w-100'
            onChange={(e) => {
              setEmail(e.currentTarget.value.toLowerCase());
            }}
            type='email'
            placeholder=''
          />
        </Form.Group>

        <div style={{ height: '100px' }} />
        <Button
          type='submit'
          className='py-2 w-100 mt-5'
          onClick={async () => {
            try {
              localStorage.clear();
              await requestEmailPwdReset(email?.toLowerCase());
              navigateTo('/reset_check_email');
            } catch (error) {
              alert('Request invalid, please refresh the page');
            }
          }}
        >
          {t('SEND_EMAIL') as string}
        </Button>
      </Container>
    </div>
  );
};

export default RecoverPwdEmail;
