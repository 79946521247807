import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import { EmploymentAttributes } from '../../../apis/attributes';
import social from '../../../apis/social';
import EmploymentCard from '../../EmployeeCreation/container/EmploymentCard';
import YearlyLogic from '../../EmployeeCreation/container/YearlyLogic';

type EmployemntListAttributes = {
  data: EmploymentAttributes[];
};

export const daysStrings = (t: any) => [
  t('Sunday'.toUpperCase()),
  t('Monday'.toUpperCase()),
  t('Tuesday'.toUpperCase()),
  t('Wednesday'.toUpperCase()),
  t('Thursday'.toUpperCase()),
  t('Friday'.toUpperCase()),
  t('Saturday'.toUpperCase()),
];

export const ReadEmploymentContract: React.FC<{ employmentId: string }> = ({ employmentId }) => {
  const { t } = useTranslation();

  const [employment] = useObservable<EmploymentAttributes | null>(
    () => social.service('employment').watch().get(employmentId) as any,
    [employmentId],
  );

  const [employments] = useObservable<EmployemntListAttributes | null>(
    () =>
      social
        .service('employment')
        .watch()
        .find({
          query: {
            $sort: {
              createdAt: 1,
            },
            employeeId: employment?.employeeId,
            facilityId: (employment as any)?.facilityId,
            finalSubmission: true,
          },
        }) as any,
    [employment?.employeeId],
  );

  return (
    <Container>
      <h4 className='mt-2'>{t('EMPLOYMENT_CONTRACT') as string}</h4>
      <div style={{ height: '10px' }} />
      {employments?.data?.map((emp, idx) => (
        <Row key={emp?.id ?? idx} className='mb-4'>
          <Row className='mb-4'>
            <Col>
              <Button variant='light' disabled className='text-dark p-0 w-100'>
                <EmploymentCard employment={emp} />
              </Button>
            </Col>
          </Row>
        </Row>
      ))}
      <YearlyLogic employeeId={employment?.employeeId as any} shouldReadOnly />
    </Container>
  );
};

export default ReadEmploymentContract;
