export default `
<h3 id="vereinbarung-zur-auftragsverarbeitung-des-browserbasierten">Vereinbarung zur Auftragsverarbeitung des browserbasierten</h3>
<h3 id="arbeitszeit-">Arbeitszeit-</h3>
<h3 id="erfassungsprogramms">erfassungsprogramms</h3>
<h3 id="kidig-az-gem-art-28-dsgvo">KiDig-AZ gemäß Art. 28 DSGVO</h3>
<p>Zwischen dem Lizenzgeber</p>
<ol>
<li>Firma ictt system-consulting,
Geschäftsführer Karl Cziumplik,
Eschholzstraße 94,
79115 Freiburg</li>
</ol>
<p>im Folgenden <strong>Auftragnehmer</strong> genannt, und dem Lizenznehmer,
dem Käufer der Lizenz, im Folgenden <strong>Auftraggeber</strong> genannt,</p>
<h3 id="1-pr-ambel">1. Präambel</h3>
<p>Diese Vereinbarung zur Auftragsverarbeitung des browserbasierten Arbeitszeiterfassungsprogramms 
KiDig-AZ gemäß § 28 DSGVO stellt die Verpflichtungen der Vertragsparteien zum Datenschutz dar, 
die sich aus der im bestehenden Nutzungsvertrag in ihren Einzelheiten beschriebenen Auftragsverarbeitung ergeben. 
Sie findet Anwendung und ist bezogen auf alle Tätigkeiten, die mit diesem Nutzungsvertrag in Zusammenhang 
stehen und bei denen Beschäftigte des Auftragnehmers oder durch den Auftragnehmer Beauftragte 
personenbezogene Daten (im weiteren Verlauf als <strong>Daten</strong> bezeichnet) des Auftraggebers verarbeiten.</p>
<h3 id="2-gegenstand-und-dauer-des-auftrags">2. Gegenstand und Dauer des Auftrags</h3>
<p><strong>2.1</strong> Der Gegenstand des Auftrags sowie Art und Zweck ergibt sich aus dem bestehenden Nutzungsvertrag.</p>
<p><strong>2.2</strong> Die Dauer dieses Auftrags entspricht der Laufzeit des Nutzungsvertrags.</p>
<p><strong>2.3</strong> Das Recht auf außerordentliche Kündigung bei einem schweren Verstoß gegen datenschutzrechtliche Vorschriften:
Der Auftraggeber kann den Vertrag jederzeit ohne Einhaltung einer Frist kündigen, 
wenn ein schwerwiegender Verstoß des Auftragnehmers gegen Datenschutzvorschriften oder die Bestimmungen dieses Vertrages 
vorliegt, der Auftragnehmer eine Weisung des Auftraggebers nicht ausführen kann oder will oder der Auftragnehmer 
Kontrollrechte des Auftraggebers vertragswidrig verweigert. Insbesondere die Nichteinhaltung der in diesem Vertrag 
vereinbarten und aus Art. 28 DSGVO abgeleiteten Pflichten stellt einen schweren Verstoß dar.</p>
<h3 id="3-differenzierte-darstellung-des-auftragsinhalts">3. Differenzierte Darstellung des Auftragsinhalts</h3>
<p><strong>3.1</strong> Art und Zweck der Verarbeitung personenbezogener Daten durch den Auftragnehmer für den Auftraggeber 
sind konkret beschrieben im bestehenden Nutzungsvertrag.
Die Erbringung der vertraglich vereinbarten Datenverarbeitung findet ausschließlich in einem Mitgliedsstaat 
der Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum statt.
Jede Verlagerung der Dienstleistung oder von Teilarbeiten dazu in ein Drittland bedarf der 
vorherigen Zustimmung des Auftraggebers und darf nur erfolgen, wenn die besonderen Voraussetzungen der 
Art. 44 ff. DSGVO erfüllt sind (z. B. Angemessenheitsbeschluss der Kommission, Standarddatenschutzklauseln, 
genehmigte Verhaltensregeln).</p>
<p><strong>3.2</strong> Die Art der verwendeten personenbezogenen Daten ist in der Anlage 1 (Auflistung der personenbezogenen Daten 
und Zweck ihrer Verarbeitung) beschrieben.</p>
<p><strong>3.3</strong> Die Kategorien der durch die Verarbeitung betroffenen Personen sind in der Anlage 1 (Auflistung der 
personenbezogenen Daten und Zweck ihrer Verarbeitung) beschrieben.</p>
<h3 id="4-anwendungsbereich-und-verantwortlichkeit">4. Anwendungsbereich und Verantwortlichkeit</h3>
<p><strong>4.1</strong> Der Auftragnehmer verarbeitet personenbezogene Daten im Auftrag des Auftraggebers. 
Dies umfasst Tätigkeiten, die im Nutzungsvertrag konkretisiert sind. 
Der Auftragnehmer ist Verantwortlicher i. S. d. Art. 4 Nr. 7 
DSGVO für die Verarbeitung von Daten im Auftrag durch den Auftraggeber.</p>
<p><strong>4.2</strong> Die Weisungen werden anfänglich durch den Nutzungsvertrag festgelegt und können vom Auftraggeber 
danach in schriftlicher Form oder in einem elektronischen Format (Textform) an die vom Auftragnehmer 
bezeichnete Stelle durch einzelne Weisungen geändert, ergänzt oder ersetzt werden (Einzelweisung). 
Weisungen, die im Nutzungsvertrag nicht vorgesehen sind, werden als Antrag auf Leistungsänderung behandelt. 
Mündliche Weisungen sind unverzüglich schriftlich oder in Textform zu bestätigen. </p>
<h2 id="5-technisch-organisatorische-ma-nahmen">5. Technisch-organisatorische Maßnahmen</h2>
<p><strong>5.1</strong> Der Auftragnehmer hat die Umsetzung der im Vorfeld der Auftragsvergabe dargelegten und erforderlichen 
technischen und organisatorischen Maßnahmen vor Beginn der Verarbeitung, insbesondere hinsichtlich der konkreten 
Auftragsdurchführung zu dokumentieren und dem Auftraggeber zur Prüfung zu übergeben. Bei Akzeptanz durch den 
Auftraggeber werden die dokumentierten Maßnahmen Grundlage des Auftrags. Soweit die Prüfung eines Audits des 
Auftraggebers einen Anpassungsbedarf ergibt, ist dieser einvernehmlich umzusetzen.</p>
<p><strong>5.2</strong> Der Auftragnehmer hat die Sicherheit gem. Art. 28 Abs. 3 lit. c, 32 DSGVO insbesondere in Verbindung 
mit Art. 5 Abs. 1, Abs. 2 DSGVO herzustellen. Insgesamt handelt es sich bei den zu treffenden Maßnahmen 
um Maßnahmen der Datensicherheit und zur Gewährleistung eines dem Risiko angemessenen Schutzniveaus 
hinsichtlich der Vertraulichkeit, der Integrität, der Verfügbarkeit sowie der Belastbarkeit der Systeme. 
Dabei sind der Stand der Technik, die Implementierungskosten und die Art, der Umfang und die Zwecke der 
Verarbeitung sowie die unterschiedliche Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte 
und Freiheiten natürlicher Personen im Sinne von Art. 32 Abs. 1 DSGVO zu berücksichtigen.</p>
<p><strong>5.3</strong> Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der 
Weiterentwicklung. Insoweit ist es dem Auftragnehmer gestattet, alternative adäquate Maßnahmen umzusetzen. 
Dabei darf das Sicherheitsniveau der festgelegten Maßnahmen nicht unterschritten werden. Wesentliche 
Änderungen sind zu dokumentieren.</p>
<h3 id="6-berichtigung-einschr-nkung-und-l-schung-von-daten">6. Berichtigung, Einschränkung und Löschung von Daten</h3>
<p><strong>6.1</strong> Der Auftragnehmer darf die Daten, die im Auftrag verarbeitet werden, nicht eigenmächtig, sondern nur 
nach dokumentierter Weisung des Auftraggebers berichtigen, löschen oder deren Verarbeitung einschränken. 
Soweit eine betroffene Person sich diesbezüglich unmittelbar an den Auftragnehmer wendet, wird der Auftragnehmer
dieses Ersuchen unverzüglich an den Auftraggeber weiterleiten.</p>
<p><strong>6.2</strong> Soweit vom Leistungsumfang umfasst, sind Löschkonzept, Recht auf Vergessenwerden, Berichtigung, 
Datenportabilität und Auskunft nach dokumentierter Weisung des Auftraggebers unmittelbar durch den Auftragnehmer 
sicherzustellen.</p>
<h3 id="7-unterauftragsverh-ltnisse">7. Unterauftragsverhältnisse</h3>
<p><strong>7.1</strong> Als Unterauftragsverhältnisse im Sinne dieser Regelung sind solche Dienstleistungen zu verstehen, 
die sich unmittelbar auf die Erbringung der Hauptleistung beziehen. Nicht hierzu gehören Nebenleistungen, 
die der Auftragnehmer z.B. als Telekommunikationsleistungen oder Post-/ Transportdienstleistungen in 
Anspruch nimmt. Der Auftragnehmer ist jedoch verpflichtet, zur Gewährleistung des Datenschutzes und der 
Datensicherheit der Daten des Auftraggebers auch bei ausgelagerten Nebenleistungen angemessene und 
gesetzeskonforme vertragliche Vereinbarungen sowie Kontrollmaßnahmen zu ergreifen.</p>
<p><strong>7.2</strong> Der Auftragnehmer darf Unterauftragnehmer (weitere Auftragsverarbeiter) nur nach vorheriger ausdrücklicher 
schriftlicher bzw. dokumentierter Zustimmung des Auftraggebers beauftragen.
Der Auftraggeber stimmt der Beauftragung der nachfolgenden Unterauftragnehmer zu unter der 
Bedingung einer vertraglichen Vereinbarung nach Maßgabe des Art. 28 Abs. 2-4 DSGVO:</p>
<p><strong>Server Hosting</strong>: AWS Germany - Amazon-web-services in Deutschland –
Region EU-central-1 (Frankfurt)</p>
<p><strong>Support-e-mail</strong>: sales@ictt-consulting.de</p>
<p>Die Auslagerung auf Unterauftragnehmer oder der Wechsel des bestehenden Unterauftragnehmers sind zulässig, soweit:</p>
<ul>
<li>der Auftragnehmer eine solche Auslagerung auf Unterauftragnehmer dem Auftraggeber eine angemessene Zeit vorab 
schriftlich oder in Textform anzeigt und</li>
<li>der Auftraggeber nicht bis zum Zeitpunkt der Übergabe der Daten gegenüber dem Auftragnehmer schriftlich oder 
in Textform Einspruch gegen die geplante Auslagerung erhebt und</li>
<li>dem Unterauftragnehmer dieselben Datenschutzpflichten auferlegt werden, die in diesem Vertrag festgelegt sind.</li>
</ul>
<p><strong>7.3</strong> Die Weitergabe von personenbezogenen Daten des Auftraggebers an den Unterauftragnehmer und dessen 
erstmaliges Tätigwerden sind erst mit Vorliegen aller Voraussetzungen für eine Unterbeauftragung gestattet.</p>
<p><strong>7.4</strong> Erbringt der Unterauftragnehmer die vereinbarte Leistung außerhalb der EU/des EWR stellt der 
Auftragnehmer die datenschutzrechtliche Zulässigkeit durch entsprechende Maßnahmen sicher. 
Gleiches gilt, wenn Dienstleister im Sinne von Abs. 1 Satz 2 eingesetzt werden sollen.</p>
<p><strong>7.5</strong> Sämtliche vertraglichen Regelungen in der Vertragskette sind auch dem weiteren 
Unterauftragnehmer aufzuerlegen. Der Auftragnehmer vereinbart hiermit mit dem Auftraggeber, 
dass alle allgemeinen Zugriffsanträge einer US-Behörde angefochten werden.</p>
<p><strong>7.6</strong> Das Nutzerverhalten wird generell anonymisiert zur Verbesserung des Produktes festgehalten.</p>
<h3 id="8-pflichten-des-auftragnehmers">8. Pflichten des Auftragnehmers</h3>
<p><strong>8.1</strong> Der Auftragnehmer darf Daten von betroffenen Personen nur im Rahmen des Auftrages und der Weisungen 
des Auftraggebers verarbeiten, außer es liegt ein Ausnahmefall im Sinne des Artikel 28 Abs. 3 a) DSGVO vor. 
In einem solchen Fall teilt der Auftragnehmer dem Verantwortlichen diese rechtlichen Anforderungen vor 
der Verarbeitung mit, sofern das betreffende Recht eine solche Mitteilung nicht wegen eines wichtigen 
öffentlichen Interesses verbietet (Art. 28 Abs. 3 Satz 2 lit. a DSGVO). Der Auftragnehmer informiert den 
Auftraggeber unverzüglich, wenn er der Auffassung ist, dass eine Weisung gegen anwendbare Gesetze verstößt. 
Der Auftragnehmer darf die Umsetzung der Weisung so lange aussetzen, bis sie vom Auftraggeber 
bestätigt oder abgeändert wurde.
Der Auftragnehmer unterstützt den Auftraggeber unter Berücksichtigung der Art der Verarbeitung und der ihm 
zur Verfügung stehenden Informationen bei der Einhaltung der in den Artikeln 32 bis 36 DSGVO genannten Pflichten 
(Gewährleistung der Sicherheit der Verarbeitung; Meldung von Verletzungen des Schutzes personenbezogener 
Daten an die Aufsichtsbehörden; Benachrichtigung der von einer Verletzung des Schutzes personenbezogener 
Daten betroffenen Person; Datenschutzfolgenabschätzung; Vorherige Konsultation).</p>
<p><strong>8.2</strong> Der Auftragnehmer hat zusätzlich zu der Einhaltung der Regelungen dieses Auftrags gesetzliche 
Pflichten gemäß Art. 28 bis 33 DSGVO; insofern gewährleistet er insbesondere die Einhaltung folgender Vorgaben:</p>
<p><strong>8.2.1</strong> Der Auftragnehmer ist nicht zur Bestellung eines Datenschutzbeauftragten verpflichtet. 
Als Ansprechpartner beim Auftragnehmer wird</p>
<p>Herr Karl Cziumplik, Geschäftsführer,
Eschholzstraße 94,
79115 Freiburg</p>
<p><strong>Tel</strong>: +49 (761) 89 75 91 20</p>
<p><strong>FAX</strong>: +49 (761) 89 75 91 21</p>
<p><strong>E-Mail</strong>:    sales@ictt-consulting.de</p>
<p>benannt.</p>
<p><strong>8.2.2</strong> Die Wahrung der Vertraulichkeit gemäß Art. 28 Abs. 3 S. 2 lit. b, 29, 32 Abs. 4 DSGVO. Der 
Auftragnehmer setzt bei der Durchführung der Arbeiten nur Beschäftigte ein, die auf die Vertraulichkeit 
verpflichtet und zuvor mit den für sie relevanten Bestimmungen zum Datenschutz vertraut gemacht wurden. 
Der Auftragnehmer und jede dem Auftragnehmer unterstellte Person, die Zugang zu personenbezogenen Daten hat, 
dürfen diese Daten ausschließlich entsprechend der Weisung des Auftraggebers verarbeiten einschließlich der 
in diesem Vertrag eingeräumten Befugnisse, es sei denn, dass sie gesetzlich zur Verarbeitung verpflichtet sind. 
Die Vertraulichkeit besteht auch nach Beendigung des Nutzungsvertrages fort.</p>
<p><strong>8.2.3</strong> Die Umsetzung und Einhaltung aller für diesen Auftrag erforderlichen technischen und organisatorischen 
Maßnahmen gemäß Art. 28 Abs. 3 S. 2 lit. c, 32 DSGVO <a href="">Einzelheiten in Anlage 1</a>.</p>
<p><strong>8.2.4</strong> Unterwerfungsklausel nach dem DSG-EKD: Der nicht-kirchliche Auftragsverarbeiter („Auftragnehmer“) 
unterstellt sich gemäß § 30 Abs.5 S.3 DSG-EKD der Kontrolle der zuständigen kirchlichen Datenschutzaufsichtsbehörde. 
Diese Behörde nimmt insbesondere die Aufgaben nach § 43 DSG-EKD sowie die Befugnisse nach § 44 DSG-EKD 
unmittelbar gegenüber dem nicht-kirchlichen Auftragsverarbeiter wahr.</p>
<p><strong>8.2.5</strong> Der Auftraggeber und der Auftragnehmer arbeiten auf Anfrage mit der Aufsichtsbehörde bei der Erfüllung 
ihrer Aufgaben zusammen.</p>
<p><strong>8.2.6</strong> Die unverzügliche Information des Auftraggebers über Kontrollhandlungen und Maßnahmen der 
Aufsichtsbehörde, soweit sie sich auf diesen Auftrag beziehen. Dies gilt auch, soweit eine zuständige 
Behörde im Rahmen eines Ordnungswidrigkeit- oder Strafverfahrens in Bezug auf die Verarbeitung personenbezogener
Daten bei der Auftragsverarbeitung beim Auftragnehmer ermittelt.</p>
<p><strong>8.2.7</strong> Soweit der Auftraggeber seinerseits einer Kontrolle der Aufsichtsbehörde, einem Ordnungswidrigkeits- 
oder Strafverfahren, dem Haftungsanspruch einer betroffenen Person oder eines Dritten oder einem anderen Anspruch
im Zusammenhang mit der Auftragsverarbeitung beim Auftragnehmer ausgesetzt ist, hat ihn der Auftragnehmer nach 
besten Kräften zu unterstützen.</p>
<p><strong>8.2.8</strong> Der Auftragnehmer kontrolliert regelmäßig die internen Prozesse sowie die technischen und 
organisatorischen Maßnahmen, um zu gewährleisten, dass die Verarbeitung in seinem Verantwortungsbereich 
im Einklang mit den Anforderungen des geltenden Datenschutzrechts erfolgt und der Schutz der Rechte der 
betroffenen Person gewährleistet wird.</p>
<p><strong>8.2.9</strong> Nachweisbarkeit der getroffenen technischen und organisatorischen Maßnahmen gegenüber dem 
Auftraggeber im Rahmen seiner Kontrollbefugnisse nach Ziffer 9 dieses Vertrages.</p>
<p><strong>8.2.10</strong> Der Auftragnehmer bestätigt, dass ihm die für die Auftragsverarbeitung einschlägigen 
datenschutzrechtlichen Vorschriften der DSGVO bekannt sind. Er verpflichtet sich, auch folgende, für 
diesen Auftrag relevante Geheimnisschutzregel zu beachten, die dem Auftraggeber obliegt: 
§ 203 StGB (Verletzung von Privatgeheimnissen).</p>
<p><strong>8.2.11</strong> Der Auftragnehmer teilt dem Auftraggeber unverzüglich Störungen, Verstöße des Auftragnehmers oder 
der bei ihm beschäftigten Personen sowie gegen datenschutzrechtliche Bestimmungen oder die im Auftrag 
getroffenen Festlegungen sowie den Verdacht auf Datenschutzverletzungen oder Unregelmäßigkeiten bei der 
Verarbeitung personenbezogener Daten mit. Dies gilt vor allem auch im Hinblick auf eventuelle Melde- und 
Benachrichtigungspflichten des Auftraggebers nach Art. 33 und Art. 34 DSGVO. Der Auftragnehmer sichert zu, 
den Auftraggeber erforderlichenfalls bei seinen Pflichten nach Art. 33 und 34 DSGVO angemessen 
zu unterstützen (Art. 28 Abs. 3 Satz 2 lit. f DSGVO). Meldungen nach Art. 33 oder 34 DSGVO für den Auftraggeber 
darf der Auftragnehmer nur nach vorheriger Weisung des Auftraggebers durchführen.</p>
<p><strong>8.2.12</strong> Der Auftragnehmer hat den Auftraggeber bei einer Datenpanne zu benachrichtigen. 
Die Meldung einer Datenschutzverletzung erfolgt vom Auftragnehmer beim Auftraggeber per E-Mail und wenn geboten, 
zusätzlich per zusätzlichen Anruf, oder per Fax oder, wenn diese Kommunikationsmittel nicht zugänglich wären, 
per Einschreiben. So soll sichergestellt werden, dass die Nachricht den Verantwortlichen auch wirklich erreicht.<br>So ist gewährleistet, dass der Auftragsverarbeiter eine geeignete 
Benachrichtigung des Verantwortlichen auch dokumentieren kann.</p>
<p><strong>8.2.13</strong> Der Auftragnehmer weist den Auftraggeber darauf hin, dass die Aufbewahrungsfrist für die Dokumentation 
von technischen und organisatorischen Maßnahmen sowie von Kontroll- und Prüfunterlagen 3 Jahre beträgt.</p>
<h3 id="9-pflichten-des-auftraggebers">9. Pflichten des Auftraggebers</h3>
<p><strong>9.1</strong> Der Auftraggeber hat den Auftragnehmer unverzüglich und vollständig zu informieren, wenn er in den 
Auftragsergebnissen Fehler oder Unregelmäßigkeiten bzgl.. datenschutzrechtlicher Bestimmungen feststellt.</p>
<p><strong>9.2</strong> Im Falle einer Inanspruchnahme des Auftraggebers durch eine betroffene Person hinsichtlich etwaiger 
Ansprüche nach Art. 82 DSGVO, verpflichtet sich der Auftragnehmer den Auftraggeber bei der Abwehr des Anspruches 
im Rahmen seiner Möglichkeiten zu unterstützen.</p>
<p><strong>9.3</strong> Der Auftraggeber nennt dem Auftragnehmer den Ansprechpartner für im Rahmen des Vertrages anfallende 
Datenschutzfragen.</p>
<h3 id="10-kontrollrechte-des-auftraggebers">10. Kontrollrechte des Auftraggebers</h3>
<p><strong>10.1</strong> Der Auftraggeber hat das Recht, im Benehmen mit dem Auftragnehmer Überprüfungen durchzuführen oder durch 
im Einzelfall zu benennende Prüfer durchführen zu lassen. Er hat das Recht, sich durch Stichprobenkontrollen, 
die in der Regel rechtzeitig anzumelden sind, von der Einhaltung dieser Vereinbarung durch den Auftragnehmer 
in dessen Geschäftsbetrieb zu überzeugen.</p>
<p><strong>10.2</strong> Sollten Inspektionen durch den Auftraggeber oder einen von diesem beauftragten Prüfer stattfinden, 
werden diese zu den üblichen Geschäftszeiten ohne Störung des Betriebsablaufs nach Anmeldung unter Berücksichtigung 
einer angemessenen Vorlaufzeit durchgeführt. Der Auftragnehmer darf diese von der vorherigen Anmeldung mit 
angemessener Vorlaufzeit und von der Unterzeichnung einer Verschwiegenheitserklärung hinsichtlich der Daten 
anderer Kunden und der eingerichteten technischen und organisatorischen Maßnahmen abhängig machen. 
Sollte der durch den Auftraggeber beauftragte Prüfer in einem Wettbewerbsverhältnis zu dem Auftragnehmer 
stehen, hat der Auftragnehmer gegen diesen ein Einspruchsrecht.</p>
<p><strong>10.3</strong> Der Auftragnehmer stellt sicher, dass sich der Auftraggeber von der Einhaltung der Pflichten des 
Auftragnehmers nach Art. 28 DSGVO überzeugen kann. Der Auftragnehmer verpflichtet sich, dem Auftraggeber auf 
Anforderung die erforderlichen Auskünfte zu erteilen und insbesondere die Umsetzung der technischen und 
organisatorischen Maßnahmen nachzuweisen.</p>
<p><strong>10.4</strong> Der Nachweis solcher Maßnahmen, die nicht nur den konkreten Auftrag betreffen, kann erfolgen durch</p>
<ul>
<li>die Einhaltung genehmigter Verhaltensregeln gemäß Art. 40 DSGVO;</li>
<li>die Zertifizierung nach einem genehmigten Zertifizierungsverfahren gemäß Art. 42 DSGVO; aktuelle Testate, 
Berichte oder Berichtsauszüge unabhängiger Instanzen (z.B. Wirtschaftsprüfer, Revision, Datenschutzbeauftragter,
IT-Sicherheitsabteilung, Datenschutzauditoren, Qualitätsauditoren);</li>
<li>eine geeignete Zertifizierung durch IT-Sicherheit- oder Datenschutzaudit (z.B. nach BSI-IT- Grundschutz).</li>
</ul>
<h3 id="11-weisungsbefugnis-des-auftraggebers">11. Weisungsbefugnis des Auftraggebers</h3>
<p><strong>11.1</strong> Mündliche Weisungen bestätigt der Auftraggeber unverzüglich (mindestens Textform).</p>
<p><strong>11.2</strong> Der Auftragnehmer hat den Auftraggeber unverzüglich zu informieren, wenn er der Meinung ist, 
eine Weisung verstoße gegen Datenschutzvorschriften. Der Auftragnehmer ist berechtigt, die Durchführung 
der entsprechenden Weisung so lange auszusetzen, bis sie durch den Auftraggeber bestätigt oder geändert wird.</p>
<h3 id="12-anfragen-betroffener-personen">12. Anfragen betroffener Personen</h3>
<p><strong>12.1</strong> Wendet sich eine betroffene Person mit Forderungen zur Berichtigung, Löschung oder Auskunft an den 
Auftragnehmer, wird der Auftragnehmer die betroffene Person an den Auftraggeber verweisen, sofern eine Zuordnung 
an den Auftraggeber nach Angaben der betroffenen Person möglich ist. Der Auftragnehmer leitet den Antrag der 
betroffenen Person unverzüglich an den Auftraggeber weiter. Der Auftragnehmer unterstützt den Auftraggeber 
angesichts der Art der Verarbeitung nach Möglichkeit mit geeigneten technischen und organisatorischen Maßnahmen 
dabei, seiner Pflicht zur Beantwortung von Anträgen auf Wahrnehmung der in Kapitel III der DGSVO genannten 
Rechte der betroffenen Personen zu unterstützen.</p>
<h3 id="13-l-schung-und-r-ckgabe-von-personenbezogenen-daten">13. Löschung und Rückgabe von personenbezogenen Daten</h3>
<p><strong>13.1</strong> Kopien oder Duplikate der Daten werden ohne Wissen des Auftraggebers nicht erstellt. Hiervon ausgenommen 
sind Sicherheitskopien, soweit sie zur Gewährleistung einer ordnungsgemäßen Datenverarbeitung erforderlich sind, 
sowie Daten, die im Hinblick auf die Einhaltung gesetzlicher Aufbewahrungspflichten erforderlich sind.</p>
<p><strong>13.2</strong> Nach Abschluss der vertraglich vereinbarten Arbeiten oder früher nach Aufforderung durch den Auftraggeber – 
spätestens mit Beendigung der Leistungsvereinbarung – hat der Auftragnehmer sämtliche in seinen sowie an 
Subunternehmen Besitz gelangten Unterlagen, erstellte Verarbeitungs- und Nutzungsergebnisse sowie Datenbestände, 
die im Zusammenhang mit dem Auftragsverhältnis stehen, dem Auftraggeber nach dessen Wahl auszuhändigen und 
etwaige Kopien zu löschen oder datenschutzgerecht zu vernichten. Gleiches gilt für Test- und Ausschussmaterial. 
Das Protokoll der Löschung ist auf Anforderung vorzulegen.</p>
<p><strong>13.3</strong> Dokumentationen, die dem Nachweis der auftrags- und ordnungsgemäßen Datenverarbeitung dienen, sind durch 
den Auftragnehmer, entsprechend den jeweiligen Aufbewahrungsfristen über das Vertragsende hinaus, aufzubewahren. 
Er kann sie zu seiner Entlastung bei Vertragsende dem Auftraggeber übergeben.</p>
<h3 id="14-haftung-und-schadensersatz">14. Haftung und Schadensersatz</h3>
<p>Auftraggeber und Auftragnehmer haften für den Schaden, der durch eine nicht der DSGVO entsprechende Verarbeitung
verursacht wird, gemeinsam im Außenverhältnis gegenüber der jeweiligen betroffenen Person.
Der Auftragnehmer haftet ausschließlich für Schäden, die auf einer von ihm durchgeführten Verarbeitung beruhen, 
bei der</p>
<ul>
<li>er den aus der DSGVO resultierenden und speziell für Auftragsverarbeiter auferlegten Pflichten nicht nachgekommen 
ist oder</li>
<li>er unter Nichtbeachtung der rechtmäßig erteilten Anweisungen des Auftraggebers handelte oder</li>
<li>er gegen die rechtmäßig erteilten Anweisungen des Auftraggebers gehandelt hat.</li>
</ul>
<p>Soweit der Auftraggeber zum Schadensersatz gegenüber dem Betroffenen verpflichtet ist, bleibt ihm der Rückgriff 
auf den Auftragnehmer vorbehalten.
Im Innenverhältnis zwischen Auftraggeber und Auftragnehmer haftet der Auftragnehmer für den durch eine 
Verarbeitung verursachten Schaden jedoch nur, wenn er</p>
<ul>
<li>seinen ihm speziell durch die DSGVO auferlegten Pflichten nicht nachgekommen ist oder</li>
<li>unter Nichtbeachtung der rechtmäßig erteilten Anweisungen des Auftraggebers oder gegen diese Anweisungen
gehandelt hat.
Weitergehende Haftungsansprüche nach den allgemeinen Gesetzen bleiben unberührt. </li>
</ul>
<h3 id="15-informationspflichten-schriftformklausel-rechtswahl">15. Informationspflichten, Schriftformklausel, Rechtswahl</h3>
<p><strong>15.1</strong> Sollten die Daten des Auftraggebers beim Auftragnehmer durch Pfändung oder Beschlagnahme, durch 
ein Insolvenz- oder Vergleichsverfahren oder durch sonstige Ereignisse oder Maßnahmen Dritter gefährdet werden, 
so hat der Auftragnehmer den Auftraggeber unverzüglich darüber zu informieren. Der Auftragnehmer wird alle in 
diesem Zusammenhang Verantwortlichen unverzüglich darüber informieren, dass die Hoheit und das Eigentum an den 
Daten ausschließlich beim Auftraggeber als »Verantwortlicher« im Sinne der Datenschutz-Grundverordnung liegen.</p>
<p><strong>15.2</strong> Änderungen und Ergänzungen dieser Anlage und aller ihrer Bestandteile – einschließlich etwaiger 
Zusicherungen des Auftragnehmers – bedürfen einer schriftlichen Vereinbarung, die auch in einem elektronischen 
Format (Textform) erfolgen kann, und des ausdrücklichen Hinweises darauf, dass es sich um eine Änderung bzw. 
Ergänzung dieser Bedingungen handelt. Dies gilt auch für den Verzicht auf dieses Formerfordernis.</p>
<p><strong>15.3</strong> Bei etwaigen Widersprüchen gehen Regelungen dieser Anlage zum Datenschutz den Regelungen des 
Nutzungsvertrags vor. Sollten einzelne Teile dieser Anlage unwirksam sein, so berührt dies die Wirksamkeit 
der Anlage im Übrigen nicht.</p>
<p><strong>15.4</strong> Es gilt deutsches Recht.</p>
<h3 id="weisungsbefugnis">Weisungsbefugnis</h3>
<p><strong>Weisungsberechtigte Personen des Lizenznehmers sind</strong>:</p>
<p>Vorname, Nachname:</p>
<p>Funktion: Kitaleitung</p>
<p><strong>Kontaktdaten</strong>:</p>
<ul>
<li><p>Telefon:</p>
</li>
<li><p>Email:</p>
</li>
</ul>
<p>Vorname, Nachname:</p>
<p>Funktion: Geschäftsführer, Träger</p>
<p><strong>Kontaktdaten</strong>:</p>
<ul>
<li><p>Telefon:</p>
</li>
<li><p>Email:</p>
</li>
</ul>
<p>Weisungsempfänger des Lizenzgebers sind:</p>
<p>Herausgeber:    ictt system-consulting</p>
<p>Funktion:        Geschäftsführer</p>
<p>Name:            Karl Cziumplik</p>
<p>Postanschrift:    ictt system-consulting</p>
<p>Eschholz- Str. 94</p>
<p>79115 Freiburg</p>
<p>Tel.: +49 (761) 89759120</p>
<p>FAX : +49 (761) 89759121</p>
<p>E-Mail: sales@ictt-consulting.de</p>
<p>...............................................................
Ort, Datum</p>
<p>...............................................................</p>
<p>Auftraggeber, Unterschrift</p>
<p>...............................................................</p>
<p>Karl Cziumplik, Geschäftsführer
ictt system-consulting</p>
<h3 id="anlage-1-zum-auftrag-gem-art-28-dsgvo-">Anlage 1 zum Auftrag gemäß Art. 28 DSGVO:</h3>
<p>Auflistung der personenbezogenen Daten und Zweck ihrer Verarbeitung</p>
<p>Art der Daten
Gegenstand der Zusatzvereinbarung sind folgende Datenarten und -kategorien:</p>
<h3 id="1-datenkategorien-von-az-web-app-arbeitszeiterfassungs-nutzenden-im-folgenden-az-web-app-nutzende-">1. Datenkategorien von AZ-Web-App- Arbeitszeiterfassungs-Nutzenden (im folgenden AZ-Web-App-Nutzende)</h3>
<h4 id="1-1-bestandsdaten-der-einrichtung">1.1 Bestandsdaten der Einrichtung</h4>
<p><strong>Name und Ort der Einrichtung</strong>: Um dem Träger der Einrichtung eine Zuordnung der Einrichtung (insbesondere, 
wenn ein Träger mehrere Einrichtungen betreibt (AZ-Web-App) zu ermöglichen.</p>
<p><strong>Anzahl der Gruppen der Einrichtung</strong>: Um die statistische Datenerhebung der Einrichtungen für den Lizenzgeber zu 
unterstützen.</p>
<p><strong>Art der Arbeitserfassung in jährlich festgelegtem Dienstplan und durchschnittlich 
tägliche Arbeitszeit gemäß TVÖD</strong>: Um die tariflich gewählten Arbeitszeiterfassungsparameter abzubilden und 
danach die Arbeitszeit zu berechnen.</p>
<p><strong>Tarifliche Einstellungen für alle Mitarbeitenden Einrichtung</strong>: </p>
<ul>
<li>Jahr, Land, Bundesland, Art der Arbeitserfassung</li>
<li>jährlich festgelegtem Dienstplan und</li>
<li>durchschnittlich tägliche Arbeitszeit gemäß TVÖD</li>
<li>Anzahl der zusätzlich festgelegten Feiertage</li>
<li>Anzahl der tariflichen Wochenarbeitstage</li>
<li>Stundenlimit monatlicher Plus- oder Minus-Stunden</li>
<li>Stundenlimit pro Tag gemäß § 3 ArbZG</li>
<li>Pausenabzüge (§§ 3, 4, 7 ArbZG</li>
<li>Prozentuale Anrechnung von Überstundenzuschläge gemäß § 8 TVÖD (ist in Planung)</li>
<li>Zeitzuschlagsbereich gemäß § 8 Abs (1) a Überstunden ab Voll- oder Teilzeitdeputat</li>
<li>Zeitzuschläge gemäß § 8 TVÖD:
   Montag bis Freitag
   Samstag
   Sonntag</li>
<li>Feiertag mit Freizeitausgleich</li>
<li>Feiertag ohne Freizeitausgleich</li>
<li>Dez. 24. fällt auf einen Werktag, Samstag oder Sonntag</li>
<li>Dez. 24. fällt auf einen Werktag, Samstag oder Sonntag</li>
</ul>
<p>Zur Sicherstellung der Mitarbeiter*innen und des Trägers für die Arbeitszeitberechnung mit dem Ergebnis einer 
validen, gemäß gesetzlicher und tariflicher Berechnungsparameter erfolgten Arbeitszeiterfassung.</p>
<h4 id="1-2-bestandsdaten-der-mitarbeiter-innen">1.2 Bestandsdaten der Mitarbeiter*innen</h4>
<p><strong>Vor- und Nachnahme der Mitarbeitenden als AZ-Web-App Nutzenden</strong>: Um der Einrichtungsleitung eine Zuordnung der 
Mitarbeitenden (AZ-Web-App) zu ermöglichen.</p>
<p><strong>Unterschrift (geschützt) des AZ-Web-App Nutzenden</strong>: Zur Unterschrift der AZ-Web App (für den Nachweis der 
erbrachten Arbeitszeit, oder Nachweis über Urlaubstage etc.</p>
<p><strong>Arbeitsvertrag der Mitarbeiter*in</strong>:</p>
<ul>
<li>Wochenarbeitsstunden </li>
<li>Wochenarbeitstage</li>
<li>wöchentliche regelmäßige Verfügungszeit </li>
<li>wöchentliche – auf das Jahr bezogene unregelmäßige Jahresverfügungszeit</li>
</ul>
<p>Um die erbrachte Arbeitszeit in Bezug zum bestehenden Arbeitsvertrag verifizieren und nachweisen zu können.</p>
<p><strong>Berechnete Arbeitszeit</strong>:</p>
<ul>
<li>SOLL Zeit und</li>
<li>IST Zeit</li>
</ul>
<p>Um die erbrachte Arbeitszeit in Bezug zum bestehenden Arbeitsvertrag verifizieren und nachweisen zu können.</p>
<p><strong>Anzurechnende Arbeitstage</strong>:</p>
<ul>
<li>Feiertage</li>
<li>Urlaube</li>
<li>Sonderurlaube</li>
<li>Fortbildungstage gemäß unterschiedlicher Fortbildungskategorien</li>
<li>Abwesenheit aufgrund von Schulbesuch</li>
<li>Krankheitstage</li>
<li>Krankheitstage wegen der Erkrankung eines leiblichen Kindes</li>
<li>Krankheitstage ohne ärztliche Bescheinigung</li>
<li>Fehltage</li>
<li>Dezember 24. und 31. - wenn anrechnungsfähig</li>
</ul>
<p>Um die erbrachte Arbeitszeit in Bezug zum bestehenden Arbeitsvertrag verifizieren und nachweisen zu können.</p>
<h4 id="1-3-kontaktdaten-der-einrichtung">1.3 Kontaktdaten der Einrichtung</h4>
<p><strong>E-Mail-Adresse und Passwort (geschützt) der Einrichtung</strong>:
zur Ermöglichung des Logins.</p>
<p><strong>Protokolldaten der AZ-web-App Nutzenden</strong>:
Zum Nachweis für die Tageseinrichtung, ob die Eintragungs-Daten überprüft worden sind.</p>
<h4 id="1-4-kontaktdaten-der-mitarbeiter-innen-als-az-web-app-nutzenden">1.4 Kontaktdaten der Mitarbeiter*innen als AZ-Web-App-Nutzenden</h4>
<p><strong>Emailadresse und Passwort (geschützt) der Mitarbeitenden -bzw. der AZ-Web-App Nutzenden</strong>:
Zur Ermöglichung des Logins</p>
<h4 id="1-5-datenkategorien-von-verantwortlichen-bei-den-tr-gern-einrichtungen-">1.5 Datenkategorien von Verantwortlichen bei den Trägern / Einrichtungen:</h4>
<p><strong>Adressdaten der Einrichtung (Straße, Hausnummer, PLZ, Ort)</strong>: Erscheint als Service für die AZ-web-App Nutzenden</p>
<p><strong>Vertragsdaten (Zeitpunkt, Inhalt, Zahlungsinformationen)</strong>: Zur Erfüllung des Vertrages über die Bereitstellung 
der AZ-wb-App und des Back-Ends</p>
<p><strong>Protokolldaten</strong>: Zum Nachweis, wann eine Nachricht an die AZ-web-App Nutzenden versand wurde</p>
<p><strong>Emailadresse und Passwort (geschützt) des Administrators / Editors</strong>: Zur Ermöglichung des Logins</p>
<h3 id="kreis-der-betroffenen">Kreis der Betroffenen</h3>
<p>Der Kreis der durch diese Zusatzvereinbarung Betroffenen umfasst:</p>
<ul>
<li>Mitarbeitende der Tageseinrichtung der Kinder und evtl. weitere Personen (AZ-web-App-NutzerInnen Kita und
evtl. weitere wie bspw. Hausmeister)</li>
<li>MitarbeiterInnen des Auftraggebers
Anlage 2 zum Auftrag gemäß Art. 28 DS-GVO:</li>
</ul>
<p>Technische und organisatorische Maßnahmen nach Art. 32 DSGVO und Anlage</p>
<h3 id="1-vertraulichkeit-art-32-abs-1-lit-b-dsgvo-">1.Vertraulichkeit (Art. 32 Abs. 1 lit. b DSGVO)</h3>
<h3 id="zutrittskontrolle">Zutrittskontrolle</h3>
<p>Verwaltung der Firma ictt system-consulting:
Verwaltung AWS Germany - Amazon-web-services in Deutschland –
Region EU-central-1 (Frankfurt)</p>
<ul>
<li>automatisches Zugangskontrollsystem mit biometrischer Zugangssperre (Fingerprint)</li>
<li>Bewegungsmelder im Eingangs- und Innenbereich</li>
<li>Sicherheitsschlösser</li>
<li>Sorgfältige Auswahl von Reinigungspersonal</li>
<li>der Zutritt für betriebsfremde Personen (z.B. BesucherInnen) zu den Räumen ist wie folgt beschränkt: nur in 
Begleitung eines Mitarbeiters der Firma ictt system-consulting</li>
</ul>
<h3 id="zugangskontrolle">Zugangskontrolle</h3>
<p>Verwaltung der Firma ictt system-consulting:</p>
<ul>
<li>Keine unbefugte Systembenutzung durch Zuordnung von Benutzerrechten und Erstellen von Benutzerprofilen für
die MitarbeiterInnen</li>
<li>Zugang ist passwortgeschützt, Zugriff besteht nur für berechtigte MitarbeiterInnen, verwendete Passwörter
müssen Mindestlänge haben und werden in regelmäßigen Abständen erneuert, automatische Sperrmechanismen</li>
<li>Einsatz von Anti-Viren-Software</li>
</ul>
<h3 id="zugriffskontrolle">Zugriffskontrolle</h3>
<p>bei internen Verwaltungssystemen der Firma ictt system-consulting:</p>
<ul>
<li>durch regelmäßige Sicherheitsupdates (nach dem jeweiligen Stand der Technik) stellt die Firma ictt 
system-consulting sicher, dass unberechtigte Zugriffe verhindert werden.</li>
<li>Revisionssicheres, verbindliches Berechtigungsvergabeverfahren für MitarbeiterInnen</li>
<li>Verwaltung der Rechte durch Systemadministrator</li>
<li>Anzahl der Administratoren auf das „Notwendigste“ reduziert</li>
<li>Passwortrichtlinie inkl. Passwortlänge, Passwortwechsel</li>
<li>Protokollierung von Zugriffen auf Anwendungen, insbesondere bei der Eingabe, Änderung und Löschung von Daten</li>
</ul>
<h3 id="bei-auftrag-az-web-app-">bei Auftrag „AZ-web-App:</h3>
<ul>
<li>durch regelmäßige Sicherheitsupdates (nach dem jeweiligen Stand der Technik) stellt die Firma ictt 
system-consulting sicher, dass unberechtigte Zugriffe verhindert werden.</li>
<li>Verwaltung der Rechte durch Systemadministrator des Auftraggebers</li>
<li>Passwortrichtlinie inkl. Passwortlänge</li>
<li>Protokollierung von Zugriffen auf die AZ-web-App, insbesondere bei der Eingabe, Änderung und Löschung von Daten</li>
</ul>
<h3 id="trennungskontrolle">Trennungskontrolle</h3>
<p>bei internen Verwaltungssystemen der Firma ictt system-consulting:</p>
<ul>
<li>Daten werden physisch oder logisch von anderen Daten getrennt gespeichert.</li>
<li>Logische Mandantentrennung (softwareseitig)</li>
<li>Versehen der Datensätze mit Zweckattributen / Datenfeldern</li>
<li>Bei pseudonymisierten Daten: Trennung Zuordnungsdatei und der Aufbewahrung auf einem getrennten, 
abgesicherten IT-System</li>
<li>Festlegung von Datenbankrechten</li>
<li>Trennung vom Produktiv- und Testsystem</li>
<li>Die Datensicherung erfolgt ebenfalls auf logisch und / oder physisch getrennten Systemen</li>
</ul>
<h3 id="bei-auftrag-az-web-app-">bei Auftrag „AZ-web-App“:</h3>
<ul>
<li>Daten werden physisch oder logisch von anderen Daten getrennt gespeichert.</li>
<li>Logische Mandantentrennung (softwareseitig)</li>
<li>Versehen der Datensätze mit Zweckattributen / Datenfeldern</li>
<li>Bei pseudonymisierten Daten: Trennung Zuordnungsdatei und der Aufbewahrung auf einem getrennten, 
abgesicherten IT-System</li>
<li>Festlegung von Datenbankrechten</li>
<li>Trennung vom Produktiv- und Testsystem</li>
<li>Die Datensicherung erfolgt ebenfalls auf logisch und / oder physisch getrennten Systemen</li>
</ul>
<h3 id="2-integrit-t-art-32-abs-1-lit-b-dsgvo-">2. Integrität (Art. 32 Abs. 1 lit. b DSGVO)</h3>
<h3 id="weitergabekontrolle">Weitergabekontrolle</h3>
<p>bei internen Verwaltungssystemen der Firma ictt system-consulting:</p>
<ul>
<li>alle MitarbeiterInnen sind i.S.d. Art. 32 Abs. 4 DSGVO unterwiesen und verpflichtet, den datenschutzkonformen 
Umgang mit personenbezogenen Daten sicherzustellen. - Datenschutzgerechte Löschung der Daten nach 
Vertragsbeendigung</li>
<li>E-Mail-Verschlüsselung</li>
<li>Erstellen einer Übersicht von regelmäßigen Abruf- und Übermittlungsvorgängen</li>
</ul>
<h3 id="bei-auftrag-az-web-app-">bei Auftrag AZ-Web-App:</h3>
<ul>
<li>Automatische Protokollierung von Zugriffen auf die App, insbesondere bei der Eingabe, Änderung und Löschung 
von Daten und beim Nachrichtenversand</li>
<li>Möglichkeiten zur verschlüsselten Datenübertragung werden im Umfang der Leistungsbeschreibung des
Auftrags zur Kita-Info-App / Schul-Info-App zur Verfügung gestellt.</li>
</ul>
<h3 id="eingabekontrolle">Eingabekontrolle</h3>
<p>bei internen Verwaltungssystemen der Firma ictt system-consulting:</p>
<ul>
<li>Die Daten werden von MitarbeiterInnen Firma ictt system-consulting selbst eingegeben, bzw. erfasst.</li>
<li>Die Änderungen der Daten werden protokolliert.</li>
<li>Nachvollziehbarkeit von Eingabe, Änderung und Löschung von Daten durch individuelle Benutzernamen 
(nicht Benutzergruppen)</li>
<li>Aufbewahrung von Formularen, von denen Daten in automatisierte Verarbeitungen übernommen worden sind</li>
<li>Vergabe von Rechten zur Eingabe, Änderung und Löschung von Daten</li>
</ul>
<h3 id="bei-auftrag-az-web-app-">bei Auftrag AZ-web App:</h3>
<ul>
<li>Die Daten werden vom Auftraggeber selbst eingegeben bzw. erfasst</li>
<li>Änderungen der Daten werden protokolliert</li>
</ul>
<h3 id="3-verf-gbarkeit-und-belastbarkeit-art-32-abs-1-lit-b-dsgvo-">3. Verfügbarkeit und Belastbarkeit (Art. 32 Abs. 1 lit. b DSGVO)</h3>
<p>Verfügbarkeitskontrolle
bei internen Verwaltungssystemen der Firma ictt system-consulting:</p>
<ul>
<li>Backup- und Recovery-Konzept mit täglicher Sicherung aller relevanten Daten</li>
<li>Sachkundiger Einsatz von Schutzprogrammen (Virenscanner, Firewalls, Verschlüsselungsprogramme, SPAM-Filter</li>
<li>Einsatz von Festplattenspiegelung bei allen relevanten Servern</li>
<li>Monitoring aller relevanten Server</li>
<li>Einsatz unterbrechungsfreier Stromversorgung, Netzersatzanlage</li>
<li>Dauerhaft aktiver DDoS-Schutz</li>
</ul>
<h3 id="bei-auftrag-az-web-app">bei Auftrag AZ-web App</h3>
<ul>
<li>Backup- und Recovery-Konzept mit täglicher Sicherung aller relevanten Daten</li>
<li>Sachkundiger Einsatz von Schutzprogrammen (Virenscanner, Firewalls, Verschlüsselungsprogramme, SPAM-Filter</li>
<li>Einsatz von Festplattenspiegelung bei allen relevanten Servern</li>
<li>Monitoring aller relevanten Server</li>
<li>Einsatz unterbrechungsfreier Stromversorgung, Netzersatzanlage</li>
<li>Dauerhaft aktiver DDoS-Schutz</li>
</ul>
<h3 id="zeitnahe-wiederherstellbarkeit">Zeitnahe Wiederherstellbarkeit</h3>
<p>Bei internen Verwaltungssystemen der Firma ictt system-consulting:
Für alle internen Systeme ist eine Eskalationskette definiert, die vorgibt, wer im Fehlerfall zu informieren ist, 
um das System schnellstmöglich wiederherzustellen.</p>
<h3 id="bei-auftrag-az-web-app-">bei Auftrag „AZ-web-App“</h3>
<p>gilt zunächst die interne Eskalationskette der Firma ictt system-consulting: anschließend die Eskalationskette 
der AWS Germany - Amazon-web-services in Deutschland – Region EU-central-1 (Frankfurt), die im Auftrag von 
Firma ictt system-consulting Datenkapazitäten auf einem Server bereitstellt.</p>
<h3 id="4-verfahren-zur-regelm-igen-berpr-fung-bewertung-und-evaluierung">4. Verfahren zur regelmäßigen Überprüfung, Bewertung und Evaluierung</h3>
<p>(Art. 32 Abs. 1 lit. d DSGVO; Art. 25 Abs. 1 DSGVO) bei internen Verwaltungssystemen der Firma ictt 
system-consulting:</p>
<ul>
<li>Datenschutz-Management ist vorhanden</li>
<li>Incident-Response-Management ist vorhanden</li>
<li>datenschutzfreundliche Voreinstellungen werden bei Software-Entwicklungen berücksichtigt (Art. 25 Abs. 2 DSGVO)</li>
</ul>
<h3 id="auftragskontrolle">Auftragskontrolle</h3>
<p>Unsere Mitarbeiter*innen werden in regelmäßigen Abständen im Datenschutzrecht unterwiesen und sie sind vertraut 
mit den Verfahrensanweisungen und Benutzerrichtlinien für die Datenverarbeitung im Auftrag, auch im Hinblick 
auf das Weisungsrecht des Auftraggebers. Der Nutzungsvertrag sowie die zugehörige Vereinbarung zur 
Auftragsverarbeitung gemäß Art. 28 DSGVO erhalten detaillierte Angaben über Art und Umfang der beauftragten 
Verarbeitung und Nutzung personenbezogener Daten des Auftragsgebers.</p>
`;
