import React, { useCallback } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { generatePath, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { useGlobalState } from '../../../App';
import { EmployeeAttributes, TariffAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import ExecuteModalBtn from '../../Payment/containers/ExecuteModalBtn';
import EyeIcon from '../images/eye.png';
import { EmployeeCard } from './EmployeeCard';
import RemoveEmployee from './RemoveEmployee';

type EmployeeListAttributes = {
  total: number;
  limit: number;
  skip: number;
  data: EmployeeAttributes[];
};

const compareEmployees = (x: EmployeeAttributes, y: EmployeeAttributes) => {
  if (x.lastName < y.lastName) {
    return -1;
  }
  return 1;
};

export const EmployeeList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [canWrite] = useGlobalState('canWrite');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const focusedEmployeeId = query.get('focusedEmployeeId');
  const { value } = usePromise(authenticatedFacility());

  const [employees] = useObservable<EmployeeListAttributes | null>(
    () =>
      social
        .service('employee')
        .watch()
        .find({
          query: {
            $limit: 100,
            facilityId: (value as any)?.user?.facility?.id as any,
            firstName: { $ne: null },
          },
        }) as any,
    [value],
  );
  const [tariff] = useObservable<TariffAttributes | null>(
    () =>
      (social.service('tariff').watch() as any).find({
        query: {
          $limit: 1,
          $sort: { createdAt: -1 },
          facilityId: (value as any)?.user?.facility?.id,
        },
      }) as any,
    [value],
  );

  const navigateTo = useCallback((pathName: string, employeeId: string) => {
    history.push({
      pathname: generatePath(`${pathName}/:employeeId`, {
        employeeId,
      }),
    });
  }, []);

  const navigateToEmployees = useCallback(
    async (pathName: string) => {
      // authorization
      const employeeObj: EmployeeAttributes = (await social.service('employee').create({
        facilityId: (value as any)?.user?.facility?.id as any,
      })) as any;
      history.push({
        pathname: generatePath(`${pathName}/:employeeId`, {
          employeeId: employeeObj?.id,
        }),
      });
    },
    [(value as any)?.user?.facility?.id as any],
  );

  return (
    <Container>
      {/* <Link
        to="/admin/employee/create-employee/18391166-e1a5-4736-8368-f1eaf2aa818c"
      >
        <p>create</p>
      </Link> */}

      <h4>
        <p className='mt-5 mb-1'>{t('OVERVIEW_EMPLOYEES') as string}</p>
      </h4>

      <p className='mt-3 mb-4'>
        <small>
          {employees?.data?.length} {t('EMPLOYEE_ACCOUNTS') as string}
        </small>
      </p>
      {employees?.data?.length ? (
        employees?.data
          .sort((e1, e2) => compareEmployees(e1, e2))
          .map((emp, idx) => (
            <>
              {focusedEmployeeId !== emp.id ? (
                <Row key={emp?.id ?? idx}>
                  <Col className='pr-0 pl-1'>
                    <Link
                      className='px-0 text-dark'
                      to={`/admin/employee/create-employee/${emp.id}`}
                    >
                      <EmployeeCard employee={emp} />
                    </Link>
                  </Col>

                  <Col
                    xs={2}
                    md={1}
                    className='d-flex align-items-center justify-content-center px-1'
                  >
                    <div className='w-100 h-100 d-flex flex-column justify-content-around align-items-center'>
                      <Button
                        className='px-2'
                        variant='link'
                        target='_blank'
                        // path='/employee/:employeeId/dashboard/:date?'

                        href={`${window.location.href.replace(
                          window.location.pathname,
                          '',
                        )}/employee/${emp.id}/dashboard`}
                      >
                        <img src={EyeIcon} alt='' style={{ height: '30px', width: '30px' }} />
                      </Button>
                      <div>
                        <RemoveEmployee employeeId={emp.id} userId={emp.userId} />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Card className='px-2 py-3'>
                  <Card.Title className='pl-3 mb-0 mt-3'>{`${emp.firstName} ${emp.lastName}`}</Card.Title>
                  <Card.Body className='pt-3'>
                    <Card.Subtitle className='pl-0'>
                      {tariff?.weeklyWorkingHoursFullyEmployed &&
                        `${tariff?.weeklyWorkingHoursFullyEmployed} 'working hours'`}
                    </Card.Subtitle>
                  </Card.Body>
                  <Card.Footer
                    className='  d-flex justify-content-between'
                    style={{
                      backgroundColor: 'white',
                      border: 'none',
                    }}
                  >
                    <Button
                      className='py-2 mr-1'
                      style={{ height: '40px' }}
                      block
                      variant='outline-primary'
                      onClick={() => navigateTo('/admin/employee/create-employee', emp.id)}
                    >
                      {t('EDIT_EMPLOYEE') as string}
                    </Button>
                    <Button
                      className='py-2 mt-0 ml-1'
                      style={{ height: '40px' }}
                      block
                      variant='primary'
                      onClick={() => navigateTo('/admin/employee/create-employee', emp.id)}
                    >
                      {t('SEE_DETAILS') as string}
                    </Button>
                  </Card.Footer>
                </Card>
              )}
              <br />
            </>
          ))
      ) : (
        <>
          <h3>{t('START_CREATE_EMPLOYEES') as string}</h3>
        </>
      )}

      {canWrite ? (
        <Button
          className='mt-3 '
          size='lg'
          variant='primary'
          onClick={() => navigateToEmployees('/admin/employee/create-employee')}
          block
        >
          {t('CREATE_EMPLOYEES') as string}
        </Button>
      ) : (
        <ExecuteModalBtn modalType='ADMIN'>
          <Button className='mt-3 ' size='lg' variant='primary' block>
            {t('CREATE_EMPLOYEES') as string}
          </Button>
        </ExecuteModalBtn>
      )}
    </Container>
  );
};

export default EmployeeList;
