import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import usePromise from 'react-promise';
import { generatePath, useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import { FacilityAttributes } from '../../../apis/attributes';
import { social, authenticatedFacility } from '../../../apis/social';

export const Submit: React.FC = observer(() => {
  const { t } = useTranslation();
  const [enableSubmit] = useState(true);

  const history = useHistory();

  const { value } = usePromise(authenticatedFacility());
  const [facility] = useObservable<FacilityAttributes | null>(
    () =>
      social
        .service('facility')
        .watch()
        .get((value as any)?.user?.facility?.id as any) as any,
    [value],
  );

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  return (
    <Container className='pt-2 px-4 pb-4'>
      <h4>{t('FACILITY_CREATED') as string}</h4>

      <br />
      <p className='mt-3'>{t('CAN_CHANGE_FACILITY_DATA') as string}</p>

      <p className='mt-5'>
        <strong>{t('SETUP_DATA_OVERVIEW') as string}</strong>
      </p>
      <br />
      <p>
        {facility?.name}
        <br />
        {facility?.town} {', '}
        {facility?.zipCode}
        <br />
        {facility?.federalState} {', '}
        {facility?.country}
        <br />
      </p>

      <p className='mt-3' />

      <div style={{ height: '170px' }} />

      <Button
        className='py-2 px-3 w-100'
        variant='primary'
        disabled={!enableSubmit}
        onClick={() => navigateTo('/admin/employees-overview')}
      >
        {t('SAVE') as string}
      </Button>
    </Container>
  );
});

export default Submit;
