import { jsPDF } from 'jspdf';
import { ITranslationLabel } from '../model/TranslationLabel';
import { IReportFooter } from '../model/ReportFooter';

export default function generateFooterReport(
  doc: jsPDF,
  pageHeight: number,
  pageWidth: number,
  footerData: IReportFooter,
  translateObj: ITranslationLabel,
) {
  const baseStartHeight = 140;
  doc.setFillColor(208, 255, 241);
  doc.rect(0, pageHeight - baseStartHeight, pageWidth, 110, 'F');

  // COL 1 TODO:
  // footer: Payed out hours (:)

  doc.setTextColor(50, 50, 69);
  doc.setFontSize(11);
  doc.setFont('Nunito-Bold');
  doc.text(`${translateObj.REPORT_PAYED_OUT_HOURS}`, 10, pageHeight - baseStartHeight + 20, {
    align: 'left',
  });

  doc.setFillColor(5, 206, 145);
  doc.rect(
    120 - doc.getTextWidth(footerData.payedOutHours) - 8,
    pageHeight - baseStartHeight + 20 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.payedOutHours) + 16,
    13,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.payedOutHours, 120, pageHeight - baseStartHeight + 20, { align: 'right' });

  doc.setTextColor(50, 50, 69);
  doc.setFontSize(11);
  doc.setFont('Nunito-Bold');
  doc.text(`${translateObj.REPORT_RECEIVED_HOURS}`, 10, pageHeight - baseStartHeight + 40, {
    align: 'left',
  });

  doc.setFillColor(5, 206, 145);
  doc.rect(
    120 - doc.getTextWidth(footerData.receivedHours) - 8,
    pageHeight - baseStartHeight + 40 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.receivedHours) + 16,
    13,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.receivedHours, 120, pageHeight - baseStartHeight + 40, { align: 'right' });

  // footer: Employee Information
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Bold');
  doc.text(
    `${translateObj.REPORT_EMPLOYEE_NAME}: ${footerData.fullEmployeeName}`,
    10,
    pageHeight - baseStartHeight + 60,
    { align: 'left' },
  );

  // footer: Date:
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(11);
  doc.setFont('Nunito-Bold');
  doc.text(
    `${translateObj.REPORT_DATE}: ___________________`,
    10,
    pageHeight - baseStartHeight + 78,
    { align: 'left' },
  );

  // footer: Signature:
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(11);
  doc.setFont('Nunito-Bold');
  doc.text(`${translateObj.REPORT_SIGNATURE}:`, 10, pageHeight - baseStartHeight + 100, {
    align: 'left',
  });
  const signatureWidth = doc.getTextWidth(`${translateObj.REPORT_SIGNATURE}:`);
  doc.setLineWidth(0.25);
  doc.setDrawColor(0, 0, 0);
  doc.line(
    10 + signatureWidth + 5,
    pageHeight - baseStartHeight + 100,
    10 + 130 - 20,
    pageHeight - baseStartHeight + 100,
  );
  // COL 2 TODO:
  // footer: Actual month :
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    `${translateObj.REPORT_ACTUAL_MONTH}: ${footerData.period}`,
    10 + 130,
    pageHeight - baseStartHeight + 20,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 - 55 - doc.getTextWidth(footerData.actualMonth) - 5,
    pageHeight - baseStartHeight + 20 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.actualMonth) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.actualMonth, 110 + 170 - 55, pageHeight - baseStartHeight + 20, {
    align: 'right',
  });
  // footer: Target total month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    `${translateObj.REPORT_TARGET_TOTAL_MONTH}: ${footerData.period}`,
    10 + 130,
    pageHeight - baseStartHeight + 40,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 - 35 - doc.getTextWidth(footerData.targetTotalMonth) - 5,
    pageHeight - baseStartHeight + 40 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.targetTotalMonth) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.targetTotalMonth, 110 + 170 - 35, pageHeight - baseStartHeight + 40, {
    align: 'right',
  });
  // footer: Balance 1 total this month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    `${translateObj.REPORT_BALANCE_1_TOTAL_THIS_MONTH}: ${footerData.period}`,
    10 + 130,
    pageHeight - baseStartHeight + 60,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 - doc.getTextWidth(footerData.balanceOneTotalThisMonth) - 5,
    pageHeight - baseStartHeight + 60 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.balanceOneTotalThisMonth) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.balanceOneTotalThisMonth, 110 + 170, pageHeight - baseStartHeight + 60, {
    align: 'right',
  });
  // footer: Balance 2 total last month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    `${translateObj.REPORT_BALANCE_2_TOTAL_LAST_MONTH}: ${footerData.period - 1}`,
    10 + 130,
    pageHeight - baseStartHeight + 80,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 - doc.getTextWidth(footerData.balanceTwoTotalLastMonth) - 5,
    pageHeight - baseStartHeight + 80 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.balanceTwoTotalLastMonth) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.balanceTwoTotalLastMonth, 110 + 170, pageHeight - baseStartHeight + 80, {
    align: 'right',
  });
  // footer: Balance 2 total this month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    `${translateObj.REPORT_BALANCE_2_TOTAL_THIS_MONTH}: ${footerData.period}`,
    10 + 130,
    pageHeight - baseStartHeight + 100,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 - doc.getTextWidth(footerData.balanceTwoTotalThisMonth) - 5,
    pageHeight - baseStartHeight + 100 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.balanceTwoTotalThisMonth) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(footerData.balanceTwoTotalThisMonth, 110 + 170, pageHeight - baseStartHeight + 100, {
    align: 'right',
  });

  // COL 3 TODO:
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    `Angefallen in Monat: ${footerData.period}`,
    10 + 130 + 160,
    pageHeight - baseStartHeight + 10,
    { align: 'left' },
  );

  // footer: Vacation days a month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    translateObj.REPORT_VACATION_DAYS_TAKEN_IN_PERIOD,
    10 + 130 + 160,
    pageHeight - baseStartHeight + 25,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 + 150 - doc.getTextWidth(footerData.vacationDaysTakenInPeriod.toString()) - 5,
    pageHeight - baseStartHeight + 25 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.vacationDaysTakenInPeriod.toString()) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    footerData.vacationDaysTakenInPeriod.toString(),
    110 + 170 + 150,
    pageHeight - baseStartHeight + 25,
    { align: 'right' },
  );

  // footer: Special vacation days a month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    translateObj.REPORT_VACATION_DAYS_SPECIAL_TAKEN_IN_PERIOD,
    10 + 130 + 160,
    pageHeight - baseStartHeight + 40,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 + 150 - doc.getTextWidth(footerData.specialVacationDaysTakenInPeriod.toString()) - 5,
    pageHeight - baseStartHeight + 40 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.specialVacationDaysTakenInPeriod.toString()) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    footerData.specialVacationDaysTakenInPeriod.toString(),
    110 + 170 + 150,
    pageHeight - baseStartHeight + 40,
    { align: 'right' },
  );

  // footer: Regeneration vacation days a month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    translateObj.REPORT_VACATION_DAYS_REGENERATION_TAKEN_IN_PERIOD,
    10 + 130 + 160,
    pageHeight - baseStartHeight + 55,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 + 150 - doc.getTextWidth(footerData.regenerationsDaysTakenInPeriod.toString()) - 5,
    pageHeight - baseStartHeight + 55 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.regenerationsDaysTakenInPeriod.toString()) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    footerData.regenerationsDaysTakenInPeriod.toString(),
    110 + 170 + 150,
    pageHeight - baseStartHeight + 55,
    { align: 'right' },
  );

  // footer: Conversion vacation days a month
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    translateObj.REPORT_VACATION_DAYS_CONVERSION_TAKEN_IN_PERIOD,
    10 + 130 + 160,
    pageHeight - baseStartHeight + 70,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 + 150 - doc.getTextWidth(footerData.conversionDaysTakenInPeriod.toString()) - 5,
    pageHeight - baseStartHeight + 70 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.conversionDaysTakenInPeriod.toString()) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    footerData.conversionDaysTakenInPeriod.toString(),
    110 + 170 + 150,
    pageHeight - baseStartHeight + 70,
    { align: 'right' },
  );

  // footer: Sick days Monat
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(translateObj.REPORT_SICK_DAYS_MONAT, 10 + 130 + 160, pageHeight - baseStartHeight + 85, {
    align: 'left',
  });

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 + 150 - doc.getTextWidth(footerData.sickDaysMonat.toString()) - 5,
    pageHeight - baseStartHeight + 85 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.sickDaysMonat.toString()) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    footerData.sickDaysMonat.toString(),
    110 + 170 + 150,
    pageHeight - baseStartHeight + 85,
    { align: 'right' },
  );
  // footer: Workingday balance time
  doc.setTextColor(50, 50, 69);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    translateObj.REPORT_WORKING_DAY_BALANCE_TIME,
    10 + 130 + 160,
    pageHeight - baseStartHeight + 100,
    { align: 'left' },
  );

  doc.setFillColor(5, 206, 145);
  doc.rect(
    110 + 170 + 150 - doc.getTextWidth(footerData.workingDayBalanceTime.toString()) - 5,
    pageHeight - baseStartHeight + 100 - doc.getLineHeight() / doc.internal.scaleFactor,
    doc.getTextWidth(footerData.workingDayBalanceTime.toString()) + 10,
    10,
    'F',
  );

  doc.setTextColor(255, 255, 255);
  doc.setFillColor(208, 255, 241);
  doc.setFontSize(9);
  doc.setFont('Nunito-Regular');
  doc.text(
    footerData.workingDayBalanceTime.toString(),
    110 + 170 + 150,
    pageHeight - baseStartHeight + 100,
    { align: 'right' },
  );

  // COPYRIGHT NOTICE
  doc.setFillColor(5, 206, 145);
  doc.rect(0, pageHeight - 30, pageWidth, 30, 'F');
  doc.setTextColor('#FFF');
  doc.setFontSize(9);
  doc.setFont('Nunito-Bold');
  doc.text(footerData.statementOfCopyRight, pageWidth / 2, pageHeight - 30 / 2, {
    align: 'center',
    baseline: 'middle',
  });
}
