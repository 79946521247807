class TimeTriplet {
  careTime: string;

  disposalTime: string;

  irregularDisposalTime: string;

  constructor(careTime: string, disposalTime: string, irregularDisposalTime: string) {
    this.careTime = careTime;
    this.disposalTime = disposalTime;
    this.irregularDisposalTime = irregularDisposalTime;
  }
}

export default TimeTriplet;
