import {buildTimeRangeCollection} from "./utility";
import {DateTime} from "luxon";
import {Tariff} from "./tariff";

export class TariffCollection {
    public tariffs: Tariff[];

    /**
     * Constructs a IntervalCollection object.
     *
     * @param {Interval[]} tariffs - Array of intervals.
     */
    constructor(tariffs: Tariff[]) {
        this.tariffs = buildTimeRangeCollection(tariffs) as Tariff[];
    }

    /**
     * HELPER to check for existence of employment at a day
     * @param date
     * @return true if an employment exists at given day else false
     */
    getTariff(date: DateTime): Tariff | undefined {
        return this.tariffs.find(
            (tariff) => tariff.start <= date && tariff.end >= date
        );
    }

    /**
     * HELPER to check for existence of employment at a day
     * @param date
     * @return true if an employment exists at given day else false
     */
    hasTariff(date: DateTime): boolean {
        for (const tariff of this.tariffs) {
            if (tariff.start <= date && tariff.end >= date) {
                return true;
            }
        }
        return false;
    }
}