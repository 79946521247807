import React from 'react';

import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from '../../LanguageSwitcher';
import logo from '../images/logo.png';
import home from '../images/home.png';
import user from '../images/user.png';

export const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{
        backgroundColor: '#f2fcff',
        bottom: '-40px',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '-20px',
        zIndex: 100,
      }}
    >
      <Container
        className='d-flex flex-column justify-content-center align-items-center position-relative'
        style={{ height: 'auto', maxWidth: '700px' }}
      >
        <div className='mb-5'>
          <img src={logo} alt={t('navbarBrand') as string} style={{ width: '100px' }} />
        </div>

        <p className='mt-3'>{t('WELCOME_KIDIG') as string}</p>

        <div className='d-flex flex-row justify-content-around align-items-start ml-5'>
          <div>
            <Link to='/facility/login'>
              <Button className='px-5 py-4 w-100 shadow'>{t('FACILITY_LOGIN') as string}</Button>
            </Link>{' '}
            <br />
            <Link to='/employee/login'>
              <Button className='px-5 py-4 my-3 w-100 shadow'>
                {t('EMPLOYEE_LOGIN') as string}
              </Button>
            </Link>
            <br />
            <Link to='/facility/signup'>
              <Button className='px-5 py-4 w-100 shadow'>{t('NEW_REGISTER') as string}</Button>
            </Link>
            <div className='mt-5 px-5 w-100 d-flex justify-content-around align-items-center'>
              <SwitchLanguage />
            </div>
          </div>

          <div className='ml-3 d-flex flex-column justify-content-start align-items-center '>
            <Link to='/facility/login '>
              <Image className='my-2' width='60px' src={home} />
            </Link>
            <Link to='/employee/login'>
              <Image className='my-4' width='60px' src={user} />
            </Link>
          </div>
        </div>

        <div style={{ height: '50px' }} />
        <Row className='mt-5 w-100'>
          <Col className='d-flex justify-content-center'>
            <Link to={{ pathname: 'https://www.kidig-online.de/' }} target='_blank'>
              {t('LEARN_ABOUT_KIDIG') as string}
            </Link>
          </Col>

          <Col className='d-flex justify-content-center'>
            <Link to='/privacy-policy'>{t('CONTRACT_INFO') as string}</Link>
          </Col>

          <Col className='d-flex justify-content-center'>
            <Link to='/terms-of-service'>{t('TERMS_CONDITIONS') as string}</Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
