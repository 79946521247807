import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import usePromise from 'react-promise';
import { useAsyncFn } from 'react-use';
import social, { authenticatedEmployee } from '../../../apis/social';
import { calculatePauseStat, calculateStat } from '../../../utility/time';
import StatisticsSection from './StatisticsSections';
import TopInlineOverviewSection from './TopInlineOverviewSection';
import buildTimeReport from '../../../utility/reportbuild';
import TimeReport from '../../../utility/TimeReport';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const monthNames = [
  '0_LAN',
  '1_LAN',
  '2_LAN',
  '3_LAN',
  '4_LAN',
  '5_LAN',
  '6_LAN',
  '7_LAN',
  '8_LAN',
  '9_LAN',
  '10_LAN',
  '11_LAN',
];

interface MonthData {
  name: string;
  numberOfDays: number;
  timeWorked: string;
  targetTime: string;
  difference: string;
  runningDifference: string;
  numberDaysSick?: number;
  numberDaysVacation?: number;
  numberRegenerationDays?: number;
  numberConversionDays?: number;
}

interface MonthComponentProps {
  monthData: MonthData[];
}

const MonthComponent: React.FC<MonthComponentProps> = ({ monthData }) => {
  const { t } = useTranslation();

  const getSeasonalIcon = (monthName: string) => {
    switch (monthName) {
      case 'März':
        return '🌸'; // Spring
      case 'Juni':
        return '☀️'; // Summer/Sun
      case 'September':
        return '🍂'; // Autumn/Leaf
      case 'Dezember':
        return '⛄'; // Winter/Snowman
      default:
        return '';
    }
  };

  return (
    <div className='row'>
      {monthData.map((month, index) => (
        <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3 mb-3'>
          <div className='border p-3'>
            <h4>
              {month.name} {getSeasonalIcon(month.name)}
            </h4>
            <p>
              {t('NUMBER_DAYS')}: <strong>{month.numberOfDays}</strong>
            </p>
            <p>
              {t('TIME_WORKED')}: <strong>{month.timeWorked}</strong>
            </p>
            <p>
              {t('TOTAL_TIME')}: <strong>{month.targetTime}</strong>
            </p>
            <p>
              {t('BALANCE')}:
              <strong style={{ color: month.difference.startsWith('-') ? '#ED4B9E' : '#45c298' }}>
                {month.difference}
              </strong>
            </p>
            <p>
              {t('SALDO_II')}: <strong>{month.runningDifference}</strong>
            </p>
            <>
              {(month.numberDaysSick ?? 0) > 0 && (
                  <p>
                    {t('SICK_DAYS')}: <strong>{month.numberDaysSick}</strong>
                  </p>
              )}
              {(month.numberDaysVacation ?? 0) > 0 && (
                  <p>
                    {t('VACATION_DAYS')}: <strong>{month.numberDaysVacation}</strong>
                  </p>
              )}
              {(month.numberRegenerationDays ?? 0) > 0 && (
                  <p>
                    {t('REGENERATION_DAYS')}: <strong>{month.numberRegenerationDays}</strong>
                  </p>
              )}
              {(month.numberConversionDays ?? 0) > 0 && (
                  <p>
                    {t('CONVERSION_DAYS')}: <strong>{month.numberConversionDays}</strong>
                  </p>
              )}
            </>

          </div>
        </div>
      ))}
    </div>
  );
};

export const YearView: React.FC = () => {
  const [startDate, setStartDate] = useState(new Date());

  const employeeId = useParams<{ employeeId: string }>().employeeId;

  const { value } = usePromise(authenticatedEmployee());
  const { t } = useTranslation();

  const [yearEmployeeStatistics, retryYearEmployeeStatistics] = useAsyncFn(async () => {
    const res: any = (await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: moment(startDate).endOf('year').toISOString(),
      startDate: moment(startDate).startOf('year').toISOString(),
      statistics: true,
      buildMonthReport: false,
      buildYearReport: true,
    })) as any;
    return res as any;
  }, [value, (value as any)?.user, startDate?.toString()]);

  const results =
    (yearEmployeeStatistics?.value?.yearReport?.map(
      (x: any) => buildTimeReport(x) || [],
    ) as TimeReport[]) || [];

  const monthData: MonthData[] = results.map((x: TimeReport, index: number) => ({
    name: t(monthNames[index]),
    numberOfDays: x.numberWorkdaysInPeriod,
    timeWorked: x.actualTimeTotal,
    targetTime: x.targetTimeTotal,
    difference: x.balanceTotal,
    runningDifference: x.dependentBalanceTotal,
    numberDaysSick: x.sickDays,
    numberDaysVacation: x.vacationDaysTakenInPeriod,
    numberRegenerationDays: x.regenerationDaysTakenInPeriod,
    numberConversionDays: x.conversionDaysTakenInPeriod,
  }));

  useEffect(() => {
    retryYearEmployeeStatistics();
  }, [startDate?.toString(), (value as any)?.user, employeeId]);

  return (
    <Container className='pt-3 pl-3'>
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        showYearPicker
        dateFormat='yyyy'
        className='yearDatePicker'
      />

      <Container className='pt-3 pl-3'>
        {/* MonthComponent added here */}
        <MonthComponent monthData={monthData} />
      </Container>

      <div style={{ height: '10px' }} />

      <hr
        className='my-3'
        style={{
          backgroundColor: 'white',
          border: 'none',
          height: '8px',
        }}
      />
      {yearEmployeeStatistics ? (
        <TopInlineOverviewSection
          totalTimeVal={calculateStat(yearEmployeeStatistics?.value?.targetTime)}
          timeWorkedVal={calculateStat(
            yearEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
          )}
          balanceVal={calculateStat(yearEmployeeStatistics?.value?.balance)}
          overtimeVal={calculatePauseStat(yearEmployeeStatistics?.value?.actualTime)}
          breakVal={1}
        />
      ) : (
        <TopInlineOverviewSection
          totalTimeVal=''
          timeWorkedVal=''
          balanceVal=''
          overtimeVal=''
          breakVal={1}
        />
      )}
      <div style={{ height: '10px' }} />

      <hr
        style={{
          backgroundColor: 'white',
          border: 'none',
          height: '8px',
        }}
      />

      {yearEmployeeStatistics && <StatisticsSection stats={yearEmployeeStatistics?.value as any} />}
    </Container>
  );
};

export default YearView;
