import React, { useEffect, useState } from 'react';
import { SickNote } from '../../../core/SickNote';
import DatePicker from 'react-datepicker';
import { Alert, Button, Col, FormCheck, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { insertTimeRangeOnDayOnly } from '../../../core/utility';
import { SickNoteCollection } from '../../../core/SickNoteCollection';

const getNoteLabelOrEmpty = (item: SickNote | undefined, t18: (key: string) => string): string => {
  if (item === undefined || item.note?.length === 0) {
    return '';
  }
  return t18('INTERVAL_NOTE');
};

interface SickEntryProps {
  disabled: boolean;
  year: number;
  sickNotes: SickNoteCollection[];
  onSickNotesChange: (sickNotes: SickNoteCollection[]) => void;
}

const CallInSickEntry: React.FC<SickEntryProps> = ({
  disabled,
  year,
  sickNotes,
  onSickNotesChange,
}) => {
  const { t: t18 } = useTranslation();

  const [alert, setAlert] = useState<undefined | string>(undefined);
  const showAlert = (msg: string) => {
    setAlert(msg);
  };

  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => {
        setAlert(undefined);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [alert]);

  const now = DateTime.now();
  const [items, setItems] = useState<SickNote[]>(
    sickNotes.find((sn) => sn.year === year)?.sickNotes ?? [],
  );

  const randomId = () => Math.random().toString(36).slice(2, 9);

  const [currentItem, setCurrentItem] = useState<SickNote>(
    new SickNote(randomId(), now, now, false, false, false, false, ''),
  );

  useEffect(() => {
    // set the items to the sick notes of the given year
    setItems(sickNotes.find((sn) => sn.year === year)?.sickNotes ?? []);

    // Reset currentItem when year changes
    setCurrentItem(
      new SickNote(
        randomId(),
        now.set({ year: year }), // set to January 1st of the given year
        now.set({ year: year }).plus({ second: 60 }), // set to January 1st of the given year + 1 minute
        false,
        false,
        false,
        false,
        '',
      ),
    );
  }, [year]);

  const handleSave = () => {
    try {
      // this function will either insert the new item or throw an error
      insertTimeRangeOnDayOnly(items, currentItem);
      const yearSickNotes = sickNotes.find((sn) => sn.year === year);
      if (yearSickNotes != undefined) {
        yearSickNotes.sickNotes = items;
        onSickNotesChange(sickNotes);
      }
    } catch (e) {
      showAlert(t18('INTERVAL_OVERLAPPING_WARNING'));
    }

    setCurrentItem(
      new SickNote(randomId(), now, now.plus({ second: 60 }), false, false, false, false, ''),
    );
  };

  const [showModal, setShowModal] = useState(false);

  const [itemToDelete, setItemToDelete] = useState<{
    index: number;
    start: DateTime;
    end: DateTime;
  } | null>(null);

  const handleDelete = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    const yearSickNotes = sickNotes.find((sn) => sn.year === year);
    if (yearSickNotes !== undefined) {
      yearSickNotes.sickNotes = newItems;
      onSickNotesChange(sickNotes);
    }
  };

  const handleShowModal = (index: number, start: DateTime, end: DateTime) => {
    setItemToDelete({ index, start, end });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setItemToDelete(null);
    setShowModal(false);
  };

  const confirmDelete = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete.index);
    }
    handleCloseModal();
  };

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div key={index}>
            <Row>
              <Col xs={6}>
                <label style={{ marginBottom: '8px' }}>{t18('FROM')}:</label>
                <div style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                  {item.start.toFormat('dd.MM.yy')}
                </div>
              </Col>
              <Col xs={6}>
                <label style={{ marginBottom: '8px' }}>{t18('TO')}:</label>
                <div style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                  {item.end.toFormat('dd.MM.yy')}
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: '16px' }}>
              {item.sickChild && (
                <Col xs={6}>
                  <label style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    {t18('SICK_CHILD')}
                  </label>
                </Col>
              )}

              {item.followUp && (
                <Col xs={6}>
                  <label style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    {t18('FOLLOW_UP')}
                  </label>
                </Col>
              )}

              {item.doctorsNote && (
                <Col xs={6}>
                  <label style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    {t18('DOCTORS_NOTE')}
                  </label>
                </Col>
              )}

              {item.presentDayOne && (
                <Col xs={6}>
                  <label style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    {t18('PRESENT_DAY_ONE')}
                  </label>
                </Col>
              )}
            </Row>
            {item.note && (
              <Row style={{ marginTop: '16px' }}>
                <Col>
                  <label style={{ fontWeight: 'bold', fontSize: '1em' }}>
                    {getNoteLabelOrEmpty(item, t18)}
                  </label>
                  <div>{item.note}</div>
                </Col>
              </Row>
            )}
            <Row className='d-flex justify-content-center' style={{ marginTop: '8px' }}>
              <Col xs={6}>
                <Button
                  onClick={() => handleShowModal(index, item.start, item.end)}
                  className='w-100'
                >
                  {t18('DELETE_SICK_NOTE')}
                </Button>
              </Col>
            </Row>

            <Modal centered show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>{t18('DELETE_SICK_NOTE_TITLE')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {itemToDelete &&
                  `${t18('CALL_IN_SICK')} ${itemToDelete.start.toFormat('dd.MM.yy')} ${t18(
                    'TO',
                  )} ${itemToDelete.end.toFormat('dd.MM.yy')} ${t18('DELETE_SICK_NOTE_WARNING')}`}
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-dark' onClick={confirmDelete}>
                  {t18('DELETE_CONFIRM')}
                </Button>
                <Button variant='primary' onClick={handleCloseModal}>
                  {t18('DELETE_CANCEL')}
                </Button>
              </Modal.Footer>
            </Modal>
            <hr />
          </div>
        );
      })}
      {/* Current item */}
      <div>
        <Row>
          <Col xs={6}>
            <label style={{ marginBottom: '8px' }}>{t18('FROM')}:</label>

            <DatePicker
              selected={new Date(currentItem.start.toString())}
              onChange={(date: Date) =>
                setCurrentItem(
                  (prev) =>
                    new SickNote(
                      randomId(),
                      DateTime.fromISO(date.toISOString()),
                      DateTime.fromISO(date.toISOString()),
                      prev.sickChild,
                      prev.followUp,
                      prev.doctorsNote,
                      prev.presentDayOne,
                      prev.note,
                    ),
                )
              }
              dateFormat='dd/MM/yyyy'
              className='w-100'
              minDate={new Date(`01/01/${year}`)}
              maxDate={new Date(`12/31/${year}`)}
            />
          </Col>
          <Col xs={6}>
            <label style={{ marginBottom: '8px' }}>{t18('TO')}:</label>
            <DatePicker
              selected={new Date(currentItem.end.toString())}
              onChange={(date: Date) => {
                setCurrentItem((prev) => {
                  try {
                    const newSickNote = new SickNote(
                      randomId(),
                      prev.start,
                      DateTime.fromISO(date.toISOString()),
                      prev.sickChild,
                      prev.followUp,
                      prev.doctorsNote,
                      prev.presentDayOne,
                      prev.note,
                    );
                    return newSickNote;
                  } catch (e) {
                    showAlert(t18('INTERVAL_BUILD_WARNING'));
                    // Return the previous state if there's an error
                    return prev;
                  }
                });
              }}
              dateFormat='dd/MM/yyyy'
              className='w-100'
              minDate={new Date(`01/01/${year}`)}
              maxDate={new Date(`12/31/${year}`)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col xs={6}>
            <FormCheck
              type='switch'
              id={`sickChild-${currentItem}`}
              label={t18('SICK_CHILD')}
              checked={currentItem.sickChild}
              disabled={disabled}
              onChange={() =>
                setCurrentItem(
                  (prev) =>
                    new SickNote(
                      randomId(),
                      prev.start,
                      prev.end,
                      !prev.sickChild,
                      prev.followUp,
                      prev.doctorsNote,
                      prev.presentDayOne,
                      prev.note,
                    ),
                )
              }
            />
          </Col>
          <Col xs={6}>
            <FormCheck
              type='switch'
              id={`followUp-${currentItem}`}
              label={t18('FOLLOW_UP')}
              checked={currentItem.followUp}
              disabled={disabled}
              onChange={() =>
                setCurrentItem(
                  (prev) =>
                    new SickNote(
                      randomId(),
                      prev.start,
                      prev.end,
                      prev.sickChild,
                      !prev.followUp,
                      prev.doctorsNote,
                      prev.presentDayOne,
                      prev.note,
                    ),
                )
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col xs={6}>
            <FormCheck
              type='switch'
              id={`doctorsNote-${currentItem}`}
              label={t18('DOCTORS_NOTE')}
              checked={currentItem.doctorsNote}
              disabled={disabled}
              onChange={() =>
                setCurrentItem(
                  (prev) =>
                    new SickNote(
                      randomId(),
                      prev.start,
                      prev.end,
                      prev.sickChild,
                      prev.followUp,
                      !prev.doctorsNote,
                      prev.presentDayOne,
                      prev.note,
                    ),
                )
              }
            />
          </Col>
          <Col xs={6}>
            <FormCheck
              type='switch'
              id={`presentDayOne-${currentItem}`}
              label={t18('PRESENT_DAY_ONE')}
              checked={currentItem.presentDayOne}
              disabled={disabled}
              onChange={() =>
                setCurrentItem(
                  (prev) =>
                    new SickNote(
                      randomId(),
                      prev.start,
                      prev.end,
                      prev.sickChild,
                      prev.followUp,
                      prev.doctorsNote,
                      !prev.presentDayOne,
                      prev.note,
                    ),
                )
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col>
            <label>{t18('INTERVAL_NOTE')}:</label>
            <textarea
              value={currentItem.note}
              onChange={(e) =>
                setCurrentItem(
                  (prev) =>
                    new SickNote(
                      randomId(),
                      prev.start,
                      prev.end,
                      prev.sickChild,
                      prev.followUp,
                      prev.doctorsNote,
                      prev.presentDayOne,
                      e.target.value,
                    ),
                )
              }
              className='w-100'
            />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center' style={{ marginTop: '16px' }}>
          <Col xs={6}>
            <Button onClick={handleSave} className='w-100'>
              {t18('SAVE_SICK_NOTE')}
            </Button>
          </Col>
        </Row>
        {/* Display alert if it exists */}
        <Row className='d-flex justify-content-center' style={{ marginTop: '16px' }}>
          <Alert variant='danger' hidden={!alert}>
            {alert}
          </Alert>
        </Row>
      </div>
    </div>
  );
};

export default CallInSickEntry;
