import * as React from 'react';
import { withModalManager } from '@vlzh/react-modal-manager';

export const ExecuteModalBtn: React.FC<{ modalType: 'EMPLOYEE' | 'ADMIN' }> = withModalManager()(
  ({
    openModal,
    modalType,
    children,
  }: {
    openModal: any;
    children: any;
    modalType: 'EMPLOYEE' | 'ADMIN';
  }) => (
    <>
      <div
        onClick={() => {
          openModal(modalType);
        }}
      >
        {children}
      </div>
    </>
  ),
);

export default ExecuteModalBtn;
