import React, { useCallback } from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import usePromise from 'react-promise';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import { TariffAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import { customIntervals } from '../../Layout/containers/TopBar';
import RemoveTariff from './RemoveTariff';
import TariffCard from '../../EmployeeCreation/container/TariffCard';
import ExecuteModalBtn from '../../Payment/containers/ExecuteModalBtn';
import { useGlobalState } from '../../../App';
import InfoModal from '../../Facility/containers/InfoModal';

export type TariffListAttributes = {
  total: number;
  limit: number;
  skip: number;
  data: TariffAttributes[];
};

export const Overview: React.FC = () => {
  const { t: t18 } = useTranslation();
  const history = useHistory();

  const [canWrite] = useGlobalState('canWrite');

  const { value } = usePromise(authenticatedFacility());
  const [tariff] = useObservable<TariffListAttributes | null>(
    () =>
      social
        .service('tariff')
        .watch()
        .find({
          query: {
            $sort: {
              createdAt: 1,
            },
            facilityId: (value as any)?.user?.facility?.id,
            finalSubmission: true,
          },
        }) as any,
    [value],
  );

  const navigateToTariffs = useCallback(
    async (pathName: string) => {
      const tariffObj: TariffAttributes = (await social.service('tariff').create({
        facilityId: (value as any)?.user?.facility?.id,
        groupIntervals: customIntervals(),
      })) as any;

      history.push({
        pathname: generatePath(`${pathName}/:tariffId`, {
          tariffId: tariffObj?.id,
        }),
      });
    },
    [(value as any)?.user?.facility?.id],
  );

  return (
    <Container>
      <h4 className='mt-4 mb-5'>
        {t18('TARIFF_SETTINGS') as string}
        <strong>
          <InfoModal content={t18('GENERAL_SETTINGS_INFO') as string} />
        </strong>
      </h4>

      {/* <Link to="/admin/tariff/settings/rzehyerhy">
        <Button variant="link" href="">
          create
        </Button>
      </Link> */}

      {tariff?.data?.length ? (
        tariff?.data?.map((t: any, idx: any) => (
          <>
            <Row style={{ cursor: 'pointer' }} key={t.id}>
              <Col key={t.id}>
                {idx + 1 !== tariff?.data?.length ? (
                  <Button variant='light' disabled className='text-dark p-0 w-100'>
                    <TariffCard tariff={t} />
                  </Button>
                ) : (
                  <Link
                    key={t.id}
                    style={idx !== 0 ? { pointerEvents: 'none' } : { pointerEvents: 'all' }}
                    className='text-dark'
                    to={`/admin/tariff/settings/${t.id}`}
                  >
                    <TariffCard tariff={t} />
                  </Link>
                )}
              </Col>

              <Col key={t.id} xs={2} className='d-flex align-items-center pl-1'>
                <RemoveTariff disabled={(tariff?.data?.length ?? -1) - 1 !== idx} tariffId={t.id} />
              </Col>
            </Row>

            <br />
          </>
        ))
      ) : (
        <>
          <h3>{t18('START_CREATE_TARIFF') as string}</h3>
        </>
      )}

      {canWrite ? (
        <Button
          className='mt-3 '
          size='lg'
          variant='primary'
          onClick={() => navigateToTariffs('/admin/tariff/settings')}
          block
        >
          {t18('CREATE_TARIFFS') as string}
        </Button>
      ) : (
        <ExecuteModalBtn modalType='ADMIN'>
          <Button className='mt-3 ' size='lg' variant='primary' block>
            {t18('CREATE_TARIFFS') as string}
          </Button>
        </ExecuteModalBtn>
      )}

      <br />
      <br />
    </Container>
  );
};

export default Overview;
