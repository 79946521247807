import moment from 'moment';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TariffAttributes } from '../../../apis/attributes';

export type TariffCardCardProps = {
  tariff: TariffAttributes | null;
};

export const TariffCard: React.FC<TariffCardCardProps> = ({ tariff }) => {
  const { t } = useTranslation();

  return (
    <Card className='w-100 shadow' style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title className='text-center'>{t('CARD_TITLE') as string}</Card.Title>
        <Row className='mt-5'>
          {/*
          <Col md={4} xs={6}>
            <p className="text-center ">

              <strong className=" text-primary">Id</strong>
              <br/>
              <small>
                {tariff?.id}
              </small>
            </p>
          </Col>
         */}
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('START_DATE') as string}</strong>

              <br />
              <small>{moment(tariff?.startDate)?.format('DD-MM-YYYY') as string}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('DEADLINE') as string}</strong>
              <br />
              <small>
                {tariff?.endDate
                  ? moment(tariff?.endDate)?.format('DD-MM-YYYY')
                  : (t('ONGOING') as string)}
              </small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('DUTY_ROSTER_TYPE') as string}</strong>
              <br />
              <small>
                {tariff?.frameworkDutyPlan
                  ? t('FRAMEWORK_DUTY_PLAN')
                  : (t('AVERAGE_DAILY_WORKING_HOURS') as string)}
              </small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('WORKING_HOURS_FULLtime') as string}</strong>

              <br />
              <small>{tariff?.weeklyWorkingHoursFullyEmployed}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('PAUSE_DEDUCTION_ACTIVE') as string}</strong>

              <br />
              <small>{tariff?.pauseDeductionActive ? t('YES') : (t('NO') as string)}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('TIME_ADDITION_ACTIVE') as string}</strong>

              <br />
              <small>{tariff?.timeAdditionActive ? t('YES') : (t('NO') as string)}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('DEC_24_NOT_WORKING') as string}</strong>

              <br />
              <small>{tariff?.dec24NotWorking ? t('YES') : (t('NO') as string)}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('DEC_31_NOT_WORKING') as string}</strong>
              <br />
              <small>{tariff?.dec31NotWorking ? t('YES') : (t('NO') as string)}</small>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TariffCard;
