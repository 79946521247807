import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { Col, Row, Container, Button, Form as ReactForm } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { generatePath, useHistory } from 'react-router';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import { TariffAttributes } from '../../../apis/attributes';
import social from '../../../apis/social';
import DateInputCustom from '../../Facility/containers/DateInputCustom';
import InfoModal from '../../Facility/containers/InfoModal';

export const PauseSettings: React.FC<{ tariffId: string }> = observer(({ tariffId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [tariff] = useObservable<TariffAttributes | null>(
    () => social.service('tariff').watch().get(tariffId) as any,
    [tariffId],
  );

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}/:tariffId`, { tariffId }),
    });
  }, []);

  const updateDB = useCallback(
    async (data: {
      firstPauseAfter: string;
      firstPauseLength: string;
      pauseDeductionActive: boolean;
      secondPauseAfter: string;
      secondPauseLength: string;
    }) => {
      try {
        await social.service('tariff').patch(tariffId, {
          ...data,
        });
        navigateTo('/admin/tariffs/time-addition');
      } catch (error) {
        alert('Error: please reload the page');
      }
    },
    [tariffId],
  );

  return (
    <Container className='mb-5'>
      <Formik
        enableReinitialize
        initialValues={{
          firstPauseAfter: tariff?.firstPauseAfter ?? '',
          firstPauseLength: tariff?.firstPauseLength ?? '',
          minPauseLength: tariff?.minPauseLength ?? '',
          pauseDeductionActive: tariff?.pauseDeductionActive ?? false,
          secondPauseAfter: tariff?.secondPauseAfter ?? '',
          secondPauseLength: tariff?.secondPauseLength ?? '',
        }}
        validationSchema={Yup.object({
          firstPauseAfter: Yup.string().required(t('REQUIRED')),
          firstPauseLength: Yup.string().required(t('REQUIRED')),
          minPauseLength: Yup.string().required(t('REQUIRED')),
          pauseDeductionActive: Yup.boolean().required(t('REQUIRED')),
          secondPauseAfter: Yup.string().required(t('REQUIRED')),
          secondPauseLength: Yup.string().required(t('REQUIRED')),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            updateDB(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {(props: FormikValues) => (
          <Form>
            <p className='mt-4 mb-2'>
              <strong>
                {t('PAUSE_SETTINGS') as string}{' '}
                <InfoModal content={t('PAUSE_DEDUCTION_INFO') as string} />
              </strong>
            </p>
            <Row>
              <Col xs={9}>
                <p>{t('PAUSE_DEDUCTION_ACTIVE') as string}</p>
              </Col>
              <Col>
                {tariff?.pauseDeductionActive !== undefined && (
                  <ReactForm.Check
                    type='switch'
                    id='custom-switch'
                    name='pauseDeductionActive'
                    defaultChecked={tariff?.pauseDeductionActive}
                    onChange={props.handleChange}
                  />
                )}
              </Col>
            </Row>

            <p className='mt-5 mb-1'>
              <strong className='creatorTitle colorBodyText'>
                {t('MINIMAL_PAUSE_LENGTH') as string}
              </strong>
            </p>
            {tariff?.minPauseLength && (
              <DateInputCustom
                defaultStrDuration={tariff?.minPauseLength}
                onChange={(e: any) => {
                  props.setFieldValue('minPauseLength', e);
                }}
              />
            )}
            {props?.errors?.minPauseLength && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.minPauseLength}{' '}
              </p>
            )}

            <p className='mt-3 mb-1'>
              <strong className='creatorTitle colorBodyText'>
                {t('FIRST_PAUSE_AFTER') as string}
              </strong>
            </p>
            {tariff?.firstPauseAfter && (
              <DateInputCustom
                defaultStrDuration={tariff?.firstPauseAfter}
                onChange={(e: any) => {
                  props.setFieldValue('firstPauseAfter', e);
                }}
              />
            )}
            {props?.errors?.firstPauseAfter && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.firstPauseAfter}{' '}
              </p>
            )}

            <p className='mt-3 mb-1'>
              <strong className='creatorTitle colorBodyText'>
                {t('DEFINE_PAUSE_LENGTH_ONE') as string}
              </strong>
            </p>
            {tariff?.firstPauseLength && (
              <DateInputCustom
                defaultStrDuration={tariff?.firstPauseLength}
                onChange={(e: any) => {
                  props.setFieldValue('firstPauseLength', e);
                }}
              />
            )}
            {props?.errors?.firstPauseLength && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.firstPauseLength}{' '}
              </p>
            )}

            <p className='mt-3 mb-1'>
              <strong className='creatorTitle colorBodyText'>
                {t('SECOND_PAUSE_AFTER') as string}
              </strong>
            </p>
            {tariff?.secondPauseAfter && (
              <DateInputCustom
                defaultStrDuration={tariff?.secondPauseAfter}
                onChange={(e: any) => {
                  props.setFieldValue('secondPauseAfter', e);
                }}
              />
            )}
            {props?.errors?.secondPauseAfter && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.secondPauseAfter}{' '}
              </p>
            )}

            <p className='mt-3 mb-1'>
              <strong className='creatorTitle colorBodyText'>
                {t('DEFINE_PAUSE_LENGTH_TWO') as string}
              </strong>
            </p>
            {tariff?.secondPauseLength && (
              <DateInputCustom
                defaultStrDuration={tariff?.secondPauseLength}
                onChange={(e: any) => {
                  props.setFieldValue('secondPauseLength', e);
                }}
              />
            )}

            {props?.errors?.secondPauseLength && (
              <p className='mb-3 text-danger' style={{ marginTop: '-17px' }}>
                {' '}
                {props?.errors?.secondPauseLength}{' '}
              </p>
            )}

            <div style={{ height: '70px' }} />
            <Button type='submit' block className='py-2'>
              {t('SAVE') as string}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
});

export default PauseSettings;
