import TimeReport from '../../../utility/TimeReport';

export interface IReportFooter {
  payedOutHours: string;
  receivedHours: string;
  actualMonth: string;
  targetTotalMonth: string;
  balanceOneTotalThisMonth: string;
  balanceTwoTotalLastMonth: string;
  balanceTwoTotalThisMonth: string;
  vacationDaysTakenInPeriod: number;
  specialVacationDaysTakenInPeriod: number;
  regenerationsDaysTakenInPeriod: number;
  conversionDaysTakenInPeriod: number;
  sickDaysMonat: number;
  workingDayBalanceTime: number;
  statementOfCopyRight: string;
  fullEmployeeName: string;
  period: number;
}

class ReportFooter implements IReportFooter {
  payedOutHours: string;

  receivedHours: string;

  actualMonth: string;

  targetTotalMonth: string;

  balanceOneTotalThisMonth: string;

  balanceTwoTotalLastMonth: string;

  balanceTwoTotalThisMonth: string;

  vacationDaysTakenInPeriod: number;

  specialVacationDaysTakenInPeriod: number;

  regenerationsDaysTakenInPeriod: number;

  conversionDaysTakenInPeriod: number;

  sickDaysMonat: number;

  workingDayBalanceTime: number;

  statementOfCopyRight: string;

  fullEmployeeName: string;

  period: number;

  constructor(timeReport: TimeReport) {
    this.payedOutHours = timeReport.paidOutHoursTotal;
    this.receivedHours = timeReport.receivedHoursTotal;
    this.actualMonth = timeReport.actualTimeTotal;
    this.targetTotalMonth = timeReport.targetTimeTotal;
    this.balanceOneTotalThisMonth = timeReport.balanceTotal;
    this.balanceTwoTotalLastMonth = timeReport.dependentBalanceTotalLastMonth;
    this.balanceTwoTotalThisMonth = timeReport.dependentBalanceTotal;
    this.vacationDaysTakenInPeriod = timeReport.vacationDaysTakenInPeriod;
    this.specialVacationDaysTakenInPeriod = timeReport.specialVacationDaysTakenInPeriod;
    this.regenerationsDaysTakenInPeriod = timeReport.regenerationDaysTakenInPeriod;
    this.conversionDaysTakenInPeriod = timeReport.conversionDaysTakenInPeriod;
    this.sickDaysMonat = timeReport.sickDays;
    this.workingDayBalanceTime = timeReport.workTimeCompensationDays;
    this.statementOfCopyRight = timeReport.statementOfCopyRight;
    this.fullEmployeeName = `${timeReport.firstNameEmployee} ${timeReport.lastNameEmployee}`.trim();
    this.period = Number(timeReport.startDate.substring(5, 7));
  }
}

export default ReportFooter;
