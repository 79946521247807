import jsPDF from 'jspdf';
import autoTable, { CellDef, Styles } from 'jspdf-autotable';
import { chunk, get, isNil } from 'lodash';

import { ITranslationLabel } from '../model/TranslationLabel';
import DayReport from '../../../utility/DayReport';
import WeekReport from '../../../utility/WeekReport';
import WeekTableLastPos from '../model/WeekTableLastPos';
import Interval from '../../../utility/Interval';

const dateOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const generateDataFromWeekData = (
  weekData: WeekReport,
  lang: string,
  translateObj: ITranslationLabel,
): CellDef[][] => {
  const dataList: CellDef[][] = [];
  const days: DayReport[] = weekData.week;

  const switchDayOfWeek = (dayData: DayReport): string => {
    switch (dayData.dayOfWeek) {
      case dateOfWeek[0]:
        return translateObj.REPORT_SORT_MONDAY;
      case dateOfWeek[1]:
        return translateObj.REPORT_SORT_TUESDAY;
      case dateOfWeek[2]:
        return translateObj.REPORT_SORT_WEDNESDAY;
      case dateOfWeek[3]:
        return translateObj.REPORT_SORT_THURSDAY;
      case dateOfWeek[4]:
        return translateObj.REPORT_SORT_FRIDAY;
      case dateOfWeek[5]:
        return translateObj.REPORT_SORT_SATURDAY;
      default:
        return translateObj.REPORT_SORT_SUNDAY;
    }
  };

  const translateFacilityDayType = (facilityDayType: string, isWorkDay: boolean): string => {
    switch (facilityDayType) {
      case 'FACILITY_WORKDAY':
        if (isWorkDay) {
          return 'Arbeitstag';
        }
        return '';
      case 'FACILITY_CLOSED_DAY':
        return '';
      case 'FEIERTAG':
        return 'Feiertag';
      case 'USER_PROVIDED_FEIERTAG':
        return 'Feiertag zusätzlich';
      case 'FACILITY_HOLIDAY':
        return 'Schließt.';
      default:
        return 'Unbekannt';
    }
  };

  const fillSummaryData = (
    data: CellDef[],
    day: DayReport,
    timeEmpty: number,
    intervals: Interval[],
    isFirst: boolean = false,
  ) => {
    if (isFirst) {
      data.push({
        title: switchDayOfWeek(day),
      });
      data.push({
        title: day.date.substring(8, 10),
      });
    } else {
      data.push({ title: '' });
      data.push({ title: '' });
    }

    for (let iEmpty = 0; iEmpty < timeEmpty; iEmpty++) {
      const interval: Interval = get(intervals, `[${iEmpty}]`) as unknown as Interval;
      if (isNil(interval)) {
        data.push({ title: '' });
        data.push({ title: '' });
      } else {
        data.push({ title: interval.start });
        data.push({ title: interval.end });
      }
    }
    if (isFirst) {
      data.push({ title: day.actualTimeTotal });
      data.push({ title: day.targetTimeTotal });
      data.push({ title: day.balanceTotal });
      data.push({ title: day.keyWordTranslation(lang) });
      data.push({ title: day.pause });
      data.push({ title: day.contractHours });
      data.push({ title: translateFacilityDayType(day.facilityDayType, day.isWorkDay) });
    }
  };

  for (const dayData of days) {
    const chunkIntervals: Interval[][] = chunk(dayData.intervals, 4);
    if (chunkIntervals.length > 0) {
      for (let chunkId = 0; chunkId < chunkIntervals.length; chunkId++) {
        const chunkInterval = chunkIntervals[chunkId];
        if (chunkId === 0) {
          const data: CellDef[] = [];
          fillSummaryData(data, dayData, 4, chunkInterval, true);
          dataList.push(data);
        } else {
          const data: CellDef[] = [];
          fillSummaryData(data, dayData, 4, chunkInterval, false);
          dataList.push(data);
        }
      }
    } else {
      const data: CellDef[] = [];
      fillSummaryData(data, dayData, 4, [], true);
      dataList.push(data);
    }
  }

  return dataList;
};

export default function generateOverviewWeekTable(
  doc: jsPDF,
  mapData: WeekReport,
  lang: string,
  translateObj: ITranslationLabel,
  startYparam?: number | undefined,
): WeekTableLastPos {
  const dataFromWeekData = generateDataFromWeekData(mapData, lang, translateObj);

  const cellWidthDefine = 24;
  const cellWidthWide = 29;
  let xPos = 0;
  let yPos = 0;
  let nextYResult = 0;
  autoTable(doc, {
    alternateRowStyles: {
      fillColor: '#FFF',
      fontSize: 8,
    },
    body: dataFromWeekData,
    columnStyles: {
      0: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      1: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      2: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      3: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      4: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fillColor: '#D9D9D9',
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      5: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fillColor: '#D9D9D9',
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      6: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      7: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      8: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fillColor: '#D9D9D9',
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      9: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fillColor: '#D9D9D9',
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      10: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      11: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      12: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      13: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      14: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      15: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
      16: {
        cellPadding: {
          bottom: 3,
          left: 1,
          right: 1,
          top: 4,
        },
        fontSize: 8,
        lineColor: '#000',
        lineWidth: 0.5,
      },
    },
    didDrawPage(data: any) {
      xPos = data.cursor.x;
      yPos = data.cursor.y;
      nextYResult = yPos + 5;
    },
    head: [
      [
        {
          styles: {
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: `${translateObj.REPORT_DATE_NAME}`,
        },
        {
          styles: {
            // cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: `${translateObj.REPORT_DAY}`,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_START,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_END,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#D9D9D9',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_START,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#D9D9D9',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_END,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_START,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_END,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#D9D9D9',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_START,
        },
        {
          styles: {
            cellWidth: cellWidthDefine,
            fillColor: '#D9D9D9',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_END,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_ACTUAL,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_TARGET,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_BALANCE,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_KEYWORD,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_PAUSE,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_CONTRACT_HOURS,
        },
        {
          styles: {
            cellWidth: cellWidthWide,
            fillColor: '#FFF',
            fontSize: 8,
            fontStyle: 'normal',
            halign: 'center',
            lineColor: '#000',
            lineWidth: 0.5,
            textColor: '#323245',
            valign: 'middle',
          },
          title: translateObj.REPORT_FACILITY_DAYTYPE,
        },
      ],
    ],

    margin: {
      bottom: 5,
      left: 5,
      right: 5,
      top: 5,
    },
    rowPageBreak: 'avoid',

    startY: startYparam,
    // startY: 350,
    styles: {
      fontSize: 9,
      halign: 'center',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.5,
  });

  doc.setFontSize(8);
  doc.setFillColor(5, 206, 145);
  doc.rect(0, nextYResult, 450, 15, 'F');

  const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;

  doc.setTextColor(255, 255, 255);
  doc.text(`${translateObj.REPORT_WEEKLY_WORKING_DAY}`, 10, nextYResult + lineHeight, {
    baseline: 'middle',
  });

  doc.setFillColor(255, 255, 255);
  doc.rect(
    110 - 5,
    nextYResult + 3,
    doc.getTextWidth(mapData.numberWorkingDays.toString()) + 10,
    9,
    'F',
  );

  doc.setTextColor('#05CE91');
  doc.text(mapData.numberWorkingDays.toString(), 110, nextYResult + 10);

  doc.setTextColor(255, 255, 255);
  doc.text(`${translateObj.REPORT_WEEKLY_TIME_TOTAL}`, 175, nextYResult + lineHeight, {
    baseline: 'middle',
  });

  doc.text(mapData.actualTimeTotal, 245, nextYResult + 10);
  doc.text(mapData.targetTimeTotal, 245 + 29, nextYResult + 10);
  doc.text(mapData.balanceTotal, 245 + 29 * 2, nextYResult + 10);

  return { nextY: nextYResult + 15 + 5, xPos, yPos };
}
