import React, { useState } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import social from '../../../apis/social';

export const RemoveTariff: React.FC<{
  tariffId: string;
  disabled: boolean;
}> = ({ tariffId, disabled }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteTariff = async () => social.service('tariff').remove(tariffId);

  return (
    <Container className='p-0'>
      <Button disabled={disabled} variant='outline-dark' onClick={handleShow}>
        X
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='contained-modal-title-hcenter'>
            {t('DELETE_TARIFF') as string}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-4'>{t('QUESTION_DELETE_TARIFF') as string}</Modal.Body>
        <Modal.Footer className='d-flex justif-content-center'>
          <Button
            variant='outline-dark'
            onClick={async () => {
              await deleteTariff();
              handleClose();
            }}
          >
            {t('REMOVE') as string}
          </Button>
          <Button className='px-5' variant='primary' onClick={handleClose}>
            {t('CANCEL') as string}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RemoveTariff;
