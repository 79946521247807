import moment from 'moment/moment';
import { ActualTime, WorkTime } from '../apis/attributes/statistics';

/**
 * get current offset to UTC as moment duration to add to dates
 */
export const locationOffset = () => moment.duration(moment(new Date()).utcOffset(), 'minutes');

/**
 * helper function to pad potential smaller than 10 number with zeros for nice display
 * @param numb a number to be padded
 * @returns a padded number
 */
export const padNumber = (numb: number | undefined) => {
  if (numb === undefined) return 'Error, given number was undefined';
  if (numb.toString().length === 1) return `0${numb}`;
  return numb;
};

/**
 *
 * @param timeAsString
 */
export const getFloatFromHoursMinutesSeconds = (timeAsString: string | undefined) => {

  if (typeof timeAsString !== 'string') {
    return 0.0;
  }

  const duration = moment.duration(timeAsString);

  return parseFloat(duration.asHours().toFixed(2));
};


/**
 *
 * @param days
 * @param hours
 * @param minutes
 * @param seconds
 */
const buildTimeString = (days: number, hours: number, minutes: number, seconds: number) => {
  let s = 'PT';
  const h = days * 24 + hours;
  if (h !== 0) {
    s += `${h}H`;
  }
  if (minutes !== 0) {
    s += `${minutes}M`;
  }
  if (seconds !== 0) {
    s += `${minutes}S`;
  }
  if (s === 'PT') {
    return 'PT0H';
  }
  return s;
};

/**
 *
 * @param timeAsDecimal
 */
export const getHoursMinutesSecondsFromFloat = (timeAsDecimal: number) => {
  if (timeAsDecimal != null) {
    const d = moment.duration(timeAsDecimal, 'hours');
    const [days, hours, minutes, seconds] = [d.days(), d.hours(), d.minutes(), d.seconds()];
    return buildTimeString(days, hours, minutes, seconds);
  }
  return 'PT0H';
};

// uses duration to collapse a work time into a sum of work-times as duration
/**
 * helper function to sum time parts from backend return
 * @param careTimePart
 * @param disposalTimePart
 * @param irregularDisposalTimePart
 * @returns the sum of all time parts as ISOString
 */
export const sumTimeParts = (
  careTimePart: string,
  disposalTimePart: string,
  irregularDisposalTimePart: string,
) => {
  const careDuration = moment.duration(careTimePart);
  const disposalDuration = moment.duration(disposalTimePart);
  const irregularDisposalDuration = moment.duration(irregularDisposalTimePart);
  return careDuration.add(disposalDuration).add(irregularDisposalDuration).toISOString();
};

/**
 * helper function to format time given as string for nice display
 * @param durationString
 * @returns a formatted time string
 */
export const formatDuration = (durationString: string) => {
  const duration = moment.duration(durationString);
  const totalSeconds = Math.abs(duration.asSeconds());
  const hoursPart = Math.floor(totalSeconds / 3600);
  const minutesPart = Math.floor((totalSeconds - hoursPart * 3600) / 60);
  const secondsPart = Math.floor(totalSeconds - hoursPart * 3600 - minutesPart * 60);
  const minutesPartTotal = secondsPart > 29 ? minutesPart + 1 : minutesPart;
  // check if a minus sign needs to be displayed
  if (duration.asMilliseconds() >= 0) {
    return `${padNumber(hoursPart).toString()}:${padNumber(minutesPartTotal).toString()}`;
  }
  return `-${padNumber(hoursPart).toString()}:${padNumber(minutesPartTotal).toString()}`;
};

/**
 * helper to sum time parts for a list of times
 * @param parts string of time parts to be summed
 * @returns the sum of all time parts as ISOString
 */
export const sumDurationFromParts = (parts: string[]) => {
  const d = moment.duration();
  parts.forEach((part) => {
    d.add(part);
  });
  return d.toISOString();
};

export const printMofoTimeReport = (rawTimeReport: any) => {};

export const numberToDayOfWeek = (number: number, language: string = 'de') => {
  switch (language) {
    case 'en':
      if (number === 1) return 'Mo';
      if (number === 2) return 'Tu';
      if (number === 3) return 'We';
      if (number === 4) return 'Th';
      if (number === 5) return 'Fr';
      if (number === 6) return 'Sa';
      if (number === 7) return 'Su';
      return '?';
    default:
      if (number === 1) return 'Mo';
      if (number === 2) return 'Di';
      if (number === 3) return 'Mi';
      if (number === 4) return 'Do';
      if (number === 5) return 'Fr';
      if (number === 6) return 'Sa';
      if (number === 7) return 'So';
      return '?';
  }
};

export const checkForFacilityWorkDay = (dayStatusList: any) => {
  if (dayStatusList === undefined) {
    return false;
  }
  const today = dayStatusList[0];
  return today.facilityDayType === 'FACILITY_WORKDAY';
};

export const checkForEmployeeWorkDay = (dayStatusList: any) => {
  if (dayStatusList === undefined) {
    return false;
  }
  const today = dayStatusList[0];
  return today.isWorkDay;
};

// This function seems to somehow calculate the sum of time
export const calculateStat = (stats: WorkTime) =>
  formatDuration(
    sumDurationFromParts([
      stats?.careTimePart,
      stats?.disposalTimeIrregularPart,
      stats?.disposalTimeRegularPart,
    ]),
  );
export const calculatePauseStat = (stat: ActualTime) =>
  formatDuration(
    sumDurationFromParts([
      stat?.pause,
      stat?.pauseDeduction.careTimePart,
      stat?.pauseDeduction.disposalTimeRegularPart,
      stat?.pauseDeduction.disposalTimeIrregularPart,
    ]),
  );
