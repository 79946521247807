import React from 'react';
import { Route } from 'react-router';
import { useTranslation } from 'react-i18next';
import TopBar from '../Layout/containers/TopBar';
import Overview from './containers/Overview';
import PauseSettings from './containers/PauseSettings';
import TariffSettings from './containers/tariffSettings';
import TimeAddition from './containers/TimeAddition';

export default () => {
  const { t } = useTranslation();
  return (
    <>
      <Route exact path='/admin/tariffs/overview' render={() => <Overview />} />
      <Route
        exact
        path='/admin/tariffs/time-addition/:tariffId'
        render={({ match }) => (
          <TopBar progress={100} title={t('TIME_ADDITION_SETTINGS') as string}>
            <TimeAddition tariffId={match.params.tariffId} />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/admin/tariffs/pause/:tariffId'
        render={({ match }) => (
          <TopBar progress={100} title={t('PAUSE_SETTINGS') as string}>
            <PauseSettings tariffId={match.params.tariffId} />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/admin/tariff/settings/:tariffId'
        render={({ match }) => (
          <TopBar progress={100} title={t('CREATE_TARIFFS') as string}>
            <TariffSettings tariffId={match.params.tariffId} />
          </TopBar>
        )}
      />
    </>
  );
};
