import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { createGlobalState } from 'react-hooks-global-state';
import Modal from 'react-modal';
import { Router } from 'react-router-dom';
import { of, timer } from 'rxjs';
import { catchError, repeat, retry, switchMap } from 'rxjs/operators';
import './App.css';
import social from './apis/social';
import history from './history';
import EmployeeAuth from './services/EmployeeAuth';
import EmployeeCreation from './services/EmployeeCreation';
import EmployeeReadEmployment from './services/EmployeeReadEmployment';
import EmployeeReadTariff from './services/EmployeeReadTariff';
import EmployeeViews from './services/EmployeeViews';
import Facility from './services/Facility';
import FacilityAuth from './services/FacilityAuth/index';
import FacilityOnboarding from './services/FacilityOnboarding/index';
import HomePage from './services/HomePage';
import Layout from './services/Layout';
import Legal from './services/Legal';
import PaymentModal from './services/Payment/containers/PaymentModal';
import TariffCreation from './services/TariffCreation';

export const { useGlobalState } = createGlobalState<{
  canWrite: false | true;
}>({ canWrite: false });

Modal.setAppElement('#root');

if (window.location.href.includes('https://app.kidig-online.de')) {
  console.log = function () {};
  console.warn = () => {};
}

function App() {
  const [userStatus, setUserStatus] = useState<false | 'EMPLOYEE' | 'ADMIN'>();
  const [, setCanWrite] = useGlobalState('canWrite');

  useEffect(() => {
    timer(0, 5000)
      .pipe(
        switchMap(() => social.get('authentication')),
        retry(3), // retry 3 times before bubbling an error
        catchError((e) => of(e)),
        repeat(),
      )
      .subscribe(
        (res) => {
          setUserStatus(res?.user?.role ?? false);

          setCanWrite(res?.user?.authorization ?? false);
        },
        () => {
          setUserStatus(false);
        },
      );
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'rgba(30, 165, 252, 0.04)',
        minHeight: '100vh',
      }}
    >
      <Router history={history}>
        <Layout>
          <PaymentModal />

          <EmployeeViews />
          <EmployeeReadEmployment />
          <EmployeeReadTariff />

          {userStatus === 'ADMIN' && (
            <>
              <FacilityOnboarding />
              <Facility />
              <EmployeeCreation />
              <TariffCreation />
            </>
          )}

          <HomePage />
          <FacilityAuth />
          <EmployeeAuth />
          <Legal />

          {/* <Route render={() => <NotFoundPage />} /> */}
        </Layout>
      </Router>
    </div>
  );
}

export default App;
