import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import DayView from './containers/DayView';
import MonthView from './containers/MonthView';
import WeekView from './containers/WeekView';
import YearView from './containers/YearView';
import PersonalSettings from './containers/PersonalSettings';
import './containers/DatePickerStyling.css';
import { useTranslation } from 'react-i18next';
import TopBar from '../Layout/containers/TopBar';
import CallInSick from './containers/CallInSickComponent';

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Route
        exact
        path='/employee/:employeeId/dashboard/:date?'
        render={({ match }) => <Dashboard defaultDate={match.params.date} />}
      />
      <Route
        path='/employee/:employeeId/year'
        render={() => (
          <TopBar
            progress={100}
            title={t('YEAR_STATS') as string}
            navigateBackTo='/employee/employeeId/dashboard'
          >
            <YearView />
          </TopBar>
        )}
      />
      <Route
        path='/employee/:employeeId/week'
        render={() => (
          <TopBar
            progress={100}
            title={t('WEEK_STATS') as string}
            navigateBackTo='/employee/employeeId/dashboard'
          >
            <WeekView />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/employee/:employeeId/personal-settings/:userId?'
        render={({ match }) => (
          <TopBar
            progress={100}
            title={t('PERSONAL_SETTINGS') as string}
            navigateBackTo='/employee/employeeId/dashboard'
          >
            <PersonalSettings userId={match.params.employeeId} />
          </TopBar>
        )}
      />
      <Route
        path='/employee/:employeeId/call-in-sick/:userId?'
        render={({ match }) => (
          <TopBar
            progress={100}
            title={t('CALL_IN_SICK') as string}
            navigateBackTo='/employee/employeeId/dashboard'
          >
            <CallInSick employeeId={match.params.employeeId} />
          </TopBar>
        )}
      />
      <Route
        path='/employee/:employeeId/month'
        render={() => (
          <TopBar
            progress={100}
            title={t('MONTH_STATS') as string}
            navigateBackTo='/employee/employeeId/dashboard'
          >
            <MonthView />
          </TopBar>
        )}
      />
      <Route
        path='/employee/:employeeId/day'
        render={() => (
          <TopBar
            progress={100}
            title={t('TODAYS_STATS') as string}
            navigateBackTo='/employee/employeeId/dashboard'
          >
            <DayView />
          </TopBar>
        )}
      />
    </>
  );
};
