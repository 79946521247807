import React from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import './fonts/Nunito-VariableFont_wght-normal';
import './fonts/Nunito-Bold-normal';
import './fonts/Nunito-Regular-normal';
import TimeReport from '../../utility/TimeReport';
import ReportHeader from './model/ReportHeader';
import WeekTableLastPos, { IWeekTableLastPos } from './model/WeekTableLastPos';
import generateHeaderReport from './utils/generateHeaderReport';
import generateDetailedWeekTable from './utils/generateDetailedWeekTable';
import generateFooterReport from './utils/generateFooterReport';
import ReportFooter from './model/ReportFooter';
import careTimeIcon from './utils/careTimeIcon';
import disposalTimeIcon from './utils/disposalTimeIcon';
import irregularTimeIcon from './utils/irregularTimeIcon';
import ITranslationLabel from './model/TranslationLabel';
import logoKidigBase54Data from './utils/logoKidigBase64Data';

const generateMonthReportDetailed = async (
  timeReport: TimeReport,
  lang: string,
  translateObj: ITranslationLabel,
) => {
  const doc = new jsPDF('p', 'px', 'a4');
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  const headerData = new ReportHeader(timeReport);
  const bodyStartY = generateHeaderReport(doc, headerData, translateObj);

  // BODY ==============================================================================
  // ===================================================================================
  let bodyRes: IWeekTableLastPos = new WeekTableLastPos();

  if (timeReport.weeks.length === 1) {
    const week = timeReport.weeks[0];
    bodyRes = generateDetailedWeekTable(doc, week, lang, translateObj, bodyStartY);
  } else {
    for (let weekIndex = 0; weekIndex < timeReport.weeks.length; weekIndex++) {
      const week = timeReport.weeks[weekIndex];
      if (weekIndex === timeReport.weeks.length - 1) {
        bodyRes = generateDetailedWeekTable(doc, week, lang, translateObj);
      } else if (weekIndex === 0) {
        generateDetailedWeekTable(doc, week, lang, translateObj, bodyStartY);
      } else {
        generateDetailedWeekTable(doc, week, lang, translateObj);
      }
    }
  }
  const { xPos, yPos, nextY } = bodyRes;

  // MONTH OVERVIEW =================================================================
  // add page for month overview
  doc.addPage();
  doc.setFillColor(5, 206, 145);
  doc.rect(0, 0, pageWidth, 40, 'F');

  // const sigOnCurrentPageCalculate = nextY + 150;

  // if (sigOnCurrentPageCalculate > pageHeight) {
  //   doc.addPage();
  // }

  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.setFont('Nunito-Bold');
  doc.text(translateObj.REPORT_MONTH_OVERVIEW, pageWidth / 2, 22, { align: 'center' });

  let nextYResult = 0;
  autoTable(doc, {
    alternateRowStyles: {
      fillColor: '#FFF',
    },
    body: [
      [
        {
          styles: {
            fillColor: '#886CE4',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_CHILD_ACTUAL,
        },
        {
          styles: {
            fillColor: '#886CE4',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_CHILD_TARGET,
        },
        {
          styles: {
            fillColor: '#886CE4',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_CHILD_BALANCE,
        },
      ],
      [
        {
          title: timeReport.actualTimeDetailed.careTime,
        },
        {
          title: timeReport.targetTimeDetailed.careTime,
        },
        {
          title: timeReport.balanceDetailed.careTime,
        },
      ],
      [],
      [
        {
          styles: {
            fillColor: '#16C60C',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_DISPOSAL_ACTUAL,
        },
        {
          styles: {
            fillColor: '#16C60C',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_DISPOSAL_TARGET,
        },
        {
          styles: {
            fillColor: '#16C60C',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_DISPOSAL_BALANCE,
        },
      ],
      [
        {
          title: timeReport.actualTimeDetailed.disposalTime,
        },
        {
          title: timeReport.targetTimeDetailed.disposalTime,
        },
        {
          title: timeReport.balanceDetailed.disposalTime,
        },
      ],
      [],
      [
        {
          styles: {
            fillColor: '#F7630C',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_IRREGULAR_DISPOSAL_ACTUAL,
        },
        {
          styles: {
            fillColor: '#F7630C',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_DISPOSAL_TARGET,
        },
        {
          styles: {
            fillColor: '#F7630C',
            textColor: '#FFF',
          },
          title: translateObj.REPORT_DISPOSAL_BALANCE,
        },
      ],
      [
        {
          title: timeReport.actualTimeDetailed.irregularDisposalTime,
        },
        {
          title: timeReport.targetTimeDetailed.irregularDisposalTime,
        },
        {
          title: timeReport.balanceDetailed.irregularDisposalTime,
        },
      ],
      [],
      [
        {
          title: translateObj.REPORT_TOTAL_ACTUAL,
        },
        {
          title: translateObj.REPORT_TOTAL_TARGET,
        },
        {
          title: translateObj.REPORT_TOTAL_BALANCE,
        },
      ],
      [
        {
          title: timeReport.actualTimeTotal,
        },
        {
          title: timeReport.targetTimeTotal,
        },
        {
          title: timeReport.balanceTotal,
        },
      ],
    ],
    didDrawPage(data: any) {
      const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
      nextYResult = data.cursor.y + lineHeight;
    },
    margin: 5,
    startY: 40 + 10,
    styles: {
      halign: 'center',
      lineColor: '#000',
      lineWidth: 0.5,
    },
  });

  const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;

  doc.setFont('Nunito-Regular');
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(9);
  doc.text('**', 10, nextYResult + 7);
  doc.addImage(careTimeIcon, 20, nextYResult, lineHeight, lineHeight);
  doc.text(translateObj.REPORT_CHILD, 10 + 20 + lineHeight, nextYResult + 7);

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(9);
  doc.addImage(disposalTimeIcon, 20 + 100, nextYResult, lineHeight, lineHeight);
  doc.text(translateObj.REPORT_DISPOSAL, 10 + 20 + 100 + lineHeight, nextYResult + 7);

  doc.setTextColor(0, 0, 0);
  doc.setFontSize(9);
  doc.addImage(irregularTimeIcon, 20 + 200, nextYResult, lineHeight, lineHeight);
  doc.text(translateObj.REPORT_IRREGULAR_DISPOSAL, 10 + 20 + 200 + lineHeight, nextYResult + 7);

  doc.text(
    doc.splitTextToSize(`**${translateObj.REPORT_KEYWORDS_LIST}`, doc.internal.pageSize.width - 10),
    10,
    nextYResult + 7 + 20,
  );

  // SIGNATURE ======================================================================
  const footerData = new ReportFooter(timeReport);
  doc.setFontSize(14);
  doc.addImage(logoKidigBase54Data, 'PNG', 15, 10, 60, 25);
  generateFooterReport(doc, pageHeight, pageWidth, footerData, translateObj);

  doc.save(`${headerData.period}_MONTH-DETAILED.pdf`);
};

export default generateMonthReportDetailed;
