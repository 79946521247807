import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Availability from './container/Availability';
import CreateEmployee from './container/CreateEmployee';
import WorkingHours from './container/WorkingHours';
import EmployeeEmail from './container/EmployeeEmail';
import TopBar from '../Layout/containers/TopBar';
import EmployeeList from './container/EmployeeList';
import OverviewData from './container/OverviewData';
import WeeklyWorkingTime from './container/WeeklyWorkingTime';
import EmploymentContract from './container/EmploymentContract';

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Route
        exact
        path='/admin/employee/create-employee/:employeeId'
        render={({ match }) => (
          <TopBar
            progress={50}
            title={t('CREATE_EMPLOYEES') as string}
            // navigateBackTo="/admin/employees-overview"
          >
            <CreateEmployee employeeId={match.params.employeeId} key={match.params.employeeId} />
          </TopBar>
        )}
      />

      <Route
        exact
        path='/admin/employee/working-hours/:employeeId'
        render={({ match }) => (
          <WorkingHours employeeId={match.params.employeeId} key={match.params.employeeId} />
        )}
      />
      <Route
        exact
        path='/admin/employee/availability/:employeeId'
        render={({ match }) => (
          <Availability employeeId={match.params.employeeId} key={match.params.employeeId} />
        )}
      />
      <Route
        exact
        path='/admin/employee/email/:employeeId'
        render={({ match }) => (
          <EmployeeEmail employeeId={match.params.employeeId} key={match.params.employeeId} />
        )}
      />
      <Route
        exact
        path='/admin/employee-data/:employeeId'
        render={({ match }) => (
          <TopBar
            progress={100}
            title={t('EMPLOYEE_DATA') as string}
            // navigateBackTo="/admin/employees-overview"
          >
            <OverviewData employeeId={match.params.employeeId} />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/admin/create-employee-contract/weekly-hours/:employmentId'
        render={({ match }) => (
          <TopBar
            progress={100}
            title={t('WEEKLY_WORKING_HOURS') as string}
            // navigateBackTo="/admin/create-employee-contract/overtime"
          >
            <WeeklyWorkingTime employmentId={match.params.employmentId} />
          </TopBar>
        )}
      />

      <Route
        exact
        path='/admin/create-employee-contract/contract/:employmentId'
        render={({ match }) => (
          <TopBar
            progress={100}
            title={t('CREATE_EMPLOYMENT') as string}
            // navigateBackTo={undefined}
          >
            <EmploymentContract employmentId={match.params.employmentId} />
          </TopBar>
        )}
      />
      <Route path='/admin/employees-overview' render={() => <EmployeeList />} />
    </>
  );
};
