import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FacilityAttributes } from '../../../apis/attributes';

export type FacilityCardProps = {
  facility: FacilityAttributes | null;
};

export const FacilityCard: React.FC<FacilityCardProps> = ({ facility }) => {
  const { t } = useTranslation();
  return (
    <Card className='w-100 shadow' style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title className='text-center'>{t('FACILITY_DETAILS') as string}</Card.Title>
        <Row className='mt-5'>
          <Col md={4} xs={6}>
            <p className='text-center '>
              <strong className=' text-primary'>{t('NAME_OF_ORG') as string}</strong>
              <br />
              <small>{facility?.name}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('TOWN_CITY') as string}</strong>

              <br />
              <small>{facility?.town}</small>
            </p>
          </Col>
          {/* <Col md={4} xs={6}>
          <p className="text-center">

            <strong className=" text-primary">Land</strong>
            <br />
            <small>
              {facility?.country}
            </small>
          </p>
        </Col> */}
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('STATE') as string}</strong>
              <br />
              <small>{facility?.federalState}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('STREET_HOUSE_NUMB') as string}</strong>
              <br />
              <small>{facility?.streetHouseNumb}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center'>
              <strong className=' text-primary'>{t('ZIP_CODE') as string}</strong>

              <br />
              <small>{facility?.zipCode}</small>
            </p>
          </Col>
          <Col md={4} xs={6}>
            <p className='text-center '>
              <strong className=' text-primary'>{t('DEFINE_GROUP_NUMBER') as string}</strong>
              <br />
              <small>{facility?.numberGroups}</small>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FacilityCard;
