import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import social from '../../../apis/social';

type PaymentDeclinedModalProps = {
  show: boolean;
  handleClose: any;
  facilityId: string;
  stripeCustomerId: string;
};

const PaymentDeclinedModal: React.FC<PaymentDeclinedModalProps> = ({
  facilityId,
  stripeCustomerId,
  show,
  handleClose,
}) => {
  const { t } = useTranslation();
  const goToPortal = async () => {
    try {
      const { url }: any = (await social.service('facility').get(facilityId, {
        query: { customerPortal: true, stripeCustomerId },
      })) as any;

      window.location.href = url as any;
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='contained-modal-title-hcenter'>
          {' '}
          {t('PAYMENT_FAILED') as string}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3 py-4'>{t('UPDATE_PAYMENT_METHOD_OR_FUNDS') as string}</Modal.Body>
      <Modal.Footer className='d-flex justif-content-center'>
        <Button variant='outline-dark' onClick={handleClose}>
          {t('CANCEL') as string}
        </Button>
        <Button
          className='px-5'
          variant='primary'
          onClick={async () => {
            goToPortal();
            handleClose();
          }}
        >
          {t('UPDATE') as string}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentDeclinedModal;
