import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type ExtendedOverviewSectionProps = {
  labelLeft: string;
  labelLeftVal: string;
  labelRight: string;
  labelRightVal: string;
  facilityDayType: string;
  isWorkDay: boolean;
  isFilledOut: boolean;
  isSick: boolean;
};

export const getDayTypeBadge = (facilityDayType: string, t: any, isSick = false) => {
  if (isSick){
      return <Badge variant='danger'>{t('DAY_VIEW_SICK_NOTIFICATION') as string}</Badge>;
  }
  switch (facilityDayType) {
    case 'FACILITY_WORKDAY':
      return <Badge variant='info'>{t('FACILITY_WORK_DAY') as string}</Badge>;
    case 'FACILITY_CLOSED_DAY':
      return <Badge variant='secondary'>{t('FACILITY_CLOSED_DAY') as string}</Badge>;
    case 'FACILITY_HOLIDAY':
      return <Badge variant='secondary'>{t('FACILITY_HOLIDAY') as string}</Badge>;
    case 'FEIERTAG':
      return <Badge variant='warning'>{t('FEIERTAG') as string}</Badge>;
    case 'USER_PROVIDED_FEIERTAG':
      return <Badge variant='warning'>{t('USER_PROVIDED_FEIERTAG') as string}</Badge>;
    default:
      return <Badge variant='danger'>{t('DAY_TYPE_UNKNOWN') as string}</Badge>;
  }
};

export const filledOutBadge = (isFilledOut: boolean, isWorkDay: boolean, t: any, isSick = false) => {
  if (isWorkDay) {
    if (isSick){
      return <Badge variant='danger'>{t('DAY_VIEW_SICK_NOTIFICATION') as string}</Badge>;
    }
    else if (isFilledOut) {
      return <Badge variant='primary'>{t('IS_FILLED_OUT') as string}</Badge>;
    }
    return <Badge variant='warning'>{t('IS_NOT_FILLED_OUT') as string}</Badge>;
  }
  return <Badge variant='secondary'>{t('IS_FILLED_OUT') as string}</Badge>;
};

export const ExtendedOverviewSection: React.FC<ExtendedOverviewSectionProps> = ({
  labelLeft,
  labelLeftVal,
  labelRight,
  labelRightVal,
  facilityDayType = 'UNKNOWN',
  isWorkDay = true,
  isFilledOut = false,
  isSick = false,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col xs={4}>{getDayTypeBadge(facilityDayType, t)}</Col>
        <Col xs={4}>{false && <Badge variant='primary'>{t('YOUR_WORK_DAY') as string}</Badge>}</Col>
        <Col xs={4}>{(isFilledOut || isWorkDay) && filledOutBadge(isFilledOut, isWorkDay, t, isSick)}</Col>
      </Row>
      <Row
        className='border py-3 px-2'
        style={{
          backgroundColor: 'rgba(28,163,250,0.05)',
          borderRadius: '8px',
        }}
      >
        <Col xs={6}>
          <div className='h-100 w-100 text-dark'>
            <p className='mb-1 text-muted'>{labelLeft}</p>
            <strong>
              {labelLeftVal}
              <small>h</small>
            </strong>
          </div>
        </Col>
        <Col xs={6}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{labelRight}</p>
            <strong style={{ color: '#ED4B9E' }}>
              {labelRightVal}
              <small>h</small>
            </strong>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ExtendedOverviewSection;
