import React, { useCallback } from 'react';
import { Button, Col, Container, Row, Form as ReactForm } from 'react-bootstrap';
import { useHistory, generatePath } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { EmploymentAttributes } from '../../../apis/attributes';
import social from '../../../apis/social';
import DateInputCustom from '../../Facility/containers/DateInputCustom';
import InfoModal from '../../Facility/containers/InfoModal';

export const WeeklyWorkingTime: React.FC<{
  employmentId: string;
}> = ({ employmentId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [employment] = useObservable<EmploymentAttributes | null>(
    () => social.service('employment').watch().get(employmentId) as any,
    [employmentId],
  );

  const navigateToTariff = useCallback(
    (pathName: string) => {
      history.push({
        pathname: generatePath(`${pathName}`),
      });
    },
    [employmentId],
  );

  const updateDB = useCallback(
    async (data: {
      careWorkingPerWeek: number;
      disposalIrregularPerWeek: number;
      disposalRegularPerWeek: number;
      limitHoursPerDay: string;
      limitHoursPerMonth: number;
      dailyLimitActive: boolean;
      monthlyLimitsActive: boolean;
    }) => {
      try {
        await social.service('employment').patch(employmentId, {
          ...data,
          employeeId: employment?.employeeId,
          finalSubmission: true,
          start: employment?.start,
        });
        navigateToTariff('/admin/employees-overview');
      } catch (error) {
        alert('Error: please reload the page');
      }
    },
    [employment?.employeeId],
  );

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          careWorkingPerWeek: employment?.careWorkingPerWeek ?? 0,
          dailyLimitActive: employment?.dailyLimitActive ?? false,
          disposalIrregularPerWeek: employment?.disposalIrregularPerWeek ?? 0,
          disposalRegularPerWeek: employment?.disposalRegularPerWeek ?? 0,
          limitHoursPerDay: employment?.limitHoursPerDay ?? 'PT0H',
          limitHoursPerMonth: employment?.limitHoursPerMonth ?? 0,
          monthlyLimitsActive: employment?.monthlyLimitsActive ?? false,
        }}
        validationSchema={Yup.object({
          careWorkingPerWeek: Yup.number().min(0.0, 'must be at least 0').required(t('REQUIRED')),
          dailyLimitActive: Yup.boolean().required(t('REQUIRED')),
          disposalIrregularPerWeek: Yup.number()
            .min(0.0, 'must be at least 0')
            .required(t('REQUIRED')),
          disposalRegularPerWeek: Yup.number()
            .min(0.0, 'must be at least 0')
            .required(t('REQUIRED')),
          limitHoursPerDay: Yup.string().required(t('REQUIRED')),
          limitHoursPerMonth: Yup.number()
            .integer('should be an integer')
            .min(1, 'should be greater than 0')
            .required(t('REQUIRED')),
          monthlyLimitsActive: Yup.boolean().required(t('REQUIRED')),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            updateDB(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {(props: FormikValues) => (
          <Form>
            <p className='mt-5 mb-1'>
              <strong>{t('WEEKLY_HOURS_CARE_TIME') as string}</strong>
            </p>
            <input
              className='w-100 '
              placeholder=''
              name='careWorkingPerWeek'
              defaultValue={employment?.careWorkingPerWeek}
              type='number'
              step='0.01'
              min='0.0'
              max='100.0'
              onChange={props.handleChange}
            />
            {props?.errors?.careWorkingPerWeek && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.careWorkingPerWeek}{' '}
              </p>
            )}
            <div style={{ height: '10px' }} />
            <p className='mt-4 mb-1'>
              <strong>{t('WEEKLY_REGULAR_DISPOSAL') as string}</strong>
            </p>
            <input
              className='w-100 '
              placeholder=''
              name='disposalRegularPerWeek'
              defaultValue={employment?.disposalRegularPerWeek}
              type='number'
              step='0.01'
              min='0.0'
              max='100.0'
              onChange={props.handleChange}
            />
            {props?.errors?.disposalRegularPerWeek && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.disposalRegularPerWeek}{' '}
              </p>
            )}

            <p className='mt-4 mb-1'>
              <strong>{t('WEEKLY_IRREGULAR_DISPOSAL') as string}</strong>
            </p>
            <input
              className='w-100 '
              placeholder=''
              name='disposalIrregularPerWeek'
              defaultValue={employment?.disposalIrregularPerWeek}
              type='number'
              step='0.01'
              min='0.0'
              max='100.0'
              onChange={props.handleChange}
            />
            {props?.errors?.disposalIrregularPerWeek && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.disposalIrregularPerWeek}{' '}
              </p>
            )}

            <div style={{ height: '30px' }} />

            {employment && (
              <>
                <Row>
                  <Col xs={4}>
                    <p className='m-0'>{t('DAILY_LIMIT_ACTIVE') as string}</p>
                  </Col>
                  <Col xs={5}>
                    <InfoModal content={t('LIMIT_DAY') as string} />
                  </Col>
                  <Col>
                    <ReactForm.Check
                      type='switch'
                      id='custom-switch-4'
                      name='dailyLimitActive'
                      defaultChecked={employment?.dailyLimitActive}
                      onChange={(e) => {
                        props.setFieldValue('dailyLimitActive', e.target.checked);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

            <p className='mb-1 mt-2'>
              {' '}
              <strong>{t('MAX_HOURS_DAY') as string}</strong>{' '}
            </p>

            {employment?.limitHoursPerDay && (
              <DateInputCustom
                defaultStrDuration={employment?.limitHoursPerDay}
                onChange={(e: any) => {
                  props.setFieldValue('limitHoursPerDay', e);
                }}
              />
            )}
            {props?.errors?.limitHoursPerDay && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.limitHoursPerDay}{' '}
              </p>
            )}

            <div style={{ height: '25px' }} />

            {employment && (
              <>
                <Row>
                  <Col xs={4}>
                    <p className='m-0'>{t('MONTHLY_LIMIT_ACTIVE') as string}</p>
                  </Col>
                  <Col xs={5}>
                    <InfoModal content={t('LIMIT_MONTH') as string} />
                  </Col>
                  <Col>
                    <ReactForm.Check
                      type='switch'
                      id='custom-switch-5'
                      name='monthlyLimitsActive'
                      defaultChecked={employment?.monthlyLimitsActive}
                      onChange={(e) => {
                        props.setFieldValue('monthlyLimitsActive', e.target.checked);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            <p className='mb-1 mt-1'>
              {' '}
              <strong>{t('MAX_HOURS_MONTH') as string}</strong>{' '}
            </p>
            <input
              name='limitHoursPerMonth'
              className='w-100 '
              defaultValue={employment?.limitHoursPerMonth || ''}
              type='number'
              min='0'
              max='1000'
              placeholder=''
              onChange={props.handleChange}
            />
            {props?.errors?.limitHoursPerMonth && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {t('LIMIT_HOURS_MONTH_ERROR') as string}{' '}
              </p>
            )}

            <div style={{ height: '110px' }} />

            <Button block className='py-2' type='submit'>
              {t('NEXT') as string}
            </Button>

            <div style={{ height: '110px' }} />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default WeeklyWorkingTime;
