import React, { useCallback, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { DailyIntervalType, TimeInterval } from '../../TimeInterval/containers/Availability';
import CommentImg from '../images/comment.png';
import SaveNoteModal, { SaveNoteModalAttributes } from './CommentModalClosedDays';

export type DayComponentProps = {
  key: string;
  disabled: boolean;
  timeIntervals: TimeInterval[];
  year: number;
  onSubmit(timeIntervals: TimeInterval[]): void;
};

const dummyInterval = (id: string): TimeInterval => ({
  endDate: undefined,
  id,
  startDate: undefined,
  type: DailyIntervalType.CARE_TIME,
});

export const AdditionalFeastDaysLogic: React.FC<DayComponentProps> = ({
  timeIntervals,
  onSubmit,
  disabled,
  year,
}) => {
  const { t: t18 } = useTranslation();
  const [alert, setAlert] = useState<undefined | string>(undefined);

  const [localTimeSlots, setLocalTimeSlots] = useState<TimeInterval[]>(timeIntervals);
  const [forceRerender] = useState(false);

  const [toggleModal, setToggleModal] = useState<SaveNoteModalAttributes | undefined>(undefined);

  const showAlert = useCallback(
    (msg: string) => {
      setAlert(msg);
      setTimeout(() => {
        setAlert(undefined);
      }, 3000);
    },
    [alert],
  );

  const addFreeSlot = () => {
    setLocalTimeSlots([...localTimeSlots, dummyInterval(uuid())]);
  };

  const handleSubmit = useCallback(() => {
    // check if all info is valid

    const lastItem = localTimeSlots[localTimeSlots.length - 1];

    if (localTimeSlots.length === 1 && !lastItem.startDate) {
      setLocalTimeSlots(localTimeSlots);

      // callback to store new state
      onSubmit(localTimeSlots);
      return;
    }

    if (!lastItem.startDate) {
      showAlert('Please, finish defining your time interval');
      return;
    }
    // sort by start date
    const duplicateTimeSlots = localTimeSlots;

    const sortedTimes = duplicateTimeSlots.sort((a, b) => {
      if (!a.startDate || !b.startDate) {
        return 0;
      }

      return a.startDate.getTime() - b.startDate.getTime();
    });

    setLocalTimeSlots(sortedTimes);

    // callback to store new state
    onSubmit(sortedTimes);

    // add time slot
    addFreeSlot();
  }, [localTimeSlots]);

  const uniqueKeys = React.useMemo(() => localTimeSlots.map(() => uuid()), [localTimeSlots]);

  return (
    <div>
      <Alert variant='danger' hidden={!alert}>
        {alert}
      </Alert>

      {localTimeSlots.map((interval, idx) => (
        <div key={uniqueKeys[idx]}>
          <Row>
            <Col className='d-flex justify-content-center' xs={8} md={3}>
              <div className='w-100'>
                <p className='m-0 text-start'>{t18('DATE') as string}</p>
                <DatePicker
                  className='text-center w-100'
                  selected={interval?.startDate}
                  onChange={(date: Date) => {
                    // add 2 hours
                    const offsetedDate = new Date(date.getTime() + 2 * 60 * 60 * 1000);
                    const copyIntervals = [...localTimeSlots];
                    const updatedInterval = {
                      ...localTimeSlots[localTimeSlots.length - 1],
                    };
                    updatedInterval.startDate = offsetedDate;
                    copyIntervals[localTimeSlots.length - 1] = updatedInterval;

                    setLocalTimeSlots(copyIntervals);
                  }}
                  dateFormat='dd/MM/yyyy'
                  disabled={localTimeSlots.length > idx + 1 || disabled}
                  minDate={new Date(`01/01/${year}`)}
                  maxDate={new Date(`12/31/${year}`)}
                />
              </div>
            </Col>

            <Col className='d-flex align-items-center p-0' xs={4} md={7}>
              <div className='pb-1 pl-2 pt-1 '>
                <p className='text-start m-0'>‏</p>
                <Button
                  className='mb-1 mr-4 ml-2'
                  style={{ height: '27px', width: '27px' }}
                  variant='outline-light px-0 py-0 mx-0'
                  onClick={() => {
                    setToggleModal({
                      allTimeIntervals: localTimeSlots,
                      idxTimeInterval: idx,
                      isDisabled: localTimeSlots.length > idx + 1 || disabled,
                      setTimeIntervals: setLocalTimeSlots,
                      setToggleModal,
                      toggleModal,
                    });
                  }}
                >
                  <img style={{ height: '30px', width: '30px' }} alt='' src={CommentImg} />
                </Button>
                <Button
                  disabled={disabled}
                  variant='outline-dark px-2 py-0 ml-2'
                  onClick={() => {
                    if (localTimeSlots.length > 1) {
                      const copyIntervals = localTimeSlots.filter((interv) => {
                        const bool = interv.startDate?.getTime() !== interval.startDate?.getTime();

                        return bool;
                      });
                      setLocalTimeSlots(copyIntervals);
                    } else {
                      const blankInterval = localTimeSlots[0];
                      blankInterval.startDate = undefined;
                      blankInterval.id = uuid();
                      blankInterval.type = undefined;
                      blankInterval.note = undefined;
                      setLocalTimeSlots([blankInterval]);
                    }
                  }}
                >
                  X
                </Button>
              </div>
            </Col>

            <Col className='d-md-none' xs={7} />

            <Col
              className='d-flex justify-content-end justify-content-md-between align-items-end '
              xs={5}
              md={2}
            >
              {localTimeSlots.length - 1 === idx && (
                <Button
                  disabled={disabled}
                  className='mt-4'
                  onClick={handleSubmit}
                  style={{
                    height: '37px',
                    paddingBottom: '0',
                    paddingTop: '0',
                  }}
                >
                  {t18('SAVE_INTERVAL') as string}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      ))}
      {!!toggleModal && <SaveNoteModal {...toggleModal} />}
      {forceRerender && <div />}
    </div>
  );
};

export default AdditionalFeastDaysLogic;
