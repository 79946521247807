import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SickNote } from '../../../core/SickNote';
import { SickNoteCollection } from '../../../core/SickNoteCollection';

const getNoteLabelOrEmpty = (item: SickNote | undefined, t18: (key: string) => string): string => {
  if (item === undefined || item.note?.length === 0) {
    return '';
  }
  return t18('INTERVAL_NOTE');
};

interface SickEntryProps {
  disabled: boolean;
  sickNotes: SickNoteCollection[];
  onSickNotesChange: (sickNotes: SickNoteCollection[]) => void;
}

const FacilitySickEntryEmployee: React.FC<SickEntryProps> = ({ sickNotes }) => {
  const { t: t18 } = useTranslation();

  const items: SickNote[] = sickNotes.map((sn) => sn.sickNotes).flat();

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div key={index}>
            <Row className=''>
              <Col xs={6}>
                <label style={{ marginBottom: '8px' }}>{t18('FROM')}: </label>
                <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                  {' '}
                  {item.start.toFormat('dd.MM.yy')}
                </span>
              </Col>
              <Col xs={6}>
                <label style={{ marginBottom: '8px' }}>{t18('TO')}:</label>
                <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                  {' '}
                  {item.end.toFormat('dd.MM.yy')}
                </span>
              </Col>
            </Row>

            <Row style={{ marginTop: '16px' }}>
              {item.sickChild && (
                <Col xs={6}>
                  <label className='pl-md-3' style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    ☑ {t18('SICK_CHILD')}
                  </label>
                </Col>
              )}

              {item.followUp && (
                <Col xs={6}>
                  <label className='pl-md-3' style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    ☑ {t18('FOLLOW_UP')}
                  </label>
                </Col>
              )}

              {item.doctorsNote && (
                <Col xs={6}>
                  <label className='pl-md-3' style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    ☑ {t18('DOCTORS_NOTE')}
                  </label>
                </Col>
              )}

              {item.presentDayOne && (
                <Col xs={6}>
                  <label className='pl-md-3' style={{ fontWeight: 'regular', fontSize: '1em' }}>
                    ☑ {t18('PRESENT_DAY_ONE')}
                  </label>
                </Col>
              )}
            </Row>
            {item.note && (
              <Row style={{ marginTop: '16px' }}>
                <Col>
                  <label style={{ fontWeight: 'bold', fontSize: '1em' }}>
                    {getNoteLabelOrEmpty(item, t18)}
                  </label>
                  <div className='pl-md-3'> • {item.note}</div>
                </Col>
              </Row>
            )}
            <br />
            <hr />
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default FacilitySickEntryEmployee;
