import TimeReport from '../../../utility/TimeReport';

export interface IReportHeader {
  nameAdministration: string;
  firstNameEmployee: string;
  lastNameEmployee: string;
  vacationDays: number;
  vacationDaysLeft: number;
  numberWorkdaysInPeriod: number;
  specialVacationDays: number;
  specialVacationDaysLeft: number;
  regenerationDays: number;
  regenerationDaysLeft: number;
  conversionDays: number;
  conversionDaysLeft: number;
  printDate: string;
  period: string;
}

class ReportHeader implements IReportHeader {
  nameAdministration: string;

  firstNameEmployee: string;

  lastNameEmployee: string;

  vacationDays: number;

  vacationDaysLeft: number;

  numberWorkdaysInPeriod: number;

  specialVacationDays: number;

  specialVacationDaysLeft: number;

  regenerationDays: number;

  regenerationDaysLeft: number;

  conversionDays: number;

  conversionDaysLeft: number;

  printDate: string;

  period: string;

  constructor(timeReport: TimeReport) {
    this.nameAdministration = timeReport.nameAdministration;
    this.firstNameEmployee = timeReport.firstNameEmployee;
    this.lastNameEmployee = timeReport.lastNameEmployee;
    this.vacationDays = timeReport.vacationDays;
    this.vacationDaysLeft = timeReport.vacationDaysLeft;
    this.numberWorkdaysInPeriod = timeReport.numberWorkdaysInPeriod;
    this.specialVacationDays = timeReport.specialVacationDays;
    this.specialVacationDaysLeft = timeReport.specialVacationDaysLeft;
    this.regenerationDays = timeReport.regenerationDays;
    this.regenerationDaysLeft = timeReport.regenerationDaysLeft;
    this.conversionDays = timeReport.conversionDays;
    this.conversionDaysLeft = timeReport.conversionDaysLeft;
    this.printDate = timeReport.printDate;
    this.period = timeReport.startDate.substring(0, 7);
  }
}

export default ReportHeader;
