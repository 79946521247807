export interface IWeekTableLastPos {
  xPos: number;
  yPos: number;
  nextY: number;
}

class WeekTableLastPos implements IWeekTableLastPos {
  xPos: number;

  yPos: number;

  nextY: number;

  constructor() {
    this.xPos = 0;
    this.yPos = 0;
    this.nextY = 0;
  }
}

export default WeekTableLastPos;
