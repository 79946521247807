import React from 'react';
import { Container } from 'react-bootstrap';
import 'flatpickr/dist/themes/material_green.css';

export enum DailyIntervalType {
  CARE_TIME = '🟣 care time',
  DISPOSAL_TIME = '🟢 disposal time',
  IRREGULAR_DISPOSAL_TIME = '🟠 irregular disposal time',
}

export type TimeInterval = {
  id: string;
  startDate?: Date;
  endDate?: Date;
  percentage?: number;
  type?: DailyIntervalType;
  note?: string;
};

export type TimeSlot = {
  dayType: string;
  freeIntervals: TimeInterval[];
};

export type TariffTimeInterval = {
  id?: string;
  startDate?: Date;
  endDate?: Date;
  percentage?: number;
};

export type TariffTimeSlot = {
  dayType: string;
  freeIntervals: TimeInterval[];
};

export const Availability: React.FC = () => {
  return <Container />;
};

export default Availability;
