import React from 'react';

import { Card, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { useObservable } from 'react-use-observable';
import { EmployeeAttributes } from '../../../apis/attributes';
import { UserAttributes } from '../../../apis/attributes/user';
import social from '../../../apis/social';

export type EmployeeCardProps = {
  employee: EmployeeAttributes;
};

export const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee }) => {
  const { t } = useTranslation();

  const [employeeInUser] = useObservable<UserAttributes | null>(
    () =>
      social
        .service('user')
        .watch()
        .get(employee?.userId ?? '') as any,
    [employee],
  );

  return (
    <Card className='w-100' style={{ width: '18rem' }}>
      <Card.Body>
        {/* <Card.Title className="text-center">Employee</Card.Title> */}
        {/* <Row className="mt-5"> */}
        <Row className='py-0'>
          <Col md={6} xs={6} className='py-0'>
            <p className='text-center '>
              <strong className=' text-primary'>{t('FULL_NAME') as string}</strong>
              <br />
              <small>
                {employee?.firstName} {employee?.lastName}
              </small>
            </p>
          </Col>
          <Col md={6} xs={6} className='py-0'>
            <p className='text-center'>
              <strong className=' text-primary'>{t('EMAIL_ADDRESS') as string}</strong>

              <br />
              <small>{employeeInUser?.email}</small>
            </p>
          </Col>

          <Col xs={12} className='d-flex justify-content-center'>
            <LinkContainer exact to={`/admin/employee-data/${employee?.id}`}>
              <Button>{t('VIEW_CONTRACTS') as string}</Button>
            </LinkContainer>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EmployeeCard;
