import React from 'react';
import { Route } from 'react-router';
import Login from './containers/Login';
import Signup from './containers/Signup';

export default () => (
  <>
    <Route exact path='/facility/login' render={() => <Login />} />
    <Route exact path='/facility/signup' render={() => <Signup />} />
  </>
);
