import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Form as ReactForm } from 'react-bootstrap';
import usePromise from 'react-promise';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { useObservable } from 'react-use-observable';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import { TariffAttributes } from '../../../apis/attributes';
import { social, authenticatedFacility } from '../../../apis/social';
import './TagsPackageTheme.css';
import InfoModal from '../../Facility/containers/InfoModal';

export const KeyCodes = {
  colon: 190,
  comma: 188,
  enter: 13,
  space: 32,
};

export type ReactTagsType = {
  id: string;
  text: string;
};

const daysStrings = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const options = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 0 },
];

export const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space, KeyCodes.colon];

export const ReadTariffSettings: React.FC<{ tariffId: string }> = observer(({ tariffId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { value } = usePromise(authenticatedFacility());
  const [selected, setSelected] = useState<{ label: string; value: number }[]>([]);

  const [tariff] = useObservable<TariffAttributes | null>(
    () => social.service('tariff').watch().get(tariffId) as any,
    [tariffId],
  );

  const [prevTariff] = useObservable<any | null>(
    () =>
      social
        .service('tariff')
        .watch()
        .find({
          query: {
            $limit: 1,
            $sort: { createdAt: -1 },
            facilityId: (value as any)?.user?.facility?.id,
            finalSubmission: true,
            id: {
              $ne: tariff?.id,
            },
          },
        }) as any,
    [tariffId, tariff?.id, value, (value as any)?.user],
  );

  const navigateTo = useCallback(
    (pathName: string) => {
      history.push({
        pathname: generatePath(`${pathName}/:tariffId`, {
          tariffId,
        }),
      });
    },
    [tariffId],
  );

  useEffect(() => {
    const newArr =
      tariff?.workDayDefiner?.map((day) => ({
        label: daysStrings[day],
        value: day,
      })) ?? [];
    setSelected(newArr);
  }, [tariff?.workDayDefiner]);

  const updateDB = useCallback(async () => {
    try {
      navigateTo('/read-tariff-pause');
    } catch (error) {
      alert('Error: please reload the page');
    }
  }, [tariffId]);

  const displayDate = (time: string | undefined) => {
    if (!time) return undefined;
    return moment(time).format('YYYY-MM-DD');
  };

  return (
    <Container className='pt-4 px-4 pb-4'>
      <Formik
        enableReinitialize
        initialValues={{
          averageDailyWorkingHours: tariff?.averageDailyWorkingHours ?? false,
          frameworkDutyPlan: tariff?.frameworkDutyPlan ?? false,
          startDate: tariff?.startDate ?? '',
          weeklyWorkingHoursFullyEmployed: tariff?.weeklyWorkingHoursFullyEmployed ?? 0,
          workDayDefiner: tariff?.workDayDefiner ?? [],
        }}
        validationSchema={Yup.object({
          startDate: Yup.string().required(t('REQUIRED')),
          weeklyWorkingHoursFullyEmployed: Yup.number()
            .integer('should be an integer')
            .min(1, 'should be greater than 0')
            .required(t('REQUIRED')),
          workDayDefiner: Yup.array().of(Yup.number().integer()).min(1, t('WORKDAY_WARNING')),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            updateDB();
            setSubmitting(false);
          }, 400);
        }}
      >
        {(props: FormikValues) => (
          <Form>
            <h4>{t('TARIFF_SETTINGS') as string}</h4>
            <br />
            <h5>
              {t('GENERAL_SETTINGS') as string}{' '}
              {/* <strong><InfoModal content={t('PAUSE_DEDUCTION_INFO') as string} /></strong> */}{' '}
            </h5>
            <p className='mt-4 mb-2'>
              <strong>{t('PAUSE_SETTINGS') as string}</strong>
            </p>
            {tariff && prevTariff?.data !== undefined && (
              <>
                <p className='mb-1 mt-4'>
                  {' '}
                  <strong>{t('START_DATE') as string}</strong>{' '}
                </p>
                <input
                  disabled
                  name='startDate'
                  className='w-100 '
                  type='date'
                  min={`${
                    prevTariff?.data?.length
                      ? moment(prevTariff?.data[0]?.startDate)
                          .add(1, 'day')
                          .format('YYYY-MM-DD')
                      : ''
                  }`}
                  defaultValue={displayDate(tariff?.startDate)}
                  onChange={props.handleChange}
                />
                {props?.errors?.startDate && (
                  <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                    {' '}
                    {props?.errors?.startDate}{' '}
                  </p>
                )}
              </>
            )}
            <p className='mt-4 mb-2'>
              <strong>
                {t('DUTY_ROSTER_TYPE') as string}
                <InfoModal content={t('DUTY_ROSTER_TYPE_INFO') as string} />
              </strong>
            </p>
            <ReactForm>
              <ReactForm.Check
                disabled
                type='radio'
                label={
                  t('AVERAGE_DAILY_WORKING_HOURS') as string
                } /* "Average daily working hours" */
                name='averageDailyWorkingHours'
                id='inline-aeougb-1'
                defaultChecked={tariff?.averageDailyWorkingHours}
                onChange={(e) => {
                  props.setFieldValue('frameworkDutyPlan', !e.target.checked);
                  props.setFieldValue('averageDailyWorkingHours', e.target.checked);
                }}
              />
              <ReactForm.Check
                disabled
                type='radio'
                label={t('FRAMEWORK_DUTY_PLAN') as string} /* "Framework duty plan" */
                name='averageDailyWorkingHours'
                id='apeig'
                defaultChecked={tariff?.frameworkDutyPlan}
                onChange={(e) => {
                  props.setFieldValue('frameworkDutyPlan', e.target.checked);
                  props.setFieldValue('averageDailyWorkingHours', !e.target.checked);
                }}
              />
            </ReactForm>
            <br /> <br />
            <h5>
              {t('TIME_LIMITS_WORKING_HOURS') as string}
              {'    '}
              {/* <strong>
              <InfoModal content={t('CREATE_TARIFF_LIMIT_WORKING_HOURS') as string} /></strong> */}{' '}
            </h5>
            <p className='mb-1 mt-4'>
              {' '}
              <strong>{t('FIXED_DAYS_WEEK') as string}</strong>{' '}
            </p>
            <MultiSelect
              disabled
              options={options}
              value={selected}
              onChange={(e: any) => {
                props.setFieldValue('workDayDefiner', (e as any[])?.map((val) => val.value));
                setSelected(e);
              }}
              labelledBy='Select'
            />
            {props?.errors?.workDayDefiner && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.workDayDefiner}{' '}
              </p>
            )}
            <p className='mb-1 mt-4'>
              {' '}
              <strong>{t('WORKING_HOURS_FULLtime') as string}</strong>{' '}
            </p>
            <input
              disabled
              name='weeklyWorkingHoursFullyEmployed'
              className='w-100 '
              defaultValue={tariff?.weeklyWorkingHoursFullyEmployed || ''}
              type='number'
              min='1'
              max='1000'
              placeholder=''
              onChange={props.handleChange}
            />
            {props?.errors?.weeklyWorkingHoursFullyEmployed && (
              <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                {' '}
                {props?.errors?.weeklyWorkingHoursFullyEmployed}{' '}
              </p>
            )}
            <Button
              className='py-2'
              block
              type='submit'
              style={{ marginTop: '50px' }}
              variant='primary'
            >
              {t('SAVE') as string}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
});

export default ReadTariffSettings;
