import React, { useCallback, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import usePromise from 'react-promise';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { useTranslation } from 'react-i18next';
import { EmployeeAttributes, EmploymentAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import RemoveEmpContract from './RemoveEmpContract';
import EmploymentCard from './EmploymentCard';
import { UserAttributes } from '../../../apis/attributes/user';
import YearlyLogic from './YearlyLogic';

type EmployemntListAttributes = {
  data: EmploymentAttributes[];
};

export const OverviewData: React.FC<{ employeeId: string }> = ({ employeeId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [toggleEditName, setToggleEditName] = useState(false);
  const [toggleEditEmail, setToggleEditEmail] = useState(false);

  const [localFirstName, setLocalFirstName] = useState<string | undefined>();
  const [localLastName, setLocalLastName] = useState<string | undefined>();

  const [localEmail, setLocalEmail] = useState<string | undefined>(undefined);

  const [enableSubmit, setEnableSubmit] = useState(true);

  const [employee] = useObservable<EmployeeAttributes | null>(
    () => social.service('employee').watch().get(employeeId) as any,
    [employeeId],
  );
  const [employeeInUser] = useObservable<UserAttributes | null>(
    () =>
      social
        .service('user')
        .watch()
        .get(employee?.userId ?? '') as any,
    [employee?.id],
  );

  const { value } = usePromise(authenticatedFacility());
  const [employments] = useObservable<EmployemntListAttributes | null>(
    () =>
      social
        .service('employment')
        .watch()
        .find({
          query: {
            $sort: {
              createdAt: 1,
            },
            employeeId,
            facilityId: (value as any)?.user?.facility?.id as any,
            finalSubmission: true,
            $limit: 50,
          },
        }) as any,
    [(value as any)?.facility],
  );

  const updateFirstLastName = async () => {
    try {
      await social.service('employee').patch(employeeId, {
        firstName: localFirstName!,
        lastName: localLastName!,
      });
      setToggleEditName(!toggleEditName);
    } catch (error) {
      alert('Error: please reload the page');
    }
  };

  const updateEmail = async () => {
    setEnableSubmit(false);

    try {
      // await social.service('employee').patch(employeeId, { contactEmail: localEmail! });
    } catch (error) {
      setEnableSubmit(true);

      alert('Error: please reload the page');
    }
  };

  const navigateTo = useCallback(
    (pathName: string, employmentId) => {
      history.push({
        pathname: generatePath(`${pathName}/:employmentId`, { employmentId }),
      });
    },
    [employeeId],
  );

  return (
    <Container className='pb-5'>
      <h4 className='mt-2'>{t('EMPLOYEE_DATA') as string}</h4>
      <div style={{ height: '10px' }} />

      {!toggleEditName ? (
        <Row className='border-bottom mt-4'>
          <Col xs={8} className='pb-3'>
            <p className='p-0 m-0'>
              <strong>{t('OFFICIAL_NAME') as string}</strong>
              <br />
              <p className='mt-2 mb-0 pb-0'>{`${employee?.firstName} ${employee?.lastName}`}</p>
            </p>
          </Col>
          <Col>
            <Button variant='link' onClick={() => setToggleEditName(!toggleEditName)}>
              <strong>{t('TO_EDIT') as string}</strong>
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={8} className='d-flex align-items-center'>
              <p className='p-0 m-0'>
                <strong>{t('OFFICIAL_NAME') as string}</strong>
              </p>
            </Col>
            <Col>
              <Button variant='link' onClick={() => setToggleEditName(!toggleEditName)}>
                <strong>{t('CANCEL') as string}</strong>
              </Button>
            </Col>
          </Row>

          <p className='mt-3 mb-1'>
            <strong>{t('FIRST_NAME') as string}</strong>{' '}
          </p>
          <input
            className='w-100'
            placeholder=''
            defaultValue={localFirstName || employee?.firstName || ''}
            onChange={(e) => setLocalFirstName(e.currentTarget.value)}
          />

          <p className='mt-4 mb-1'>
            <strong>{t('LAST_NAME') as string}</strong>{' '}
          </p>
          <input
            className='w-100'
            placeholder=''
            defaultValue={localLastName || employee?.lastName || ''}
            onChange={(e) => setLocalLastName(e.currentTarget.value)}
          />
          <Button
            className='mt-4 py-2 px-5 '
            disabled={!enableSubmit || !localFirstName || !localLastName}
            onClick={() => updateFirstLastName()}
          >
            {t('To save') as string}
          </Button>

          <hr style={{ borderTop: '1px solid #B8B8C3' }} />
        </>
      )}

      {!localEmail && (setLocalEmail('contactEmail') as any)}
      {!toggleEditEmail ? (
        <Row className='border-bottom mt-4'>
          <Col xs={8} className='pb-3'>
            <p className='p-0 m-0'>
              <strong>{t('Email') as string}</strong>
              <br />
              <p className='mt-2 mb-0 pb-0'>{employeeInUser?.email}</p>
            </p>
          </Col>
          <Col>
            <Button disabled variant='link' onClick={() => setToggleEditEmail(!toggleEditEmail)}>
              <strong>{t('TO_EDIT') as string}</strong>
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={8} className='d-flex align-items-center'>
              <p className='p-0 m-0'>
                <strong>{t('EMAIL') as string}</strong>
              </p>
            </Col>
            <Col>
              <Button variant='link' onClick={() => setToggleEditEmail(!toggleEditEmail)}>
                <strong>{t('CANCEL') as string}</strong>
              </Button>
            </Col>
          </Row>

          <p className='mt-4 mb-1'>
            <strong>{t('EMAIL_ADDRESS') as string}</strong>
          </p>

          <input
            className='w-100'
            placeholder=''
            defaultValue={localEmail || ''}
            onChange={(e) => setLocalEmail(e.currentTarget.value)}
          />

          <Button
            className='mt-4 py-2 px-5 '
            disabled={!enableSubmit || !localEmail}
            onClick={() => updateEmail()}
          >
            {t('SAVE') as string}
          </Button>

          <hr style={{ borderTop: '1px solid #B8B8C3' }} />
        </>
      )}

      <Row className='border-bottom mt-4'>
        <Col xs={8} className='pb-3'>
          <p className='p-0 m-0'>
            <strong>{t('EMPLOYEE_ID') as string}</strong>
            <br />
            <p className='mt-2 mb-0 pb-0'>{employee?.id}</p>
          </p>
        </Col>
        <Col>
          <Button variant='link' disabled>
            <strong>{t('TO_EDIT') as string}</strong>
          </Button>
        </Col>
      </Row>

      {employeeId && <YearlyLogic employeeId={employeeId} shouldReadOnly={false} />}

      <div style={{ height: '20px' }} />

      <h5 className='mt-5 mb-4'>
        <strong>{t('EMPLOYMENT_CONTRACT') as string}</strong>
      </h5>

      {employments?.data?.map((emp, idx) => (
        <Row className='mb-4' key={emp?.id ?? idx}>
          <Col>
            {idx + 1 !== employments?.data?.length ? (
              <Button variant='light' disabled className='text-dark p-0 w-100'>
                <EmploymentCard employment={emp} />
              </Button>
            ) : (
              <Link
                className='text-dark'
                to={`/admin/create-employee-contract/contract/${emp?.id}`}
              >
                <EmploymentCard employment={emp} />
              </Link>
            )}
          </Col>

          <Col xs={2} md={1} className='d-flex align-items-center pl-1'>
            <RemoveEmpContract
              disabled={(employments?.data?.length ?? -1) - 1 !== idx}
              employmentId={emp.id}
            />
          </Col>
        </Row>
      ))}

      <Button
        className='shadow w-100 rounded py-3 mt-3 '
        variant='light'
        onClick={async () => {
          const employment: EmployeeAttributes = (await (
            social.service('employment') as any
          ).create({
            employeeId,
            facilityId: (value as any)?.user?.facility?.id,
          })) as any;
          navigateTo('/admin/create-employee-contract/contract', employment?.id ?? '');
        }}
      >
        {t('CREATE_EMPLOYMENT') as string}
      </Button>
    </Container>
  );
};

export default OverviewData;
