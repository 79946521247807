import * as React from 'react';
import Modal from 'react-modal';
import { useModalManager } from '@vlzh/react-modal-manager';
import { Button, Modal as BootModal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';

export const PaymentModal: React.FC = () => {
  const { isOpen: isEmployeeOpen, closeModal: isEmployeeClose } = useModalManager('EMPLOYEE');
  const { isOpen: isFacilityOpen, closeModal: isFacilityClose } = useModalManager('ADMIN');
  const { t } = useTranslation();

  const history = useHistory();

  const customStyles = {
    content: {
      bottom: 'auto',
      left: '50%',
      marginRight: '-50%',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const navigateTo = React.useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);
  return (
    <>
      <Modal
        isOpen={isEmployeeOpen('EMPLOYEE')}
        onRequestClose={() => isEmployeeClose('EMPLOYEE')}
        style={customStyles}
      >
        <div>
          <BootModal
            centered
            show={isEmployeeOpen('EMPLOYEE')}
            onHide={() => isEmployeeClose('EMPLOYEE')}
          >
            <BootModal.Header closeButton>
              <BootModal.Title className='contained-modal-title-hcenter'>
                {' '}
                {t('UPGRADE_OR_ACTIVATE_EMPLOYEE')}{' '}
              </BootModal.Title>
            </BootModal.Header>
            <BootModal.Body className='px-3 py-4'>
              {t('PAYMENT_REQUIRED_EMPLOYEE')}
            </BootModal.Body>
          </BootModal>
        </div>
      </Modal>

      <Modal
        isOpen={isFacilityOpen('ADMIN')}
        onRequestClose={() => isFacilityClose('ADMIN')}
        style={customStyles}
      >
        <div>
          <BootModal
            centered
            show={isFacilityOpen('ADMIN') }
            onHide={() => isFacilityClose('ADMIN')}
          >
            <BootModal.Header closeButton>
              <BootModal.Title className='contained-modal-title-hcenter'>
                {' '}
                {t('UPGRADE_OR_ACTIVATE') as string}{' '}
              </BootModal.Title>
            </BootModal.Header>
            <BootModal.Body className='px-3 py-4'>{t('PAYMENT_REQUIRED') as string}</BootModal.Body>

            <BootModal.Footer className='d-flex justif-content-center'>
              <Button variant='outline-dark' onClick={() => isFacilityClose('ADMIN') }>
                {t('CANCEL') as string}
              </Button>
              <Button
                className='px-5'
                variant='primary'
                onClick={async () => {
                  navigateTo('/admin/plans');
                  isFacilityClose('ADMIN');
                }}
              >
                {t('GO_TO_PLANS') as string}
              </Button>
            </BootModal.Footer>
          </BootModal>
        </div>
      </Modal>
    </>
  );
};

export default PaymentModal;
