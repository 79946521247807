import { buildTimeRangeCollection } from "./utility";
import { Employment } from "./employment";
import { DateTime } from "luxon";

export class EmploymentCollection {

    public employments: Employment[];

    /**
     * Constructs a IntervalCollection object.
     *
     * @param {Interval[]} employments - Array of intervals.
     */
    constructor(employments: Employment[]) {
        this.employments = buildTimeRangeCollection(employments) as Employment[];
    }

    /**
     * HELPER to check for existence of employment at a day
     * @param date
     * @return true if an employment exists at given day else false
     */
    getEmployment(date: DateTime): Employment | undefined {
        return this.employments.find(
            (employment) => employment.start <= date && employment.end >= date
        );
    }

    /**
     * HELPER to check for existence of employment at a day
     * @param date
     * @return true if an employment exists at given day else false
     */
    hasEmployment(date: DateTime): boolean {
        for (const employment of this.employments) {
            if (employment.start <= date && employment.end >= date) {
                return true;
            }
        }
        return false;
    }
}