import * as React from 'react';
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import terms_de from '../../apis/terms_de';
import terms_en from '../../apis/terms_en';
import i18n from '../../apis/i18n';

export const TermsService = () => {
  return (
    <Container className='p-5'>
      {i18n.language === 'en' ? parse(terms_en) : parse(terms_de)}
    </Container>
  );
};
export default TermsService;
