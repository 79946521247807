import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TimeInterval } from './Availability';

export type SaveNoteModalAttributes = {
  isDisabled: boolean;
  toggleModal: any;
  // eslint-disable-next-line no-unused-vars
  setToggleModal: (arg: any) => void;
  allTimeIntervals: TimeInterval[];
  idxTimeInterval: number;
  // eslint-disable-next-line no-unused-vars
  setTimeIntervals: (timeInterval: TimeInterval[]) => void;
};

export const SaveNoteModal: React.FC<SaveNoteModalAttributes> = (
  props: SaveNoteModalAttributes,
) => {
  const {
    isDisabled,
    toggleModal,
    setToggleModal,
    allTimeIntervals,
    idxTimeInterval,
    setTimeIntervals,
  } = props;

  const { t } = useTranslation();

  const [newNote, setNewNote] = React.useState(allTimeIntervals[idxTimeInterval].note ?? '');
  const closeModal = React.useCallback(() => setToggleModal(undefined), [toggleModal]);

  const saveNote = React.useCallback(() => {
    const clonedIntervals: TimeInterval[] = [];
    allTimeIntervals.forEach((val) => clonedIntervals.push({ ...val }));
    clonedIntervals[idxTimeInterval].note = newNote;
    setTimeIntervals(clonedIntervals);
  }, [allTimeIntervals, newNote]);

  const clampBool = React.useMemo(() => isDisabled, []);
  const disableInputRef = React.useRef<any>(null);

  return (
    <Modal centered show={props !== undefined} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className='contained-modal-title-hcenter'>
          {t('INTERVAL_NOTE') as string}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3 py-4'>
        {t('WRITE_A_NOTE_FOR_INTERVAL') as string}
        <input
          ref={disableInputRef}
          disabled={clampBool}
          className='w-100'
          onChange={(e) => {
            setNewNote(e.currentTarget.value);
          }}
          placeholder=''
          defaultValue={allTimeIntervals[idxTimeInterval].note}
        />
      </Modal.Body>
      <Modal.Footer className='d-flex justif-content-center'>
        <Button variant='outline-dark' onClick={closeModal}>
          {t('CANCEL') as string}
        </Button>
        <Button
          disabled={clampBool}
          className='px-5'
          variant='primary'
          onClick={() => {
            saveNote();
            setToggleModal(undefined);
          }}
        >
          {t('SAVE') as string}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveNoteModal;
