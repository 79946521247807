// display eslint for this file
export default `
<h2 id="agreement-on-order-processing-of-the-browser-based-time-recording-program-kidig-az-in-accordance-with-art-28-gdpr">Agreement on order processing of the browser-based time recording program KiDig-AZ in accordance with Art. 28 GDPR</h2>
<p>Between the Licensor</p>
<ol>
<li>Company ictt system-consulting,
Managing Director Karl Cziumplik,
Eschholzstrasse 94,
79115 Fribourg</li>
</ol>
<p>hereinafter referred to as <strong>main licensee</strong> or <strong>licensor</strong>, and the licensee</p>
<ol>
<li>.............................................. (Designation the institution, etc.)</li>
</ol>
<p>Represented by</p>
<p>.................................................. .. (Street and house number)</p>
<p>.................................................. .. (ZIP code and location)</p>
<p>the buyer of the license, hereinafter referred to as <strong>sub-licensee</strong> or <strong>licensee</strong>,</p>
<h3 id="1-preamble">1. Preamble</h3>
<p>This agreement for order processing of the browser-based time recording program
KiDig-AZ according to § 28 DSGVO represents the obligations of the contracting parties to data protection,
which result from the order processing described in detail in the existing usage contract.
It applies and is related to all activities related to this contract of use
stand and where employees of the contractor or persons commissioned by the contractor
process personal data (hereinafter referred to as <strong>data</strong>) of the client.</p>
<h3 id="2-subject-and-duration-of-the-assignment">2. Subject and duration of the assignment</h3>
<p><strong>2.1</strong> The object of the order as well as the type and purpose result from the existing user contract.</p>
<p><strong>2.2</strong> The duration of this order corresponds to the term of the license agreement.</p>
<h3 id="3-differentiated-presentation-of-the-order-content">3. Differentiated presentation of the order content</h3>
<p><strong>3.1</strong> Type and purpose of the processing of personal data by the contractor for the client
are specifically described in the existing license agreement.
The provision of the contractually agreed data processing takes place exclusively in a member state
of the European Union or in another state party to the Agreement on the European Economic Area.
Any relocation of the service or part of the work to a third country requires the
prior consent of the client and may only take place if the special requirements of the
Art. 44 ff. GDPR are fulfilled (e.g. adequacy decision of the Commission, standard data protection clauses,
approved code of conduct).</p>
<p><strong>3.2</strong> The type of personal data used is in Appendix 1 (list of personal data
and purpose of their processing).</p>
<p><strong>3.3</strong> The categories of persons affected by the processing are set out in Annex 1 (list of
personal data and the purpose of their processing).</p>
<h3 id="4-scope-and-responsibility">4. Scope and Responsibility</h3>
<p><strong>4.1</strong> The contractor processes personal data on behalf of the client.
This includes activities that are specified in the contract of use. The client is responsible
i. s.d. Art. 4 No. 7 GDPR for the processing of data on behalf of the contractor.</p>
<p><strong>4.2</strong> The instructions are initially set by the user agreement and can be changed by the customer
then in writing or in an electronic format (text form) to the contractor
designated body can be changed, supplemented or replaced by individual instructions (individual instructions).
Instructions that are not provided for in the contract of use will be treated as a request for a change in service.
Verbal instructions must be confirmed immediately in writing or in text form.</p>
<h2 id="5-technical-and-organizational-measures">5. Technical and organizational measures</h2>
<p><strong>5.1</strong> The contractor is responsible for implementing the necessary requirements set out in advance of the award of the contract
technical and organizational measures before the start of processing, in particular with regard to the specific
to document the execution of the order and to hand it over to the customer for inspection. Upon acceptance by the
Clients are the documented measures basis of the order. As far as the examination of an audit of the
If there is a need for adjustment on the part of the customer, this must be implemented by mutual agreement.</p>
<p><strong>5.2</strong> The contractor has security in accordance with Art. 28 Para. 3 lit. c, 32 GDPR in particular
with Art. 5 Para. 1, Para. 2 GDPR. Overall, these are the measures to be taken
to take data security measures and to ensure a level of protection appropriate to the risk
with regard to confidentiality, integrity, availability and the resilience of the systems.
The state of the art, the implementation costs and the type, scope and purposes of the
Processing and the different probability of occurrence and severity of the risk for the rights
and freedoms of natural persons within the meaning of Art. 32 Para. 1 GDPR.</p>
<p><strong>5.3</strong> The technical and organizational measures are subject to technical progress and the
advancement. In this respect, the contractor is permitted to implement alternative, adequate measures.
The safety level of the specified measures must not be undercut. essentials
Changes must be documented.</p>
<h3 id="6-correction-restriction-and-deletion-of-data">6. Correction, restriction and deletion of data</h3>
<p><strong>6.1</strong> The contractor may not authorize the data that is processed in the order, but only
correct, delete or restrict their processing according to documented instructions from the client.
If an affected person contacts the contractor directly in this regard, the contractor
immediately forward this request to the client.</p>
<p><strong>6.2</strong> To the extent included in the scope of services, the deletion concept, right to be forgotten, correction,
Data portability and information directly from the contractor according to documented instructions from the client
ensure.</p>
<h3 id="7-subcontract-relationships">7. Subcontract relationships</h3>
<p><strong>7.1</strong> Such services are to be understood as subcontracting relationships within the meaning of this regulation,
which relate directly to the provision of the main service. This does not include ancillary services,
which the contractor provides, e.g. as telecommunications services or postal/transport services in
claims. However, the contractor is obliged to ensure data protection and
Data security of the client&#39;s data, even with outsourced ancillary services, is appropriate and
to take legally compliant contractual agreements and control measures.</p>
<p><strong>7.2</strong> The contractor may only use subcontractors (further processors) after prior express consent
written or documented consent of the client.
The client agrees to the commissioning of the following subcontractors under the
Condition of a contractual agreement in accordance with Art. 28 Para. 2-4 GDPR:</p>
<p><strong>Server Hosting</strong>: AWS Germany - Amazon web services in Germany –
Region EU-central-1 (Frankfurt)</p>
<p><strong>Support email</strong>: sales@ictt-consulting.de</p>
<p>Outsourcing to subcontractors or changing the existing subcontractor is permitted if:</p>
<ul>
<li>the contractor shall notify the client of such outsourcing to subcontractors a reasonable time in advance
in writing or in text form and</li>
<li>the client fails to notify the contractor in writing by the time the data is handed over or
raises an objection to the planned outsourcing in text form and</li>
<li>the subcontractor is subject to the same data protection obligations as set out in this contract.</li>
</ul>
<p><strong>7.3</strong> The transfer of the client&#39;s personal data to the subcontractor and his
First-time action is only permitted when all the requirements for subcontracting are met.</p>
<p><strong>7.4</strong> If the subcontractor provides the agreed service outside the EU/EEA, the
Contractor ensures admissibility under data protection law by taking appropriate measures.
The same applies if service providers within the meaning of paragraph 1 sentence 2 are to be used.</p>
<p><strong>7.5</strong> All contractual regulations in the contract chain are also the further
impose subcontractors.</p>
<h3 id="8-obligations-of-the-contractor">8. Obligations of the contractor</h3>
<p><strong>8.1</strong> The contractor may only process data from data subjects within the framework of the order and the instructions
of the client, unless there is an exceptional case within the meaning of Article 28 (3) a) GDPR.
In such a case, the contractor shall notify the controller of these legal requirements
to the processing, provided that the relevant right does not require such communication for an important reason
public interest (Art. 28 para. 3 sentence 2 lit. a GDPR). The contractor informs the
Client immediately if he believes that an instruction violates applicable laws.
The contractor may suspend the implementation of the instruction until the client
has been confirmed or modified.
The contractor supports the client, taking into account the type of processing and the
available information in compliance with the obligations set out in Articles 32 to 36 GDPR
(ensuring security of processing; reporting personal data breaches
data to the supervisory authorities; Notification of a personal data breach
data subject; data protection impact assessment; prior consultation).</p>
<p><strong>8.2</strong> In addition to complying with the provisions of this order, the contractor has statutory
Obligations according to Articles 28 to 33 GDPR; in this respect, he guarantees in particular compliance with the following requirements:</p>
<p><strong>8.2.1</strong> The contractor is not obliged to appoint a data protection officer.
As a contact person at the contractor</p>
<p>Mr. Karl Cziumplik, Managing Director,
Eschholzstrasse 94,
79115 Fribourg</p>
<p><strong>Tel</strong>: +49 (761) 89 75 91 20</p>
<p><strong>FAX</strong>: +49 (761) 89 75 91 21</p>
<p><strong>Email</strong>: sales@ictt-consulting.de</p>
<p>named.</p>
<p><strong>8.2.2</strong> Maintaining confidentiality in accordance with Art. 28 (3) sentence 2 lit. b, 29, 32 (4) GDPR. Of the
When carrying out the work, the contractor only uses employees who are committed to confidentiality
obliged and have previously been familiarized with the data protection provisions relevant to them.
The contractor and any person subordinate to the contractor who has access to personal data,
may only process this data in accordance with the instructions of the client, including
powers granted in this contract, unless they are required by law to process.
Confidentiality continues even after termination of the contract of use.</p>
<p><strong>8.3.3</strong> The implementation of and compliance with all technical and organizational requirements required for this order
Measures in accordance with Art. 28 (3) sentence 2 lit. c, 32 GDPR <a href="">details in Annex 1</a>.</p>
<p><strong>8.3.4</strong> The Client and the Contractor will work with the Regulatory Authority in compliance upon request
their tasks together.</p>
<p><strong>8.3.5</strong> The immediate information of the client about control actions and measures
supervisory authority, insofar as they relate to this order. This also applies if a responsible
Authority in the context of administrative offense or criminal proceedings in relation to the processing of personal data
Data determined during order processing by the contractor.</p>
<p><strong>8.3.6</strong> If the client, for his part, is subject to an inspection by the supervisory authority, a
or criminal proceedings, the liability claim of a data subject or a third party or any other claim
is suspended in connection with the order processing by the contractor, the contractor has to do it again
support to the best of our ability.</p>
<p><strong>8.3.7</strong> The contractor regularly checks the internal processes as well as the technical and
organizational measures to ensure that the processing is within his area of responsibility
is carried out in accordance with the requirements of applicable data protection law and the protection of the rights of
affected person is guaranteed.</p>
<p><strong>8.3.8</strong> Verifiability of the technical and organizational measures taken against the
Client within the scope of his control powers according to Section 9 of this contract.</p>
<p><strong>8.3.9</strong> The contractor confirms that he has the relevant data for order processing
are aware of the data protection regulations of the GDPR. He undertakes, also the following, for
to observe the secret protection rule relevant to this order, which is the responsibility of the client:
§ 203 StGB (violation of private secrets).</p>
<p><strong>8.3.10</strong> The contractor shall notify the client immediately of any disruptions, violations by the contractor or
of the persons employed by him as well as against data protection regulations or those on behalf of him
determinations made as well as the suspicion of data protection violations or irregularities in the
processing of personal data. This applies above all with regard to possible reporting and
Notification obligations of the client according to Art. 33 and Art. 34 GDPR. The contractor assures
if necessary, adequately meet the client&#39;s obligations under Art. 33 and 34 GDPR
to support (Art. 28 para. 3 sentence 2 lit. f GDPR). Reports according to Art. 33 or 34 GDPR for the client
may only be carried out by the contractor following prior instructions from the customer.</p>
<h3 id="9-obligations-of-the-client">9. Obligations of the client</h3>
<p><strong>9.1</strong> The client must inform the contractor immediately and completely if he is in the
Errors or irregularities regarding data protection regulations are found in the order results.</p>
<p><strong>9.2</strong> In the event of a claim against the client by an affected person with regard to any
Claims according to Art. 82 GDPR, the contractor undertakes to defend the client against the claim
support as much as possible.</p>
<p><strong>9.3</strong> The client shall inform the contractor of the contact person for any matters arising under the contract
privacy issues.</p>
<h3 id="10-client-s-control-rights">10. Client&#39;s control rights</h3>
<p><strong>10.1</strong> The client has the right to carry out checks in consultation with the contractor or through
to have inspectors to be appointed carried out on a case-by-case basis. He has the right to make random checks,
which are usually to be reported in good time, from the contractor&#39;s compliance with this agreement
to convince in his business operations.</p>
<p><strong>10.2</strong> Should inspections be carried out by the customer or an inspector commissioned by him,
these will be taken into account during normal business hours without disrupting operations after registration
carried out with a reasonable lead time. The contractor may take this from the previous registration
reasonable lead time and from signing a non-disclosure agreement regarding the data
other customers and the set up technical and organizational measures.
If the auditor commissioned by the client is in a competitive relationship with the contractor
stand, the contractor has a right of objection against this.</p>
<p><strong>10.3</strong> The contractor ensures that the client is informed of compliance with the obligations of the
contractor according to Art. 28 GDPR. The contractor undertakes to inform the client
Request to provide the necessary information and in particular the implementation of the technical and
organizational measures.</p>
<p><strong>10.4</strong> Evidence of such measures that do not only relate to the specific order can be provided by</p>
<ul>
<li>compliance with approved codes of conduct pursuant to Art. 40 GDPR;</li>
<li>Certification according to an approved certification procedure in accordance with Art. 42 GDPR; current certificates,
Reports or excerpts from reports from independent bodies (e.g. auditors, auditors, data protection officer,
IT security department, data protection auditors, quality auditors);</li>
<li>Appropriate certification through an IT security or data protection audit (e.g. according to BSI IT basic protection).</li>
</ul>
<h3 id="11-the-client-s-authority-to-issue-instructions">11. The client&#39;s authority to issue instructions</h3>
<p><strong>11.1</strong> Verbal instructions are confirmed by the client immediately (at least in text form).</p>
<p><strong>11.2</strong> The contractor must inform the client immediately if he is of the opinion
an instruction violates data protection regulations. The contractor is entitled to carry out
to suspend the corresponding instruction until it is confirmed or changed by the client.</p>
<h3 id="12-data-subject-requests">12. Data Subject Requests</h3>
<p><strong>12.1</strong> If a data subject contacts the data subject with requests for correction, deletion or information
Contractor, the contractor will refer the data subject to the client, provided an assignment
to the client is possible according to the data subject. The contractor forwards the application to the
affected person immediately to the client. The contractor supports the client
given the nature of the processing, where possible with appropriate technical and organizational measures
in doing so, his duty to respond to applications for the exercise of the data referred to in Chapter III of the GDPR
support the rights of data subjects.</p>
<h3 id="13-deletion-and-return-of-personal-data">13. Deletion and return of personal data</h3>
<p><strong>13.1</strong> Copies or duplicates of the data will not be made without the knowledge of the client. Excluded from this
are backup copies, insofar as they are necessary to ensure proper data processing,
as well as data required to comply with statutory retention requirements.</p>
<p><strong>13.2</strong> After completion of the contractually agreed work or earlier at the request of the client -
at the latest upon termination of the service agreement - the contractor has all in his as well as on
Documents obtained from subcontractors, processing and usage results created as well as databases,
which are related to the contractual relationship, to be handed over to the client at his option and
delete any copies or destroy them in accordance with data protection regulations. The same applies to test and scrap material.
The log of the deletion must be presented on request.</p>
<p><strong>13.3</strong> Documentation that serves as proof of orderly and proper data processing has been completed
to store the contractor in accordance with the respective retention periods beyond the end of the contract.
He can hand them over to the client to relieve himself at the end of the contract.</p>
<h3 id="14-liability-and-indemnification">14. Liability and Indemnification</h3>
<p>Client and contractor are liable for the damage caused by processing that does not comply with the GDPR
is caused, together in the external relationship with the respective data subject.
The contractor is only liable for damage that is based on processing carried out by him,
in the</p>
<ul>
<li>he has not complied with the obligations resulting from the GDPR and specifically imposed on processors
is or</li>
<li>he acted in disregard of the lawfully given instructions of the client or</li>
<li>he has acted against the lawfully issued instructions of the client.</li>
</ul>
<p>Insofar as the customer is obliged to pay damages to the person concerned, he has recourse
reserved for the contractor.
In the internal relationship between the client and the contractor, the contractor is liable for the
Processing caused damage, however, only if he</p>
<ul>
<li>has not complied with its obligations specifically imposed by the GDPR or</li>
<li>in non-observance of the client&#39;s legitimately given instructions or contrary to these instructions
acted.
Further liability claims according to the general laws remain unaffected.</li>
</ul>
<h3 id="15-information-obligations-written-form-clause-choice-of-law">15. Information obligations, written form clause, choice of law</h3>
<p><strong>15.1</strong> Should the client&#39;s data be held by the contractor through attachment or confiscation, through
insolvency or settlement proceedings or other events or measures by third parties,
the contractor must inform the customer immediately. The contractor will all in
immediately inform those responsible in this context that the sovereignty and ownership of the
Data lies exclusively with the client as the “responsible person” within the meaning of the General Data Protection Regulation.</p>
<p><strong>15.2</strong> Changes and additions to this Annex and all of its components - including any
Assurances of the contractor - require a written agreement, which can also be in an electronic
Format (text form) can take place, and the express reference to the fact that it is a matter of a change or
addition to these conditions. This also applies to the waiver of this formal requirement.</p>
<p><strong>15.3</strong> In the event of any contradictions, the provisions of this annex on data protection shall take precedence over the provisions of
contract of use. If individual parts of this system are ineffective, this affects the effectiveness
the plant otherwise not.</p>
<p><strong>15.4</strong> German law applies.</p>
<h3 id="authority-to-issue-instructions">Authority to issue instructions</h3>
<p><strong>Persons authorized to issue instructions to the licensee are</strong>:</p>
<p>First name Last Name:</p>
<p>Function: Head of daycare</p>
<p><strong>Contact details</strong>:</p>
<ul>
<li><p>Telephone:</p>
</li>
<li><p>e-mail:</p>
</li>
</ul>
<p>First name Last Name:</p>
<p>Function: managing director, porter</p>
<p><strong>Contact details</strong>:</p>
<ul>
<li><p>Telephone:</p>
</li>
<li><p>e-mail:</p>
</li>
</ul>
<p>The recipients of instructions from the licensor are:</p>
<p>Publisher: ictt system consulting</p>
<p>Function: Managing Director</p>
<p>Name: Karl Cziumplik</p>
<p>Postal address: ictt system-consulting</p>
<p>Eschholz-Str. 94</p>
<p>79115 Fribourg</p>
<p>Phone: +49 (761) 89759120</p>
<p>FAX : +49 (761) 89759121</p>
<p>Email: sales@ictt-consulting.de</p>
<p>.................................................. ............................................
Place and date</p>
<p>.................................................. ............................................</p>
<p>client, signature</p>
<p>.................................................. ............................................</p>
<p>Karl Cziumplik, Managing Director
ictt system consulting</p>
<h3 id="appendix-1-to-the-order-pursuant-to-art-28-gdpr-">Appendix 1 to the order pursuant to Art. 28 GDPR:</h3>
<p>List of personal data and purpose of their processing</p>
<p>type of data
The subject of the additional agreement are the following data types and categories:</p>
<h3 id="1-data-categories-of-az-web-app-time-tracking-users-hereinafter-az-web-app-users-">1. Data Categories of AZ Web App Time Tracking Users (hereinafter AZ Web App Users)</h3>
<h4 id="1-1-facility-inventory-data">1.1 Facility Inventory Data</h4>
<p><strong>Name and location of the institution</strong>: In order to assign the institution (in particular,
if a carrier operates several facilities (AZ web app).</p>
<p><strong>Number of Facility Groups</strong>: To enable the Licensor to collect statistical data from the Facilities
support.</p>
<p><strong>Type of work recording in annually fixed roster and average
Daily working hours according to TVÖD</strong>: In order to map the collectively agreed working time recording parameters and
then calculate the working time.</p>
<p><strong>Tariff settings for all employees setup</strong>:</p>
<ul>
<li>Year, country, state, type of work registration</li>
<li>annually fixed roster and</li>
<li>Average daily working time according to TVÖD</li>
<li>Number of additionally defined public holidays</li>
<li>Number of contractual weekly working days</li>
<li>Hourly limit of monthly plus or minus hours</li>
<li>Hour limit per day according to § 3 ArbZG</li>
<li>Break deductions (§§ 3, 4, 7 ArbZG</li>
<li>Percentage crediting of overtime surcharges according to § 8 TVÖD (is being planned)</li>
<li>Time bonus area according to § 8 paragraph (1) a Overtime from full or part-time work load</li>
<li>Time surcharges according to § 8 TVÖD:
Monday to Friday
Saturday
Sunday</li>
<li>Public holiday with time off in lieu</li>
<li>Public holiday without compensatory time off</li>
<li>Dec 24th falls on a business day, Saturday or Sunday</li>
<li>Dec 24th falls on a business day, Saturday or Sunday</li>
</ul>
<p>To ensure the employees and the institution for the calculation of working hours with the result of a
valid recording of working hours in accordance with statutory and collective agreement calculation parameters.</p>
<h4 id="1-2-inventory-data-of-employees">1.2 Inventory data of employees</h4>
<p><strong> First and last name of the employees as AZ web app users </strong>: In order for the facility management to be able to assign the
to enable employees (AZ web app).</p>
<p><strong>Signature (protected) of the AZ-Web-App user</strong>: To sign the AZ-Web App (to prove the
hours worked, or proof of vacation days, etc.</p>
<p><strong>employee contract</strong>:</p>
<ul>
<li>weekly working hours</li>
<li>Weekly working days</li>
<li>weekly regular disposal time</li>
<li>weekly – irregular annual availability related to the year</li>
</ul>
<p>In order to be able to verify and prove the hours worked in relation to the existing employment contract.</p>
<p><strong>Calculated working time</strong>:</p>
<ul>
<li>TARGET time and</li>
<li>IS time</li>
</ul>
<p>In order to be able to verify and prove the hours worked in relation to the existing employment contract.</p>
<p><strong>Calculated working days</strong>:</p>
<ul>
<li>Public holidays</li>
<li>Vacations</li>
<li>special leave</li>
<li>Training days according to different training categories</li>
<li>Absence due to school attendance</li>
<li>Sick days</li>
<li>Sick days due to the illness of a natural child</li>
<li>Sick days without medical certificate</li>
<li>absent days</li>
<li>December 24th and 31st - if eligible</li>
</ul>
<p>In order to be able to verify and prove the hours worked in relation to the existing employment contract.</p>
<h4 id="1-3-institution-contact-information">1.3 Institution contact information</h4>
<p><strong>Email address and password (protected) of the institution</strong>:
To enable login.</p>
<p><strong>Log data of AZ-web-App users</strong>:
To prove to the day care center whether the entry data has been checked.</p>
<h4 id="1-4-contact-details-of-employees-as-az-web-app-users">1.4 Contact details of employees as AZ web app users</h4>
<p><strong>Email address and password (protected) of the employees -or. of the AZ web app users</strong>:
To enable login</p>
<h4 id="1-5-data-categories-of-those-responsible-at-the-institutions-institutions-">1.5 Data categories of those responsible at the institutions / institutions:</h4>
<p><strong>Address data of the facility (street, house number, zip code, city)</strong>: Appears as a service for the AZ web app user</p>
<p><strong>Contract data (time, content, payment information)</strong>: To fulfill the contract for the provision
of the AZ-wb app and the backend</p>
<p><strong>Log data</strong>: To prove when a message was sent to the AZ web app user</p>
<p><strong>Email address and password (protected) of the administrator / editor</strong>: To enable login</p>
<h3 id="group-of-those-affected">Group of those affected</h3>
<p>The circle of those affected by this additional agreement includes:</p>
<ul>
<li>Employees of the children&#39;s day care center and possibly other people (AZ web app users day care center and
possibly others such as caretaker)</li>
<li>Employees of the client
Appendix 2 to the order in accordance with Art. 28 DS-GVO:</li>
</ul>
<p>Technical and organizational measures according to Art. 32 GDPR and Annex</p>
<h3 id="1-confidentiality-art-32-para-1-lit-b-gdpr-">1. Confidentiality (Art. 32 Para. 1 lit. b GDPR)</h3>
<h3 id="access-control">Access control</h3>
<p>Management of the company ictt system-consulting:
Administration AWS Germany - Amazon web services in Germany –
Region EU-central-1 (Frankfurt)</p>
<ul>
<li>automatic access control system with biometric access lock (fingerprint)</li>
<li>Motion detectors in the entrance and indoor areas</li>
<li>Security locks</li>
<li>Careful selection of cleaning staff</li>
<li>Access to the rooms for non-employees (e.g. visitors) is restricted as follows: only in
Accompanying an employee of the company ictt system-consulting</li>
</ul>
<h3 id="access-control">Access control</h3>
<p>Management of the company ictt system-consulting:</p>
<ul>
<li>No unauthorized system use by assigning user rights and creating user profiles for
the employees</li>
<li>Access is password-protected, access is only for authorized employees, passwords used
must have a minimum length and are renewed at regular intervals, automatic locking mechanisms</li>
<li>Use of anti-virus software</li>
</ul>
<h3 id="access-control">Access control</h3>
<p>for internal administration systems of the company ictt system-consulting:</p>
<ul>
<li>through regular security updates (according to the current state of the art), the company ictt
system-consulting ensures that unauthorized access is prevented.</li>
<li>Audit-proof, binding authorization procedure for employees</li>
<li>Management of rights by system administrator</li>
<li>Number of administrators reduced to the &quot;essentials&quot;.</li>
<li>Password policy including password length, password change</li>
<li>Logging of access to applications, especially when entering, changing and deleting data</li>
</ul>
<h3 id="for-order-az-web-app-">for order &quot;AZ-web-App:</h3>
<ul>
<li>through regular security updates (according to the current state of the art), the company ictt
system-consulting ensures that unauthorized access is prevented.</li>
<li>Management of rights by the client&#39;s system administrator</li>
<li>Password policy including password length</li>
<li>Logging of access to the AZ web app, especially when entering, changing and deleting data</li>
</ul>
<h3 id="separation-control">Separation control</h3>
<p>for internal administration systems of the company ictt system-consulting:</p>
<ul>
<li>Data is stored physically or logically separately from other data.</li>
<li>Logical client separation (on the software side)</li>
<li>Providing the data records with purpose attributes / data fields</li>
<li>In the case of pseudonymised data: Separation of the assignment file and storage on a separate,
secure IT system</li>
<li>Definition of database rights</li>
<li>Separation of productive and test system</li>
<li>The data is also backed up on logically and/or physically separate systems</li>
</ul>
<h3 id="for-order-az-web-app-">for order &quot;AZ-web-App&quot;:</h3>
<ul>
<li>Data is stored physically or logically separately from other data.</li>
<li>Logical client separation (on the software side)</li>
<li>Providing the data records with purpose attributes / data fields</li>
<li>In the case of pseudonymised data: Separation of the assignment file and storage on a separate,
secure IT system</li>
<li>Definition of database rights</li>
<li>Separation of productive and test system</li>
<li>The data is also backed up on logically and/or physically separate systems</li>
</ul>
<h3 id="2-integrity-art-32-para-1-lit-b-gdpr-">2. Integrity (Art. 32 Para. 1 lit. b GDPR)</h3>
<h3 id="distribution-control">Distribution control</h3>
<p>for internal administration systems of the company ictt system-consulting:</p>
<ul>
<li>all employees are instructed in accordance with Art. 32 (4) GDPR and are obliged to comply with data protection regulations
ensure the handling of personal data. - Data protection-compliant deletion of the data after
termination of contract</li>
<li>Email encryption</li>
<li>Creating an overview of regular retrieval and transmission processes</li>
</ul>
<h3 id="for-order-az-web-app-">for order AZ web app:</h3>
<ul>
<li>Automatic logging of access to the app, especially when entering, changing and deleting
of data and when sending messages</li>
<li>Possibilities for encrypted data transmission are within the scope of the service description of the
Order for day care center info app / school info app made available.</li>
</ul>
<h3 id="input-control">Input control</h3>
<p>for internal administration systems of the company ictt system-consulting:</p>
<ul>
<li>The data is entered or recorded by employees of the company ictt system-consulting themselves.</li>
<li>The changes to the data are logged.</li>
<li>Traceability of entering, changing and deleting data through individual user names
(not user groups)</li>
<li>Storage of forms from which data has been transferred to automated processing</li>
<li>Assignment of rights to enter, change and delete data</li>
</ul>
<h3 id="for-order-az-web-app-">for order AZ-web App:</h3>
<ul>
<li>The data is entered or recorded by the customer himself</li>
<li>Changes in data are logged</li>
</ul>
<h3 id="3-availability-and-resilience-art-32-para-1-lit-b-gdpr-">3. Availability and resilience (Art. 32 Para. 1 lit. b GDPR)</h3>
<p>availability control
For internal administration systems of the company ictt system-consulting:</p>
<ul>
<li>Backup and recovery concept with daily backup of all relevant data</li>
<li>Expert use of protection programs (virus scanners, firewalls, encryption programs, SPAM filters</li>
<li>Use of hard disk mirroring on all relevant servers</li>
<li>Monitoring of all relevant servers</li>
<li>Use of uninterruptible power supply, emergency power system</li>
<li>Always active DDoS protection</li>
</ul>
<h3 id="for-order-az-web-app">for order AZ-web App</h3>
<ul>
<li>Backup and recovery concept with daily backup of all relevant data</li>
<li>Expert use of protection programs (virus scanners, firewalls, encryption programs, SPAM filters</li>
<li>Use of hard disk mirroring on all relevant servers</li>
<li>Monitoring of all relevant servers</li>
<li>Use of uninterruptible power supply, emergency power system</li>
<li>Always active DDoS protection</li>
</ul>
<h3 id="timely-recoverability">Timely recoverability</h3>
<p>For internal administration systems of the company ictt system-consulting:
An escalation chain is defined for all internal systems, which specifies who is to be informed in the event of an error,
to restore the system as soon as possible.</p>
<h3 id="for-order-az-web-app-">for order &quot;AZ-web-App&quot;</h3>
<p>The internal escalation chain of the company ictt system-consulting applies first, followed by the escalation chain
of AWS Germany - Amazon web services in Germany – Region EU-central-1 (Frankfurt), on behalf of
Company ictt system-consulting provides data capacities on a server.</p>
<h3 id="4-periodic-review-assessment-and-evaluation-procedures">4. Periodic review, assessment and evaluation procedures</h3>
<p>(Art. 32 Para. 1 lit. d GDPR; Art. 25 Para. 1 GDPR) for internal management systems of the company ictt
system consulting:</p>
<ul>
<li>Data protection management is in place</li>
<li>Incident response management is in place</li>
<li>Data protection-friendly default settings are taken into account in software developments (Article 25 (2) GDPR)</li>
</ul>
<h3 id="order-control">Order control</h3>
<p>Our employees are instructed in data protection law at regular intervals and they are familiar with it
with the procedural instructions and user guidelines for data processing on behalf, also with regard to
to the client&#39;s right to issue instructions. The license agreement and the associated agreement for
Order processing according to Art. 28 GDPR receives detailed information about the type and scope of the commissioned
Processing and use of the client&#39;s personal data.</p>

`;
