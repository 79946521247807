import { of, Observable, fromEvent, concat, from } from 'rxjs';
import { map, mapTo, mergeMap, shareReplay } from 'rxjs/operators';
import io from 'socket.io-client';
import feathers, { Application as Feathers, ServiceAddons } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import rx from 'feathers-reactive';
import { AdapterService } from '@feathersjs/adapter-commons';
import { AuthenticationResult } from '@feathersjs/authentication';
import authentication from '@feathersjs/authentication-client';
import { AttributeTypes } from './attributes';

declare module '@feathersjs/feathers' {
  interface ReactiveService<T> {
    find(params?: Params): Observable<Paginated<T>>;
  }
}

declare module '@feathersjs/authentication-client' {
  interface AuthenticationClient {
    result(): Observable<AuthenticationResult | false>;
  }
}

export type ServiceTypes = {
  [Property in keyof AttributeTypes]: AdapterService<AttributeTypes[Property]> &
    ServiceAddons<AttributeTypes[Property]>;
};

export type Application = Feathers<ServiceTypes>;

/**
 * A mirror of the feathers api-social app.
 */

export const social = feathers<ServiceTypes>();

social.configure(socketio(io(process.env.REACT_APP_API_SOCIAL_URL!, { autoConnect: true })));
social.configure(rx({ idField: 'id' }));
social.configure(authentication());
social.reAuthenticate(true, 'jwt').catch((e) => {});

// social.authentication.logout();

export const authenticatedFacility = () => social.get('authentication');

export const authenticatedEmployee = () => social.get('authentication');

export const isAuthenticated = of(!!localStorage.getItem('feathers-jwt'));

const init = async () => {
  await social.reAuthenticate(true, 'jwt').catch((e) => {});
};

const socialInit = init();

const authenticationObservable = concat(
  from(socialInit),
  fromEvent<AuthenticationResult>(social, 'logout').pipe(mapTo<AuthenticationResult, false>(false)),
);
const mappedAuthenticationObservable = authenticationObservable.pipe(
  mergeMap((auth) =>
    !auth
      ? of(auth)
      : (social
          .service('user')
          .watch()
          .get((auth as any).user.id)
          .pipe(
            map((user) => Object.assign(auth, { user } as AuthenticationResult)) as any,
          ) as any),
  ),
  shareReplay(1),
);
social.authentication.result = () => mappedAuthenticationObservable as any;

export const feathersAuthenticateFacility = async (
  email: string,
  password: string,
): Promise<AuthenticationResult> => {
  await social.io.connect();

  // if(localStorage.getItem('feathers-jwt'))
  const { accessToken } = await (social.service('authentication') as any).create(
    {
      email: email?.toLowerCase(),
      password,
      strategy: 'local',
    },
    {},
  );
  return social.authenticate(
    {
      accessToken,
      strategy: 'jwt',
    },
    {},
  );
};

export const feathersAuthenticateEmployee = async (
  email: string,
  password: string,
): Promise<AuthenticationResult> => {
  await social.io.connect();

  const { accessToken } = await (social.service('authentication') as any).create(
    {
      email: email?.toLowerCase(),
      password,
      strategy: 'local',
    },
    {},
  );
  return social.authenticate(
    {
      accessToken,
      strategy: 'jwt',
    },
    {},
  );
};

export const isWriteAuthorized = async (facilityId: string): Promise<boolean> => {
  const { authorization } = (await social
    .service('facility')
    .get(facilityId, { query: { canWrite: true } })) as any;
  return authorization;
};

// Convert get() function for adding more search capabilities
social.hooks({
  before: {
    async get(context) {
      if (!context.id) return context;
      const [slug, ...rest] = context.id.toString().split(':');
      if (!rest.length) return context;
      const res = await context.service.find({
        query: { [slug]: rest.join(':') },
      });
      const data = Array.isArray(res) ? res : res.data;
      if (!data.length) throw new Error('Not found');
      [context.result] = data;
      return context;
    },
  },
});

export const containsUndefined = (obj: any): boolean => {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === null) return true;
  }
  return false;
};

export default social;

if (window.location.hostname !== 'www.audiochills.art') Object.assign(window, { social });

export const Pending: React.FC<{}> = () => {
  throw new Promise(() => undefined);
};
