import moment from 'moment';
import { uuid } from 'uuidv4';
import Holidays from 'date-holidays';
import React, {useState} from 'react';
import {Button, Form, Image, Modal, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {DayAttributes} from '../../../apis/attributes';
import social from '../../../apis/social';
import Copy from '../images/copy.png';
import {TariffCollection} from "../../../core/tariffCollection";
import {DateTime} from "luxon";
import {TARIFFTYPE} from "../../../core/enums";
import {EmploymentCollection} from "../../../core/employmentCollection";
import {Tariff} from "../../../core/tariff";
import {Employment} from "../../../core/employment";

export type DuplicateDateProps = {
  sourceDate: Date;
  disabled?: boolean;
  sourceDay?: DayAttributes;
  sourceDays?: DayAttributes[];
  state?: string;
  dec24NotWorking?: boolean;
  dec31NotWorking?: boolean;
  tariffCollection?: TariffCollection;
  employmentCollection?: EmploymentCollection;
  adminCanEdit?: boolean;
};

const isSameMoment = (date1: moment.Moment, date2: moment.Moment) => date1.isSame(date2, 'day');

const isContainedIn = (date1: moment.Moment, date2: moment.Moment[]) => {
  for (const date of date2) {
    if (isSameMoment(date1, date)) {
      return true;
    }
  }
  return false;
};

export const DuplicateDayRange: React.FC<DuplicateDateProps> = ({
                                                                  sourceDate,
                                                                  sourceDay,
                                                                  sourceDays,
                                                                  state,
                                                                  tariffCollection,
                                                                  employmentCollection,
                                                                }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const [onlyWorkingDays, setOnlyWorkingDays] = useState(true);
  const [onlyCopyKeywords, setOnlyCopyKeywords] = useState(true);
  const [maxDate, setMaxDate] = useState((sourceDate.getDate() == 31) && (sourceDate.getMonth() == 11)
      ? sourceDate : moment(sourceDate).add(1, 'days').toDate());

  const [copyFrequency, setCopyFrequency] = useState(1); // Default to every week
  const [copyExecutes, setCopyExecutes] = useState(false);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = DateTime.fromISO(e.target.value);
    const maxDate = DateTime.fromJSDate(sourceDate).endOf('year');

    if (selectedDate > maxDate) {
      // Reset the input to the max allowed date if the selected date exceeds it
      e.target.value = maxDate.toISODate() as unknown as string;
    } else {
      // Update your state as normal if the date is valid
      setMaxDate(selectedDate.toJSDate());
    }
  };

  const addDates = async (days: Date[], onlyKeyWordBranch = false) => {
    // cannot copy anything if sourceDay is missing
    if (!sourceDay) return;

    // don't copy if the days only has one entry and this entry is the sourceDay
    if (days.length === 1 && isSameMoment(moment(days[0]), moment(sourceDate))) return;

    // remove first day from days, it is the sourceDay
    if (isSameMoment(moment(days[0]), moment(sourceDate))) {
      days.shift();
    }

    // this branch is for copying keywords only, should only be executed for the until end date option
    if(onlyCopyKeywords && onlyKeyWordBranch) {

      // cannot copy anything if sourceDays is missing
      if(!sourceDays) return;

      // filter sourceDays to only include days that have a date up until the maxDate, will make lookup faster
      const filteredDays = sourceDays.filter((d) => {
        return d.date.toJSDate() <= maxDate;
      });

      // go over every day in days, check if a day is in filteredDays. If it is copy into it the keywords from sourceDay
      // if it is not, create a new day with the keywords from sourceDay, but empty customIntervals
      const daysToSubmit = days.map((d) => {

        // calculate combined key values for the day
        const moDate = moment(d);
        const weekOfMonth = parseInt(moment(moDate)?.format('WW'), 10);
        const monthOfYear = moDate?.month();

        // CHECK WHAT TO DO WITH ID LOGIC
        const newD = {
          // THIS IS THE CRUCIAL PART
          id: uuid(),
          // THIS SEEMS TO BE OKAY
          customIntervals: {dayType: "", freeIntervals: [{id: uuid()}]},
          dayOfWeek: d.getDay(),
          employeeId: sourceDay.employeeId,
          enforceFeastRestriction: true,
          monthOfYear,
          qualifyTag: sourceDay.qualifyTag,
          weekOfMonth,
          year: sourceDay.year,
        };

        // check for the first day (sourceDay), copy the customIntervals from it
        if (isSameMoment(moment(d), moment(sourceDate))) {
          newD.id = sourceDay.id;
          newD.customIntervals = sourceDay.customIntervals;
        }

        // check if the day is in filteredDays, if it is copy the customIntervals from it
        const found = filteredDays.find((x) => isSameMoment(moment(x.date.toJSDate()), moDate));

        // set id to the found id, entered time intervals to found entered time intervals
        if(found) {
          newD.id = found.id;
          newD.customIntervals = found.customIntervals;
        }
        return newD;
      });
      // add to db
      await Promise.all(daysToSubmit.map((d) => social.service('day').create(d)));
    }

    // this branch is for copying everything
    else {
      const daysToSubmit = days.map((d) => {
        // calculate combined key values for the day
        const moDate = moment(d);
        const weekOfMonth = parseInt(moment(moDate)?.format('WW'), 10);
        const monthOfYear = moDate?.month();

        const newD = {
          customIntervals: sourceDay.customIntervals as any,
          dayOfWeek: d.getDay(),
          employeeId: sourceDay.employeeId,
          enforceFeastRestriction: true,
          monthOfYear,
          qualifyTag: sourceDay.qualifyTag,
          weekOfMonth,
          year: sourceDay.year,
        };
        return newD;
      });

      // add to db
      await Promise.all(daysToSubmit.map((d) => social.service('day').create(d)));
    }
  };

  function isEligibleForCopy(date: DateTime, isHoliday: boolean, onlyWorkingDays: boolean, currentEmployment: Employment | undefined, currentTariff: Tariff | undefined): boolean {
    // check if the day is a holiday
    if (isHoliday) {
      // check if the tariff is fixed
      if (currentTariff?.tariffType === TARIFFTYPE.FIXED) {
        // check if the day is a working day
        console.log(`Tariff is fixed, checking if ${date.day}-${date.month}-${date.year} is a working day`);
        if (currentEmployment?.isWorkingDay(date)){
          return true;
        }
      }
      return false;
    }
    // check if only working days should be copied
    if (onlyWorkingDays) {
      if (currentEmployment?.isWorkingDay(date)) {
        return true;
      }
    } else {
      if (currentTariff?.isWorkingDay(date)) {
        return true;
      }
    }
    return false;
  }

  // TODO: add facility country and state to the return of dependent batch request
  const sameDayInInterval = async (start: Date, end: Date, country = 'DE', federal = 'BW', frequency: number = 1) => {
    // set copyExecutes to true
    // set copyExecutes to true
    setCopyExecutes(true);

    // get all holidays in the given year for the given country and state
    const hd = new Holidays(country, federal)
        .getHolidays(new Date().getFullYear())
        .filter((x) => x.type === 'public');
    // convert to moment
    const holidays = hd.map((x) => moment(x.date));
    const startMoment = moment(start); // Sept. 1st
    const endMoment = moment(end); // Nov. 2nd
    const day = startMoment.day(); // Sunday
    const result = [];
    const current = startMoment.clone();
    const currentCheckTwo = startMoment.clone();
    let weekCounter = 0;

    while (current.day(7 + day).isBefore(endMoment)) {
      weekCounter++;

      // Perform the copying if the current week matches the frequency
      if (weekCounter % frequency === 0) {
        // check if a given target would be a holiday, should not copy if it is
        if (!isContainedIn(current, holidays)) {
          result.push(current.clone().toDate());
        }
        // if the day is a holiday but the tariff is fixed, copy it
        else {
          const maybeTariff = tariffCollection?.getTariff(DateTime.fromISO(current.format('YYYY-MM-DD')));
          if (maybeTariff?.tariffType == TARIFFTYPE.FIXED) {
            result.push(current.clone().toDate());
          }
        }
      }
    }

    // also copy if the day should go into tomorrow
    if (currentCheckTwo.day(1 + day).isSame(endMoment) && result.length < 1) {
      if (!isContainedIn(currentCheckTwo, holidays)) {
        result.push(currentCheckTwo.clone().toDate());
      }
    }

    // add in db
    if (result.length > 0) {
      await addDates(result, false);
    }

    setCopyExecutes(false);
    handleClose();
  };

  // TODO: THIS LOGIC NEEDS TO BE CHECKED THOROUGHLY
  const copyByRange = async (start: Date, end: Date, onlyWorkingDays: boolean, country = 'DE', federal = 'BW') => {
    setCopyExecutes(true);

    // generate start and end date
    const startDate = DateTime.fromJSDate(start).startOf('day');
    const endDate = DateTime.fromJSDate(end).startOf('day');

    // If start and end dates are the same, close the modal and do nothing
    if (startDate.equals(endDate)) {
      setCopyExecutes(false);
      handleClose();
      return;
    }

    // get all holidays in the given year for the given country and state
    const holidays = new Holidays(country, federal)
        .getHolidays(new Date().getFullYear())
        .filter(x => x.type === 'public')
        .map(x => moment(x.date));

    // create list for return
    const result: Date[] = [];

    // go over all days in the range
    for (let i = startDate; i <= endDate; i = i.plus({ days: 1 })) {
      // check if the day is a holiday
      const isHoliday = isContainedIn(moment(i.toISODate()), holidays);
      // get the maybe tariff and employment for the current day
      const currentTariff = tariffCollection?.getTariff(i);
      const currentEmployment = employmentCollection?.getEmployment(i);
      // check if the day is eligible for copy
      if (isEligibleForCopy(i, isHoliday, onlyWorkingDays, currentEmployment, currentTariff)) {
        // check if date is the 24th of December
        if (i.day === 24 && i.month === 12) {
          // check if this day is set as not working in the tariff
          if (!currentTariff?.dec24NotWorking){
            result.push(i.toJSDate());
          }
        }
        // check if date is the 31st of December
        else if (i.day === 31 && i.month === 12) {
          // check if this day is set as not working in the tariff
          if (!currentTariff?.dec31NotWorking){
            result.push(i.toJSDate());
          }
        }
        else {
          result.push(i.toJSDate());
        }
      }
    }

    // add in db
    if (result.length > 0) {
      await addDates(result,true);
    }
    setCopyExecutes(false);
    handleClose();
  };

  const copyDateToTarget = (start: Date, end: Date, federal: string | undefined) => {
    sameDayInInterval(start, end, 'DE', federal || 'BW', copyFrequency);
    handleClose();
  };

  return (
      <>
        <Button disabled={!sourceDay} className='p-0 m-0' variant='light' onClick={handleShow}>
          <Image width='27px' height='27px' alt='' src={Copy} />
        </Button>
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='contained-modal-title-center text-center'>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='px-2 py-1'>
            {' '}
            {t('DUPLICATE_CURR_DATE_MESSAGE') as string}
            {' '}
          </Modal.Body>
          <Modal.Footer className='d-flex justif-content-center'>
            <div className="existing-options mb-3">
              <h5>{t('COPY_USING_PREDEFINED_OPTIONS')}</h5>
              <div className="frequency-selection mb-3 w-100">
                <Form.Check
                    type="radio"
                    label={t('EVERY_WEEK')}
                    name="frequencyOptions"
                    id="frequency1"
                    checked={copyFrequency === 1}
                    onChange={() => setCopyFrequency(1)}
                    className="mb-1"
                />
                <Form.Check
                    type="radio"
                    label={t('EVERY_SECOND_WEEK')}
                    name="frequencyOptions"
                    id="frequency2"
                    checked={copyFrequency === 2}
                    onChange={() => setCopyFrequency(2)}
                    className="mb-1"
                />
                <Form.Check
                    type="radio"
                    label={t('EVERY_THIRD_WEEK')}
                    name="frequencyOptions"
                    id="frequency3"
                    checked={copyFrequency === 3}
                    onChange={() => setCopyFrequency(3)}
                    className="mb-1"
                />
              </div>
              <Button
                  block
                  variant='primary'
                  onClick={() => {
                    const end = moment(sourceDate).add(1, 'day').toDate();
                    copyDateToTarget(sourceDate, end, state);
                  }}
              >
                {`${t('OF_NEXT_DAY') as string}, ${t(
                    moment(sourceDate).add(1, 'day').format('dddd').toString(),
                )}.`}
              </Button>

              <Button
                  block
                  variant='primary'
                  onClick={() => {
                    const end = moment(sourceDate).add(8, 'day').toDate();
                    copyDateToTarget(sourceDate, end, state);
                  }}
              >
                {t('DUP_TO') as string} {t(moment(sourceDate).format('dddd').toString())}{' '}
                {t('OF_NEXT_WEEK') as string}
              </Button>

              <Button
                  block
                  variant='primary'
                  onClick={() => {
                    const endOfMonth = moment(sourceDate).endOf('month').toDate();
                    copyDateToTarget(sourceDate, endOfMonth, state);
                  }}
              >
                {t('DUP_TO_ALL') as string} {t(moment(sourceDate).format('dddd').toString())}
                {t('DAYS_OF_MONTH') as string}
              </Button>

              <Button
                  block
                  variant='primary'
                  onClick={() => {
                    const endOfYear = moment(sourceDate).endOf('year').toDate();
                    copyDateToTarget(sourceDate, endOfYear, state);
                  }}
              >
                {t('DUP_TO_ALL') as string} {t(moment(sourceDate).format('dddd').toString())}
                {t('DAYS_OF_YEAR') as string}
              </Button>
            </div>
            {/* Divider (optional for better UI separation) */}
            <hr />

            {/* New "till end date" option with a heading */}
            <div className="till-end-date-option mb-3 w-100">
              <h5>{t('COPY_TILL_END_DATE')}</h5>

              {/* Checkbox for "only official work days" */}
              <Form.Check
                  type="checkbox"
                  label={t('ONLY_OFFICIAL_WORK_DAYS')}
                  checked={onlyWorkingDays}
                  onChange={(e) => setOnlyWorkingDays(e.target.checked)}
                  className="mb-1 w-100"
              />

              <Form.Check
                  type="checkbox"
                  label={t('ONLY_COPY_KEYWORDS')}
                  checked={onlyCopyKeywords}
                  onChange={(e) => setOnlyCopyKeywords(e.target.checked)}
                  className="mb-1 w-100"
              />

              {/* Date input for setting a maximum date */}
              <Form.Group controlId="formEndDate" className="mb-2 w-100">
                <Form.Label>{t('SET_MAX_DATE')}</Form.Label>
                <Form.Control
                    type="date"
                    defaultValue={(sourceDate.getDate() == 31) && (sourceDate.getMonth() == 11)
                        ? moment(sourceDate).format('YYYY-MM-DD') : moment(sourceDate).add(1, 'days').format('YYYY-MM-DD')}
                    onChange={handleDateChange}
                    max={moment(sourceDate).endOf('year').format('YYYY-MM-DD')}
                    className="w-100"
                />
              </Form.Group>

              {/* Display loading indicator when copyExecutes is true */}
              {copyExecutes && (
                  <div className="d-flex justify-content-center mb-3">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </div>
              )}

              {/* Button for executing copy based on "till end date" */}
              <Button
                  block
                  variant='primary'
                  onClick={() => {
                    copyByRange(sourceDate, maxDate, onlyWorkingDays, 'DE', state);
                  }}
                  className="w-100"
              >
                {t('COPY_TILL_END_DATE')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default DuplicateDayRange;