// disable eslint for this file
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DependentBatchResponse } from '../../../apis/attributes/statistics';
import { calculateStat, formatDuration } from '../../../utility/time';

export const StatisticsSection: React.FC<{ stats: DependentBatchResponse }> = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <Container className='pb-5'>
      <p className='mb-3' style={{ fontWeight: 'bold' }}>
        {' '}
        {t('SALDO_II') as string}{' '}
      </p>
      <hr
        className='mt-5 mb-4'
        style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
      />
      <Row className=''>
        <Col xs={3}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('SALDO_TOTAL') as string}</p>
            <strong>{calculateStat(stats?.dependentBalance)}h</strong>
          </div>
        </Col>

        <Col xs={3}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('SALDO_CARE') as string}</p>
            <strong>{formatDuration(stats?.dependentBalance?.careTimePart)}h</strong>
          </div>
        </Col>

        <Col xs={3}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('SALDO_DISPOSAL') as string}</p>
            <strong>{formatDuration(stats?.dependentBalance?.disposalTimeRegularPart)}h</strong>
          </div>
        </Col>
        <Col xs={3}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('SALDO_IRREGULAR_DISPOSAL') as string}</p>
            <strong>{formatDuration(stats?.dependentBalance?.disposalTimeIrregularPart)}h</strong>
          </div>
        </Col>
      </Row>

      <hr
        className='mt-5 mb-4'
        style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
      />

      <p className='mb-3' style={{ fontWeight: 'bold' }}>
        {' '}
        {t('DETAILED_TIME_STATISTICS') as string}{' '}
      </p>
      <hr
        className='mt-5 mb-4'
        style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
      />

      <Row className=''>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TARGET_TIME_CARE') as string}</p>
            <strong>{formatDuration(stats?.targetTime?.careTimePart)}h</strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('ACTUAL_TIME_CARE') as string}</p>
            <strong>{formatDuration(stats?.actualTime?.actualTimeAccepted?.careTimePart)}h</strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE_CARE') as string}</p>
            <strong>{formatDuration(stats?.balance?.careTimePart)}h</strong>
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TARGET_TIME_DISPOSAL') as string}</p>
            <strong>{formatDuration(stats?.targetTime?.disposalTimeRegularPart)}h</strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('ACTUAL_TIME_DISPOSAL') as string}</p>
            <strong>
              {formatDuration(stats?.actualTime?.actualTimeAccepted?.disposalTimeRegularPart)}h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE_DISPOSAL') as string}</p>
            <strong>{formatDuration(stats?.balance?.disposalTimeRegularPart)}h</strong>
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TARGET_TIME_IRREGULAR') as string}</p>
            <strong>{formatDuration(stats?.targetTime?.disposalTimeIrregularPart)}h</strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('ACTUAL_TIME_IRREGULAR') as string}</p>
            <strong>
              {formatDuration(stats?.actualTime?.actualTimeAccepted?.disposalTimeIrregularPart)}h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE_IRREGULAR') as string}</p>
            <strong>{formatDuration(stats?.balance?.disposalTimeIrregularPart)}h</strong>
          </div>
        </Col>
      </Row>

      <hr
        className='mt-5 mb-4'
        style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
      />
      <p className='mb-3' style={{ fontWeight: 'bold' }}>
        {' '}
        {t('KEYWORD_STATISTICS') as string}{' '}
      </p>
      <hr
        className='mt-5 mb-4'
        style={{ backgroundColor: 'white', border: 'none', height: '8px' }}
      />

      <Row className=''>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('VACATION_LEFT') as string}</p>
            <strong>{stats?.l3?.holiday}</strong>
          </div>
        </Col>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('SICK_DAYS') as string}</p>
            <strong>{stats?.l3?.sick}</strong>
          </div>
        </Col>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('SICK_CHILD') as string}</p>
            <strong>{stats?.l3?.sickChild}</strong>
          </div>
        </Col>

        <Col xs={4} className='mt-3'>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('DAYS_IN_TRAINING') as string}</p>
            <strong>{stats?.l3?.training}</strong>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StatisticsSection;
