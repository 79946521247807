import { observer } from 'mobx-react';
import { useLocalObservable } from 'mobx-react-lite';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { Pending, social } from '../../../apis/social';
import { EmployeeProps } from './CreateEmployee';

export const EmployeeEmail: React.FC<EmployeeProps> = observer(({ employeeId }) => {
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [localEmail, setLocalEmail] = useState<string | undefined>(undefined);

  const history = useHistory();

  const store = useLocalObservable(() => ({
    get facility() {
      return fromPromise(social.service('employee').get(employeeId));
    },
  }));

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}/:employeeId`, { employeeId }),
    });
  }, []);

  const updateDB = async () => {
    setEnableSubmit(false);

    try {
      // await social.service('employee').patch(employeeId, { contactEmail: localEmail! });

      navigateTo('/admin/employee-data');
    } catch (error) {
      setEnableSubmit(true);

      alert('Error: please reload the page');
    }
  };

  return (
    <Container className='pt-5 px-4 pb-4'>
      {store.facility.case({
        fulfilled: () => (
          <>
            {!localEmail && setLocalEmail('contactEmail')}

            <p className='creatorTitle colorBodyText'>Enter a contact email</p>

            <p className='creatorTitle colorBodyText mb-1 mt-4'>
              <strong>Contact email</strong>
            </p>

            <input
              className='w-100 p-3 border'
              placeholder=''
              defaultValue={localEmail || ''}
              onChange={(e) => setLocalEmail(e.currentTarget.value?.toLowerCase())}
            />
          </>
        ),
        pending: () => <Pending />,
        // rejected: () => <Redirect to="/post/general/" />,
      })}

      <Button
        className='py-3 px-3 w-100'
        style={{ marginTop: '350px' }}
        variant='primary'
        disabled={!enableSubmit || !localEmail}
        onClick={() => updateDB()}
      >
        save
      </Button>
    </Container>
  );
});

export default EmployeeEmail;
