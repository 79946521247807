import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import { Alert, Button, Container, Form as ReactForm } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router';
import usePromise from 'react-promise';
import { useTranslation } from 'react-i18next';
import social, { authenticatedEmployee } from '../../../apis/social';
import LanguageSwitcher from '../../LanguageSwitcher';
import { togglePassword } from '../../../utility/common';

export const Signup: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [alert, setAlert] = useState<undefined | string>(undefined);
  const [successAlert, setSuccessAlert] = useState<undefined | string>(undefined);

  const showSuccessAlert = useCallback(
    (msg: string) => {
      setSuccessAlert(msg);
      setTimeout(() => {
        setSuccessAlert(undefined);
      }, 3000);
    },
    [successAlert],
  );

  const auth = usePromise(authenticatedEmployee());

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  useEffect(() => {
    if ((auth?.value as any)?.user && (auth?.value as any)?.user?.role === 'EMPLOYEE')
      navigateTo('/employee/dashboard');
    if ((auth?.value as any)?.user && (auth?.value as any)?.user?.role === 'ADMIN') navigateTo('/');
  }, [(auth?.value as any)?.user]);

  const showAlert = useCallback(
    (msg: string) => {
      setAlert(msg);
      setTimeout(() => {
        setAlert(undefined);
      }, 3000);
    },
    [alert],
  );

  const navigate = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}`),
    });
  }, []);

  const updateDB = useCallback(
    async (data: { email: string; password: string; checkTerms: boolean }) => {
      try {
        const newData = {
          email: data.email?.toLowerCase(),
          password: data.password,
          role: 'ADMIN',
        };

        await (social.service('user') as any).create(newData);

        showSuccessAlert(t('SIGN_UP_SUCCESS'));

        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error: any) {
        showAlert(t('SIGN_UP_ERROR'));
      }
    },
    [],
  );

  return (
    <div
      style={{
        backgroundColor: '#f2fcff',
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: 100,
      }}
    >
      <Container style={{ paddingTop: '80px' }}>
        <Alert className='text-center' variant='danger' hidden={!alert}>
          {alert}
        </Alert>
        <Alert className='text-center' variant='success' hidden={!successAlert}>
          {successAlert}
        </Alert>
        <Formik
          enableReinitialize
          initialValues={{
            checkTerms: false,
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            checkTerms: Yup.bool().oneOf([true], t('REQUIRED')),
            email: Yup.string().email('should be a valid email').required(t('REQUIRED')),
            password: Yup.string()
              .min(5, 'should be at least 5 characters')
              .required(t('REQUIRED')),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              updateDB(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {(props: FormikValues) => (
            <Form>
              <h4 className='text-center'>{t('WELCOME') as string}</h4>

              <p className='text-center mb-3'>{t('ENTER_ACCES_KEY') as string}</p>

              <br />
              <ReactForm.Group className='mt-5 pt-3' controlId='formBasicEmail'>
                <ReactForm.Label>{t('EMAIL_ADDRESS') as string}</ReactForm.Label> <br />
                <input
                  className='w-100'
                  name='email'
                  onChange={(e) => {
                    props.handleChange(e);
                  }}
                  type='email'
                  placeholder=''
                />
                {props?.errors?.email && (
                  <p className='text-danger' style={{ marginTop: '0px' }}>
                    {' '}
                    {props?.errors?.email}{' '}
                  </p>
                )}
                <ReactForm.Label className=' mt-3'>{t('PASSWORD') as string}</ReactForm.Label>
                <input
                  id='passwordInput'
                  className='w-100'
                  name='password'
                  onChange={(e) => {
                    props.handleChange(e);
                  }}
                  type='password'
                  placeholder=''
                />
                {props?.errors?.password && (
                  <p className='text-danger' style={{ marginTop: '0px' }}>
                    {' '}
                    {props?.errors?.password}{' '}
                  </p>
                )}
                <input className='mt-3 mr-2' type='checkbox' onClick={togglePassword} />
                <span>{t('SHOW_PASSWORD') as string}</span>
              </ReactForm.Group>

              {/* accept */}

              <ReactForm.Group controlId='formBasicCheckbox'>
                <ReactForm.Check
                  type='checkbox'
                  name='checkTerms'
                  label={t('ACCEPT_TERMS_PRIVACY') as string}
                  onChange={(e) => {
                    props.handleChange(e);
                  }}
                />
                {props?.errors?.checkTerms && (
                  <p className='mb-3 text-danger' style={{ marginTop: '0px' }}>
                    {' '}
                    {props?.errors?.checkTerms}{' '}
                  </p>
                )}
              </ReactForm.Group>

              <div style={{ height: '110px' }} />
              <Button type='submit' className=' py-2' block>
                {t('NEXT') as string}
              </Button>
            </Form>
          )}
        </Formik>

        {/* <div style={{ height: '100px' }} />
      <Button
        disabled={disableBtn || !email || !pwd}
        type="submit"
        className="py-2 w-100 mt-5"
        onClick={async (e) => {
          try {
            await feathersAuthenticateEmployee(email, pwd);
            localStorage.setItem('terms_of_service_emp', 'true');
            navigateTo('/employee/dashboard');
          } catch (error) {
            //
            showAlert(t('LOGIN_ALERT'));
          }
        }}
      >
        {t('SIGN_UP') as string}
      </Button> */}

        <div
          style={{
            bottom: '100px',
            position: 'absolute',
            right: '10px',
          }}
        >
          <LanguageSwitcher />
        </div>
      </Container>
    </div>
  );
};

export default Signup;
