export default `<h2 id="license-agreement">License Agreement</h2>
<h3 id="browser-based-time-tracking-program-kidigaz">Browser-based time tracking program: KiDigAZ</h3>
<p>Between the</p>
<ol>
<li>Company ictt system-consulting
Owner Karl Cziumplik
Eschholzstrasse 94
79115 Fribourg</li>
</ol>
<p>hereinafter referred to as &quot;main licensee&quot; or &quot;licensor&quot;.</p>
<p>and</p>
<ol>
<li>................................................ ............................ <em>Name of institution etc.</em></li>
</ol>
<p>Represented by</p>
<p>.................................................. .... ........................... <em>(Street and house number)</em></p>
<p>....................... ....................... ............................... <em>(Postal code and location)</em></p>
<p>the purchaser of the license, hereinafter referred to as &quot;sub-licensee&quot; or &quot;licensee&quot;,</p>
<p>is the following</p>
<h3 id="l-i-c-e-n-c-e-n-t-a-l-c-o-u-t-e">L i c e n c e n t a l c o u t e</h3>
<p>completed:</p>
<h4 id="preamble">Preamble</h4>
<p>This (sub)license agreement concerns the temporary transfer of the browser-based
Time recording program: <code>KiDig-AZ</code> in the current version by the licensor,
in particular to legal entities under public law or to a special fund under public law,
or to private providers of kindergartens, to clubs, associations, entrepreneurs and possibly also to private individuals.
The licensor grants the licensee on the basis of this contract for a limited period of time the
use of the software package KiDig time recording program and leaves this program to the licensee
in the current version.</p>
<p>###§ 1 General Provisions</p>
<ol>
<li>The contractual relationships between the parties to this agreement are finalized in this license agreement
regulated. Any individual agreements between Licensor and Licensee shall prevail
unaffected by this. The current version of this license agreement is provided to the licensee by the licensor
provided by e-mail prior to the conclusion of the contract.</li>
</ol>
<ol>
<li>With the conclusion of this license agreement, the licensee accepts the provisions made herein.
Deviating or supplementary general terms and conditions of the licensee do not apply.
Their validity is hereby expressly contradicted, even in the event that the licensor is aware of such
General Terms and Conditions of the licensee concludes the license agreement with the licensee.</li>
</ol>
<ol>
<li>If the licensee is not a &quot;consumer&quot; within the meaning of § 13 BGB, then the provisions apply
§§ 312 g para. 1 sentence 1 no. 1-3 sentence 2, 343 BGB in the relationship between the licensor and the licensee
no use.</li>
</ol>
<p>###§ 2 Subject of the contract</p>
<ol>
<li><p>The subject matter of this license agreement is the paid license limited to the term of this license agreement
of the browser-based time recording program KiDigAZ by the licensor for use in accordance with § 5 of this
Agreement to Licensee.</p>
</li>
<li><p>Part of the KiDig software is a browser-based kindergarten time recording system KiDigAZ for use
in kindergartens. A more detailed description of the functionalities of the software is attached to the software
refer to the service description. A data carrier with the program versions, or a printed version
Documentation is not included in the scope of delivery. Rather, the documentation consists only of the information provided by the licensor
provided electronic documents, which, like the software itself, can be accessed via e-mail or browser from the licensor
to provide. The licensee has no further claim to documentation
or assistance, i.e. in particular no right to the provision of documentation in a specific
scope or a certain quality or on individual assistance from the licensor.
If the licensee nevertheless desires such a thing, a separate agreement on this is required
separate paid activities between the licensor and the licensee.</p>
</li>
</ol>
<ol>
<li>For the nature and functionality of the browser-based software provided by the licensor
the service description valid at the time the contract was concluded and which can be requested by e-mail at any time
Software finally decisive.
At the request of the licensee, the licensor will send the service description of the software by post or email
available before the conclusion of the contract.
The licensor does not owe a quality of the software that goes beyond the service description.
In particular, the licensee cannot derive such an obligation from other representations of the software package
in public statements or in the advertising of third parties, unless the licensor has the licensee
a quality that goes beyond the service description is expressly guaranteed. As by the licensor
In this respect, only those properties of the software that were guaranteed by the licensor before the conclusion of this contract apply
expressly expressed in writing to the licensee as part of the license agreement and as guaranteed properties
have been designated.
The interoperability with the hardware and software available at the licensee is not
owed quality of the licensed software, unless expressly compatible in the service description
hardware and software is shown.</li>
</ol>
<ol>
<li>The licensor expressly advises the licensee that
that the licensed browser-based time recording program can only be used if and to the extent
insofar as the licensee guarantees access to the Internet.</li>
</ol>
<h3 id="-3-contract-duration">§ 3 Contract duration</h3>
<ol>
<li><p>The license agreement runs for an indefinite period, beginning with the payment of the fee agreed upon when the order 
was placed.</p>
</li>
<li><p>The minimum contract term is one year. Ordinary termination is for the first time at the end of the minimum term
possible, thereafter at the end of each calendar year, subject to a notice period of
three months.</p>
</li>
</ol>
<ol>
<li>The right to extraordinary termination for important reasons remains unaffected.
An important reason that entitles the licensor to extraordinary termination is also given in particular if
if the licensee defaults on his payment obligations or if the licensee
Contractual penalty according to § 7 of this agreement is due. In addition, if Licensee breaches the
licensee against legal prohibitions, in particular the violation of copyright, competition law,
naming rights or data protection regulations exist, the licensee is national socialist,
racist, extremist, violent, pornographic or otherwise illegal content
published, the licensee, despite a warning from the licensor, continues to violate the provisions of the
concluded contract, the licensees illegal copies of the browser-based
Time recording program created, the technical or other standards change fundamentally.</li>
</ol>
<ol>
<li>Each notice of termination must be in writing to be effective, which is also observed if the notice of termination is given
by e-mail or by fax.</li>
</ol>
<ol>
<li>In the event of extraordinary termination, the licensee is responsible for deleting all of the licensee&#39;s and his
Program copies available to institutions and for the return of other associated material such as the
User Documentation - Committed within 3 months.</li>
</ol>
<h3 id="-4-provision-of-the-software-and-activation-code">§ 4 Provision of the Software and Activation Code</h3>
<ol>
<li>The browser-based time recording program will be provided by email immediately after
Conclusion of the contract and payment of both the basic fee and the license fee due by the licensee.</li>
</ol>
<ol>
<li>The Licensor provides the Licensee with patches or updates that replace previous versions of the software package
(&quot;Old Software&quot;), the patches and updates are also subject to the terms of this license agreement and
on the other hand, the licensee&#39;s rights to the old software expire even without an express return request
of the licensor when the licensee installs and puts this new version into operation.
For old software, paragraph 5 below applies accordingly with the proviso that instead of the end of the contract
the point in time when the patches or updates are put into operation.</li>
</ol>
<ol>
<li>The licensee is solely responsible for the computer hardware.</li>
</ol>
<ol>
<li>The licensee will receive an activation code from the licensor by e-mail to activate the browser-based
working time recording program after the start of the contract and prior payment of the basic fee and the current
license fee. This activation code must be kept secret by the licensee. The licensee warrants to the licensor
hereby agrees that he will also take suitable measures within his company/operation to ensure that the
Activation code is only accessible to those employees who need it to start up the software.
In addition, the licensee will ensure that these employees do not give the activation code to others
employees or third parties, and the activation code is not used by those employees for any other purpose
than the commissioning of the software package.</li>
</ol>
<ol>
<li>At the end of the contract period, the licensee is obliged to return all copies in his possession
to delete, destroy or hand over to the licensor the software and other copies.</li>
</ol>
<h3 id="-5-rights-of-use">§ 5 Rights of Use</h3>
<ol>
<li>The licensor grants the licensee subject to the condition precedent of prior full payment
the remuneration agreed for an individual remuneration section for the duration of this remuneration section
a simple, non-exclusive, territorially unrestricted, non-transferable, non-sublicensable and
the licensor&#39;s irrevocable right to use the software himself within the framework of its intended purpose
functions solely for the Licensee&#39;s own purposes. Licensee acquires the browser-based
Time recording program not through purchase, the subject of this contract is exclusively the time
limited paid use of the browser-based time recording program (&quot;rent&quot;).</li>
</ol>
<ol>
<li>Use is restricted to employees in the facility managed by the licensee.</li>
</ol>
<ol>
<li>The licensee is not entitled to the source code being made available or to the granting of rights of use.</li>
</ol>
<ol>
<li>Any use of the browser-based time recording program beyond the scope agreed for the license
(&quot;Overuse&quot;) is a breach of contract. For the period of such overuse, i.e. until the purchase of the
required license or the cessation of overuse by the licensee, the licensee is obliged to
to the licensor the remuneration for an adequate license according to the price and
Licensor&#39;s bill of quantities to be paid. The Licensor&#39;s current list of prices and services
can be requested at any time by email.</li>
</ol>
<h3 id="-6-compensation">§ 6 Compensation</h3>
<ol>
<li>Upon conclusion of this contract, a fee to be paid by the licensee
(one-time) basic fee payable at the rate of currently
€</li>
</ol>
<ol>
<li>In addition, the licensee has a license fee of currently monthly
€ to be paid. This license fee is equal to one annual license fee
(currently 12 x € ... -- = € , --) due for payment in advance, namely
for the first time (together with the basic fee) upon conclusion of this contract,
and then annually on September 30th. of every year. 
Are between the time of the conclusion of the contract and the next 30.09. less than 12 months, the discounted
license fee for this period to the amount resulting from multiplying the monthly fee by the
Number of months between the conclusion of the contract and the next 31.12. results. The licensor expressly points 
this out that the current version of the for the following year will only be made available to the licensee
if he has previously paid the annual license fee in advance on the due date (09/30 of each year).</li>
</ol>
<ol>
<li><p>For the amount of remuneration in future years, the currently valid price and conditions list of the licensor,
which is sent to existing customers by e-mail when it is published. This is a price increase to a maximum
Capped at 10% per year.</p>
</li>
<li><p>The remuneration includes sales tax. The licensor will invoice the licensee shortly after collection
be sent in PDF format by email.</p>
</li>
</ol>
<ol>
<li>The licensor is entitled to collect the payment due by direct debit.
The licensee authorizes the licensor to deduct the respective invoice amount from that to be named by the licensee
to collect a bank account. This direct debit authorization can be freely revoked at any time. If the direct debit 
authorization from be revoked by the licensee or otherwise be ineffective, the licensor reserves the right for each 
payment to charge a processing fee in accordance with the current list of prices and services.</li>
</ol>
<ol>
<li>The licensee is not entitled to offset claims against the licensor that are not
have been legally established or recognized by the licensor or are undisputed. This does not apply
if the licensee&#39;s claims for defects are based on this contract.</li>
</ol>
<h3 id="-7-obligations-of-the-licensee">§ 7 Obligations of the Licensee</h3>
<ol>
<li>The number of employees per facility at the time the contract is concluded (key date) is decisive for the remuneration.
The licensee informs the licensor of this upon conclusion of the contract. In the event of an extension of the contract period
the licensee shall inform the licensor of the current number of employees no later than 6 months
Contract extension with.</li>
</ol>
<ol>
<li>The licensee undertakes to protect the licensor&#39;s trade and business secrets and the documents
as far as they relate to this and the contractual relationship, to be stored in such a way that third parties are not accessible.
He has all the knowledge acquired during the contract period, business processes and internal,
in particular, to keep confidential matters confidential even after the end of the contract.</li>
</ol>
<ol>
<li>The licensor remains the owner of all rights to the program provided by the licensee, including the
related material, even if the licensee modifies it or uses its own programs or
connects that of a third party. For changes or connections of this kind as well as for the creation of copies
the licensee attaches a corresponding copyright notice and provides the licensor with a copy free of charge
Disposal.</li>
</ol>
<ol>
<li>The licensee grants the licensor the rights to his data and transmitted content such as logos
copyright rights of use and other powers necessary for the implementation of the contractual relationship
required are.</li>
</ol>
<h3 id="-8-obligations-of-the-licensor">§ 8 Obligations of the licensor</h3>
<ol>
<li>The licensor supports the licensee with the setup and with questions about the use and application
of the browser-based time recording program in his area, as well as the elimination of defects free of charge.</li>
</ol>
<ol>
<li>The licensee names two contact persons for the licensor. Only these may be sent directly by e-mail free of charge
Contact the licensor.</li>
</ol>
<ol>
<li>There is no free assistance (support) from the licensee for the respective end users Licensors under this Agreement.</li>
</ol>
<h3 id="-9-contractual-penalty">§ 9 Contractual penalty</h3>
<ol>
<li>If the licensee violates his contractual obligations according to § 4 paragraph 4, § 4 paragraph 5 or § 5 paragraph 1, for each
Violation of a contractual penalty to be paid to the licensor in the amount of € 1,000.00 is due. The pleas of natural
or legal entity, the lack of liability for vicarious agents as well as general intent
locked out. The contractual penalty is not due if the licensee is not responsible for the infringement.
In addition, the licensee reserves the right to prove that the licensor has no or only
much less damage was caused.</li>
</ol>
<ol>
<li>Due contractual penalties will be added to the licensor&#39;s claim for damages due to the same breach of duty
credited. The licensor reserves the right to prove further damage.</li>
</ol>
<h3 id="-10-material-defects">§ 10 Material defects</h3>
<ol>
<li>The browser-based time recording program has the agreed quality and is suitable for the
contractually required, in the absence of an agreement for normal use. It satisfies the criterion
practicality and has the usual quality for software of this kind. However, like any software, it is
not absolutely flawless. A functional impairment of the program resulting from hardware defects, environmental conditions,
Operating errors or the like are not a defect. In addition, there remains an insignificant reduction in quality
disregarded.</li>
</ol>
<ol>
<li><p>If a defect becomes apparent, the licensor must be informed of this immediately. In the case of material defects, the
Licensor first to perform. Subsequent performance is at the discretion of the licensor by eliminating the defect,
by supplying software that does not have the defect, or by the licensor pointing out ways
to avoid the effects of the defect. Because of the defect, at least three attempts at rectification must be accepted.
An equivalent new program version or the equivalent previous program version without the error
to be taken over by the licensee if this is reasonable for him.</p>
</li>
<li><p>The licensor can choose to remedy the defect on site or at his own business premises
or provide services through remote maintenance.</p>
</li>
</ol>
<ol>
<li>The licensor can demand additional costs if the software is changed outside of the intended environment
used or misused. He can also demand reimbursement of expenses if no defect is found and
the licensee had not raised the notice of defects without negligence. The burden of proof lies with the licensee.</li>
</ol>
<ol>
<li>If the licensor finally refuses the subsequent performance or this finally fails or the licensee
unreasonable, the licensee can either withdraw from the contract or the remuneration is reasonable
reduce or - under the following conditions - demand compensation or reimbursement of expenses. On the
Reference is made to the relevant limitation periods in accordance with Section 11 of this contract.</li>
</ol>
<ol>
<li>The licensee&#39;s claims for liability for material defects shall lapse if the licensee does so without the prior consent of the licensor
has made changes to the software or had them made by a third party or the software from
Licensee is used for a purpose not covered by the license agreement, and if the change or the
non-contractual use is solely responsible for the occurrence of the defect.</li>
</ol>
<ol>
<li>Licensor&#39;s no-fault liability for the software at the time the software was made available
existing defects are excluded.</li>
</ol>
<h3 id="-11-legal-defects">§ 11 Legal defects</h3>
<ol>
<li>The licensor guarantees that the contractual use of the software by the licensee has no rights
opposed to third parties. In the event of legal defects, the licensor warrants that he will ask the licensee
of his choice provides a legally impeccable opportunity to use the software.</li>
</ol>
<ol>
<li>The licensee shall inform the licensor immediately in writing if third parties have property rights
(e.g. copyright or patent rights) to the software. The licensee authorizes the licensor to
to conduct the dispute with the third party alone. As long as the licensor exercises this authorization
makes, the licensee may not recognize the claims of the third party without the consent of the licensor;
the licensor then fends off the claims of the third party at his own expense and provides the licensee with the
Defense of these claims free of charge, insofar as these are not based on the licensee&#39;s breach of duty
(e.g. the non-contractual use of the browser-based time recording program).</li>
</ol>
<h3 id="-12-liability-and-limitation-of-liability">§ 12 Liability and Limitation of Liability</h3>
<ol>
<li><p>The licensor pays damages or reimbursement for wasted expenses, regardless of the legal reason
(e.g. from legal and quasi-legal obligations, material and legal defects,
breach of duty and tort) only to the following extent:</p>
<p>a) Liability in the event of intent and under guarantee is unlimited.</p>
<p>b) In the event of gross negligence, the licensor is liable to the amount of the typical and at the time of conclusion of the contract
foreseeable damage.</p>
<p>c) In the case of non-grossly negligent breach of an obligation that is so essential that the purpose of the contract is not achieved
is endangered, the licensor is liable in the amount of the typical damage that was foreseeable at the time the contract was concluded,
However, no more than €10,000 per claim and €50,000 for all claims arising from and in connection with this
with the contract as a whole.</p>
</li>
</ol>
<ol>
<li>The objection of contributory negligence remains open to the licensor. In particular, the licensee has the obligation to
Data backup and defense against malware in each case according to the current state of the art.</li>
</ol>
<ol>
<li><p>In the case of fraudulent intent, injury to life, limb and health and in the case of claims under the Product Liability Act
the legal regulations without restrictions.</p>
</li>
<li><p>The licensor is only responsible for disruptions to performance insofar as these are to be provided by him
pertain to services. The licensor has disruptions within the scope of the technical and operational possibilities
to be eliminated immediately. The licensee is obliged to notify the licensor of any disruptions that he can identify
to be reported immediately. If the fault is not eliminated within a reasonable period of time,
the licensee must set the licensor a reasonable grace period.</p>
</li>
</ol>
<ol>
<li>The licensor accepts no liability for direct or indirect damage due to technical problems,
Server failure, data loss, transmission errors, data insecurity or other reasons, unless he can
Willful intent or gross negligence can be proven. All claims of the licensee are based on the order value
limited where permitted by law.</li>
</ol>
<ol>
<li>The licensor has unlimited liability to the customer for damage caused by him or one of his vicarious agents
or legal representatives are caused intentionally or through gross negligence. The is liable for slight negligence
Licensor only insofar as an obligation is violated, compliance with which is necessary for the achievement of the purpose of the contract
is of particular importance (cardinal obligation). In this case, however, the amount is limited to the contract-typical
and foreseeable damage. In addition, the licensor is liable for claims under the Product Liability Act,
as well as due to other mandatory legal liability regulations. In the case of damage resulting from injury to life,
of the body or health, liability is also limited in the event of a simple breach of duty by the licensor or
one of his legal representatives or vicarious agents is unlimited in amount.</li>
</ol>
<ol>
<li>The licensor is released from the obligation to perform in cases of force majeure. All are considered force majeure
unforeseen events as well as those events which affect the performance of the contract by either party
are to be represented. These events include, in particular, lawful industrial action, also in third-party companies,
official measures, failure of communication networks and gateways of other operators, disruptions in the area of</li>
</ol>
<ol>
<li>Managers, other technical disruptions, even if these circumstances are in the area of ​​subcontractors,
subcontractors or their subcontractors. The licensee represents the licensor in this regard
from all claims of third parties.</li>
</ol>
<ol>
<li>There are no claims for damages or other claims for the licensee if they are not made by the licensor
responsible failures.</li>
</ol>
<h3 id="-13-data-protection">§ 13 Data Protection</h3>
<ol>
<li>The Licensee is hereby informed in accordance with the European General Data Protection Regulation (GDPR) that the
licensor collects his data in machine-readable form and automatically for tasks resulting from the contract
processed. In addition, login and access data are stored for evidence purposes. Licensee agrees
expressly to. Insofar as the licensor uses third parties to provide the contractually owed services,
he is entitled to disclose the participant data if this is necessary for the provision of the service.</li>
</ol>
<ol>
<li>By signing this contract, the licensee agrees to the attached agreement on order processing
as well as Annex 1 - TOMs (Technical-Organizational Measures).</li>
</ol>
<ol>
<li><p>The following personal data is collected and stored as part of the contract:</p>
<p>a) Data categories of AZ-Listen users:
Inventory data: first and last name of the AZ list user, signature (encrypted),
Contact details: email address and password (encrypted)
log data and</p>
<p>b) Data categories of those responsible at the institutions / institutions:
Inventory data: first and last name; Address data of the institution (street, house number, zip code, city)
Contact details: email address and password (encrypted), telephone number of the institution
Contract data: time, content, payment information log data</p>
</li>
</ol>
<h3 id="-14-set-off-right-of-retention">§ 14 Set-off, right of retention</h3>
<ol>
<li>The licensee is only entitled to set-off if his counterclaims have been recognized by the licensor or
have been legally established.</li>
</ol>
<ol>
<li>The licensee is only authorized to exercise a right of retention to the extent that his counterclaim
based on the same contractual relationship.</li>
</ol>
<h3 id="-15-applicable-law-place-of-jurisdiction-place-of-performance">§ 15 Applicable law, place of jurisdiction, place of performance</h3>
<ol>
<li><p>The law of the Federal Republic of Germany applies exclusively to this contract, excluding the
UN sales law applicable.</p>
</li>
<li><p>Place of jurisdiction for all disputes arising from or in connection with this contract is Freiburg
im Breisgau, insofar as the customer is a merchant or a legal entity under public law or
is a special fund under public law. Place of fulfillment for all from this or in connection with this contract
resulting liabilities, is exclusively Freiburg im Breisgau.</p>
</li>
</ol>
<h3 id="-16-statute-of-limitations">§ 16 Statute of limitations</h3>
<ol>
<li><p>The limitation period is</p>
<p>a) for claims for repayment of remuneration from the assertion of a right of withdrawal or from
Reduction one year from delivery of the software, but not less than three months for properly notified defects
Submission of the effective declaration of withdrawal or reduction;</p>
<p>b) for other claims from material defects also one year;</p>
<p>c) in the case of claims arising from defects of title, two years if the defect of title does not relate to the rights of a third party
lies on the basis of which the third party demands the return of the software supplied or the omission to use the
software package may require;</p>
<p>d) in the case of claims for damages or compensation that are not based on defects in quality or title
expenses two years; the period begins at the point in time at which the licensee is informed of the
circumstances gained knowledge, or could gain knowledge without gross negligence.</p>
<p>e) The statute of limitations begins at the latest with the expiry of the maximum periods specified in § 199 BGB.</p>
</li>
</ol>
<ol>
<li>In the case of damages and reimbursement of expenses due to intent, gross negligence, guarantee, fraudulent intent and in § 10 paragraph 3
However, the statutory limitation periods always apply in the cases mentioned.</li>
</ol>
<h3 id="-17-program-maintenance">§ 17 Program maintenance</h3>
<p>The licensor will maintain the program in such a way that it implements changes of the law that affects the content
of the software product. The licensee is entitled to in the autumn of each year.
Provision of the subsequent version of the software package valid for the next year - but only if he
the license fee fixed in § 6 paragraph 2 is paid in due time for one year in advance. Incidentally, the licensor
not obliged to provide support outside of warranty liability.</p>
<h3 id="-18-final-provisions">§ 18 Final Provisions</h3>
<ol>
<li>If the licensee is a merchant, a legal entity under public law or a special fund under public law,
is the exclusive place of jurisdiction for all matters arising directly or indirectly from the contractual relationship
Disputes of the seat of the licensor. This provision does not apply if the dispute is other
as pecuniary claims, or if another exclusive place of jurisdiction for the dispute
is justified under the law.</li>
</ol>
<ol>
<li>The licensee&#39;s claims arising from this license agreement may only be assigned in writing beforehand
Approval by Licensor.</li>
</ol>
<ol>
<li>The parties have not made any verbal ancillary agreements to these conditions and the license agreement.
Changes or additions to the license agreement as well as contract-related declarations by one party to the other party
require the written form. This also applies to the lifting of the writing requirement.
The written form is also maintained by the text form (§ 126 b BGB), in particular by e-mail and fax.</li>
</ol>
<ol>
<li>Should any provision of this contract be wholly or partially void, ineffective or unenforceable or
this does not affect the validity of the remaining contractual provisions. Instead of the void, ineffective or
unenforceable provision, a provision shall come into force that comes closest to what the parties agree upon
sense and purpose of this contract, they would have wanted this in the light of the nullity, ineffectiveness or
impracticability considered. This also applies in the event of the nullity, ineffectiveness or unenforceability of an
performance or timing contained in this contract. In this case, the legally permissible service or
Determination of time as agreed that comes closest to what has been agreed. Sentences 1 and 2 apply accordingly to
gaps in this contract.</li>
</ol>
<p>Freiburg, the ..............................................................................</p>
<p>Location, ....................................................................................</p>
<p>....................................................................................................</p>
<p>(licensor)</p>
<p>....................................................................................................</p>
<p>(Licensee)</p>
<p>Status 04/2022, version 1.1.5</p>
`;
