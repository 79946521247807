import * as React from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import QuestionMark from '../images/question-mark.png';

export const InfoModal: React.FC<{ content: string }> = ({ content }) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='outline-light' onClick={handleShow}>
        <Image src={QuestionMark} width='25px' height='25px' />
      </Button>

      <Modal className='w-100 mr-5' centered show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body className='px-3 py-4'>{parse(content)}</Modal.Body>
        <Modal.Footer className='d-flex justif-content-center'>
          <Button
            variant='outline-dark'
            onClick={async () => {
              handleClose();
            }}
          >
            {t('CLOSE') as string}
          </Button>
          {/* <Button
            className="px-5"
            variant="primary"
            onClick={handleClose}
          >
            {t('CANCEL') as string}
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModal;
