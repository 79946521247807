import { Duration } from 'luxon';
import { IntervalCollection } from "./intervalCollection";

/**
 * Allows for relatively generic rule of when a pause has to be done
 * @property firstPauseAfter: maximum amount of time one might work for the first interval before a pause is necessary
 * @property secondPauseAfter: maximum amount of time one might work for the second interval before a pause is necessary
 * @property pauseLengthFirst: length of pause1
 * @property pauseLengthSecond: length of pause2
 * @property minPauseLength: minimum amount of time that one might take as a pause
 */
export class PauseCondition {
    constructor(
        public firstPauseAfter: Duration = Duration.fromObject({ hours: 6 }),
        public secondPauseAfter: Duration = Duration.fromObject({ hours: 9 }),
        public pauseLengthFirst: Duration = Duration.fromObject({ minutes: 30 }),
        public pauseLengthSecond: Duration = Duration.fromObject({ minutes: 45 }),
        public minPauseLength: Duration = Duration.fromObject({ minutes: 15 })
    ) {
        if (firstPauseAfter >= secondPauseAfter) {
            throw new Error(`${firstPauseAfter} needs to be < ${secondPauseAfter}.`);
        }
        if (pauseLengthFirst >= pauseLengthSecond) {
            throw new Error(`${pauseLengthFirst} needs to be < ${pauseLengthSecond}`);
        }
    }

    /**
     * Check for a given interval how long a pause should be present
     * @param durationWorked: amount of time that was worked
     * @return: length of pause that should have been done
     */
    targetPause(durationWorked: Duration): Duration {
        if (durationWorked <= this.firstPauseAfter) {
            return Duration.fromMillis(0);
        } else if (
            durationWorked > this.firstPauseAfter &&
            durationWorked <= this.secondPauseAfter
        ) {
            return this.pauseLengthFirst;
        }

        return this.pauseLengthSecond;
    }

    toString(): string {
        return `first pause after: ${this.firstPauseAfter},
     length first: ${this.secondPauseAfter},
     second pause after: ${this.pauseLengthFirst},
     length second: ${this.pauseLengthSecond},
     minimal pause length: ${this.minPauseLength}`;
    }
}

/**
 * Calculate pause deduction
 * @param intervals: IntervalCollection to calculate pause deduction for
 * @param hasPauseDeduction: pause type
 * @param pauseCondition: set of rules what defines a pause
 * @return: Duration of calculated pause deduction
 */
export function calculateDeductionPauses(
    intervals: IntervalCollection,
    hasPauseDeduction: boolean,
    pauseCondition: PauseCondition
): Duration {
    if (!hasPauseDeduction) {
        return Duration.fromMillis(0);
    }

    const interceptions = intervals.getIntervalInterceptions();

    let accumWorkTimePassed = Duration.fromMillis(0);
    let realPause = Duration.fromMillis(0);

    let forcePause = Duration.fromMillis(0);
    let totalPause: Duration;

    let pauseToDo: Duration;

    intervals.intervals.forEach((item, index) => {
        if (index === 0) {
            // for the first interval, time passed is only the length of the first interval
            accumWorkTimePassed = item.length();
            pauseToDo = pauseCondition.targetPause(accumWorkTimePassed);
        } else {
            // check if a pause was made
            if (interceptions[index - 1] >= pauseCondition.minPauseLength) {
                realPause = realPause.plus(interceptions[index - 1]);
                realPause = realPause.minus(
                    Duration.fromMillis(realPause.as("milliseconds") % pauseCondition.minPauseLength.as("milliseconds"))
                );
            }

            // check the amount of time passed
            accumWorkTimePassed = accumWorkTimePassed.plus(item.length());
            pauseToDo = pauseCondition.targetPause(accumWorkTimePassed);
        }

        // total pause summed by force pause and real pause
        totalPause = realPause.plus(forcePause);

        // check if another force pause is necessary
        if (totalPause < pauseToDo) {
            forcePause = forcePause.plus(pauseToDo.minus(totalPause));
        }
    });

    return forcePause;
}

/**
 * Calculate how much real pause was made
 * @param intervals: IntervalCollection to check for pause
 * @param hasPauseDeduction: pause type
 * @param pauseCondition: set of rules that define a pause
 * @return: Duration of real pause done
 */
export function calculateRealPause(
    intervals: IntervalCollection,
    hasPauseDeduction: boolean,
    pauseCondition: PauseCondition
): Duration {
    let minPauseLength = Duration.fromMillis(0);
    let realPause = Duration.fromMillis(0);

    if (hasPauseDeduction) {
        minPauseLength = pauseCondition.minPauseLength;
    }

    const interceptions = intervals.getIntervalInterceptions();

    intervals.intervals.forEach((_, index) => {
        if (index > 0) {
            // check if a pause was made
            if (interceptions[index - 1] >= minPauseLength) {
                realPause = realPause.plus(interceptions[index - 1]);
            }
        }
    });

    return realPause;
}
