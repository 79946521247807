import React from 'react';
import { Route } from 'react-router-dom';
import CheckEmail from './containers/CheckEmail';
import Login from './containers/Login';
import RecoverPwdConfirm from './containers/RecoverPwdConfirm';
import RecoverPwdEmail from './containers/RecoverPwdEmail';

export default () => (
  <>
    <Route exact path='/employee/login' render={() => <Login />} />
    <Route exact path='/reset_email' render={() => <RecoverPwdEmail />} />
    <Route exact path='/reset_pwd' render={() => <RecoverPwdConfirm />} />
    <Route exact path='/reset_check_email' render={() => <CheckEmail />} />
  </>
);
