import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type TopOverviewSectionProps = {
  totalTimeVal: string;
  timeWorkedVal: string;
  balanceVal: string;
  overtimeVal: string;
  breakVal?: 1 | 2;
};

export const TopInlineOverviewSection: React.FC<TopOverviewSectionProps> = ({
  totalTimeVal,
  timeWorkedVal,
  balanceVal,
  overtimeVal,
  breakVal = 2,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row className='py-3 gy-5' style={{ borderRadius: '8px' }}>
        <Col xs={3 * breakVal}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TOTAL_TIME') as string}</p>
            <strong>
              {totalTimeVal}
              <small>h</small>
            </strong>
          </div>
        </Col>

        <Col xs={3 * breakVal}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TIME_WORKED') as string}</p>
            <strong>
              {timeWorkedVal}
              <small>h</small>
            </strong>
          </div>
        </Col>

        <Col xs={3 * breakVal} className={breakVal === 2 ? 'mt-3' : ''}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE') as string}</p>
            <strong style={{ color: '#ED4B9E' }}>
              {balanceVal}
              <small>h</small>
            </strong>
          </div>
        </Col>

        <Col xs={3 * breakVal} className={breakVal === 2 ? 'mt-3' : ''}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('OVERTIME') as string}</p>
            <strong>
              {overtimeVal}
              <small>h</small>
            </strong>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TopInlineOverviewSection;
