import {DateTime} from "luxon";

export enum INTERVALTYPE {
    CARE = 'Kindzeit/Verwaltung',
    DISPOSAL = 'Verfügungszeit',
    IRREGULAR_DISPOSAL = 'Verfügung unregelmäßig',
}

export const translateIntervalType = (t: INTERVALTYPE, language: string = 'de') => {
    switch (language){
        case 'en':
            switch (t) {
                case INTERVALTYPE.CARE:
                    return 'Child / Governance'
                case INTERVALTYPE.DISPOSAL:
                    return 'Disposal'
                case INTERVALTYPE.IRREGULAR_DISPOSAL:
                    return 'Disposal irregular'
                default:
                    return 'Unknown'
            }
        default:
            return t;
    }
}

export enum DAYTYPE {
    UNKNOWN = "Unbekannt",
    FACILITY_WORKDAY = "Einrichtung geöffnet",
    FACILITY_CLOSED_DAY = "Einrichtung geschlossen",
    FEIERTAG = "Feiertag",
    USER_PROVIDED_FEIERTAG = "Feiertag extra",
    FACILITY_HOLIDAY = "Einrichtung Ferien",
}

export function translateDayType(dayType: DAYTYPE, language: string = 'de'): string {
    return dayType;
}

export enum KEYWORD {
    NONE = "nichts",
    HOLIDAY = "Urlaub",
    HOLIDAY_HALF = "Urlaub halber Tag",
    HOLIDAY_SPECIAL = "Sonderurlaub",
    HOLIDAY_UNPAID = "unbezahlter Urlaub",
    EDUCATION = "Fortbildung",
    EDUCATION_K1 = "Fortbildung K1",
    EDUCATION_INDIVIDUAL = "Fortbildung individuell",
    SCHOOL = "Schule",
    SCHOOL_INDIVIDUAL = "Schule individuell",
    SICK = "krank",
    SICK_ATTEST = "krank mit Attest",
    AZ_COMPENSATION = "Arbeitszeitausgleich",
    SICK_CHILD = "krank Kind",
    MISSING = "Fehltag",
    FEIERTAG_FULL_COMPENSATION = "Feiertag Lohnausgleich",
    CONVERSION_DAY = "Umwandlungstag",
    REGENERATION = "Regenerationstag",
}

export function translateKeyWord(keyword: KEYWORD, language: string = 'de'): string {
    return keyword;
}

// ADDITION.ts
export enum ADDITION {
    NIGHT = "Wochentag",
    SATURDAY = "Samstag",
    SUNDAY = "Sonntag",
    FEAST = "Feiertag",
    FULL_COMPENSATION = "Feiertag Lohnausgleich",
    DEC_24 = "24. Dezember",
    DEC_31 = "31. Dezember",
}
export enum WEEKDAY {
    MONDAY = "Montag",
    TUESDAY = "Dienstag",
    WEDNESDAY = "Mittwoch",
    THURSDAY = "Donnerstag",
    FRIDAY = "Freitag",
    SATURDAY = "Samstag",
    SUNDAY = "Sonntag",
}
export enum MONTH {
    JANUARY = "Januar",
    FEBRUARY = "Februar",
    MARCH = "März",
    APRIL = "April",
    MAY = "Mai",
    JUNE = "Juni",
    JULY = "Juli",
    AUGUST = "August",
    SEPTEMBER = "September",
    OCTOBER = "Oktober",
    NOVEMBER = "November",
    DECEMBER = "Dezember",
}

export function translateAddition(addition: ADDITION, language: string = 'de'): string {
    return addition;
}

export enum TARIFFTYPE {
    AVERAGE_DAILY = "durchschnittlich tägliche Arbeitszeit",
    FIXED = "fester Rahmendienstplan",
}

export function translateTariffType(tariffType: TARIFFTYPE, language: string = 'de'): string {
    return tariffType;
}

export function dateToWeekday(date: DateTime): WEEKDAY {
    const dayIndex = date.weekday - 1;
    switch (dayIndex) {
        case 0:
            return WEEKDAY.MONDAY;
        case 1:
            return WEEKDAY.TUESDAY;
        case 2:
            return WEEKDAY.WEDNESDAY;
        case 3:
            return WEEKDAY.THURSDAY;
        case 4:
            return WEEKDAY.FRIDAY;
        case 5:
            return WEEKDAY.SATURDAY;
        case 6:
            return WEEKDAY.SUNDAY;
        default:
            return WEEKDAY.MONDAY;
    }
}