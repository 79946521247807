import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const CheckEmail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: '#f2fcff',
        bottom: '0',
        left: '0',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: 100,
      }}
    >
      <Container style={{ paddingTop: '80px' }}>
        <h4 className='text-center'>{t('EMAIL_SENT') as string}</h4>

        <p className='text-center mb-3 mt-5'>{t('CHECK_INBOX') as string}</p>
      </Container>
    </div>
  );
};

export default CheckEmail;
