import TimeTriplet from './TimeTriplet';

class TimeSummary {
  targetTimeDetailed: TimeTriplet;

  actualTimeDetailed: TimeTriplet;

  balanceDetailed: TimeTriplet;

  targetTimeTotal: string;

  actualTimeTotal: string;

  balanceTotal: string;

  constructor(
    targetTimeDetailed: TimeTriplet,
    actualTimeDetailed: TimeTriplet,
    balanceDetailed: TimeTriplet,
    targetTimeTotal: string,
    actualTimeTotal: string,
    balanceTotal: string,
  ) {
    this.targetTimeDetailed = targetTimeDetailed;
    this.actualTimeDetailed = actualTimeDetailed;
    this.balanceDetailed = balanceDetailed;
    this.targetTimeTotal = targetTimeTotal;
    this.actualTimeTotal = actualTimeTotal;
    this.balanceTotal = balanceTotal;
  }
}

export default TimeSummary;
