import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopBar from '../Layout/containers/TopBar';
import ReadWeeklyWorkingTime from './container/ReadWeeklyWorkingTime';
import { ReadEmploymentContract } from './container/ReadEmploymentContract';

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Route
        exact
        path='/read-employment-hours/:employmentId'
        render={({ match }) => (
          <TopBar progress={100} title={t('WEEKLY_WORKING_HOURS') as string}>
            <ReadWeeklyWorkingTime employmentId={match.params.employmentId} />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/read-employment/:employmentId'
        render={({ match }) => (
          <TopBar progress={100} title={t('EMPLOYMENT_CONTRACT') as string}>
            <ReadEmploymentContract employmentId={match.params.employmentId} />
          </TopBar>
        )}
      />
    </>
  );
};
