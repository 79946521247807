import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useObservable } from 'react-use-observable';
import { EmployeeAttributes } from '../../../apis/attributes';
import social from '../../../apis/social';
import InfoModal from '../../Facility/containers/InfoModal';

const PersonalSettings: React.FC<{ userId: string | undefined }> = ({ userId }) => {
  const { t } = useTranslation();

  const [employee] = useObservable<EmployeeAttributes | null>(
    () =>
      social
        .service('employee')
        .watch()
        .get(userId as string) as any,
    [userId],
  );

  // update employee boolean adminCanEdit in the database
  const updateAdminCanEdit = async (canEdit: boolean) => {
    await social.service('employee').patch(employee?.id as string, {
      adminCanEdit: canEdit,
    });
  };

  return (
    <Container className='pt-5 px-4 pb-4'>
      <div style={{ height: '20px' }} />

      <Row>
        <Col xs={12} md={10} lg={8}>
          <h2 className='text-primary'>{t('PERSONAL_SETTINGS')}</h2>

          {/* Display first and last name */}
          <h5 className='mt-2 '>
            {employee?.firstName} {employee?.lastName}
          </h5>

          <hr style={{ backgroundColor: 'white', border: 'none', height: '8px' }} />

          <h5 className='mt-5'>
            {t('AUTHORIZE_ADMIN') as string}{' '}
            <strong>
              <InfoModal content={t('AUTHORIZE_ADMIN_INFO') as string} />
            </strong>{' '}
          </h5>

          <Row className='mt-3'>
            <Col xs={6}>
              <label htmlFor='settings-switch'>{t('AUTHORIZE_ADMIN')}</label>
            </Col>
            <Col xs={6}>
              <Form.Switch
                type='switch'
                id='custom-switch'
                name='pauseDeductionActive'
                checked={employee?.adminCanEdit || false}
                onChange={(val) => {
                  updateAdminCanEdit(!employee?.adminCanEdit);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PersonalSettings;
