import React from 'react';

import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { Link } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { FacilityAttributes } from '../../../apis/attributes';
import social, { authenticatedFacility } from '../../../apis/social';
import FacilityCard from '../../EmployeeCreation/container/FacilityCard';
import { FacilityYearlyLogic } from './FacilityYearlyLogic';

export const FurnishingDetails: React.FC = () => {
  const { t } = useTranslation();
  const { value } = usePromise(authenticatedFacility());
  const [facility] = useObservable<FacilityAttributes | null>(
    () =>
      social
        .service('facility')
        .watch()
        .get((value as any)?.user?.facility?.id as any) as any,
    [value],
  );

  return (
    <Container className='pt-3'>
      <h4 className='mt-5 mb-5'> {t('SETUP_DATA_OVERVIEW') as string}</h4>

      <p className='text-dark'>
        <Link className='text-dark' to='/admin/onboarding/country'>
          <FacilityCard facility={facility} />
        </Link>
      </p>
      <FacilityYearlyLogic facilityId={facility?.id || ''} />
    </Container>
  );
};

export default FurnishingDetails;
