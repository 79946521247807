import React from 'react';
import { Button, Image } from 'react-bootstrap';
import ger from '../images/germany.png';
import bri from '../images/britain.png';
import i18n from '../../../apis/i18n';

export const SwitchLanguage: React.FC = () => {
  return (
    <>
      <Button
        variant='light'
        onClick={() => {
          localStorage.setItem('lan', 'en');
          i18n.changeLanguage('en');
        }}
      >
        <Image src={bri} width='30px' height='30px' />
      </Button>

      <Button
        variant='light'
        onClick={() => {
          localStorage.setItem('lan', 'ger');
          i18n.changeLanguage('ger');
        }}
      >
        <Image src={ger} width='30px' height='30px' />
      </Button>
    </>
  );
};

export default SwitchLanguage;
