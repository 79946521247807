import React, {Suspense, useMemo} from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import OrgName from './containers/OrgName';
import Submit from './containers/Submit';
import TopBar from '../Layout/containers/TopBar';
import { FacilityAttributes } from '../../apis/attributes';
import { authenticatedFacility } from '../../apis/social';

const Country = React.lazy(() => import('./containers/Country'));

export default () => {
  const { t } = useTranslation();
  const { value } = usePromise(authenticatedFacility());

  const backToLink = useMemo(() => {
    if (((value as any)?.user?.facility as FacilityAttributes)?.country)
      return '/admin/furnishing-details';
    return '/admin/onboarding/country';
  }, [(value as any)?.user?.facility]);

  return (
    <>
      <Route
        exact
        path='/admin/onboarding/country'
        render={() => (
          <TopBar
            progress={33}
            title={t('COUNTRY_SELECTION') as string}
            navigateBackTo={backToLink}
          >
            <Suspense fallback={<div>Creating tables @KiDig for new facility...</div>}> {''}
            <Country />
            </Suspense>
          </TopBar>
        )}
      />
      <Route
        exact
        path='/admin/onboarding/org-name'
        render={() => (
          <TopBar
            progress={66}
            title={t('ORG_NAME') as string}
            navigateBackTo='/admin/onboarding/country'
          >
            <OrgName />
          </TopBar>
        )}
      />
      <Route
        exact
        path='/admin/onboarding/submit'
        render={() => (
          <TopBar
            progress={100}
            title={t('REVIEW') as string}
            navigateBackTo='/admin/onboarding/org-name'
          >
            <Submit />
          </TopBar>
        )}
      />
    </>
  );
};
