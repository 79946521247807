import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { useLocation, useParams } from 'react-router';
import { useAsyncFn } from 'react-use';
import social, { authenticatedEmployee } from '../../../apis/social';
import { calculatePauseStat, calculateStat, formatDuration } from '../../../utility/time';
import TopInlineOverviewSection from './TopInlineOverviewSection';

export const daysStrings = (t: any) => [
  t('Sunday'.toUpperCase()),
  t('Monday'.toUpperCase()),
  t('Tuesday'.toUpperCase()),
  t('Wednesday'.toUpperCase()),
  t('Thursday'.toUpperCase()),
  t('Friday'.toUpperCase()),
  t('Saturday'.toUpperCase()),
];

export const options = (t: any) => [
  { label: t('Sunday'.toUpperCase()), value: 0 },
  { label: t('Monday'.toUpperCase()), value: 1 },
  { label: t('Tuesday'.toUpperCase()), value: 2 },
  { label: t('Wednesday'.toUpperCase()), value: 3 },
  { label: t('Thursday'.toUpperCase()), value: 4 },
  { label: t('Friday'.toUpperCase()), value: 5 },
  { label: t('Saturday'.toUpperCase()), value: 6 },
];

export const WeekView: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dd = query.get('dd');

  const employeeId = useParams<{ employeeId: string }>().employeeId;

  const [weekDate, setWeekDate] = useState(dd ? new Date(dd) : new Date());

  const [, setWeekOfMonth] = useState<number | undefined>(
    parseInt(moment(dd ? new Date(dd) : new Date())?.format('WW'), 10),
  );
  const [, setMonthOfYear] = useState<number | undefined>(
    moment(dd ? new Date(dd) : new Date())?.month(),
  );
  const [, setYear] = useState<number | undefined>(moment(dd ? new Date(dd) : new Date())?.year());

  const { value } = usePromise(authenticatedEmployee());

  const [weekEmployeeStatistics, retryWeekEmployment] = useAsyncFn(async () => {
    const res: any = (await (social.service('employee') as any).patch(
      (value as any)?.user?.employee?.id,
      {
        employeeId,
        endDate: moment(weekDate).endOf('week').toISOString(),
        startDate: moment(weekDate).startOf('week').toISOString(),
        statistics: true,
        buildMonthReport: false,
        buildYearReport: false,
      },
    )) as any;
    return res as any;
  }, [value, (value as any)?.user, weekDate?.toString()]);
  useEffect(() => {
    retryWeekEmployment();
  }, [value, (value as any)?.user, (value as any)?.user?.employee?.id, weekDate?.toString()]);

  const updateWeekDate = (date: Date) => {
    setWeekDate(date);
    const moDate = moment(date);
    setWeekOfMonth(parseInt(moDate?.format('WW'), 10));
    setMonthOfYear(moDate?.month());
    setYear(moDate?.year());
  };
  const cal = useRef();

  return (
    <Container className='pb-5'>
      <div style={{ height: '40px' }} />

      <Row>
        <Col xs={4} className='d-flex flex-column justify-content-center'>
          <input
            ref={cal as any}
            type='week'
            name='week'
            id='camp-week'
            className='weekDatePicker mt-3'
            value={`${moment(weekDate).format('YYYY') as string}-W${
              moment(weekDate).format('WW') as string
            }`}
            required
            onChange={(e) => {
              updateWeekDate(moment(e.currentTarget?.value)?.toDate());
              e.preventDefault();
            }}
          />
        </Col>
        <Col xs={8} />
        {/* <Col xs={2} className="d-flex flex-column justify-content-center">
          <DuplicateWeekRange sourceWeekDB={(week as any)?.value?.data?.length && (week as any)?.value?.data[0]} sourceWeek={weekOfMonth ?? -1} />
        </Col> */}
      </Row>

      <hr
        className='my-3'
        style={{
          backgroundColor: 'white',
          border: 'none',
          height: '8px',
        }}
      />
      {weekEmployeeStatistics ? (
        <TopInlineOverviewSection
          totalTimeVal={calculateStat(weekEmployeeStatistics?.value?.targetTime)}
          timeWorkedVal={calculateStat(
            weekEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
          )}
          balanceVal={calculateStat(weekEmployeeStatistics?.value?.balance)}
          overtimeVal={calculatePauseStat(weekEmployeeStatistics?.value?.actualTime)}
          breakVal={1}
        />
      ) : (
        <TopInlineOverviewSection
          totalTimeVal=''
          timeWorkedVal=''
          balanceVal=''
          overtimeVal=''
          breakVal={1}
        />
      )}

      <div style={{ height: '10px' }} />

      <hr
        className='mt-5 mb-4'
        style={{
          backgroundColor: 'white',
          border: 'none',
          height: '8px',
        }}
      />

      <p className='mb-3' style={{ fontWeight: 'bold' }}>
        {' '}
        {t('DETAILED_TIME_STATISTICS') as string}{' '}
      </p>
      <hr
        className='mt-5 mb-4'
        style={{
          backgroundColor: 'white',
          border: 'none',
          height: '8px',
        }}
      />

      <Row className=''>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TARGET_TIME_CARE') as string}</p>
            <strong>
              {formatDuration(weekEmployeeStatistics?.value?.targetTime?.careTimePart)}h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('ACTUAL_TIME_CARE') as string}</p>
            <strong>
              {formatDuration(
                weekEmployeeStatistics?.value?.actualTime?.actualTimeAccepted?.careTimePart,
              )}
              h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE_CARE') as string}</p>
            <strong>{formatDuration(weekEmployeeStatistics?.value?.balance?.careTimePart)}h</strong>
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TARGET_TIME_DISPOSAL') as string}</p>
            <strong>
              {formatDuration(weekEmployeeStatistics?.value?.targetTime?.disposalTimeRegularPart)}h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('ACTUAL_TIME_DISPOSAL') as string}</p>
            <strong>
              {formatDuration(
                weekEmployeeStatistics?.value?.actualTime?.actualTimeAccepted
                  ?.disposalTimeRegularPart,
              )}
              h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE_DISPOSAL') as string}</p>
            <strong>
              {formatDuration(weekEmployeeStatistics?.value?.balance?.disposalTimeRegularPart)}h
            </strong>
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('TARGET_TIME_IRREGULAR') as string}</p>
            <strong>
              {formatDuration(weekEmployeeStatistics?.value?.targetTime?.disposalTimeIrregularPart)}
              h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('ACTUAL_TIME_IRREGULAR') as string}</p>
            <strong>
              {formatDuration(
                weekEmployeeStatistics?.value?.actualTime?.actualTimeAccepted
                  ?.disposalTimeIrregularPart,
              )}
              h
            </strong>
          </div>
        </Col>

        <Col xs={4}>
          <div className='h-100 w-100'>
            <p className='mb-1 text-muted'>{t('BALANCE_IRREGULAR') as string}</p>
            <strong>
              {formatDuration(weekEmployeeStatistics?.value?.balance?.disposalTimeIrregularPart)}h
            </strong>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WeekView;
