import { observer } from 'mobx-react';
import { useLocalObservable } from 'mobx-react-lite';
import { fromPromise } from 'mobx-utils';
import React, { useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { social, Pending } from '../../../apis/social';
import { EmployeeProps } from './CreateEmployee';

export const Availability: React.FC<EmployeeProps> = observer(({ employeeId }) => {
  const { t } = useTranslation();
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [localTotalTimeAvailable, setLocalTotalTimeAvailable] = useState<number | undefined>(
    undefined,
  );
  const [localSpecialDispoTime, setLocalSpecialDispoTime] = useState<number | undefined>(undefined);

  const history = useHistory();

  const store = useLocalObservable(() => ({
    get facility() {
      return fromPromise(social.service('employee').get(employeeId));
    },
  }));

  const navigateTo = useCallback((pathName: string) => {
    history.push({
      pathname: generatePath(`${pathName}/:employeeId`, { employeeId }),
    });
  }, []);

  const updateDB = async () => {
    setEnableSubmit(false);

    try {
      // await social.service('employee').patch(employeeId,
      // { monthlyAvailabilityHours: localTotalTimeAvailable!,
      // spcialDispositionHours: localSpecialDispoTime! });

      navigateTo('/admin/employee/email');
    } catch (error) {
      setEnableSubmit(true);

      alert('Error: please reload the page');
    }
  };

  return (
    <Container className='pt-5 px-4 pb-4'>
      {store.facility.case({
        fulfilled: () => (
          <>
            {!localTotalTimeAvailable && setLocalTotalTimeAvailable(0)}
            {!localSpecialDispoTime && setLocalSpecialDispoTime(0)}

            <p className='mt-5 mb-1'>
              <strong>{t('TOTAL_TIME_AVAILABLE') as string}</strong>
            </p>
            <input
              className='w-100 p-3 border'
              placeholder=''
              defaultValue={localTotalTimeAvailable || ''}
              onChange={(e) => setLocalTotalTimeAvailable(parseInt(e.currentTarget.value, 10))}
            />

            <p className='mt-5 mb-1'>
              <strong>{t('SPECIAL_DISPOSITION_TIME') as string}</strong>
            </p>
            <input
              className=' w-100 p-3 border'
              placeholder=''
              defaultValue={localSpecialDispoTime || ''}
              onChange={(e) => setLocalSpecialDispoTime(parseInt(e.currentTarget.value, 10))}
            />
          </>
        ),
        pending: () => <Pending />,
        // rejected: () => <Redirect to="/post/general/" />,
      })}

      <Button
        className='py-3 px-3 w-100'
        style={{ marginTop: '200px' }}
        variant='primary'
        disabled={!enableSubmit || !localTotalTimeAvailable || !localSpecialDispoTime}
        onClick={() => updateDB()}
      >
        {t('SAVE') as string}
      </Button>
    </Container>
  );
});

export default Availability;
